import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { ModalPaper } from 'Components/Styles/Modals/ModalContainers';

import BaseModal from '../BaseModal';
import { Page } from 'Constants/constants';
import { ReactComponent as CheckmarkIcon } from 'resources/images/icons/partialOutlinedCheckIcon.svg';
import { useHistory } from 'react-router';
import { FixedSizeIcon } from 'Components/Styles/Modals/Questionnaire';
import { TitleText, ModalButton } from 'Components/Styles/Modals/Components';
import { resetContents } from 'redux/reducers/questionnaires/actions';

const t = _ => {};
t('questionnaire.completedBaselineTitle');
t('questionnaire.completedEligibilityTitle');

export default function QuestionnaireCompleteModal({open, title, isBaseline, studyId}) {
    const {t} = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const translatedTitle = t(title);

    function handleConfirm() {
        if (isBaseline) {
            history.push(Page.EXPLORE);
        } else {
            history.push(`/study/${studyId}`);
        }
        dispatch(resetContents);
    }

    return (
        <BaseModal
            aria-describedby="baseline-questionnaire-complete-modal-description"
            aria-labelledby="baseline-questionnaire-complete-modal-title"
            onClose={handleConfirm}
            open={open}
        >
            <ModalPaper>
                <Grid container direction="column" alignItems="center" spacing={2}>
                    <Grid item>
                        <FixedSizeIcon component={CheckmarkIcon} color="primary" />
                    </Grid>
                    <Grid item>
                        <Trans i18nKey={isBaseline ? 'questionnaire.completedBaselineTitle' : 'questionnaire.completedEligibilityTitle'} t={t} values={{title: translatedTitle}}>
                            <TitleText>Thank you for completing the </TitleText>
                            <TitleText color="primary" style={{fontStyle: 'italic'}}>{{translatedTitle}}</TitleText>
                        </Trans>
                    </Grid>
                    <Grid item>
                        <Trans i18nKey='questionnaire.completedBaselineDescription' t={t}>
                            <Typography variant="body2" color="textSecondary" paragraph align="center">Your answers have been saved and you can find them under the settings section </Typography>
                            <Typography variant="body2" color="primary" align="center">Profile &amp; Consent</Typography>
                        </Trans>    
                        {!isBaseline && <Typography variant="body2" color="textSecondary" align="center">{t('questionnaire.completedEligibilityDescription')}</Typography>}
                    </Grid>
                    <ModalButton variant="contained" color="primary" size="small" onClick={handleConfirm}>{t('questionnaire.closeAndContinue')}</ModalButton>
                </Grid>
            </ModalPaper>
        </BaseModal>
    );
}