import React from 'react';
import styled from 'styled-components';
import { GOOGLE_API_KEY } from 'Constants/constants';

const StaticImageContainer = styled.div`
    display: block;
    background-image: url(${props => props.url});
    background-repeat: no-repeat;
    border-radius: ${props => props.radius && '16px'};
    line-height: 0;
    width: 640px;
    height: ${props => props.height}px;
`;

function GoogleMapsStaticImage({location, widthRatio, radius, height}) {
    const imageUrl=`https://maps.googleapis.com/maps/api/staticmap?zoom=15&maptype=roadmap&size=640x${height}&markers=color:red%7C${location.lat},${location.lon}&key=${GOOGLE_API_KEY}`;
    const linkUrl=`https://www.google.com/maps/search/?api=1&query=${location.lat},${location.lon}&zoom=15`;

    return (
        <StaticImageContainer url={imageUrl} widthRatio={widthRatio} onClick={() => window.open(linkUrl)} height={height} radius={radius}/>
    );
}

GoogleMapsStaticImage.defaultProps = {
    height: 190
};

export default GoogleMapsStaticImage;