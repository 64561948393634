import React, { useState, useEffect } from 'react';
import { Grid, CircularProgress, useMediaQuery } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useTheme } from '@material-ui/core/styles';
import InfiniteScroll from 'react-infinite-scroll-component';
import styled from 'styled-components';
import StudyCard from 'Components/Parts/Dashboard/StudyCard/StudyCard';
import { useDispatch, useSelector } from 'react-redux';
import { getResearchStudyList, getNextUrl, getMyStudiesList } from 'redux/reducers/dashboard/actions';
import FilterMenu from 'Components/Parts/Dashboard/StudyActions/FilterMenu';
import SortMenu from 'Components/Parts/Dashboard/StudyActions/SortMenu';
import { useLocation } from 'react-router';
import { UserType } from 'Constants/constants';
import { PagesWithSortMenu } from 'Components/Parts/Dashboard/Navigation/DashboardNavbar';

const BottomPaddedDiv = styled.div`
    padding-bottom: 16px;
`;

const CustomInfiniteScroll = styled(InfiniteScroll)`
    overflow: hidden !important;
`;

export default function StudyDisplay({ status, emptyComponent, isPublic, showFilters = true }) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const dispatch = useDispatch();
    const fhirId = useSelector(state => state.authorization.fhirId);
    const loading = useSelector(state => state.serverCommunication.loading);
    const location = useLocation();
    const accountType = useSelector(state => state.authorization.accountType);
    const { sort, filters, filterEnglish, filterFrench } = useSelector(state => state.dashboard.filters[location.pathname]) ?? {};
    const [nextUrl, setNextUrl] = React.useState();
    const [studies, setStudies] = React.useState([]);

    React.useEffect(() => {
        let mounted = true;
        if (accountType === UserType.PATIENT && location.pathname === "/dashboard") {
            dispatch(getMyStudiesList(data => handleResponse(data, true)));
        } else {
            const limit = undefined;
            dispatch(getResearchStudyList(fhirId, status, sort, filters, filterEnglish, filterFrench, limit, data => {
                if (mounted) {
                    handleResponse(data, true);
                }
            }));
        }

        return _ => mounted = false;
    }, [status, accountType, sort, filters, filterEnglish, filterFrench])

    function handleResponse(data, replace) {
        if (data.link) {
            const next = data.link.find(l => l.relation === "next");
            setNextUrl(next ? next.url : null);
        }
        const newStudies = data.entry ? data.entry : [];
        if (replace) {
            setStudies(newStudies);
        } else {
            setStudies(studies.concat(newStudies));
        }
    }

    function fetchNextPage() {
        if (!loading) {
            dispatch(getNextUrl(nextUrl, data => handleResponse(data, false)));
        }
    }

    return (
        <React.Fragment>
            <BottomPaddedDiv>
                <Grid item container spacing={2}>
                    {showFilters && !isMobile && PagesWithSortMenu.includes(location.pathname) && (
                        <Grid container justify="space-between">
                            <Grid item>
                                <FilterMenu/>
                            </Grid>
                            <Grid item>
                                <SortMenu/>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </BottomPaddedDiv>
            {studies.length > 0 || loading
                ? <CustomInfiniteScroll
                        dataLength={studies.length}
                        next={fetchNextPage}
                        hasMore={!!nextUrl}
                        loader={<Grid container justify="center">
                            <CircularProgress color="primary" />
                        </Grid>}
                    >
                        <Grid container spacing={1}>
                            {loading 
                            ? Array.from({length: 12}, (x, i) => (<Grid item xs={6} md={3} key={i}>
                                    <Skeleton variant="rect" height={199} width="100%" style={{borderRadius: '8px'}}/>
                                </Grid>))
                            : studies.map((study, i) => <StudyDisplayItem key={i} study={study} isPublic={isPublic}/>)}
                        </Grid>
                    </CustomInfiniteScroll>
                : emptyComponent
            }
        </React.Fragment>
    );
}


function StudyDisplayItem({study, isPublic}) {
    const { accountType, lastLoggedInAt } = useSelector(state => state.authorization);
    const { title, status, extension } = study.resource;
    const studyExtension = extension.find(ext => ext.url === "https://app.connect1d.ca/ext/relatedstudy");
    const studyId = studyExtension ? studyExtension.valueInteger : undefined;
    const [studyDate, setStudyDate] = useState();

    useEffect(() => {
        setStudyDate(new Date(study.resource.period ? study.resource.period.start : study.resource.meta.lastUpdated));
    }, [study]);
    
    return (
        <Grid item xs={6} md={3}>
            <StudyCard
                id={studyId}
                title={title}
                status={status}
                showStatusTag={!isPublic && accountType === UserType.RESEARCHER}
                isNew={studyDate > lastLoggedInAt}
                date={studyDate}
            />
        </Grid> 
    );
}
