import produce from "immer";
import * as Actions from "./actions";

const initialState = {
    id: "",
    title: "",
    activeResponseId: "",
    exitRequestActive: false,
    questions: [],
    valueSets: {},
    currentQuestion: 0,
    totalQuestions: 0,
};

export default (state = initialState, action) =>
    produce(state, draft => {
        const { type, payload } = action;
        switch(type) {
            case Actions.SET_CONTENTS:
                draft.id = payload.id;
                draft.title = payload.title;
                draft.questions = payload.questions;
                draft.totalQuestions = payload.questions.length;
                break;
            case Actions.RESET_CONTENTS:
                draft.id = "";
                draft.title = "";
                draft.activeResponseId = "";
                draft.questions = [];
                draft.totalQuestions = 0;
                draft.currentQuestion = 0;
                break;
            case Actions.SET_QUESTION:
                draft.currentQuestion = payload.questionNumber;
                break;
            case Actions.ADD_VALUE_SET:
                draft.valueSets[payload.id] = payload.valueSet.concept?.map( valueSet => ({
                    id: valueSet.code,
                    label: valueSet.display,
                    system: valueSet.system
                }));
                break;
            case Actions.SET_QUESTIONNAIRE_REPONSE_ID:
                draft.activeResponseId = payload.id;
                break;
            case Actions.TOGGLE_EXIT_REQUEST_ACTIVE:
                draft.exitRequestActive = !draft.exitRequestActive;
                break;
            default:
                return draft;
        }
    });
            