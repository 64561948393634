import React from 'react';
import styled from 'styled-components';
import { Grid, SvgIcon, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useField } from 'formik';

import { ReactComponent as EmailOutlinedIcon} from 'resources/icons/emailOutlineIcon.svg';
import { resendActivation } from 'redux/reducers/accountCreation/actions';
import { ModalButton } from 'Components/Styles/Modals/Components';

const TitleText = styled(Typography)`
    font-family: Varela Round;
    text-align: center;
    padding-bottom: 1rem;
`;

const DescriptionBox = styled.div`
    min-height: 200px;
`;

export default function AccountCreationSuccess() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [field] = useField('email');

    const handleResend = () => {
        dispatch(resendActivation(field.value));
    };

    return (
        <Grid container justify="center">
            <Grid item>
                <SvgIcon component={EmailOutlinedIcon} color="primary" style={{ fontSize: '88px' }} viewBox="0 0 88 88"/>
            </Grid>
            <Grid item xs={12}>
                <TitleText variant="h4">{t('accountCreationSuccess.title')}</TitleText>
            </Grid>
            <Grid item>
                <DescriptionBox>
                    <Typography variant="body2" color="textSecondary" align="center">{t('accountCreationSuccess.descriptionPart1')}</Typography><br/>
                    <Typography variant="body2" color="textSecondary" align="center">{t('accountCreationSuccess.descriptionPart2')}</Typography>
                </DescriptionBox>
            </Grid>
            <Grid item sm={8} xs={12}>
                <ModalButton size="small" variant="outlined" color="primary" onClick={handleResend} fullWidth>{t('accountCreationSuccess.resend')}</ModalButton>
            </Grid>
        </Grid>
    );
}