import React from 'react'
import styled from 'styled-components';
import { Container } from '@material-ui/core';


const ContainerWrapper = styled.section`
    padding: 7rem 0;
    position: relative;

    @media (min-width: 960px) {
        padding: 11.5rem 0;
    }
`;

const RelativeContainer = styled(Container)`
    position: relative;
`;

const Content = styled.div`
    padding: 0 1rem;
    position: relative;
    z-index: 1;

    @media (min-width: 600) {
        padding: 0 2.75rem;
    }

    @media (min-width: 960px) {
        padding: 0 6rem;
    }

    @media (min-width: 1280px) {
        padding: 0 10rem;
    }

    @media (min-width: 1920px) {
        padding: 0 11rem;
    }
`;

export default function Section({ className, sectionStyle, children, wrapperContent }) {
    return (
        <ContainerWrapper className={className} style={sectionStyle}>
            <RelativeContainer maxWidth="xl" disableGutters>
                {wrapperContent}
                <Content className="section-content">
                    {children}
                </Content>
            </RelativeContainer>
        </ContainerWrapper>
    )
};
