import React, { useState } from 'react';
import { Field } from 'formik';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import PasswordTextBox from 'Components/Parts/Form/TextBoxes/PasswordTextBox';
import PasswordValidation from 'Components/Parts/Form/PasswordValidation';


export default function NewPasswordFields() {
    const { t } = useTranslation();
    const [passwordFocus, setPasswordFocus] = useState(false);

    return (
        <React.Fragment>
            <Grid container item spacing={0}>
                <Grid item xs={12}>
                    <Field 
                        name="newPassword" 
                        label={t('resetPassword.newPasswordLabel')}
                        component={PasswordTextBox}
                        handleFocus={() => setPasswordFocus(true)}
                        handleBlur={() => setPasswordFocus(false)}
                    />
                </Grid>
                {passwordFocus && <Grid item>
                    <PasswordValidation name="newPassword"/>
                </Grid>}
            </Grid>
            <Grid item xs={12}>
                <Field
                    name="confirmPassword" 
                    label={t('resetPassword.confirmPasswordLabel')}
                    component={PasswordTextBox}
                />
            </Grid>
        </React.Fragment>
    );
}