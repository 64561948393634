import produce from "immer";
import * as Actions from "./actions";

const initialState = {
    eligibilityCategories: []
};

export default (state = initialState, action) =>
    produce(state, draft => {
        const {type, payload} = action;
        switch(type) {
            case Actions.ELIGIBILITY_TOGGLE: {
                const {category} = payload;
                const index = draft.eligibilityCategories.indexOf(category);
                if (index < 0 ) {
                    draft.eligibilityCategories.push(category);
                } else {
                    draft.eligibilityCategories.splice(index, 1);
                }
                break;
            }
            case Actions.UPDATE_ELIGIBILITY_TRACKING: {
                const {category, add} = payload;
                const index = draft.eligibilityCategories.indexOf(category);
                if (add && index === -1) {
                    draft.eligibilityCategories.push(category);
                } else if (!add && index !== -1) {
                    draft.eligibilityCategories.splice(index, 1);
                }
                break;
            }
            case Actions.RESET_ELIGIBILITY_TRACKING:
                draft.eligibilityCategories = [];
                break;
            default:
                return draft;
        }
    });