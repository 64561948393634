import React from 'react';
import { Grid } from '@material-ui/core';
import styled from 'styled-components';


const Page = styled(Grid)`
    height: 100%;
`;

function MobileModalPage({ children }) {
    return (
        <Page container direction="column" justify="space-between" wrap="nowrap">
            {children}
        </Page>
    );
}

export default MobileModalPage;
