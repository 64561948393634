import React from 'react';
import styled from 'styled-components';
import { useField } from 'formik';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const PhraseTypography = styled(Typography)`
    font-family: Inter;
    font-size: 26px;
    line-height: 1.73;
    color: #000000;
`;

export default function EligibilityCriteriaPhrase({name, criteria, getProperties, shareTranslationId, shouldShow}) {
    const { t } = useTranslation();
    const [field] = useField(name);
    const value = field.value;
    const inclusionId = value.inclusion ? value.inclusion.id : undefined;

    const properties = getProperties ? getProperties(value) : {};
    const optionId = shareTranslationId ? 'option' : value.answer.id;
    const translationKey = `studyEligibilityComparitivePhrase.${criteria}.${inclusionId}.${optionId}`;

    const text = t(translationKey, properties)
        .replace(/<b>/g, '<span style="font-weight:600;">')
        .replace(/<\/b>/g, '</span>');

    return (
        typeof shouldShow !== 'function' || shouldShow({ inclusionId })
            ? <PhraseTypography display="inline" dangerouslySetInnerHTML={{__html: text}} />
            : null
    );
}
