import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Paper, Tab, Tabs, SvgIcon, useMediaQuery, Badge } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ReactComponent as BookOutlineIcon } from 'resources/images/icons/tabBar/bookOutline.svg';
import { ReactComponent as CompassOutlineIcon } from 'resources/images/icons/tabBar/compassOutline.svg';
import { ReactComponent as LighbulbOnOutlineIcon } from 'resources/images/icons/tabBar/lighbulbOnOutline.svg';
import { ReactComponent as SettingsOutlineIcon } from 'resources/images/icons/tabBar/settingsOutline.svg';
import { Page } from 'Constants/constants';
import getNewStudyCount from 'api/fhir/getNewStudyCount';


const MAX_STUDY_COUNT = 20;

const ScreenSize = {
    DESKTOP: 1,
    MOBILE: 2,
};

const TabBar = styled(Paper)`
    bottom: 0;
    left: 0;
    position: fixed;
    width: 100%;
    z-index: 10;
    background: ${props => props.theme.palette.background.default};

    @media (min-width: 960px) {
        bottom: auto;
        left: auto;
        margin-bottom: 60px;
        position: static;
        width: auto;
    }
`;

const StyledTab = styled(Tab)`
    font-size: 10px;
    overflow: visible;
    text-transform: none;

    @media (min-width: 960px) {
        font-size: 16px;
        margin-right: 60px;
        min-width: 0;
        padding: 0;

        &.Mui-selected {
            font-weight: 700;
        }
    }
`;

const TabContent = styled.div`
    height: 100%;
    position: relative;
    width: 100%;
`;

const StyledBadge = styled(Badge)`
    position: absolute;
    right: calc(50% - 7px);
    top: 5px;

    @media (min-width: 960px) {
        right: -6px;
        top: 4px
    }

    .MuiBadge-badge {
        background-color: #f10ebc;
        color: #fff;

        @media (max-width: 959px) {
            height: 8px;
            min-width: 8px;
            padding: 0;

            .countBadge {
                display: none;
            }
        }
    }
`;

const TabId = {
    MY_STUDIES: 'MY_STUDIES',
    EXPLORE: 'EXPLORE',
    FINDINGS: 'FINDINGS',
    SETTINGS: 'SETTINGS',
};

const tabs = [{
    id: TabId.MY_STUDIES,
    icon: <SvgIcon component={BookOutlineIcon} />,
    getText: t => t("tabBar.myStudies"),
    link: Page.DASHBOARD,
    matchesPath: pathname => pathname === Page.DASHBOARD,
}, {
    id: TabId.EXPLORE,
    icon: <SvgIcon component={CompassOutlineIcon} />,
    getText: t => t("tabBar.explore"),
    link: Page.EXPLORE,
    matchesPath: pathname => pathname === Page.EXPLORE,
}, {
    id: TabId.FINDINGS,
    icon: <SvgIcon component={LighbulbOnOutlineIcon} />,
    getText: t => t("tabBar.findings"),
    link: Page.FINDINGS,
    matchesPath: pathname => pathname === Page.FINDINGS,
}, {
    id: TabId.SETTINGS,
    icon: <SvgIcon component={SettingsOutlineIcon} />,
    getText: t => t("tabBar.settings"),
    link: Page.SETTINGS,
    matchesPath: pathname => pathname.startsWith(Page.SETTINGS),
}];

function getCurrentTabIndex(pathname) {
    const tabIndex = tabs.findIndex(tab => tab.matchesPath(pathname));
    return tabIndex !== -1 ? tabIndex : 0;
}

function CountBadge({ count }) {
    if (!count) {
        return null;
    }

    const countDisplay = count > MAX_STUDY_COUNT 
        ? `${MAX_STUDY_COUNT}+`
        : String(count);

    return (
        <StyledBadge badgeContent={<span className="countBadge">{countDisplay}</span>} />
    );
}

function DashboardTabBar() {
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const screenSize = isMobile ? ScreenSize.MOBILE : ScreenSize.DESKTOP;
    const [ tabIndex, setTabIndex ] = useState(getCurrentTabIndex(location.pathname));
    const { lastLoggedInAt, authorized } = useSelector(state => state.authorization);
    const [tabIndicatorCount, setTabIndicatorCount] = useState({
        [TabId.MY_STUDIES]: 0,
        [TabId.EXPLORE]: 0,
        [TabId.FINDINGS]: 0,
        [TabId.SETTINGS]: 0,
    });

    useEffect(() => {
        let mounted = true;
        if (!tabIndicatorCount[TabId.EXPLORE] && authorized && mounted) {
            getNewStudyCount(lastLoggedInAt)
            .then(function ({ data }) {
                if (mounted) {
                    setTabIndicatorCount({...tabIndicatorCount,
                        [TabId.EXPLORE]: data.total,
                    });
                }
            });
        }

        return () => mounted = false;
    }, [lastLoggedInAt]);
    
    useEffect(() => {
        setTabIndex(getCurrentTabIndex(location.pathname));
    }, [location]);

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
        history.push(tabs[newValue].link);
    };

    return (
        <TabBar square elevation={isMobile ? 1 : 0}>
            <Tabs
                value={tabIndex}
                onChange={handleTabChange}
                variant={isMobile ? "fullWidth" : "standard"}
                indicatorColor="primary"
                textColor="primary"
            >
                {tabs
                    .filter(({ visibleIn }) => !visibleIn || screenSize & visibleIn)
                    .map(({ icon, getText, id }) => 
                        <StyledTab
                            icon={
                                <TabContent>
                                    <CountBadge count={tabIndicatorCount[id]} />
                                    {isMobile ? icon : null}
                                </TabContent>
                            }
                            label={getText(t)}
                            key={id}
                        />
                    )
                }
            </Tabs>
        </TabBar>
    );
}

export default DashboardTabBar;
