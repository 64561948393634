import React from 'react';
import { useField } from 'formik';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { containsLowercaseValidator,
    containsUppercaseValidator,
    containsNonAlphaValidator,
    minimumLengthValidator } from './Validators';
import { SuccessText, FailureText } from './Validators/ValidationText';

const validationSchema = Object.freeze([
    {
        validator: containsLowercaseValidator,
        getMessage: t => t("passwordValidation.requireLowerCase"),
    },
    {
        validator: containsUppercaseValidator,
        getMessage: t => t("passwordValidation.requireUpperCase"),
    },
    {
        validator: containsNonAlphaValidator,
        getMessage: t => t("passwordValidation.requireNumberAndSymbol"),
    },
    {
        validator: (password) => minimumLengthValidator(password, 8),
        getMessage: t => t("passwordValidation.requireMinLength"),
    },
]);

export function getValidationResult(password) {
    const hasLower = containsLowercaseValidator(password);
    const hasUpper = containsUppercaseValidator(password)
    const hasNonAlpha = containsNonAlphaValidator(password)
    const isMinimumLength = minimumLengthValidator(password, 8);
    return hasLower && hasUpper && hasNonAlpha && isMinimumLength;
}

function ValidationResultDisplay({password, validator, message}) {
    if (validator(password)) {
        return <SuccessText message={message}/>;
    } else {
        return <FailureText message={message}/>;
    }
}

export default function PasswordValidation(props) {
    const { t } = useTranslation();
    const [field] = useField(props);
    return (
        <Grid>
            {validationSchema.map(({validator, getMessage}, idx) => {
                return <ValidationResultDisplay password={field.value} validator={validator} message={getMessage(t)} key={idx} />;
            })}
        </Grid>
    );
}