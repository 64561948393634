import React from 'react';
import styled, { css } from 'styled-components';
import {
    Container,
    Grid,
    Typography,
} from '@material-ui/core';

import { RoundedButton } from 'Components/Styles/Buttons/RoundedButton';


const EligibilityBarWrapper = styled.div`
    margin-bottom: 24px;
    padding: 24px 0;
    text-align: center;

    @media (min-width: 960px) {
        padding: 14px 0;
        text-align: left;
    }

    ${props => props.color === 'primary' && css`
        background: #dfe6fb;

        svg {
            color: ${props => props.theme.palette.primary.main};
        }

        button:not(.inherit),
        button:not(.inherit):hover {
            background: ${props => props.theme.palette.primary.main};
            color: #fff;

            svg {
                color: #fff;
            }
        }
    `}

    ${props => props.color === 'secondary' && css`
        background: #d6f4f9;

        svg {
            color: rgb(25, 104, 118);
        }

        button:not(.inherit),
        button:not(.inherit):hover {
            background: ${props => props.theme.palette.secondary.main};
            color: #fff;

            svg {
                color: #fff;
            }
        }
    `}

    ${props => props.color === 'warning' && css`
        background: #fee7f9;

        svg {
            color: ${props => props.theme.palette.error.main};
        }

        button:not(.inherit),
        button:not(.inherit):hover {
            background: ${props => props.theme.palette.error.main};
            color: #fff;

            svg {
                color: #fff;
            }
        }
    `}
`;

const ImageSpacer = styled.div`
    @media (min-width: 960px) {
        flex-grow: 0;
        flex-shrink: 0;
        width: 183px;   // To offset the item (the study header's image + margin)
    }
`;

const BarContent = styled(Grid)`
    @media (max-width: 959px) {
        flex-direction: column;
    }
`;

const BarText = styled(Typography)`
    color: #000;
    font-size: 16px;
    font-weight: 500;
    margin: 16px 0 24px;

    @media (min-width: 960px) {
        margin: 0 39px 0 20px;
    }
`;

const ActionContainer = styled.div`
    flex-shrink: 0;
    text-align: center;

    .MuiButton-label {
        display: block;
    }

    @media (max-width: 959px) {
        max-width: 375px;
        width: 100%;
        
        &:not(:last-child) {
            margin-bottom: 16px;
        }
    }

    @media (min-width: 960px) {
        &:not(:last-child) {
            margin-right: 16px;
        }
    }
`;

function BarButton({ endIcon, onClick, children, disabled, variant='contained' }) {
    const inherit = variant === 'outlined';
    return (
        <ActionContainer className="action-container">
            <RoundedButton
                variant={variant}
                className={inherit ? 'inherit' : ''}
                size="small"
                endIcon={endIcon}
                fullWidth
                onClick={onClick}
                disabled={disabled}
            >
                {children}
            </RoundedButton>
        </ActionContainer>
    );
}

function EligibilityBarTemplate({ color, children }) {
    return (
        <EligibilityBarWrapper color={color}>
            <Container>
                <BarContent container alignItems="center" wrap="nowrap">
                    <ImageSpacer />
                    {children}
                </BarContent>
            </Container>
        </EligibilityBarWrapper>
    )
}

export default EligibilityBarTemplate;
export {
    BarButton,
    EligibilityBarTemplate,
    BarText,
};
