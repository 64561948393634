import React from 'react';
import SelectEligibilityContainer from '../SelectEligibilityContainer';
import { exerciseSelection } from 'data/study/Eligibility/eligibilitySelectionOptions';


const name="eligibility.lifestyle.activity";

// Register all the required translation keys
const t = _ => {};
t('studyEligibilityComparitivePhrase.lifestyleActivity.include.option');
t('studyEligibilityComparitivePhrase.lifestyleActivity.exclude.option');
t('studyEligibilityComparitivePhrase.lifestyleActivity.undefined.option');

export default function StudyEligibilityActivity({title}) {
    return (
        <SelectEligibilityContainer
            criteria="lifestyleActivity"
            name={name}
            options={exerciseSelection}
            title={title}
        />
    );
}