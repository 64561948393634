import React from 'react';
import { Fade, Backdrop, Modal } from '@material-ui/core';


/**
 * The basic modal to be used throughout the site.
 *
 * Has fading animation support.
 */
const BaseModal = function ({ open, children, ...props }) {
    return (
        <Modal
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout: 500 }}
            open={open}
            {...props}
        >
            <Fade in={open}>
                {children}
            </Fade>
        </Modal>
    );
};

export default BaseModal;
