import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import {
    Grid,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import MobileModalHeader from 'Components/Parts/Modals/Components/MobileModalHeader';
import { RoundedButton } from 'Components/Styles/Buttons/RoundedButton';
import { forgotPassword } from 'redux/reducers/authorization/actions';
import { TextBox } from 'Components/Parts/Form/TextBoxes';
import AuthCardPageLayout from 'Components/Layouts/Authorization/AuthCardPageLayout';


function ForgotYourPassword({ initialEmail }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    return (
        <AuthCardPageLayout>
            <Formik
            initialValues={{
                email: initialEmail
            }}
            validationSchema={Yup.object({
                email: Yup.string()
                    .email(t('errorMessage.invalidEmail'))
                    .required(t('errorMessage.requiredField'))
            })}
            onSubmit={({email}) => dispatch(forgotPassword(email))}>
                {({isValid, isSubmitting, values}) => 
                    <Form>
                        <MobileModalHeader
                            title={t('forgotPassword.title')}
                            description={t('forgotPassword.description')}
                        />
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Field
                                    component={TextBox} 
                                    name="email" 
                                    type="email"
                                    label={t('forgotPassword.emailLabel')}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <RoundedButton 
                                    variant="contained" 
                                    color="primary" 
                                    type="submit" 
                                    disabled={!isValid || isSubmitting || !values.email} 
                                    fullWidth
                                    style={{display: 'block'}}
                                >
                                    {t('forgotPassword.submitButtonText')}
                                </RoundedButton>
                            </Grid>
                        </Grid>
                    </Form>
                }
            </Formik>
        </AuthCardPageLayout>
    );
}

function mapStateToProps(state) {
    return {
        initialEmail: state.loginInformation.username,
    };
}

export default connect(
    mapStateToProps
)(ForgotYourPassword);