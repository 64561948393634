import React from 'react';
import styled from 'styled-components';
import { SvgIcon } from '@material-ui/core';

import { ReactComponent as ConfirmedIcon } from 'resources/images/icons/checkCircleOutlineIcon.svg';
import { ReactComponent as CrossCircledIcon } from 'resources/images/icons/crossCircledOutline.svg';
import { ReactComponent as QuestionIcon } from 'resources/icons/questionOutlineIcon.svg';
import { ReactComponent as PadlockOutlinedIcon} from 'resources/icons/padlockOutlineIcon.svg';
import { EligibilityStatus } from 'Constants/constants';
import { useSelector } from 'react-redux';


const StyledIcon = styled(SvgIcon)`
    display: flex;
    font-size: 19px;
    margin-left: 6px;
`;

export default function CardStatusTag({eligibility, theme}) {
    const {baselineQuestionnaireCompleted, eligibilitiesCalculated} = useSelector(state => state.authorization.profile);

    if (!baselineQuestionnaireCompleted || !eligibilitiesCalculated) {
        return <StyledIcon color="primary" component={PadlockOutlinedIcon} viewBox="0 0 88 88"/>;
    }

    if (!eligibility || eligibility.interested) {
        return null;
    }

    switch (eligibility.status) {
        case EligibilityStatus.ELIGIBLE:
            return <StyledIcon htmlColor={theme.palette.success.main} component={ConfirmedIcon} viewBox="0 0 32 32"/>;
        case EligibilityStatus.NOT_ELIGIBLE:
            return <StyledIcon htmlColor={theme.palette.error.main} component={CrossCircledIcon} viewBox="0 0 24 24"/>;
        case EligibilityStatus.POTENTIALLY_ELIGIBLE_CUSTOM_CRITERIA:
        case EligibilityStatus.POTENTIALLY_ELIGIBLE_QUESTIONS_PENDING:
            return <StyledIcon color="primary" component={QuestionIcon} viewBox="0 0 32 32"/>;
        default:
            return null;
    }
}