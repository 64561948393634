import React from 'react';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import { SvgIcon } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSnackbar, SnackbarContent } from 'notistack';
import { ackGlobalUserMessageState } from 'redux/reducers/globalUserMessaging/index';
import { useDispatch } from 'react-redux';
import { ReactComponent as AlertFillIcon} from 'resources/icons/alertTriangleFillIcon.svg';
import { ReactComponent as CheckmarkCircleFilledIcon} from 'resources/icons/checkmarkCircledFillIcon.svg';
import { ReactComponent as InfoFillIcon} from 'resources/icons/infoFillIcon.svg';


const variantIcons = {
    success: <SvgIcon component={CheckmarkCircleFilledIcon}/>,
    info: <SvgIcon component={InfoFillIcon}/>,
    warning: <SvgIcon component={AlertFillIcon}/>,
    error: <SvgIcon component={AlertFillIcon}/>
};

const useStyles = makeStyles(theme => ({
    root: props => ({
        [theme.breakpoints.up('md')]: {
            marginTop: props.hasNavbar ? '68px' : '0px',
            width: '100vw'
        }
    })
}));

const GlobalUserMessage = React.forwardRef((props, ref) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles(props);
    const { closeSnackbar } = useSnackbar();

    const handleDismiss = () => {
        closeSnackbar(props.key);
        dispatch(ackGlobalUserMessageState(props.key));
    };

    return (
        <SnackbarContent ref={ref} className={classes.root}>
            <Alert icon={variantIcons[props.variant]} severity={props.variant} onClose={handleDismiss} style={{width: '100%'}}>
                {props.message && Array.isArray(props.message)
                ? props.message.map(msg => t(msg) + '\n')
                : t(props.message) }
            </Alert>
        </SnackbarContent>
    );
});

export default GlobalUserMessage;