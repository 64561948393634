import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { SvgIcon, Grid, Typography, Chip, Button } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import { ReactComponent as FunnelLineIcon } from 'resources/images/icons/funnelLine.svg';
import { sortOrderings } from './sortOrderings';
import { chipValues } from 'data/study/studyCategoryValues';
import { useDispatch, useSelector } from 'react-redux';
import { setSort, setFilters, setEnglishFilter, setFrenchFilter } from 'redux/reducers/dashboard/actions';
import { useLocation } from 'react-router-dom';
import { CircularButton } from 'Components/Styles/Buttons/CircularButton';
import MobileModal from 'Components/Parts/Modals/MobileModal';


const BodySection = styled.div`
    background: #fff;
    border-radius: 25px 25px 0 0;
`;

const TitleText = styled(Typography)`
    text-transform: uppercase;
    padding-bottom: 1em;
`;

const ScrollableSection = styled.div`
    margin-bottom: 3em;
    padding-bottom: 1em;
`;

const ActionSection = styled.div`
    margin-left: 1em;
    margin-right: 1em;

    &:not(:first-child) {
        margin-top: 2em;
    }
    
    &:not(:last-child) {
        margin-bottom: 2em;
    }
`;

const ButtonSection = styled.div`
    background: #fff;
    position: absolute;
    bottom: 0px;
    padding: 0.5em 0;
    width: calc(100% - 41px);
    border-top: 1px solid #e6e6e6;
`;

const UnselectedChip = styled(Chip)`
    border: 2px solid;
    font-weight: 500;
`;

const SelectedChip = styled(Chip)`
    font-weight: 700;
`;

const FilterText = styled(Typography)`
    padding-left: 0.5em;
    padding-right: 0.5em;
`; 

export default function MobileCombinationMenu() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { pathname: page } = useLocation();
    const {sort, filters, filterEnglish, filterFrench} = useSelector(state => state.dashboard.filters[page]) ?? {};
    const [open, setOpen] = React.useState(false);
    const [ordering, setOrdering] = React.useState(sort ?? "-_lastUpdated");
    const [localFilters, setLocalFilters] = React.useState(filters ?? []);
    const [english, setEnglish] = React.useState(!!filterEnglish);
    const [french, setFrench] = React.useState(!!filterFrench);

    function handleSelect(value) {
        setOrdering(value);
    }

    function handleToggle(value) {
        if (localFilters.indexOf(value) < 0) {
            setLocalFilters([...localFilters, value]);
        } else {
            setLocalFilters(localFilters.filter(f => f !== value));
        }
    }

    function handleReset() {
        setOrdering("-_lastUpdated");
        setLocalFilters([]);
    }

    function handleApply() {
        dispatch(setSort(ordering, page));
        dispatch(setFilters(localFilters, page));
        dispatch(setEnglishFilter(english, page))
        dispatch(setFrenchFilter(french, page))
        setOpen(false);
    }

    const toggleDrawer = (open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setOpen(open);
    };

    return (
        <React.Fragment>
            <CircularButton variant="outlined" color="default" onClick={toggleDrawer(true)}>
                {filters.length > 0 &&
                    <FilterText variant="h6">
                        {filters.length}
                    </FilterText>
                }
                <SvgIcon component={FunnelLineIcon} htmlColor="#fff" />
            </CircularButton>
            <MobileModal
                title={t('dashboardMenu.mobileTitle')}
                open={open}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
            >
                <BodySection>
                    <ScrollableSection>
                    <ActionSection>
                        <TitleText color="textSecondary">{t("dashboardMenu.sortBy")}</TitleText>
                        <Grid container spacing={1}>
                            {sortOrderings.map(({value, label}) => (
                                <Grid item key={value}>
                                    { value === ordering 
                                        ? <SelectedChip color="primary" label={t(label)}/>
                                        : <UnselectedChip variant="outlined" color="primary" label={t(label)} clickable onClick={() => handleSelect(value)}/>
                                    }
                                </Grid>
                            ))}
                        </Grid>
                    </ActionSection>
                    <ActionSection>
                        <TitleText color="textSecondary">{t("dashboardMenu.studyLanguages")}</TitleText>
                        <Grid container spacing={1}>
                            <Grid item key="language-select-english">
                                { english
                                    ? <SelectedChip color="primary" label={t('changeLangaugeModal.language.englishLabel')} icon={<DoneIcon />} clickable onClick={() => setEnglish(!english)}/>
                                    : <UnselectedChip variant="outlined" color="primary" label={t('changeLangaugeModal.language.englishLabel')} clickable onClick={() => setEnglish(!english)}/>
                                }
                            </Grid>
                            <Grid item key="language-select-french">
                                { french
                                    ? <SelectedChip color="primary" label={t('changeLangaugeModal.language.frenchLabel')} icon={<DoneIcon />} clickable onClick={() => setFrench(!french)}/>
                                    : <UnselectedChip variant="outlined" color="primary" label={t('changeLangaugeModal.language.frenchLabel')} clickable onClick={() => setFrench(!french)}/>
                                }
                            </Grid>
                        </Grid>
                    </ActionSection>
                    <ActionSection>
                        <TitleText color="textSecondary">{t("dashboardMenu.studyCategories")}</TitleText>
                        <Grid container spacing={1}>
                            {chipValues.map(({id, getLabel}) => (
                                <Grid item key={id}>
                                    { localFilters.indexOf(id) < 0
                                        ? <UnselectedChip variant="outlined" color="primary" label={getLabel(t)} clickable onClick={() => handleToggle(id)}/>
                                        : <SelectedChip color="primary" label={getLabel(t)} icon={<DoneIcon />} clickable onClick={() => handleToggle(id)}/>
                                    }
                                </Grid>
                            ))}
                        </Grid>
                    </ActionSection>
                    </ScrollableSection>
                    <ButtonSection>
                        <Grid container justify="space-between">
                            <Button color="primary" disabled={localFilters.length === 0 && ordering === "-_lastUpdated"} onClick={handleReset}>{t("dashboardMenu.reset")}</Button>
                            <Button color="primary" onClick={handleApply}>{t("dashboardMenu.apply")}</Button>
                        </Grid>
                    </ButtonSection>
                </BodySection>
            </MobileModal>
        </React.Fragment>
    );
}