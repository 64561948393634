import { chipValues } from "data/study/studyCategoryValues";

export function getCategories(study) {
    if (study.category) {
        return study.category.map(
            cat => chipValues.find(chip => chip.id === cat.text.toLowerCase()));
    } else {
        return [];
    }
}

export function getStudyPeriod(study) {
    let recruitStart = null;
    let recruitEnd = null;

    if (study.period) {
        recruitStart = study.period.start ? new Date(study.period.start) : null;
        recruitEnd = study.period.end ? new Date(study.period.end) : null;
    }

    return [recruitStart, recruitEnd]
}

export function getArtifactAsPdf(document) {
    if (document && document.resource && document.resource.content[0]) {
        const byteArray = Uint8Array.from(atob(document.resource.content[0].attachment.data), c => c.charCodeAt(0));

        return new File([byteArray], 
            document.resource.content[0].attachment.title,
            { type: "application/pdf" });
    }
}

export function getOrganizationName(organization) {
    if (organization) {
        return organization.resource.name;
    }
}

export function isStudyOnline(locations) {
    return locations.some(location => location.resource.id === "online");
}

export function isStudyInHome(locations) {
    return locations.some(location => location.resource.id === "homeVisit");
}

export function getLocations(locations) {
    const externalLocations = locations
            .filter(location => !!location.resource.name)
            .map(location => ({
                    address: location.resource.name,
                    lat: location.resource.position.latitude,
                    lon: location.resource.position.longitude
                })
            );

    return [externalLocations.length > 0, externalLocations];
}

export function createTeamMemberList(teamMembers) {
    if (teamMembers && teamMembers.length > 0) {
        return teamMembers.map(member => createTeamMember(member));
    }

    return [];
}

export function createTeamMember(teamMember) {
    if (teamMember) {
        return {
            name: teamMember.name ?? "",
            email: teamMember.email ?? "",
            signupComplete: teamMember.isMember ?? false
        };
    }
}