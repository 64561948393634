import React from 'react';
import { InputAdornment, 
    IconButton, withStyles } from '@material-ui/core';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import { TextField } from 'formik-material-ui';
import { useTranslation } from 'react-i18next';
import { getColor, getWidth } from './ConfirmationTextBox';

function PasswordTextField({label, handleFocus, handleBlur, success, ...props}) {
    const { t } = useTranslation();
    const [showPassword, setShowPassword] = React.useState(false);
    label = label ?? t('passwordValidation.passwordLabel');

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = event => {
        event.preventDefault();
    };

    return <TextField
                {...props}
                success={success}
                variant="outlined"
                label={label}
                type={showPassword ? "text" : "password"}
                InputProps={{
                    endAdornment: <InputAdornment position="end">
                            <IconButton
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                {showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                            </IconButton>
                        </InputAdornment>,
                    ...(handleFocus ? {onFocus: handleFocus} : {}),
                    ...(handleBlur ? {onBlur: handleBlur} : {})
                }}
                fullWidth
            />;
}

const PasswordTextBox = withStyles(theme => ({
    root: props => ({
        "& .MuiInputLabel-root": {
            color: getColor(theme, props)
        },
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: getColor(theme, props),
            borderWidth: getWidth(props)
        }
    })
}))(PasswordTextField);

export default PasswordTextBox;