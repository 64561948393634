import React from 'react';
import {
    Grid,
    Typography,
} from '@material-ui/core';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Field, useField } from 'formik';

import { MultilineTextBox } from 'Components/Parts/Form/TextBoxes';
import ExpandableSelection from 'Components/Parts/Form/ExpandableSelection';
import { updateEligibilityTracking } from 'redux/reducers/eligibilityTracking/actions';


const name = 'eligibility.customCategory.categories';
const maxLength = 255;
const eligibilityId = 'CUSTOM_ELIGIBILITY';

const getDefaultForIndex = index => ({
    serverCoding: `CUSTOM_${index}`,
    customText: '',
});

const DescriptionText = styled(Typography)`
    margin-bottom: 14px;
`;

function CategoryInput({field}) {
    const { t } = useTranslation();
    return (
        <Field
            component={MultilineTextBox} 
            name={`${field.name}.customText`}
            type="text"
            placeholder={t('studySetupInfoPage.characterMaxPlaceholder', {value: maxLength})}
            maxLength={maxLength}
            variant="filled"
        />
    );
}

export default function StudyEligibilityCustomCategory() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [field] = useField(name);
    const hasData = field.value.filter(f => f.customText !== '').length !== 0;

    React.useEffect(() => {
        dispatch(updateEligibilityTracking(eligibilityId, hasData));
    }, [hasData])

    return (
        <Grid container direction="column" spacing={2}>
            <DescriptionText variant="body2" color="textSecondary">
                {t('studyEligibilityCustomCategory.description')}
            </DescriptionText>
            <ExpandableSelection 
                name={name}
                title={t('studyEligibilityCustomCategory.fieldLabel')}
                defaultValue={newIndex => getDefaultForIndex(newIndex)}
                component={CategoryInput}
                justifyAdd="flex-start"
            />
        </Grid>
    );
}
