import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { CompletedField } from '../ApprovalFields';

function TeamMember({name, email}) {
    const { t } = useTranslation();
    return (
        <Grid container spacing={6}>
            <Grid item xs={6}>
                <CompletedField
                    label={t('studySetupTeam.namePlaceholder')}
                    text={name}
                />
            </Grid>
            <Grid item xs={6}>
                <CompletedField
                    label={t('studySetupTeam.emailPlaceholder')}
                    text={email}
                />
            </Grid>
        </Grid>
    );
}

function Section({title, children}) {
    return (
        <Grid item container spacing={1}> 
            <SectionTitle title={title}/>
            <Grid item xs={12}>
                {children}
            </Grid>
        </Grid>
    );
}

function SectionTitle({title}) {
    return (
        <Grid item xs={12}>
            <Typography variant="h5">{title}</Typography>
        </Grid>
    );
} 

export default function StudyTeamApproval({principalInvestigator, researchers, coinvestigators}) {
    const { t } = useTranslation();
    return (
        <Grid container spacing={4}>
            <Section 
                title={t('studySetupTeam.principalInvestigatorTitle')}
            >
                <TeamMember {...principalInvestigator} />
            </Section>
            <Section 
                title={t('studySetupTeam.researchAssistantsTitle')} 
            >
                {researchers.map((researcher, i) => <TeamMember key={i} {...researcher}/>)}
            </Section>
            {coinvestigators && coinvestigators.length > 0 && <Section 
                title={t('studySetupTeam.coinvestigatorsTitle')} 
            >
                {coinvestigators.map((coin, i) => <TeamMember key={i} {...coin}/>)}
            </Section>}
        </Grid>
    );
}