import React from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import { Container } from '@material-ui/core';
import { getQuestionnaire, sendQuestionnaireResponse } from 'redux/reducers/questionnaires/actions';
import QuestionnaireFooter from 'Components/Parts/Questionnaire/Footer/QuestionnaireFooter';
import QuestionContent from 'Components/Parts/Questionnaire/QuestionContent';
import GlobalProgressOverlay from 'Components/Parts/Progress/GlobalProgressOverlay';
import PickerProvider from 'Components/Parts/Form/PickerProvider';
import createQuestionnaireResponse from 'data/questionnaires/createQuestionnaireResponse';
import QuestionnaireCompleteModal from 'Components/Parts/Modals/Questionnaire/QuestionnaireCompleteModal';
import ExitQuestionnaireModal from '../Modals/Questionnaire/ExitQuestionnaireModal';
import { QuestionnaireStatus } from 'Constants/constants';
import IntroductionQuestionnaireModal from '../Modals/Questionnaire/IntroductionQuestionnaireModal';

const MainContentPlacement = styled.div`
    height: 80%;
    width: 100%;
    padding: 2em 0;
`;

const FooterPlacement = styled.div`
    position: absolute;
    bottom: 1em;
    left: inherit;
    width: 90%;
    max-width: 1232px;
`;

function QuestionnaireNavigator({questionnaireId, noGutters}) {
    const dispatch = useDispatch();
    const { title, questions, activeResponseId, currentQuestion, totalQuestions } = useSelector(state => state.questionnaires);
    const loading = useSelector(state => state.serverCommunication.loading);
    const fhirId = useSelector(state => state.authorization.fhirId);
    const [initialValues, setInitialValues] = React.useState();
    const [completeModalOpen, setCompleteModalOpen] = React.useState(false);
    const isBaseline = questionnaireId === "baselineQuestionnaire";
    const [introModalOpen, setIntroModalOpen] = React.useState(!isBaseline);
    const studyId = !isBaseline && questionnaireId.split('-')[1];

    React.useEffect(() => {
        dispatch(getQuestionnaire(questionnaireId, setInitialValues));
    }, []);

    function handleSubmit(values, actions) {
        const lastQuestion = currentQuestion === totalQuestions;

        const response = createQuestionnaireResponse(fhirId, activeResponseId, questionnaireId, questions, values, lastQuestion ? QuestionnaireStatus.COMPLETED : QuestionnaireStatus.ACTIVE);
        dispatch(sendQuestionnaireResponse(response, lastQuestion, isBaseline, studyId));
        if (lastQuestion) {
            setCompleteModalOpen(true);
        }

        actions.setSubmitting(false);
    }

    if (!initialValues) {
        return null;
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            enableReinitialize
        >
            {({values, setFieldValue}) => 
                <Form>
                    <GlobalProgressOverlay open={loading}/>
                    <IntroductionQuestionnaireModal open={introModalOpen} handleClose={_ => setIntroModalOpen(false)} studyId={studyId}/>
                    <QuestionnaireCompleteModal isBaseline={isBaseline} title={title} open={completeModalOpen} studyId={studyId}/>
                    <ExitQuestionnaireModal isBaseline={isBaseline} studyId={studyId} />
                    <Container maxWidth="lg" disableGutters={noGutters}>
                        <MainContentPlacement>
                            <PickerProvider>
                                <QuestionContent />
                            </PickerProvider>
                        </MainContentPlacement>
                        <FooterPlacement>
                            <QuestionnaireFooter formikValues={values} setFieldValue={setFieldValue} />
                        </FooterPlacement>
                    </Container>
                </Form>
            }
        </Formik>
    );
}

QuestionnaireNavigator.defaultProps = {
    noGutters: false
};

export default QuestionnaireNavigator;