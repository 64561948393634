import React from 'react';
import styled from 'styled-components';
import { Grid, SvgIcon } from '@material-ui/core';
import { ReactComponent as ExternalLinkIcon } from 'resources/images/icons/externalLinkOutlineIcon.svg';
import { InlineIcon } from 'Components/Styles/Images/InlineIcon';

const Link = styled.a`
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    color: ${props => props.theme.palette.primary.main};
    text-decoration: underline;
    &:hover {
        color: ${props => props.theme.palette.primary.dark};
    }
`;

export default function ExternalLink({url, children}) {
    return (
        <Grid container wrap="nowrap">
            <InlineIcon>
                <SvgIcon color="primary" component={ExternalLinkIcon}/>
            </InlineIcon>
            <Link href={url} target="_blank">
                {children}
            </Link>
        </Grid>
    );
}