import React from 'react';
import { timeframeSelection } from 'data/study/Eligibility/eligibilitySelectionOptions';
import SelectEligibilityContainer from '../SelectEligibilityContainer';

const name="eligibility.ketoacidosis.ketoepisode";

// Register all the required translation keys
const t = _ => {};
t('studyEligibilityComparitivePhrase.ketoacidosisKetoepisode.include.option');
t('studyEligibilityComparitivePhrase.ketoacidosisKetoepisode.exclude.option');
t('studyEligibilityComparitivePhrase.ketoacidosisKetoepisode.undefined.option');

export default function StudyEligibilityKetoacidosis({title}) {
    return (
        <SelectEligibilityContainer 
            criteria="ketoacidosisKetoepisode"
            name={name}
            options={timeframeSelection} 
            title={title}
        />
    );
}