import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Grid, IconButton, Tooltip, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { RoundedButton } from 'Components/Styles/Buttons/RoundedButton';
import PersonIcon from '@material-ui/icons/Person';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { useHistory } from 'react-router';

const NavButtonWrapper = styled(Grid)`
    align-items: center;

    a:not(:first-child) {
        margin-left: 24px;
    }
`;

export default function AccountButtons({allowMobile}) {
    const { t } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <NavButtonWrapper container item justify="flex-end">
            {allowMobile && isMobile
                ? <MobileButtons t={t}/>
                : <DesktopButtons t={t}/>}
        </NavButtonWrapper>
    );
}

function DesktopButtons({t}) {
    return (
        <Grid container>
            <RoundedButton
                variant="outlined"
                color="default"
                size="small"
                href="/signin"
            >
                {t('homePage.signInButtonText')}
            </RoundedButton>
            <RoundedButton
                variant="contained"
                color="default"
                size="small"
                href="/signup"
            >
                {t('homePage.createAccountButtonText')}
            </RoundedButton>
        </Grid>
    );
}

function MobileButtons({t}) {
    const history = useHistory();
    return (
        <React.Fragment>
            <Tooltip title={t('homePage.signInButtonText')}>
                <IconButton color="inherit" onClick={() => history.push("/signin")}>
                    <PersonIcon/>
                </IconButton>
            </Tooltip>
            <Tooltip title={t('homePage.createAccountButtonText')}>
                <IconButton color="inherit" onClick={() => history.push("/signup")}>
                    <PersonAddIcon/>
                </IconButton>
            </Tooltip>
        </React.Fragment>
    );
}