import React from 'react';
import { SvgIcon, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { ReactComponent as CheckOpenCircleOutlineIcon } from 'resources/images/icons/checkOpenCircleOutlineIcon.svg';
import AccountCardLayout from 'Components/Layouts/Authorization/AccountCardLayout';
import AuthCardPageLayout from 'Components/Layouts/Authorization/AuthCardPageLayout';


function RegistrationSuccess() {
    const history = useHistory();
    const { t } = useTranslation();
    return (
        <AuthCardPageLayout>
            <AccountCardLayout
                icon={<SvgIcon component={CheckOpenCircleOutlineIcon} style={{ fontSize: '88px' }} viewBox="0 0 88 88" color="primary" />}
                title={t('signUpSuccess.title')}
                primaryButtonText={t('signUpSuccess.signInButtonText')}
                onPrimaryClick={_ => history.push('/signin')}
            >
                <Typography variant="body2" color="textSecondary" dangerouslySetInnerHTML={{__html: t('signUpSuccess.description')}} />
            </AccountCardLayout>
        </AuthCardPageLayout>
    );
}

export default RegistrationSuccess;
