import React from 'react';
import { useHistory } from 'react-router';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import AccountCardLayout from 'Components/Layouts/Authorization/AccountCardLayout';
import AuthCardPageLayout from 'Components/Layouts/Authorization/AuthCardPageLayout';

export default function RegistrationErrorActivated() {
    const history = useHistory();
    const { t } = useTranslation();

    return (
        <AuthCardPageLayout>
            <AccountCardLayout
                title={t('signUpError.activatedTitle')}
                primaryButtonText={t('signUpSuccess.signInButtonText')}
                onPrimaryClick={_ => history.push('/signin')}
            >
                <Typography variant="body2" color="textSecondary" dangerouslySetInnerHTML={{__html: t('signUpError.activatedDescription')}} />
            </AccountCardLayout>
        </AuthCardPageLayout>
    );
}