import React from 'react';
import { Grid, Typography, SvgIcon } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { CardDetailRow, CardDetailTextValue } from 'Components/Parts/Study/StudyReview';
import DocumentPreview from 'Components/Parts/Study/DocumentPreview';
import CardFieldError from '../CardFieldError';
import { ReactComponent as CheckCircleOutlineIcon } from 'resources/images/icons/checkCircleOutlineIcon.svg';
import ExternalLink from '../../Navigation/ExternalLink';
import { InlineIcon } from 'Components/Styles/Images/InlineIcon';

export default function StudyApprovalDetails({funder, letter, isFinalized=false, meta}) {
    const { t } = useTranslation();

    if (isFinalized) {
        return <StudyApprovalFinalDetails funder={funder} letter={letter} t={t}/>;
    } else {
        return <StudyApprovalReviewDetails funder={funder} letter={letter} meta={meta} t={t}/>;
    }
}

function StudyApprovalReviewDetails({funder, letter, meta, t}) {
    return (
        <Grid container item spacing={2}>
            <CardDetailRow name={t('studySetupReview.funderLabel')} value={<CardDetailTextValue>{funder}</CardDetailTextValue>} />
            <CardDetailRow name={t('studySetupReview.letterLabel')} value={
            <Grid item xs={6}>
                <DocumentPreview files={[letter]}/>
            </Grid>} />
            <CardFieldError meta={meta}/>
        </Grid>
    );
}

function StudyApprovalFinalDetails({funder, letter, t}) {
    const [url, setUrl] = React.useState();

    React.useEffect(() => {
        if (!url) {
            setUrl(URL.createObjectURL(letter));
        }

        return _ => URL.revokeObjectURL(url);
    }, [letter]);

    return (
        <Grid container spacing={2} direction="column">
            <Grid container item alignItems="center">
                <InlineIcon>
                    <SvgIcon component={CheckCircleOutlineIcon} viewBox="0 0 32 32" color="secondary"/>
                </InlineIcon>
                <Typography variant="h6" display="inline">{t('studyDetails.rebApproved')}</Typography>
            </Grid>
            <Grid item>
                <CardDetailTextValue>
                    {t('studyDetails.approvedBy', {value: funder})}
                </CardDetailTextValue>
            </Grid>
            <Grid item>
                <ExternalLink url={url}>
                    {t('studyDetails.viewApproval')}
                </ExternalLink>
            </Grid>
        </Grid>
    );
}