import styled from 'styled-components';
import {ExpansionPanel, ExpansionPanelSummary, Chip} from '@material-ui/core';

export const StyledPanel = styled(ExpansionPanel)`
    && {
        box-shadow: none;
    }
`;

export const StyledPanelSummary = styled(ExpansionPanelSummary)`
    && {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
        border-bottom: 2px solid ${props => props.theme.palette.primary.main};
    }
`;

export const GroupMarker = styled(Chip)`
    && {
        font-weight: bold;
        font-size: 1rem;
        border: 2px solid #000;
        margin-left: 0.5em;
    }
`; 