import React from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { CardDetailOption } from 'Components/Parts/Study/StudyReview';
import CardDetailTextValue from 'Components/Parts/Study/StudyReview/CardDetailTextValue';
import GoogleMapsStaticImage from 'Components/Parts/Google/GoogleMapsStaticImage';
import CardFieldError from '../CardFieldError';

export default function LocationDetails({ inClinic, clinics, online, homeVisit, isFinalized=false, meta }) {
    const { t } = useTranslation();

    return (
        <Grid container spacing={1}>
            <LocationDetailRow display={online} prefix="online" t={t} displayCheck={!isFinalized}/>
            <LocationDetailRow display={homeVisit} prefix="home" t={t} displayCheck={!isFinalized}/>
            <ClinicExtendedCheckedRow display={inClinic} clinics={clinics} t={t} displayCheck={!isFinalized}/>
            <Grid item xs={12}>
                <CardFieldError meta={meta}/>
            </Grid>
        </Grid>
    );
}

function LocationDetailRow({display, prefix, t, displayCheck}) {
    if (!display) {
        return null;
    }

    return (
        <Grid item xs={12}>
            <CardDetailOption title={t(`studySetupLocation.${prefix}Label`)} description={t(`studySetupLocation.${prefix}Description`)} displayCheck={displayCheck}/>
        </Grid>
    );
}

function ClinicExtendedCheckedRow({display, clinics, t, displayCheck}) {
    if (!display) {
        return null;
    }

    return (
        <React.Fragment>
            <Grid item xs={12}>
                <CardDetailOption title={t('studySetupLocation.clinicLabel')} description={t('studySetupLocation.clinicDescription')} displayCheck={displayCheck}/>
            </Grid>
            <Grid item container spacing={2} xs={12}>
                {clinics.map((clinic, i) => {
                    if (clinic.lat && clinic.lon) {
                        return (
                            <Grid item container key={i}>
                                <Grid item>
                                    <CardDetailTextValue>
                                        {clinic.address}
                                    </CardDetailTextValue>
                                </Grid>
                                <Grid item xs={9}>
                                    <GoogleMapsStaticImage location={clinic}/>
                                </Grid>
                            </Grid>
                        );
                    } else {
                        return null;
                    }
                })}
            </Grid>
        </React.Fragment>
    );
}