import React from 'react';
import styled from 'styled-components';
import { SvgIcon, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloudOutlineIcon } from 'resources/icons/uploadCloudOutlineIcon.svg';
import { RoundedButton } from 'Components/Styles/Buttons/RoundedButton';

const DropzoneTitleText = styled(Typography)`
    padding-top: 10px;
    font-weight: bold;
    font-size: 19px;
    line-height: 0.84;
    letter-spacing: 0.63px;
`;

const DropzoneDescriptionText = styled(Typography)`
    padding-top: 26px;
    color: #000;
`;

const DropzoneExplanationText = styled(Typography)`
    padding-top: 12px;
    padding-bottom: 32px;
    white-space: pre;
`;

export function DropzoneDefaultContent({open, title, description, explanation}) {
    const { t } = useTranslation();
    return (
        <React.Fragment>
            <SvgIcon component={CloudOutlineIcon} color="primary" style={{fontSize: '54px'}} viewBox="0 0 54 54" />
            <DropzoneTitleText color="primary">{title}</DropzoneTitleText>
            <DropzoneDescriptionText variant="subtitle1">{description}</DropzoneDescriptionText>
            <DropzoneExplanationText variant="subtitle1">{explanation}</DropzoneExplanationText>
            <RoundedButton onClick={open} color="primary" variant="outlined" size="small">
                {t('dropzone.uploadButtonText')}
            </RoundedButton>
        </React.Fragment>
    );
}

export function handleDrop(field, form, files) {
    form.setFieldValue(field.name, files);
    form.setFieldTouched(field.name);
}