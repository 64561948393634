import React from 'react';
import {
    Typography,
    SvgIcon,
    Link
} from '@material-ui/core';
import { SERVER_API_CONTEXT_PATH, UserType } from 'Constants/constants';
import { RoundedButton } from 'Components/Styles/Buttons/RoundedButton';
import { ReactComponent as DownloadIcon } from 'resources/icons/downloadIcon.svg';
import getWebsiteLink from 'utilities/getWebsiteLink';


export const FaqContentEn = accountType => [{
    question: 'What is Connect1d Canada?',
    answer: <React.Fragment>
        <Typography>Connect1d Canada is a digital platform that aims to connect people living with diabetes with T1D research in Canada, drive participation in research studies, and through this engagement generate a T1D registry.  Connect1d Canada aims to accelerate research in diabetes and enable people living with T1D to drive future priorities in research, policy, and clinical care.</Typography><br/>
        <Typography>For people living with T1D, Connect1d Canada offers you the ability to easily learn about and participate in innovative diabetes research from across Canada. The data you provide by creating an account, completing the registration survey, and engaging with the platform, not only enables Connect1d Canada to more accurately match you to relevant research opportunities , but also helps contribute data to the registry itself.</Typography><br/>
        <Typography>For people researching T1D, Connect1d Canada provides you with a platform through which you can accelerate recruitment into your diabetes studies, diversify your participant inclusion, and communicate your research and findings directly with those who it impacts first hand. The platform gives you the  ability to post your Research Ethics Board approved studies, make key pieces of information like compensation accessible in a patient-friendly way, and directly connect with potentially eligible participants from across Canada.</Typography><br/>
    </React.Fragment>
},{
    question: 'Who created Connect1d Canada?',
    answer: <React.Fragment>
        <Typography>Connect1d Canada was created by <Link href="https://diabetesaction.ca/" target="_blank" rel="noopener noreferrer">Diabetes Action Canada</Link> (DAC), focused on diabetes and its complications . The platform was co-created with people living with T1D and led by Canada’s top researchers and contributors in diabetes:</Typography>
        <Typography dangerouslySetInnerHTML={{__html: "Dr. Joe Cafazzo, Biomedical Engineer & Executive Director, eHealth Innovation at UHN<br/>Dr. Peter Senior,  Endocrinologist &amp; Scientist<br/>Kate Farnsworth, Advocate & Director of Nightscout<br/>Tracy McQuire, Manager of Research Operations, Diabetes Action Canada<br/>Dr. Gary Lewis, Endocrinologist &amp; Scientist<br/>Dr. Bruce Perkins, Endocrinologist & Scientist"}}/> <br/>
        <Typography>In addition, <Link href="https://jdrf.ca/" target="_blank" rel="noopener noreferrer">JDRF International</Link> provides financial support to this project.</Typography><br/>
        <Typography>Connect1d Canada was built by <Link href="https://ehealthinnovation.org/" target="_blank" rel="noopener noreferrer">University Health Network’s Centre for Global eHealth Innovation</Link>, who are also responsible for data collection, storage and maintenance.</Typography>
    </React.Fragment>
},{
    question: 'Why is Connect1d Canada important? ',
    answer: <React.Fragment>
        <Typography>Did you know that the actual number of people diagnosed with T1D in Canada is not known? This presents challenges when trying to provide data to inform policy change, or determine how best to allocate health care resources.  The need for a diabetes registry, a database that identifies individuals with a particular condition, is very clear. It is also evident that we need new ways of building registries so that they can be continuously refreshed with new data, and be sustained for years to come.</Typography><br/>
        <Typography>Connect1d Canada is the first patient-led diabetes registry, which aims to not only collect data for registry purposes from people living with T1D, but also deliver immediate value for doing so.</Typography><br/>
        <Typography>In consulting with the T1D community, we have heard from those living with T1D that they feel people living with T1D have made it clear they feel disconnected from T1D research priorities. Similarly, researchers have told us that they struggle to connect with people living with T1D and recruit them into studies, sometimes taking years to find enough of the right people to complete a clinical study or trial in T1D. </Typography><br/>
        <Typography>Connect1d Canada aims to bridge the gap felt in the diabetes research community by bringing research opportunities and novel results directly to people living with T1D, and through this exchange, continuously collect meaningful registry data. By joining Connect1d Canada, you are contributing to the T1D community by volunteering to help researchers study and develop new treatments and management strategies for T1D. </Typography><br/>
    </React.Fragment>
},{
    question: 'Who can register for Connect1d Canada?',
    answer: <React.Fragment>
        <Typography>For this first phase, researchers of T1D, and people who are diagnosed with T1D, over the age of 18 years, and living in Canada, are invited to register. We hope to expand to loved ones, youth, and the community at large in future versions. </Typography><br/>
        {accountType === UserType.RESEARCHER && 
            <React.Fragment>
                <Typography>If you are a researcher, you can download a Researcher Onboarding Guide to help you get started!</Typography><br/>
                <a href={`${SERVER_API_CONTEXT_PATH(process.env.REACT_APP_STAGE)}/api/rebguide/en-CA`} download>
                    <RoundedButton size="small" variant="contained" color="primary" endIcon={<SvgIcon component={DownloadIcon}/>}>Download PDF</RoundedButton>
                </a>
            </React.Fragment>}
    </React.Fragment>
},{
    question: 'Are people with T1D paid for their participation and their data?',
    answer: <Typography>Upon joining Connect1d Canada, people living with T1D have the opportunity to consent to share their data with the registry, and participate in studies for which  they might be eligible. Connect1d Canada does not compensate users for joining the platform, however, some of the studies that are posted on Connect1d Canada may disclose the compensation type and amount available to those who qualify to participate. The research teams using Connect1d Canada as a recruitment tool are responsible for communicating with and managing interested study participants across all areas of their study, such as scheduling, and compensation (if applicable to their study). The research team’s contact information for each active study is available in each study’s Detail view. Please contact the researcher for the study you participated in if you have any questions about compensation.</Typography>
},{
    question: 'Where does Connect1d Canada store its data and how is it protected?',
    answer: <Typography dangerouslySetInnerHTML={{__html: `Please refer to the <a href="${getWebsiteLink('/privacy', { lang: 'en' })}">privacy policy</a> for specific information on how Connect1d Canada stores data.`}}/>
},{
    question: 'Who has access to Connect1d Canada’s data, and how will it be used?',
    answer: <Typography dangerouslySetInnerHTML={{__html: `Please refer to the <a href="${getWebsiteLink('/privacy', { lang: 'en' })}">privacy policy</a> for specific information on who has access to Connect1d Canada data and how it will be used.`}}/>
},{
    question: 'How will research be disseminated?',
    answer: <Typography>Researchers who post their studies on Connect1d Canada will be responsible for distributing their research findings to their participants and the Connect1d Canada community at large. Connect1d Canada will highly encourage researchers to report their findings through publications in appropriate scientific journals and through presentations at scientific conferences. Connect1d Canada will also recommend that researchers provide summarized results understandable to a lay audience on the Connect1d Canada platform as well as through general media so that those outside the study with diabetes may take advantage and understand research efforts.</Typography>
},{
    question: 'Do you need more help?',
    answer: <React.Fragment>
        <Typography>We welcome and thank you for your comments, questions, and suggestions. Please do not hesitate to contact the Connect1d Canada research team at:</Typography><br/>
        <Typography dangerouslySetInnerHTML={{__html: "eHealth Innovation @ UHN (Connect1d Canada)<br/><a href=\"mailto:help@connect1d.ca\">help@connect1d.ca</a><br/>200 Elizabeth St 4th Floor, Toronto, Ontario, M5G 2C4."}} />
    </React.Fragment>
}];

export const FaqContentFr = accountType => [{
    question: 'Qu’est-ce que Connect1d Canada?',
    answer: <React.Fragment>
        <Typography>Connect1d Canada est une plateforme numérique qui vise à mettre en contact des personnes qui vivent avec le DT1 et la recherche sur le DT1 au Canada, inciter les gens à participer à des études de recherche et à ainsi créer un registre sur le DT1. Connect1d Canada a pour objectif d’accélérer la recherche sur le diabète et de permettre aux personnes qui vivent avec le DT1 d’orienter les futures priorités en matière de recherche, de politiques et de soins cliniques.</Typography><br/>
        <Typography>Pour les personnes qui vivent avec le DT1, Connect1d Canada vous permet de facilement apprendre sur la recherche innovatrice dans le domaine du diabète au Canada et d’y participer. Les données que vous fournissez en créant un compte, en répondant à un questionnaire d’inscription et en participant sur la plateforme contribuent à fournir des données pour le registre en plus de permettre à Connect1d Canada de mieux vous jumeler avec des occasions de recherche pertinentes.</Typography><br/>
        <Typography>Pour les chercheurs, Connect1d Canada offre une plateforme par l’entremise de laquelle vous pouvez accélérer le recrutement pour vos études sur le diabète, diversifier votre inclusion des participants et communiquer votre recherche et les résultats directement aux personnes qui sont les premières touchées. Cette plateforme vous permet de publier vos études approuvées par le Comité d’éthique de la recherche, de rendre accessible de l’information essentielle comme la compensation de façon conviviale et de communiquer directement avec des participants potentiellement admissibles de partout au Canada.</Typography><br/>
    </React.Fragment>
},{
    question: 'Qui a créé Connect1d Canada?',
    answer: <React.Fragment>
        <Typography>La plateforme Connect1d Canada a été créée par <Link href="https://diabetesaction.ca/fr" target="_blank" rel="noopener noreferrer">Action diabète Canada</Link> (ADC) et est axée sur le diabète et ses complications. La plateforme a été cocréée par des personnes qui vivent avec le DT1 et est dirigée par les meilleurs chercheurs et contributeurs dans le domaine du diabète au Canada :  </Typography>
        <Typography dangerouslySetInnerHTML={{__html: "Dr. Joe Cafazzo, Ingénieur biomédical et directeur général, eHealth Innovation à UHN<br/> Dr. Peter Senior,  Endocrinologue et chercheur<br/>Kate Farnsworth, Porte-parole et directrice de Nightscout<br/>Tracy McQuire, Gestionnaire des opérations de recherche, Action diabète Canada<br/>Dr. Gary Lewis, Endocrinologue et chercheur<br/>Dr. Bruce Perkins, Endocrinologue et chercheur"}}/> <br/>
        <Typography>De plus, <Link href="https://frdj.ca/" target="_blank" rel="noopener noreferrer">FRDJ International</Link> fournit un soutien financier à ce projet.</Typography><br/>
        <Typography>Connect1d Canada a été conçue par le <Link href="https://ehealthinnovation.org/" target="_blank" rel="noopener noreferrer">Centre for Global eHealth Innovation du University Health Network</Link> qui est aussi responsable de la collecte, du stockage et de la maintenance des données.</Typography>
    </React.Fragment>
},{
    question: 'Pourquoi est-ce que la plateforme Connect1d Canada est importante?',
    answer: <React.Fragment>
        <Typography>Saviez-vous qu’on ignore le nombre réel de personnes qui vivent avec le DT1 au Canada? Il est donc difficile d’essayer de fournir des données pour orienter des changements aux politiques ou de déterminer comment mieux allouer les ressources en soins de santé. La nécessité d’un registre des DT1, une base de données qui identifie les personnes avec une affection particulière est manifeste. Il est aussi évident que nous avons besoin de nouvelles manières d’établir des registres pour qu’ils puissent constamment être mis à jour grâce à de nouvelles données et être maintenus pendant des années.</Typography><br/>
        <Typography>Connect1d Canada est le premier registre sur le diabète dirigé par des patients, qui vise, non seulement à recueillir des données sur les personnes qui vivent avec le DT1 pour le registre, mais qui vise aussi à donner une valeur immédiate par le fait même.</Typography><br/>
        <Typography>En consultant la communauté des DT1, les personnes qui vivent avec le DT1 nous ont clairement dit qu’elles se sentaient déconnectées des priorités des recherches sur le DT1. De même, les chercheurs nous ont dit qu’ils avaient de la difficulté à entrer en contact avec les personnes qui vivent avec le DT1 et à les recruter pour leurs études au point où cela prend parfois des années avant d’avoir assez de participants pour réaliser une étude ou un essai clinique dans le domaine du DT1.</Typography><br/>
        <Typography>Le but de Connect1d Canada est de combler les lacunes dans la communauté de la recherche sur le DT1 en créant des occasions de recherche et des nouveaux résultats directement aux personnes qui vivent avec le DT1 et, par cet échange, recueillir constamment des données pertinentes pour le registre. En vous joignant à Connect1d Canada, vous contribuez à la communauté DT1 en faisant du bénévolat pour aider l’équipe de chercheurs et en élaborant de nouveaux traitements et des stratégies de gestion pour le DT1.</Typography><br/>
    </React.Fragment>
},{
    question: 'Qui peut s’inscrire à Connect1d Canada?',
    answer: <React.Fragment>
        <Typography>Pour la première phase, les chercheurs dans le domaine du DT1 et les personnes qui vivent avec le DT1, qui ont plus de 18 ans et qui vivent au Canada, sont invités à s’inscrire. Nous espérons élargir les participants aux proches, des jeunes et de la communauté en général dans des versions ultérieures.</Typography><br/>
        {accountType === UserType.RESEARCHER && 
            <React.Fragment>
                <Typography>Si vous êtes un chercheur, vous pouvez télécharger un guide d'intégration des chercheurs pour vous aider à démarrer!</Typography><br/>
                <a href={`${SERVER_API_CONTEXT_PATH(process.env.REACT_APP_STAGE)}/api/rebguide/fr-CA`} download>
                    <RoundedButton size="small" variant="contained" color="primary" endIcon={<SvgIcon component={DownloadIcon}/>}>Télécharger le PDF</RoundedButton>
                </a>
        </React.Fragment>}
    </React.Fragment>
},{
    question: 'Est-ce que les personnes qui vivent avec le DT1 sont rémunérées pour leur participation et pour leurs données?',
    answer: <Typography>Avant de se joindre à Connect1d Canada, les personnes qui vivent avec le DT1 ont l’occasion de consentir à partager leurs données par l’entremise du registre et à participer à des études auxquelles elles pourraient être admissibles. Connect1d Canada ne donne pas de compensation aux utilisateurs qui s’inscrivent à la plateforme. Cependant, certaines des études publiées sur Connect1d Canada peuvent afficher le type de compensation et le montant offert aux gens qui répondent aux critères d’une étude donnée. Les équipes de recherche qui utilisent Connect1d Canada comme outil de recrutement sont responsables de contacter et de gérer les participants qui désirent participer dans toutes les sphères de leur étude, comme l’échéancier et la compensation (le cas échéant). Les coordonnées de l’équipe de recherche pour chaque étude active sont disponibles dans l’aperçu détaillé de chaque étude. Veuillez communiquer avec le chercheur de l’étude à laquelle vous avez participé si vous avez des questions au sujet de la compensation.</Typography>
},{
    question: 'À quel endroit est-ce que Connect1d Canada entrepose les données et comment sont-elles protégées?',
    answer: <Typography dangerouslySetInnerHTML={{__html: `Veuillez consulter la <a href="${getWebsiteLink('/privacy', { lang: 'fr' })}">politique de confidentialité</a> pour savoir précisément comment Connect1d Canada entrepose les données.`}}/>
},{
    question: 'Qui a accès aux données de Connect1d Canada et comment seront-elles utilisées?',
    answer: <Typography dangerouslySetInnerHTML={{__html: `Veuillez consulter la <a href="${getWebsiteLink('/privacy', { lang: 'fr' })}">politique de confidentialité</a> pour savoir précisément qui a accès aux données de Connect1d Canada et comment elles seront utilisées.`}}/>
},{
    question: 'Comment la recherche sera-t-elle diffusée?',
    answer: <Typography>Les chercheurs qui affichent leurs études sur Connect1d Canada seront responsables de la distribution des résultats de leur recherche à leurs participants et à la communauté de Connect1d Canada en général. Connect1d Canada encouragera fortement les chercheurs à présenter leurs constatations par l’entremise de publications dans des revues scientifiques appropriées et par l’entremise de présentations lors de conférences scientifiques. Connect1d Canada recommandera aussi que les chercheurs fournissent un résumé des résultats compréhensible pour un public non spécialisé sur la plateforme Connect1d Canada de même que sur les médias en général pour que les gens qui n’ont pas participé à l’étude sur le diabète puissent comprendre et profiter des efforts de recherche.</Typography>
},{
    question: 'Vous avez besoin d’aide?',
    answer: <React.Fragment>
        <Typography>Nous accueillons vos commentaires, vos questions et vos suggestions et vous en remercions. N’hésitez pas à communiquer avec l’équipe de recherche de Connect1d Canada à :</Typography><br/>
        <Typography dangerouslySetInnerHTML={{__html: "eHealth Innovation @ UHN (Connect1d Canada)<br/><a href=\"mailto:help@connect1d.ca\">help@connect1d.ca</a><br/>200 Elizabeth St 4th Floor, Toronto, Ontario, M5G 2C4."}} />
    </React.Fragment>
}]

export const FaqContent = accountType => ({
    en: FaqContentEn(accountType),
    fr: FaqContentFr(accountType),
});
