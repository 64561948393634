export function conditionallyReturnExtension(study, extensionName) {
    if (!study.extension) {
        return null;
    }

    const extension = study.extension.find(ext => ext.url === extensionName);

    if (extension) {
        return getExtensionValue(extension);
    }
}

export function conditionallyReturnDateFromExtension(study, extensionName) {
    const extension = conditionallyReturnExtension(study, extensionName);
    if (extension) {
        return new Date(extension);
    }

    return null;
}

export function conditionallyReturnObjectFromExtensionByLabel(study, extensionName, objectCollection) {
    const extension = conditionallyReturnExtension(study, extensionName);
    if (extension) {
        return objectCollection.find(object => object.label === extension);
    }
}

export function conditionallyReturnObjectFromExtensionById(study, extensionName, objectCollection) {
    const extension = conditionallyReturnExtension(study, extensionName);
    if (extension && objectCollection) {
        return objectCollection.find(object => object.id === extension);
    } else if (extension) {
        return {
            id: extension
        };
    }
}

export function conditionallyReturnMultipleExtension(study, extensionName) {
    if (!study.extension) {
        return null;
    }

    const matches = study.extension.filter(ext => ext.url === extensionName);

    if (matches && matches.length > 0) {
        return matches.map(match => getExtensionValue(match)).filter(Boolean);
    } else {
        return null;
    }
}

export function conditionallyReturnMultipleObjectsFromExtensions(study, extensionName, objectCollection) {
    const extensions = conditionallyReturnMultipleExtension(study, extensionName);

    if (extensions) {
        return extensions.map(ext => objectCollection.find(object => object.id === ext));
    }
}

function getExtensionValue(extension) {
    return extension.valueInteger || extension.valueString || extension.valueDate;
}