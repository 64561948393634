import {GOOGLE_API_KEY} from 'Constants/constants';

function loadScript(src, position, id) {
    if (!position) {
        return;
    }

    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.src = src;
    position.appendChild(script);
}

export default function GoogleScriptLoader() {
    if (typeof window !== 'undefined') {
        if (!document.querySelector('#googleMaps')) {
            loadScript(
                `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=places`,
                document.querySelector('head'),
                'googleMaps',
            );
        }

        return true;
    }
}