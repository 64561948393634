import React, { useRef } from 'react';
import { FormHelperText, InputLabel, Select, ListSubheader }  from '@material-ui/core';
import { getIn } from 'formik';
import { CustomFormControl } from '../TextBoxes/CustomInputs';
import { ReactComponent as ChevronDownIcon } from 'resources/icons/chevronDownIcon.svg';

function fieldToSelect({
    disabled,
    field,
    handleChange,
    form: { isSubmitting },
    ...props
}) {
    return {
        disabled: !!disabled || isSubmitting,
        ...props,
        ...field,
        onChange: e => {
            if (!props.multiple || e.target.value.length <= props.maximum) {
                field.onChange(e);
            }
            handleChange && handleChange(e);
        } 
    };
}

export default function Dropdown({ dropdownTitle, helperText, defaultText, ...props }) {
    const { field, form, label, variant, fullWidth, disabled } = props;
    const { errors, touched } = form;

    const labelRef = useRef();
    const labelWidth = labelRef.current ? labelRef.current.clientWidth : 0;

    const fieldError = getIn(errors, field.name);
    const showError = getIn(touched, field.name) && !!fieldError;
    return (
        <CustomFormControl variant={variant} fullWidth={fullWidth} error={showError} field={field} disabled={disabled}>
            <InputLabel ref={labelRef}>{label}</InputLabel>
            <Select {...fieldToSelect(props)} labelWidth={labelWidth} IconComponent={ChevronDownIcon} displayEmpty>
                {dropdownTitle && <ListSubheader>{dropdownTitle}</ListSubheader>}
                {props.children}
            </Select>
            {showError ? <FormHelperText>{fieldError}</FormHelperText> : <FormHelperText>{helperText}</FormHelperText>}
        </CustomFormControl>
    );
}