import React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Grid } from '@material-ui/core';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { RoundedButton } from 'Components/Styles/Buttons/RoundedButton';
import NewPasswordFields from 'Components/Parts/Form/NewPasswordFields';
import { changePassword } from 'redux/reducers/authorization/actions';
import AuthCardPageLayout from 'Components/Layouts/Authorization/AuthCardPageLayout';
import MobileModalHeader from 'Components/Parts/Modals/Components/MobileModalHeader';


const SubmitButton = styled(RoundedButton)`
    display: block;
    margin-top: 16px;
`;

export default function ResetPassword() {
    const { t } = useTranslation();
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();

    const {id, token} = queryString.parse(location.search);
    function handleError() {
        // TODO: determine if we are sending the user anywhere after an error
    }

    function handleSuccess() {
        history.push("/signin");
    }

    function handleSubmit(newPassword) {
        dispatch(changePassword(id, token, newPassword, handleSuccess, handleError));
    }

    return (
        <AuthCardPageLayout>
            <Formik
                initialValues={{
                    newPassword: "",
                    confirmPassword: ""
                }}
                validationSchema={Yup.object({
                    newPassword: Yup.string()
                        .min(8, " ")
                        .matches(/[a-z]/, " ")
                        .matches(/[A-Z]/, " ")
                        .matches(/[^a-zA-Z]/, " ")
                        .required(" "),
                    confirmPassword: Yup.string()
                        .oneOf([Yup.ref("newPassword"), null], t('errorMessage.passwordMismatch'))
                        .required(t('errorMessage.passwordMismatch'))
                })}
                onSubmit={({newPassword}) => handleSubmit(newPassword)}
            >
                {({isValid, isSubmitting, dirty}) => 
                    <Form>
                        <MobileModalHeader
                            title={t('resetPassword.title')}
                            description={t('resetPassword.description')}
                        />
                        <Grid container spacing={2}>
                            <NewPasswordFields />
                            <Grid item xs={12}>
                                <SubmitButton 
                                    variant="contained" 
                                    color="primary" 
                                    type="submit" 
                                    disabled={!isValid || isSubmitting || !dirty} 
                                    fullWidth
                                    style={{display: 'block'}}
                                >
                                    {t('resetPassword.submitButtonText')}
                                </SubmitButton>
                            </Grid>
                        </Grid>
                    </Form>
                }
            </Formik>
        </AuthCardPageLayout>
    );
}