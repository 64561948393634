import produce from "immer";
import { v4 as uuidv4 } from 'uuid';
import { ENQUEUE_GLOBAL_USER_MESSAGE_CUSTOM,
    ENQUEUE_GLOBAL_USER_MESSAGE_UNKNOWN_ERROR,
    ENQUEUE_GLOBAL_USER_MESSAGE_LOGIN_EXPIRED,
    ACK_GLOBAL_USER_MESSAGE } from "./actions";

const initialState = {
    messages: []
};

export default (state = initialState, action) =>
    produce(state, draft => {
        const {type, payload} = action;
        switch(type) {
            case ENQUEUE_GLOBAL_USER_MESSAGE_CUSTOM:
                const { urgency, message } = payload;

                draft.messages.push({
                    key: payload.key ?? uuidv4(),
                    visible: true,
                    urgency,
                    message,
                    source: payload.source
                });
                break;
            case ENQUEUE_GLOBAL_USER_MESSAGE_UNKNOWN_ERROR:
                draft.messages.push({
                    key: payload.key ?? uuidv4(),
                    visible: true,
                    urgency: "error",
                    message: "error.unableToConnect",
                    source: payload.source
                });
                break;
            case ENQUEUE_GLOBAL_USER_MESSAGE_LOGIN_EXPIRED:
                draft.messages.push({
                    key: payload.key ?? uuidv4(),
                    visible: true,
                    urgency: "warning",
                    message: "warning.loginSessionExpired",
                    source: payload.source
                });
                break;
            case ACK_GLOBAL_USER_MESSAGE:
                draft.messages = draft.messages.filter(
                    message => message.key !== payload.key
                );
                break;
            default:
                return draft;
        }
    });