import React from 'react';
import { useTranslation } from 'react-i18next';

import { StudyStatus } from 'Constants/constants';
import { ReactComponent as PersonOutlineSmallIcon } from 'resources/images/icons/personOutlineSmall.svg';
import CardTag from './CardTag';


const primaryTagData = {
    [StudyStatus.DRAFT]: {
        getLabel: t => t('studyStatus.draft'),
        color: '#ffbb38',
    },
    [StudyStatus.UNDER_REVIEW]: {
        getLabel: t => t('studyStatus.underReview'),
        color: '#f10ebc',
    },
    [StudyStatus.ACTIVE]: {
        getLabel: t => t('studyStatus.active'),
        color: '#2cc7de',
    },
};

function getSecondaryTagData({ status, interestCount }) {
    const data = {};

    if (status === StudyStatus.ACTIVE && interestCount !== undefined) {
        data.secondaryButtonColor = '#145b66';
        data.secondaryButtonText = String(interestCount);
        data.secondaryButtonIcon = <PersonOutlineSmallIcon />;
    }

    return data;
}

function getTagData(study) {
    const data = primaryTagData[study.status];
    if (!data) {
        return null;
    }

    return Object.assign(data, getSecondaryTagData(study));
}

function StudyCardTag({ className, status, hasFindings, interestCount }) {
    const { t } = useTranslation();
    const studyStatus = getTagData({ status, hasFindings, interestCount });

    if (!studyStatus) {
        return null;
    }

    return (
        <CardTag
            className={className}
            label={studyStatus.getLabel(t)}
            primaryColor={studyStatus.color}
            secondaryButtonText={studyStatus.secondaryButtonText}
            secondaryButtonColor={studyStatus.secondaryButtonColor}
            secondaryButtonIcon={studyStatus.secondaryButtonIcon}
        />
    );
}

export default StudyCardTag;
