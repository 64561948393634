import React from 'react';
import { useTranslation } from 'react-i18next';
import NumericFieldEligibilityContainer,
{MinField, MaxField, RangeField} from '../NumericFieldEligibilityContainer';
import { ageSelectionOptions } from 'data/study/Eligibility/eligibilitySelectionOptions';


const name="eligibility.demographics.age";

const getComponentOptions = t => {
    const minLabel = t('studyEligibilityAge.minAgeLabel');
    const maxLabel = t('studyEligibilityAge.maxAgeLabel');
    return Object.freeze([
        Object.freeze({id: "range", component: <RangeField name={name} minLabel={minLabel} maxLabel={maxLabel}/>}),
        Object.freeze({id: "less", component: <MaxField name={name} label={maxLabel}/>}),
        Object.freeze({id: "greater", component: <MinField name={name} label={minLabel}/>}),
        Object.freeze({id: "equal", component: <MinField name={name} label={minLabel}/>}),
    ]);
};

// Register all the required translation keys
const t = _ => {};
t('studyEligibilityComparitivePhrase.demographicsAge.include.range');
t('studyEligibilityComparitivePhrase.demographicsAge.include.less');
t('studyEligibilityComparitivePhrase.demographicsAge.include.greater');
t('studyEligibilityComparitivePhrase.demographicsAge.include.equal');
t('studyEligibilityComparitivePhrase.demographicsAge.exclude.range');
t('studyEligibilityComparitivePhrase.demographicsAge.exclude.less');
t('studyEligibilityComparitivePhrase.demographicsAge.exclude.greater');
t('studyEligibilityComparitivePhrase.demographicsAge.exclude.equal');
t('studyEligibilityComparitivePhrase.demographicsAge.undefined.range');
t('studyEligibilityComparitivePhrase.demographicsAge.undefined.less');
t('studyEligibilityComparitivePhrase.demographicsAge.undefined.greater');
t('studyEligibilityComparitivePhrase.demographicsAge.undefined.equal');

t('studyEligibilityReview.demographicsAge.greater');
t('studyEligibilityReview.demographicsAge.less');
t('studyEligibilityReview.demographicsAge.range');
t('studyEligibilityReview.demographicsAge.equal');

export default function StudyEligibilityAge({title}) {
    const { t } = useTranslation();
    return (
        <NumericFieldEligibilityContainer
            criteria="demographicsAge"
            name={name}
            title={title}
            answerOptions={ageSelectionOptions}
            componentOptions={getComponentOptions(t)}
        />
    );
}