export const chipValues = Object.freeze([
    Object.freeze({ id: "children", getLabel: t => t('studyCategories.children') }),
    Object.freeze({ id: "exercise", getLabel: t => t('studyCategories.exercise') }),
    Object.freeze({ id: "experimental", getLabel: t => t('studyCategories.experimental') }),
    Object.freeze({ id: "food", getLabel: t => t('studyCategories.food') }),
    Object.freeze({ id: "hypoglycemia", getLabel: t => t('studyCategories.hypoglycemia') }),
    Object.freeze({ id: "insulin", getLabel: t => t('studyCategories.insulin') }),
    Object.freeze({ id: "mental", getLabel: t => t('studyCategories.mental') }),
    Object.freeze({ id: "drugs", getLabel: t => t('studyCategories.drugs') }),
    Object.freeze({ id: "technology", getLabel: t => t('studyCategories.technology') }),
]);