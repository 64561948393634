import React from 'react';
import { timeframeSelection } from 'data/study/Eligibility/eligibilitySelectionOptions';
import SelectEligibilityContainer from '../SelectEligibilityContainer';

const name="eligibility.hypoglycemia.hypoepisode";

// Register all the required translation keys
const t = _ => {};
t('studyEligibilityComparitivePhrase.hypoglycemiaHypoepisode.include.option');
t('studyEligibilityComparitivePhrase.hypoglycemiaHypoepisode.exclude.option');
t('studyEligibilityComparitivePhrase.hypoglycemiaHypoepisode.undefined.option');

export default function StudyEligibilityHypoglycemia({title}) {
    return (
        <SelectEligibilityContainer
            criteria="hypoglycemiaHypoepisode"
            name={name}
            options={timeframeSelection} 
            title={title}
        />
    );
}