import React, { useState } from 'react';
import { Typography, SvgIcon } from '@material-ui/core';
import { ReactComponent as PadlockOutlinedIcon} from 'resources/icons/padlockOutlineIcon.svg';
import { ReactComponent as PageWithLinesIcon} from 'resources/icons/pageWithLinesIcon.svg';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { incrementTermsPage, decliningAccountCreationTerms } from 'redux/reducers/accountCreation/actions';
import AccountCardLayout from 'Components/Layouts/Authorization/AccountCardLayout';
import AccountCreationFeedback from './AccountCreationFeedback';
import AccountCreationLegal from './AccountCreationLegal';
import { AccountCreationTermsPage, UserType } from 'Constants/constants';
import getWebsiteLink from 'utilities/getWebsiteLink';


export default function AgreementPage({submitForm}) {
    const currentPage = useSelector(state => state.accountCreation.termsPage);
    const isDecliningTerms = useSelector(state => state.accountCreation.isDecliningTerms);
    const type = useSelector(state => state.accountCreation.accountType);
    const [showProvideFeedback, setShowProvideFeedback] = useState(false);

    if (showProvideFeedback) {
        return <AccountCreationFeedback />;
    } else if (isDecliningTerms) {
        return <AccountCreationLegal handleDecline={_ => setShowProvideFeedback(true)} />;
    } else if (currentPage === AccountCreationTermsPage.PRIVACY_POLICY) {
        return <PrivacyPolicyPage type={type}/>;
    } else if (currentPage === AccountCreationTermsPage.TERMS_OF_SERVICE) {
        return <TermsOfServicePage type={type} submitForm={submitForm} />;
    } else {
        return null;
    }
}

function PrivacyPolicyPage({type}) {
    const { t } = useTranslation();

    return <AccountCreationAgreeToTerms
            icon={PadlockOutlinedIcon}
            title={type === UserType.RESEARCHER ? t('signUpTerms.researcherPrivacyTitle') : t('signUpTerms.privacyTitle')}
            description={type === UserType.RESEARCHER ? t('signUpTerms.researcherPrivacyDescription') : t('signUpTerms.privacyDescription')}
            readButton={type === UserType.RESEARCHER ? t('signUpTerms.researcherReadPrivacy') : t('signUpTerms.readPrivacyPolicy')}
            acceptButton={type === UserType.RESEARCHER ? t('signUpTerms.researcherAcceptPrivacy') : t('signUpTerms.acceptPrivacyPolicy')}
            landingPagePath="/privacy"
            type={type}
        />
}

function TermsOfServicePage({type, submitForm}) {
    const { t } = useTranslation();

    return <AccountCreationAgreeToTerms
            icon={PageWithLinesIcon}
            title={t('signUpTerms.termsTitle')}
            description={t('signUpTerms.termsDescription')}
            readButton={t('signUpTerms.readTerms')}
            acceptButton={t('signUpTerms.acceptTerms')}
            landingPagePath="/terms"
            type={type}
            submitForm={submitForm}
        />
}

function AccountCreationAgreeToTerms({icon, title, description, readButton, acceptButton, landingPagePath, type, submitForm}) {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    function handleDecline() {
        dispatch(decliningAccountCreationTerms);
    }

    function handleAccept() {
        if (submitForm) {
            submitForm();
        } else {
            dispatch(incrementTermsPage);
        }
    }

    return (
        <AccountCardLayout
            icon={<SvgIcon component={icon} color="primary" style={{ fontSize: '88px' }} viewBox="0 0 88 88"/>}
            title={title}
            secondaryButtonText={readButton}
            onSecondaryClick={_ => {
                const url = getWebsiteLink(landingPagePath, { type });
                const win = window.open(url, "_blank");
                win.focus();
            }}
            primaryButtonText={acceptButton}
            onPrimaryClick={handleAccept}
            ternaryButtonText={t('signUpTerms.declineLinkText')}
            onTernaryClick={handleDecline}
        >
            <Typography variant="body2" color="textSecondary" dangerouslySetInnerHTML={{__html: description}} />
        </AccountCardLayout>
    );
}