import React from 'react';
import {
    Grid,
    IconButton,
    TableCell,
    TableRow,
    Typography,
} from '@material-ui/core';
import SwapVertOutlinedIcon from '@material-ui/icons/SwapVertOutlined';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { setParticipantListSortOrder } from 'redux/reducers/myStudy/actions';
import { ParticipantListOrderBy } from 'Constants/constants';


const StyledTableCell = styled(TableCell)`
    background-color: #fff;
    border-bottom: none;
`;

const TableCellHeaderLabel = styled(Typography)`
    color: #000;
    font-family: Inter;
    font-size: 12px;
    font-weight: 600;

    @media (min-width: 960px) {
        margin-left: 22px;
        font-size: 14px;
    }
`;

const StyledSortIcon = styled(SwapVertOutlinedIcon)`
    font-size: 16px;

    ${props => props.className.includes('active') ? css`  
        color: ${props => props.theme.palette.primary.main};
    ` : css`
        opacity: 0.52;
    `}

    @media (min-width: 960px) {
        font-size: 20px;
    }
`;

const RoundIconButton = styled(IconButton)`
    color: #000;
    font-size: 12px;
    padding: 0;
`;

const HeaderCell = ({ title, active, onClick }) => (
    <StyledTableCell>
        <Grid container wrap="nowrap">
            <RoundIconButton onClick={onClick}> 
                <StyledSortIcon className={active ? 'active' : ''} />
            </RoundIconButton>
            <TableCellHeaderLabel>{title}</TableCellHeaderLabel>
        </Grid>
    </StyledTableCell>
);

export default function ParticipantTableHead({ status }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const orderBy = useSelector(state => state.myStudy[status].orderBy);

    const handleOnClick = field => {
        dispatch(setParticipantListSortOrder(status, field));
    };

    return (
        <TableRow>
            <HeaderCell
                active={orderBy === ParticipantListOrderBy.DATE}
                onClick={() => handleOnClick('date')}
                title={t('myStudyPage.participantList.dateLabel')}
            />
            <HeaderCell
                active={orderBy === ParticipantListOrderBy.USER}
                onClick={() => handleOnClick('user')}
                title={t('myStudyPage.participantList.userInfoLabel')}
            />
            <HeaderCell
                active={orderBy === ParticipantListOrderBy.NOTE}
                onClick={() => handleOnClick('note')}
                title={t('myStudyPage.participantList.noteLabel')}
            />
            <StyledTableCell />
        </TableRow>
    );
}