import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { CardDetailOption } from 'Components/Parts/Study/StudyReview';
import { getEligibilityReviewPhrases } from 'data/study/Eligibility/eligibilityReviewPhrases';
import CardFieldError from '../CardFieldError';
import EligibilitySelectedOptionsField from 'Components/Parts/Study/StudyEligibility/EligibilitySelectedOptionsField';
import CardDetailTextValue from '../CardDetailTextValue';
import { WizardDataRowTitle } from 'Components/Styles/Typography/Heading'
import { isCustomEligibility } from 'data/apiResponses/eligibilityHelpers';


// Register all the required translation keys
const t = _ => {};
t('studySetupReview.inclusionLabel');
t('studySetupReview.inclusionDescription');
t('studySetupReview.exclusionLabel');
t('studySetupReview.exclusionDescription');

t('studyDetails.inclusionLabel');
t('studyDetails.exclusionLabel');

const StyledInclusionDescription = styled(InclusionDescription)`
    margin-bottom: 16px;
`;

const ExclusionGrid = styled(Grid)`
    @media (max-width: 959px) {
        margin-top: 32px;
    }
`;

const CustomCriteriaWrapper = styled(Grid)`
    margin-bottom: 16px;
`;

const Divider = styled.div`
    background-color: #e6e6e6;
    display: inline-block;
    height: 1px;
    margin: 32px 0 16px;
    width: 100%;
`;

export function generateEligibilityArray(object, inclusion, phrases) {
    if (object.length > 0) {
        return object
            .filter(item => item.inclusion?.id === inclusion)
            .map(item => capitalize(phrases[item.serverCoding](item)));
    }

    return Object.keys(object).flatMap(val => {
        if (object[val].serverCoding && object[val].inclusion && object[val].inclusion.id === inclusion) {
            return capitalize(phrases[object[val].serverCoding](object[val]));
        } else if (object[val].inclusion === undefined) {
            return generateEligibilityArray(object[val], inclusion, phrases);
        } 
        
        return null;
    })
    .filter(Boolean);
}

export function generateCustomCriteriaArray(object) {
    const values = !Array.isArray(object)
        ? object.customCategory.categories
        : object;

    return values
        .filter(value => isCustomEligibility(value.serverCoding))
        .map(value => value.customText);
};

function InclusionDescription({type, t, isFinalized, className}) {
    if (isFinalized) {
        const text = t(`studyDetails.${type}Label`)
            .replace(/<b>/g, '<span style="font-weight:800;">')
            .replace(/<\/b>/g, '</span>');
        return <Typography className={className} variant="body2" dangerouslySetInnerHTML={{__html: text}}/>
    } else {
        return (
            <Grid item className={className}>
                <Typography variant="h6">{t(`studySetupReview.${type}Label`)}</Typography>
                <CardDetailTextValue>{t(`studySetupReview.${type}Description`)}</CardDetailTextValue>
            </Grid>
        );
    }
}

function CustomCriteriaInfo() {
    const { t } = useTranslation();
    return (
        <CustomCriteriaWrapper item xs={12}>
            <Divider />
            <WizardDataRowTitle>{t('studySetupReview.customCriteriaLabel')}</WizardDataRowTitle>
            <CardDetailTextValue>{t('studySetupReview.customCriteriaDescription')}</CardDetailTextValue>
        </CustomCriteriaWrapper>
    );
}

function capitalize(text = '') {
    return text.charAt(0).toUpperCase() + text.slice(1);
}

function EligibilityDetails({value, isFinalized, meta}) {
    const { t } = useTranslation();
    if (!value) {
        return null;
    }

    // this is true when we are dealing with formik data, false when we are generating an approval or study detail page
    const isObject = value.length === undefined;
    const eligibilityReviewPhrases = getEligibilityReviewPhrases(t);
    const inclusionsArray = generateEligibilityArray(value, "include", eligibilityReviewPhrases);
    const exclusionsArray = generateEligibilityArray(value, "exclude", eligibilityReviewPhrases);
    const customCriteria = generateCustomCriteriaArray(value);
    return (
        <React.Fragment>
            <Grid container item>
                <Grid container item direction="column" sm={12} md={6}>
                    <StyledInclusionDescription type="inclusion" t={t} isFinalized={isFinalized}/>
                    {inclusionsArray.map((answer, i) => {
                        return <CardDetailOption key={i} title={answer} displayCheck={true} checkType={isFinalized ? "positive" : "default"}/>;
                    })}
                </Grid>
                <ExclusionGrid container item direction="column" sm={12} md={6}>
                    <StyledInclusionDescription type="exclusion" t={t} isFinalized={isFinalized}/>
                    {exclusionsArray.map((answer, i) => {
                        return <CardDetailOption key={i} title={answer} displayCheck={true} checkType={isFinalized ? "negative" : "default"}/>;
                    })}
                </ExclusionGrid>
                {customCriteria.length !== 0 &&
                    <ExclusionGrid container item xs={12}>
                        <CustomCriteriaInfo />
                        {customCriteria.map((criteria, i) => (
                            <Grid item xs={12} md={6} key={i}>
                                <CardDetailOption title={criteria} displayCheck={true} checkType={isFinalized ? "positive" : "default"} />
                            </Grid>
                        ))}
                    </ExclusionGrid>
                }
            </Grid>
            {isObject && value && <EligibilitySelectedOptionsField/>}
            <Grid container item justify="flex-start">
                <CardFieldError meta={meta}/>
            </Grid>
        </React.Fragment>
    );
}

EligibilityDetails.defaultProps = {
    isFinalized: true
}

export default EligibilityDetails;
