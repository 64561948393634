import { axiosInstance } from '../api';

export default function(studyValues) {
    return axiosInstance.post('/api/study',
        studyValues, {
            headers: {
                'Content-Type': undefined,
            },
            withCredentials: true
        }
    );
}