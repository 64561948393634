import React from 'react';
import styled from 'styled-components';
import { Card, CardActionArea, CardContent, CardMedia, Typography, Grid } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { format as dateFormat } from 'date-fns'

import { EligibilityStatus, SERVER_API_CONTEXT_PATH, StudyStatus, UserType } from 'Constants/constants';
import StudyCardTag from './StudyCardTag';
import CardTag from './CardTag';
import { useHistory } from 'react-router';
import CardStatusTag from './CardStatusTag';
import { getDateLocale } from 'translations/i18n';
import { useSelector } from 'react-redux';


// setting the height and width will be the job of the grid
const FullWidthCard = styled(Card)`
    height: 199px;
    width: 100%;

    @media (min-width: 960px) {
        height: 215px;
    }
`;

const StyledCardActionArea = styled(CardActionArea)`
    height: 100%;
`;

const StyledCardContent = styled(CardContent)`
    height: 50%;
    padding: 4px 10px 8px;
`;

const SetHeightCardMedia = styled(CardMedia)`
    height: 50%;
`;

const SpaceBetweenDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`;

const FixedSizeImage = styled.img`
    height: 100px;
    object-fit: cover;
    width: 100%;
`;

const StudyTitle = styled(Typography)`
    display: -webkit-box;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.38;
    overflow: hidden;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    
    @media (min-width: 960px) {
        font-size: 17px;
        line-height: 1.35;
    }
`;

const Date = styled(Typography)`
    color: rgba(0, 0, 0, 0.54);
    font-size: 10px;
`;

const NoWidthGrid = styled(Grid)`
    width: auto;
`;

export default function StudyCard({id, title, date, status, interestCount, showStatusTag, isNew}) {
    const { i18n } = useTranslation();
    const theme = useTheme();
    const history = useHistory();
    const accountType = useSelector(state => state.authorization.accountType);
    const isPatient = accountType === UserType.PATIENT;
    const userEligibilities = useSelector(state => state.userEligibility.eligibilities);
    const eligibility = userEligibilities ? userEligibilities[id] : { interested: false, status: EligibilityStatus.POTENTIALLY_ELIGIBLE_QUESTIONS_PENDING };
    const hasFindings = status === StudyStatus.COMPLETED;

    function redirectToStudy() {
        let link = `/dashboard/mystudy/${id}`;
        if (status === StudyStatus.DRAFT) {
            link = '/setup';
        } else if (status === StudyStatus.UNDER_REVIEW) {
            history.push(link);
        }

        history.push({
            pathname: link,
            state: {
                allowRedirect: true
            }
        });
    }

    return (
        <FullWidthCard elevation={2}>
            <StyledCardActionArea onClick={redirectToStudy}>
                <SetHeightCardMedia>
                    <FixedSizeImage
                        src={`${SERVER_API_CONTEXT_PATH(process.env.REACT_APP_STAGE)}/api/study/${id}/image`} 
                        alt={title}
                    />
                </SetHeightCardMedia>
                <StyledCardContent>
                    <SpaceBetweenDiv>
                        <StudyTitle component="h2">
                            {title}
                        </StudyTitle>
                        <Grid container justify="space-between" alignItems="center" wrap="nowrap">
                            <NoWidthGrid container item >
                                <Date>
                                    {date && dateFormat(date, 'MMM d, yyyy', { locale: getDateLocale(i18n.language) }).toUpperCase()}
                                </Date>
                            </NoWidthGrid>
                            <NoWidthGrid container item alignItems="center">
                                <Grid item>
                                    <Tag 
                                        showStatusTag={showStatusTag} 
                                        status={status} 
                                        hasFindings={hasFindings}
                                        interestCount={interestCount}
                                        isNew={isNew}
                                        interested={eligibility?.interested}
                                        theme={theme}
                                    />
                                </Grid>
                                <Grid item>
                                    {isPatient && !hasFindings &&
                                        <CardStatusTag 
                                            eligibility={eligibility}
                                            theme={theme}
                                        />
                                    }
                                </Grid>
                            </NoWidthGrid>
                        </Grid>
                    </SpaceBetweenDiv>
                </StyledCardContent>
            </StyledCardActionArea>
        </FullWidthCard>
    );
  }

  function Tag({showStatusTag, status, hasFindings, interestCount, isNew, interested, theme}) {
    const { t } = useTranslation();

    if (showStatusTag) {
        return <StudyCardTag
            status={status}
            hasFindings={hasFindings}
            interestCount={interestCount}
        />;
    }
    if (isNew) {
        return <CardTag
            label={t('studyCard.newTag')}
            primaryColor={theme.palette.primary.main}
            variant="outlined"
        />;
    }
    if (interested) {
        return <CardTag
            label={t('studyCard.interestedTag')}
            primaryColor={theme.palette.success.main}
        />;
    }

    return null;
  }