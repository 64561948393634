import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import {Divider} from '@material-ui/core';

export const ItemDivider = styled(Divider)`
    && {
        background: rgba(255, 255, 255, 0.15);
    }
`;

export const StyledMenuItem = styled(NavLink)`
    font-family: Varela Round;
    color: rgba(255, 255, 255, 0.45);
    padding: 1em 2em;
    text-decoration: none;

    &:hover {
        color: white;
        text-decoration: none;
    }
`;

export const SelectedMenuItem = styled.div`
    font-family: Varela Round;
    font-size: 18px;
    color: white;
    font-weight: bold;
    padding: 22.5px 2rem;
    background-color: rgba(255, 255, 255, 0.27);
`;
