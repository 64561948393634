import React from 'react';
import {
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core';
import styled from 'styled-components';


const StyledListItem = styled(ListItem)`
    border-bottom: 1px solid #e6e6e6;
`;

const IconWrapper = styled(ListItemIcon)`
    color: ${props => props.theme.palette.primary.main};
    margin-right: 8px;
    min-width: 0;
`;

const StyledListItemText = styled(ListItemText)`
    .MuiListItemText-primary {
        color: #000;
        font-size: 20px;
        font-weight: 700;
    }
`;

export default function ListHeadingRow({ icon, text }) {
    return (
        <StyledListItem>
            {icon && 
                <IconWrapper>
                    {icon}
                </IconWrapper>
            }
            <StyledListItemText primary={text} />
        </StyledListItem>
    );
}