import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import toggleStudyInterest from 'api/study/toggleStudyInterest';
import { ReactComponent as CheckCircleOutlineIcon } from 'resources/images/icons/checkCircleOutlineIcon.svg';
import { ReactComponent as MailOutlineIcon } from 'resources/icons/mailOutlineIcon.svg';
import {
    BarText,
    BarButton,
    EligibilityBarTemplate,
} from './EligibilityBarTemplate';
import { updateUserInterest } from 'redux/reducers/userEligibility/actions';
import { useDispatch } from 'react-redux';
import { useState } from 'react';


function EligibleBar({ studyId, interested }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [processing, setProcessing] = useState(false);

    const handleClick = async () => {
        setProcessing(true);
        const { status } = await toggleStudyInterest(studyId);
        if (status === 204) {
            dispatch(updateUserInterest(studyId, !interested));
        }
        setProcessing(false);
    };

    const text = interested
        ? t('eligibilityBar.interestedText')
        : t('eligibilityBar.isEligibleText');

    const buttonText = interested
        ? t('eligibilityBar.interestedButtonText')
        : t('eligibilityBar.isEligibleButtonText');

    const icon = interested
        ? MailOutlineIcon
        : CheckCircleOutlineIcon;

    const buttonStyle = interested ? 'outlined' : 'contained';

    return (
        <EligibilityBarTemplate color="secondary">
            <SvgIcon component={icon} style={{ fontSize: "2rem" }} viewBox="0 0 32 32" />
            <BarText>{text}</BarText>
            <BarButton onClick={handleClick} variant={buttonStyle} disabled={processing}>{buttonText}</BarButton>
        </EligibilityBarTemplate>
    );
}

EligibleBar.defaultProps = {
    interested: false
};

export default EligibleBar;
