import React from 'react';
import { useSelector } from 'react-redux';
import { Form } from 'formik';
import { Grid, SvgIcon } from '@material-ui/core';
import { ReactComponent as ArrowForwardIcon } from 'resources/images/icons/study/arrowCircleRightOutline.svg';
import { RoundedButton } from 'Components/Styles/Buttons/RoundedButton';
import StudyHeader from 'Components/Parts/Study/StudyHeader';
import { WizardContent, FooterWrap } from 'Components/Styles/Study/WizardPages';
import LocalProgressOverlay from 'Components/Parts/Progress/LocalProgressOverlay';
import { useTranslation } from 'react-i18next';

export default function WizardPage({title, subtitle, icon, children}) {
    const { t } = useTranslation();
    const saving = useSelector(state => state.serverCommunication.saving);

    return (
        <Form>
            <WizardContent>
                <Grid container spacing={4}>
                    <Grid container item xs={12}>
                        <StudyHeader title={title} subtitle={subtitle} icon={icon} />
                    </Grid>
                    <Grid item xs={12}>
                        {children}
                    </Grid>
                </Grid>
            </WizardContent>
            <FooterWrap>
                <Grid container item xs={12} justify="flex-end">
                    <LocalProgressOverlay waiting={saving}>
                        <RoundedButton 
                            variant="contained" 
                            color="primary" 
                            type="submit" 
                            disabled={saving}
                            endIcon={<SvgIcon component={ArrowForwardIcon} htmlColor="#fff" style={{fontSize: '24px'}} />}
                        >
                            {t('study.continueButtonText')}
                        </RoundedButton>
                    </LocalProgressOverlay>
                </Grid>
            </FooterWrap>
        </Form>
    );
}