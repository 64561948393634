import React from 'react';
import { Switch, Route, useLocation, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import DashboardTabBar from 'Components/Parts/Dashboard/Navigation/DashboardTabBar';
import DashboardTemplate from 'Components/Layouts/NavbarTemplate';
import DashboardPage from './DashboardStudyPages/DashboardPage';
import ExplorePage from './DashboardStudyPages/DashboardExplorePage';
import FindingsPage from './DashboardStudyPages/DashboardFindingsPage';
import { DashboardTabBarHeightMobile } from 'Constants/constants';
import BaselineQuestionnaireWaitingModal from 'Components/Parts/Modals/Questionnaire/BaselineQuestionnaireWaitingModal';
import { UserType, Page } from 'Constants/constants';
import DashboardSettingsContainer from './DashboardSettingPages/DashboardSettingsContainer';
import { getUserEligibilities } from 'redux/reducers/userEligibility/actions';
import { getUserProfile } from 'redux/reducers/authorization/actions';
import { isEqual } from 'lodash';


const StyledDashboardTemplate = styled(DashboardTemplate)`
    margin-bottom: ${DashboardTabBarHeightMobile};  // margin for mobile tab bar

    @media (min-width: 960px) {
        margin-bottom: 0;
    }
`;

export default function DashboardContainer() {
    const { userId, accountType, dismissedBaseline, profile } = useSelector(state => state.authorization);
    const eligibilities = useSelector(state => state.userEligibility.eligibilities);
    const isPatient = accountType === UserType.PATIENT;
    const showModal = isPatient && !dismissedBaseline && !profile.baselineQuestionnaireCompleted && !profile.processingEligibilities;
    const location = useLocation();
    const dispatch = useDispatch();
    const [oldProfile, setOldProfile] = React.useState(null);

    React.useEffect(() => {
        if (isPatient && (!eligibilities || !isEqual(oldProfile, profile))) {
            dispatch(getUserEligibilities());
            setOldProfile(profile);
        }

        const interval = setInterval(() => {
            if (isPatient && profile.processingEligibilities) {
                dispatch(getUserProfile(userId));
            }}, 5000);

        return _ => clearInterval(interval);
    }, [userId, eligibilities, profile])

    if (location.state?.firstLogin) {
        return <Redirect to="/questionnaire/welcome"/>;
    }

    return (        
        <StyledDashboardTemplate>
            <DashboardTabBar />
            <Switch>
                <Route path="/" exact component={DashboardPage} />
                <Route path="/dashboard" exact component={DashboardPage} />
                <Route path="/dashboard/findings" component={FindingsPage} />
                <Route path="/dashboard/explore" component={ExplorePage} />
                <Route path={Page.SETTINGS} component={DashboardSettingsContainer} />
            </Switch>
            <BaselineQuestionnaireWaitingModal open={showModal} />
        </StyledDashboardTemplate>
    );
}
