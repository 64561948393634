import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { Grid, Typography, SvgIcon } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { RoundedButton } from 'Components/Styles/Buttons/RoundedButton';
import { ReactComponent as CompassFilledIcon } from 'resources/images/icons/compassFilled.svg';


const Wrapper = styled(Grid)`
    justify-content: center;

    @media (min-width: 960px) {
        justify-content: flex-start;
    }
`;

const OuterGrid = styled(Grid)`
    align-items: center;
    display: flex;
    max-width: 430px;
    text-align: center;

    @media (min-width: 960px) {
        align-items: flex-start;
        text-align: left;
    }
`;

const Title = styled(Typography)`
    font-size: 38px;
`;

function ExploreLatestStudyPlaceholder() {
    const history = useHistory();
    const { t } = useTranslation();

    return (
        <Wrapper container>
            <OuterGrid container direction="column" spacing={4}>
                <Grid item>
                    <Title variant="h4" color="primary">{t('exploreStudy.titlePlaceholder')}</Title>
                </Grid>
                <Grid item>
                    <Typography>{t('exploreStudy.descriptionPlaceholder')}</Typography>
                </Grid>
                <Grid item>
                    <RoundedButton
                        variant="contained"
                        color="primary"
                        onClick={() => history.push('/dashboard/explore')}
                        endIcon={<SvgIcon component={CompassFilledIcon} viewBox="0 0 16 16" />}
                    >
                        {t('exploreStudy.exploreButtonText')}
                    </RoundedButton>
                </Grid>
            </OuterGrid>
        </Wrapper>
    );
}

export default ExploreLatestStudyPlaceholder;
