import React from 'react';
import { Container } from '@material-ui/core';
import styled from 'styled-components';
import DashboardNavbar from 'Components/Parts/Dashboard/Navigation/DashboardNavbar';

const DashboardContentContainer = styled.div`
    margin-top: 72px; // margin for top navbar
`;

function NavbarTemplate({ className, children, noGutters, maxWidth }) {
    return (
        <Container className={className} disableGutters={noGutters} maxWidth={maxWidth} >
            <DashboardNavbar/>
            <DashboardContentContainer>
                {children}
            </DashboardContentContainer>
        </Container>
    );
}

NavbarTemplate.defaultProps = {
    noGutters: false,
    maxWidth: 'lg',
};

export default NavbarTemplate;