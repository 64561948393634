import { WEBSITE_URL } from 'Constants/constants';
import { getCurrentLocale } from 'translations/i18n';


export default (path = '/', queryParam = {}) => {
    let siteUrl = WEBSITE_URL(process.env.REACT_APP_STAGE);
    let queryString = new URLSearchParams({
        lang: getCurrentLocale(),
        ...queryParam
    }).toString();
    return `${siteUrl}${path}?${queryString}`;
};
