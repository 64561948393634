export const ELIGIBILITY_TOGGLE = 'ELIGIBILITY_TOGGLE';
export function eligibilityToggle(value) {
    return {
        type: ELIGIBILITY_TOGGLE,
        payload: {
            category: value
        }
    };
}

export const UPDATE_ELIGIBILITY_TRACKING = 'UPDATE_ELIGIBILITY_TRACKING';
export function updateEligibilityTracking(category, add) {
    return {
        type: UPDATE_ELIGIBILITY_TRACKING,
        payload: {
            category,
            add,
        }
    };
}

export const RESET_ELIGIBILITY_TRACKING = 'RESET_ELIGIBILITY_TRACKING';
export const resetEligibilityTracking = {
    type: RESET_ELIGIBILITY_TRACKING,
};