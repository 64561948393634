import React from 'react';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Grid, Container, Divider } from '@material-ui/core';
import { sidebarItems } from 'data/study/sidebarItems';
import { ReactComponent as PencilOutlineIcon } from 'resources/images/icons/study/pencilWritingOutline.svg';
import StudyHeader from 'Components/Parts/Study/StudyHeader';
import ApprovalOptions from 'Components/Parts/Study/StudyApproval/ApprovalOptions';
import ApprovalForm from 'Components/Parts/Study/StudyApproval/ApprovalForm';
import ApprovalButton from 'Components/Parts/Study/StudyApproval/ApprovalButton';
import { fetchStudyDetails } from 'redux/reducers/serverCommunication/actions';
import GlobalProgressOverlay from 'Components/Parts/Progress/GlobalProgressOverlay';
import { buildDetailsContents } from 'data/study/Details/detailsContentsArray';
import checkStudyRequest from 'api/studyReview/checkStudy';
import ReviewNotNeededModal from 'Components/Parts/Modals/ReviewNotNeededModal';

const ReviewHeader = styled.div`
    padding-top: 3em;
`;

const ReviewDivider = styled(Divider)`
    margin: 1em 0;
`;

const ApprovalContent = styled.div`
    padding-top: 2em;
    width: 100%;
`;

const ApprovalFooter = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-top: 2em;
    padding-bottom: 1em;
`;

const ReviewSection = styled.div`
    padding-top: 1.5em;
`;

export default function ReviewPage() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();
    const loading = useSelector(state => state.serverCommunication.loading);
    const { study : studyId } = queryString.parse(location.search);
    const [needsReview, setNeedsReview] = React.useState(false);
    const [study, setStudy] = React.useState();
    const [sponsor, setSponsor] = React.useState();
    const [locations, setLocations] = React.useState([]);
    const [letter, setLetter] = React.useState();
    const [studyTeam, setStudyTeam] = React.useState();
    const [studyEligibility, setStudyEligibility] = React.useState();
    const [loaded, setLoaded] = React.useState(false);

    React.useEffect(() => {
        checkStudyRequest(studyId)
            .then(({data}) => {
                setNeedsReview(!data);
                if (!data) {
                    dispatch(fetchStudyDetails(studyId, setStudyTeam, setStudyEligibility, setStudy, setSponsor, setLetter, setLocations));
                }
            })
            .catch(_ => {
                setNeedsReview(false);
            })
            .finally(_ => {
                setLoaded(true);
            });
    }, [studyId, dispatch]);

    if (!loaded || !study) {
        return null;
    }

    if (loaded && !needsReview) {
        return <ReviewNotNeededModal/>;
    }

    const contentsArray = buildDetailsContents(studyId, study, studyTeam, studyEligibility, sponsor, locations, letter);

    return (
        <ApprovalForm studyId={studyId}>
            <GlobalProgressOverlay open={loading}/>
            <Container maxWidth="lg">
                <Grid container>
                    <Grid item xs={12}>
                        <ReviewHeader>
                            <StudyHeader title={t("studyApproval.title")} subtitle={t("studyApproval.subtitle")} icon={PencilOutlineIcon} />
                        </ReviewHeader>
                    </Grid>
                    {contentsArray.map(({name, content, reviewComponent}, i) => {
                        const {getTitle, icon} = sidebarItems[i];
                        return (
                            <Grid item xs={12} key={i}>
                                <ApprovalCard name={name} content={content} reviewComponent={reviewComponent} title={getTitle(t)} icon={icon}/>
                            </Grid>
                        );
                    })}
                    <Grid item xs={12}>
                        <ApprovalFooter>
                            <ApprovalButton/>
                        </ApprovalFooter>
                    </Grid>
                </Grid>
            </Container>
        </ApprovalForm>
    );
}

function ApprovalCard({name, content, reviewComponent : Component, title, icon}) {
    return (
        <ApprovalContent>
            <StudyHeader title={title} icon={icon}/>
            <ReviewSection>
                <Component {...content}/>
                <ReviewDivider/>
                <ApprovalOptions title={title} name={name}/>
            </ReviewSection>
        </ApprovalContent>
    );
}