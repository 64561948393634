const eligibilityCodingToTitle = t =>  ({
    "BIRTHDATE": t('studyEligibilityCodingTitle.BIRTHDATE'),
    "ETHNICITY": t('studyEligibilityCodingTitle.ETHNICITY'),
    "BIRTHSEX": t('studyEligibilityCodingTitle.BIRTHSEX'),
    "TWIN": t('studyEligibilityCodingTitle.TWIN'),
    "DIAGNOSIS": t('studyEligibilityCodingTitle.DIAGNOSIS'),
    "BRITTLE": t('studyEligibilityCodingTitle.BRITTLE'),
    "EXERCISE": t('studyEligibilityCodingTitle.EXERCISE'),
    "MONITOR": t('studyEligibilityCodingTitle.MONITOR'),
    "DEVICES": t('studyEligibilityCodingTitle.DEVICES'),
    "INSULIN_DURATION": t('studyEligibilityCodingTitle.INSULIN_DURATION'),
    "HYBRID_SYSTEM": t('studyEligibilityCodingTitle.HYBRID_SYSTEM'),
    "MEDICATION": t('studyEligibilityCodingTitle.MEDICATION'),
    "IMMUNOSUPPRESSANT": t('studyEligibilityCodingTitle.IMMUNOSUPPRESSANT'),
    "A1C_VALUE": t('studyEligibilityCodingTitle.A1C_VALUE'),
    "A1C_TIME": t('studyEligibilityCodingTitle.A1C_TIME'),
    "KETO": t('studyEligibilityCodingTitle.KETO'),
    "KETO_HOSPITAL": t('studyEligibilityCodingTitle.KETO_HOSPITAL'),
    "HYPOGLYCEMIA": t('studyEligibilityCodingTitle.HYPOGLYCEMIA'),
    "HYPOGLYCEMIA_HOSPITAL": t('studyEligibilityCodingTitle.HYPOGLYCEMIA_HOSPITAL'),
    "PREGNANCY": t('studyEligibilityCodingTitle.PREGNANCY'),
    "BREASTFEEDING": t('studyEligibilityCodingTitle.BREASTFEEDING'),
    "TRY_PREGNANCY": t('studyEligibilityCodingTitle.TRY_PREGNANCY'),
    "SECONDARY_NEPHRO": t('studyEligibilityCodingTitle.SECONDARY_NEPHRO'),
    "SECONDARY_NEURO": t('studyEligibilityCodingTitle.SECONDARY_NEURO'),
    "SECONDARY_RETINO": t('studyEligibilityCodingTitle.SECONDARY_RETINO'),
    "CONDITIONS_CELIAC": t('studyEligibilityCodingTitle.CONDITIONS_CELIAC'),
    "CONDITIONS_FOOTULCER": t('studyEligibilityCodingTitle.CONDITIONS_FOOTULCER'),
    "CONDITIONS_GASTRO": t('studyEligibilityCodingTitle.CONDITIONS_GASTRO'),
    "CONDITIONS_HEART": t('studyEligibilityCodingTitle.CONDITIONS_HEART'),
    "CONDITIONS_HEPATITIS": t('studyEligibilityCodingTitle.CONDITIONS_HEPATITIS'),
    "CONDITIONS_HYPERT": t('studyEligibilityCodingTitle.CONDITIONS_HYPERT'),
    "CONDITIONS_HYPOT": t('studyEligibilityCodingTitle.CONDITIONS_HYPOT'),
    "CONDITIONS_LIVER": t('studyEligibilityCodingTitle.CONDITIONS_LIVER'),
    "CONDITIONS_PSYCH": t('studyEligibilityCodingTitle.CONDITIONS_PSYCH'),
});

export default eligibilityCodingToTitle;