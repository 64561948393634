import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import getAffiliatedStudies from 'api/study/getAffiliatedStudies';
import getResearchStudy from 'api/fhir/getResearchStudy';
import StudyCard from 'Components/Parts/Dashboard/StudyCard/StudyCard';
import { StudyStatus } from 'Constants/constants';


const transformToActiveFhirStudies = async (studies) => {
    return (await Promise.all(studies.map(study => getResearchStudy(study.fhirId))))
        .map(res => res.data.entry.find(item => item.resource.resourceType === 'ResearchStudy').resource)
        .map((study, idx) => ({
            ...study,
            id: studies[idx].id,
            fhirId: study.id,
        }))
        .filter(study => study.status === StudyStatus.ACTIVE);
};

function Studies({ studies }) {
    return (
        <Grid container spacing={1}>
            {studies.map(study => {
                const date = new Date(study.period ? study.period.start : study.meta.lastUpdated);
                return (
                    <Grid item xs={6} md={3} key={study.id}>
                        <StudyCard
                            id={study.id}
                            title={study.title}
                            status={study.status}
                            date={date}
                        />
                    </Grid>
                );
            })}
        </Grid>
    );
}

export default function ActiveStudies() {
    const { t } = useTranslation();
    const [studies, setStudies] = React.useState();

    React.useEffect(() => {
        (async () => {
            let studies = [];
            try {
                const { data } = await getAffiliatedStudies();
                studies = data.studies ? await transformToActiveFhirStudies(data.studies) : [];
            } catch (error) { }
            setStudies(studies);
        })();
    }, []);

    if (!studies) {
        return (
            <Grid container spacing={1}>
                {Array.from({length: 4}, (x, i) => (
                    <Grid item xs={6} md={3} key={i}>
                        <Skeleton variant="rect" height={199} width="100%" style={{borderRadius: '8px'}}/>
                    </Grid>
                ))}
            </Grid>
        );
    }

    return studies.length
        ? <Studies studies={studies} />
        : <Typography variant="body2">{t('dashboardPage.activeStudiesNone')}</Typography>;
}