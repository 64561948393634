import React from 'react';
import { Radio, FormControlLabel, ListItemText } from '@material-ui/core';
import { RadioGroup } from 'formik-material-ui';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';

function RadioSelectors({values}) {
    const { t } = useTranslation();

    return values.map(({id, label, subtitle, getLabel, getSubtitle}) => {
            const labelText = getLabel ? getLabel(t) : label;
            const subtitleText = getSubtitle ? `(${getSubtitle(t)})` : subtitle;

            return <FormControlLabel 
                key={id}
                value={id} 
                control={<Radio color="primary"/>} 
                label={<ListItemText primary={labelText ? labelText : id} secondary={subtitleText} secondaryTypographyProps={{style: {fontStyle: 'italic'}}}/>} 
            />
        });
}

export default function RadioSelection({name, alignment="vertical", values}) {
    return (
        <Field 
            component={RadioGroup}
            name={name}
            row={alignment === "horizontal"}
        >
            <RadioSelectors values={values}/>
        </Field>
    );
}