import React from 'react';
import {
    IconButton,
    Toolbar,
    Typography,
} from '@material-ui/core';
import styled from 'styled-components';
import ExpandLessOutlinedIcon from '@material-ui/icons/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';


const StyledToolbar = styled(Toolbar)`
    padding: 0 24px 0 31px;

    & > .MuiSvgIcon-root {
        margin-right: 17px;
    }
`;

const ToolbarTitle = styled(Typography)`
    flex-grow: 1;
    font-size: 16px;
    font-weight: 700;
`;

const RoundIconButton = styled(IconButton)`
    border: 2px solid #000;
    color: #000;
    font-size: 12px;
    padding: 0;
`;


export default function TableToolbar({ title, icon, expand, onExpandClick }) {
    return (
        <StyledToolbar>
            {icon}
            <ToolbarTitle variant="h6">
                {title}
            </ToolbarTitle>
            <RoundIconButton onClick={onExpandClick}> 
                {expand
                    ? <ExpandLessOutlinedIcon />
                    : <ExpandMoreOutlinedIcon />
                }
            </RoundIconButton>
        </StyledToolbar>
    );
}