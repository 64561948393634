import styled, { css } from 'styled-components';

const getColor = (props) => {
    if (props.isDragAccept) {
        return '#00e676';
    }
    if (props.isDragReject) {
        return '#ff1744';
    }
    if (props.isDragActive) {
        return '#2196f3';
    }
    return '#a4a4a4';
};
  
export const DropzoneContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 1px;
    border-radius: 12px;
    border-color: ${props => getColor(props)};
    border-style: dashed;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
    height: 285px;
`;

export const ImageDropzoneContainer = styled(DropzoneContainer)`
    ${props => props.preview && css`
        background-image: url(${props.preview});
        background-repeat: no-repeat;
        background-position: center; 
        background-size: contain;
    `}
`;

export const DropzoneText = styled.p`
    color: black;
    font-weight: bold;
`;