import React from 'react';
import { useDispatch } from 'react-redux';
import { Grid } from '@material-ui/core';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';
import Dropdown from 'Components/Parts/Form/Dropdowns/SingleDropdown';
import { RoundedButton } from 'Components/Styles/Buttons/RoundedButton';
import { eligibilityToggle } from 'redux/reducers/eligibilityTracking/actions';
import EligibilityCriteriaPhrase from './StudyEligibilityCriteriaPhrase';
import { inclusionOptions } from 'data/study/Eligibility/eligibilitySelectionOptions';


export default function StudyEligibilityContainer({name, options, components, disabled, emptyValue, criteria, getProperties, shareTranslationId}) {
    const dispatch = useDispatch();

    const [field, , helpers] = useField(name);
    const eligibilityCoding = field.value.serverCoding;
    const object = components && field.value.answer && components.find(component => component.id === field.value.answer.id);
    const component = object && object.component;

    function handleReset() {
        dispatch(eligibilityToggle(eligibilityCoding));
        const thisEmptyValue = emptyValue(field.value.serverCoding);
        helpers.setValue(thisEmptyValue);
    }

    function handleChange() {
        dispatch(eligibilityToggle(eligibilityCoding));
    }

    return (
        <Grid container direction="column" spacing={2}>
            {!field.value.answer
                ? <EmptySelection name={`${name}.answer`} options={options} disabled={disabled} handleChange={handleChange}/>
                : <Selection
                    name={name}
                    initialName={`${name}.answer`}
                    initialOptions={options}
                    initialChange={handleChange}
                    handleReset={handleReset}
                    component={component}
                    criteria={criteria}
                    getProperties={getProperties}
                    shareTranslationId={shareTranslationId}
                />
            }
        </Grid>
    );
}

function EmptySelection({name, options, handleChange, disabled}) {
    const { t } = useTranslation();
    return (
        <Grid item xs={6}>
            <Dropdown 
                name={name}
                defaultText={t('eligibilityForm.makeSelectionText')}
                values={options}
                handleChange={handleChange}
                disabled={disabled}
                variant="filled"
            />
        </Grid>
    );
}

function Selection({name, initialName, initialOptions, initialChange, handleReset, component, criteria, getProperties, shareTranslationId}) {
    const { t } = useTranslation();
    return (
        <React.Fragment>
            <Grid item xs={9}>
                <EligibilityCriteriaPhrase
                    name={name}
                    criteria={criteria}
                    getProperties={getProperties}
                    shareTranslationId={shareTranslationId}
                />
            </Grid>
            <EmptySelection name={initialName} options={initialOptions} handleChange={initialChange}/>
            <Grid container item xs={6}>
                {component}
            </Grid>
            <Grid item xs={6}>
                <Dropdown
                    name={`${name}.inclusion`}
                    defaultText={t('eligibilityForm.inclusionDropdownText')}
                    values={inclusionOptions}
                    variant="filled"
                />
            </Grid>
            <Grid item>
                <RoundedButton variant="outlined" color="primary" endIcon={<NotInterestedIcon/>} onClick={handleReset}>{t('eligibilityForm.clearButtonText')}</RoundedButton>
            </Grid>
        </React.Fragment>
    );
}