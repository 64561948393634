import styled, { css } from 'styled-components';

export const RoundedImage = styled.img`
    border-radius: 50%;
    object-fit: cover;

    ${props => props.size && css`
        height: ${props.size};
        width: ${props.size};
    `}
`;
