import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ModalPaper, ModalDescriptionSection } from 'Components/Styles/Modals/ModalContainers';

import BaseModal from '../BaseModal';
import { ReactComponent as InfoOutlineIcon } from 'resources/images/icons/study/infoOutline.svg';
import { useHistory } from 'react-router';
import { dismissBaseline } from 'redux/reducers/authorization/actions';
import { useDispatch } from 'react-redux';
import { FixedSizeIcon } from 'Components/Styles/Modals/Questionnaire';
import { TitleText, ModalButton } from 'Components/Styles/Modals/Components';


export default function BaselineQuestionnaireWaitingModal({open}) {
    const {t} = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();

    function handleConfirm() {
        dispatch(dismissBaseline);
        history.push("/questionnaire");
    }

    function handleDismiss() {
        dispatch(dismissBaseline);
    }

    return (
        <BaseModal
            aria-describedby="baseline-questionnaire-modal-description"
            aria-labelledby="baseline-questionnaire-modal-title"
            onClose={handleDismiss}
            open={open}
        >
            <ModalPaper>
                <Grid container direction="column" alignItems="center" spacing={2}>
                    <Grid item>
                        <FixedSizeIcon component={InfoOutlineIcon} color="primary" />
                    </Grid>
                    <Grid item>
                        <TitleText>{t('questionnaire.notCompleteTitle')}</TitleText>
                    </Grid>
                    <Grid item>
                        <ModalDescriptionSection>
                            <Typography variant="body2" color="textSecondary">{t('questionnaire.notCompleteDescription')}</Typography>
                        </ModalDescriptionSection>
                    </Grid>
                    <ModalButton variant="outlined" color="primary" size="small" onClick={handleDismiss}>{t('questionnaire.takeLater')}</ModalButton>
                    <ModalButton variant="contained" color="primary" size="small" onClick={handleConfirm}>{t('questionnaire.completeNow')}</ModalButton>
                </Grid>
            </ModalPaper>
        </BaseModal>
    );
}