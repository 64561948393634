import axios from 'axios';
import {
    FRESHDESK_API_KEY,
    FRESHDESK_DOMAIN,
    SERVER_API_CONTEXT_PATH,
} from 'Constants/constants';

export function setToken(token) {
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

export function clearToken() {
    if (!!axiosInstance.defaults.headers.common['Authorization']) {
        delete axiosInstance.defaults.headers.common['Authorization'];
    }
}

export const axiosInstance = axios.create({
    baseURL: SERVER_API_CONTEXT_PATH(process.env.REACT_APP_STAGE),
    headers: { 
        'Content-Type' : 'application/json'
    },
    withCredentials: true
});

const freshdeskAuth = new Buffer(FRESHDESK_API_KEY + ':X').toString('base64');
export const HttpFreshdesk = axios.create({
    baseURL: `https://${FRESHDESK_DOMAIN}.freshdesk.com`,
    headers: { 
        'Authorization':  `Basic ${freshdeskAuth}`,
        'Content-Type' : 'application/json',
    },
});
