import registerRequest from 'api/auth/register';
import validateEmailRequest from 'api/auth/validateEmail';
import resendActivationRequest from 'api/auth/resendActivation';
import { enqueueGlobalUserMessageState, enqueueGlobalUserMessageUnknownError } from '../globalUserMessaging';
import { setSaving, unsetSaving } from '../serverCommunication/actions';

export const EMAIL_AVAILABLE = 'EMAIL_AVAILABLE';
export const emailAvailable = {
    type: EMAIL_AVAILABLE
};

export const EMAIL_UNAVAILABLE = 'EMAIL_UNAVAILABLE';
export const emailUnavailable = {
    type: EMAIL_UNAVAILABLE
};

export const SELECT_ACCOUNT_TYPE_PATIENT = "SELECT_ACCOUNT_TYPE_PATIENT";
export const selectAccountTypePatient = {
    type: SELECT_ACCOUNT_TYPE_PATIENT
};

export const SELECT_ACCOUNT_TYPE_RESEARCHER = "SELECT_ACCOUNT_TYPE_RESEARCHER";
export const selectAccountTypeResearcher = {
    type: SELECT_ACCOUNT_TYPE_RESEARCHER
};

export const SELECT_ACCOUNT_TYPE_LOVED_ONE = "SELECT_ACCOUNT_TYPE_LOVED_ONE";
export const selectAccountTypeLovedOne = {
    type: SELECT_ACCOUNT_TYPE_LOVED_ONE
};

export const SET_ACCOUNT_CREATE_ERROR = "SET_ACCOUNT_CREATE_ERROR";
export function setAccountCreateError(errorText) {
    return {
        type: SET_ACCOUNT_CREATE_ERROR,
        payload: {
            errorText: errorText
        }
    };
}

export const RESET_ACCOUNT_CREATE_ERROR = "RESET_ACCOUNT_CREATE_ERROR";
export const resetAccountCreateError = {
    type: RESET_ACCOUNT_CREATE_ERROR
};

export const INCREMENT_TERMS_PAGE = "INCREMENT_TERMS_PAGE";
export const incrementTermsPage = {
    type: INCREMENT_TERMS_PAGE
};

export const DECLINING_ACCOUNT_CREATION_TERMS = "DECLINING_ACCOUNT_CREATION_TERMS";
export const decliningAccountCreationTerms = {
    type: DECLINING_ACCOUNT_CREATION_TERMS,
};

export const CONTINUE_REGISTRATION = "CONTINUE_REGISTRATION";
export const continueRegistration = {
    type: CONTINUE_REGISTRATION,
};

export const RESET_TERMS_PAGE = "RESET_TERMS_PAGE";
export const resetTermsPage = {
    type: RESET_TERMS_PAGE
};

export function register(email, password, accountType, firstName, lastName, credentials, language, allowsCommunication, handleSuccess) {
    return function(dispatch) {
        dispatch(setSaving);
        return registerRequest(email, password, accountType, firstName, lastName, credentials, language, allowsCommunication)
        .then(function() {
            dispatch(resetAccountCreateError);
            handleSuccess();
        }).catch(function(error) {
            if (error.response) {
                if (error.response.status === 400) {
                    dispatch(
                        setAccountCreateError(
                            "Oops! It looks like this email address already has an account initiated, please check your email to complete verification."
                        )
                    );
                } else {
                    dispatch(
                        setAccountCreateError(
                            "We're sorry, we are experiencing technical difficulties processing this request. Please try again later to create an account."
                        )
                    );
                }
            } else {
                dispatch(enqueueGlobalUserMessageUnknownError());
            }
        }).finally(_ => dispatch(unsetSaving));
    };
}

export function validateEmail(email) {
    return function(dispatch) {
        return validateEmailRequest(email)
        .then(function() {
            dispatch(emailAvailable);
        }).catch(function(error) {
            if (error.response) {
                dispatch(emailUnavailable);
            } else {
                dispatch(enqueueGlobalUserMessageUnknownError());
            }
        });
    };
}

export function resendActivation(email) {
    return function(dispatch) {
        return resendActivationRequest(email)
        .then(function() {
            dispatch(enqueueGlobalUserMessageState(
                "success",
                "success.registrationResent"
            ));
        }).catch(function({response}) {
            if (response?.status === 401 || response?.status >= 500) {
                dispatch(enqueueGlobalUserMessageState(
                    "error",
                    "error.registrationSendFail"
                ));
            } else {
                dispatch(enqueueGlobalUserMessageState(
                    "success",
                    "success.registrationResent"
                ));
            }
        });
    };
}