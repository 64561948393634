import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';

import ActiveStudies from 'Components/Parts/Dashboard/Researcher/ActiveStudies';
import CompletedStudies from 'Components/Parts/Dashboard/Researcher/CompletedStudies';
import CurrentStudy from 'Components/Parts/Dashboard/Researcher/CurrentStudy';


const Section = styled.div`
    margin-bottom: 36px;

    @media (min-width: 960px) {
        margin-bottom: 56px;
    }
`;

const Title = styled(Typography)`
    margin-bottom: 8px;

    @media (min-width: 960px) {
        margin-bottom: 24px;
    }
`;

export default function ResearcherStudiesDashboard() {
    const { t } = useTranslation();
    return (
        <React.Fragment>
            <Section>
                <Title variant="h4" color="primary">{t('dashboardPage.submitStudyTitle')}</Title>
                <CurrentStudy />
            </Section>
            <Section>
                <Title variant="h4" color="primary">{t('dashboardPage.activeStudiesTitle')}</Title>
                <ActiveStudies />
            </Section>
            <Section>
                <Title variant="h4" color="primary">{t('dashboardPage.completedStudiesTitle')}</Title>
                <CompletedStudies />
            </Section>
        </React.Fragment>
    );
}
