import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Typography, Grid } from '@material-ui/core';
import QuestionnaireContainer from 'Components/Parts/Questionnaire/QuestionnaireNavigator';
import { useTranslation } from 'react-i18next';
import NavbarTemplate from '../NavbarTemplate';
import QuestionnaireExitButton from 'Components/Parts/Questionnaire/QuestionnaireExitButton';

const QuestionnaireTitleBar = styled.div`
    padding: 2rem 0 1.25rem;
    border-bottom: 1px solid #eaeaea;
    max-width: 1280px;
`;

const QuestionnaireTitle = styled(Typography)`
    font-size: 24px;
    line-height: 0.67;
`;

export default function QuestionnaireDesktopLayout({questionnaireId}) {
    const { t } = useTranslation();
    const title = useSelector(state => state.questionnaires.title);

    return (
        <NavbarTemplate>
            <QuestionnaireTitleBar>
                <Grid container justify="space-between" alignItems="center">
                    <QuestionnaireTitle color="primary">{t(title)}</QuestionnaireTitle>
                    <Grid item>
                        <QuestionnaireExitButton color="primary"/>
                    </Grid>
                </Grid>
            </QuestionnaireTitleBar>
            <QuestionnaireContainer questionnaireId={questionnaireId} noGutters/>
        </NavbarTemplate>
    );
}