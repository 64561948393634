import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import styled from 'styled-components';
import CardDetailTextValue from './CardDetailTextValue';
import CheckIcon from '../Details/CheckIcon';


const DetailCheckContainer = styled(Grid)`
    margin-bottom: 4px;
`;

const StyledCheckIcon = styled(CheckIcon)`
    margin-right: 8px;
`;

export default function CardDetailOption({title, description, displayCheck=true, checkType}) {
    if (displayCheck) {
        return <CardDetailCheckedOption title={title} description={description} checkType={checkType}/>;
    } else {
        return <CardTextDescription title={title} description={description}/>;
    }
}

function CardDetailCheckedOption({title, description, checkType}) {
    return (
        <DetailCheckContainer container wrap="nowrap">
            <StyledCheckIcon type={checkType}/>
            <CardTextDescription title={title} description={description}/>
        </DetailCheckContainer>
    );
}

function CardTextDescription({title, description}) {
    if (description) {
        return (
            <Grid item container direction="column">
                <Grid item>
                    <Typography variant="h6">{title}</Typography>
                </Grid>
                <Grid item>
                    <CardDetailTextValue>{description}</CardDetailTextValue>
                </Grid>
            </Grid>
        );
    } else {
        return <Typography variant="body2">{title}</Typography>;
    }
}