import React from 'react';
import StudyEligibilityAge from "Components/Parts/Study/StudyEligibility/Demographics/StudyEligibilityAge";
import StudyEligibilityEthnicity from 'Components/Parts/Study/StudyEligibility/Demographics/StudyEligibilityEthnicity';
import StudyEligibilitySex from 'Components/Parts/Study/StudyEligibility/Demographics/StudyEligibilitySex';
import StudyEligibilityDiagnosis from 'Components/Parts/Study/StudyEligibility/Condition/StudyEligibilityDiagnosis';
import StudyEligibilityActivity from 'Components/Parts/Study/StudyEligibility/Lifestyle/StudyEligibilityActivity';
import StudyEligibilityMonitor from 'Components/Parts/Study/StudyEligibility/Monitoring/StudyEligibilityMonitor';
import StudyEligibilityInsulinDevice from 'Components/Parts/Study/StudyEligibility/InsulinDelivery/StudyEligibilityInsulinDevice';
import StudyEligibilityInsulinDuration from 'Components/Parts/Study/StudyEligibility/InsulinDelivery/StudyEligibilityInsulinDuration';
import StudyEligibilityHybrids from 'Components/Parts/Study/StudyEligibility/Advanced/StudyEligibilityHybrids';
import YesNoStudyEligibilityContainer from 'Components/Parts/Study/StudyEligibility/YesNoEligibilityContainer';
import StudyEligibilityKetoacidosis from 'Components/Parts/Study/StudyEligibility/Ketoacidosis/StudyEligibilityKetoacidosis';
import StudyEligibilityHypoglycemia from 'Components/Parts/Study/StudyEligibility/Hypoglycemia/StudyEligibilityHypoglycemia';
import StudyEligibilityA1cTimeframe from 'Components/Parts/Study/StudyEligibility/A1C/StudyEligibilityA1cTimeframe';
import StudyEligibilityA1cValue from 'Components/Parts/Study/StudyEligibility/A1C/StudyEligibilityA1cValue';
import StudyEligibilityCustomCategory from 'Components/Parts/Study/StudyEligibility/Custom/StudyEligibilityCustomCategory';
import StudyEligibilityRequireAssistance from 'Components/Parts/Study/StudyEligibility/StudyEligibilityRequireAssistance';
import StudyEligibilityGenetics from 'Components/Parts/Study/StudyEligibility/Demographics/StudyEligibilityGenetics';

export const getEligibilityQuestions = t => ({
    demographics: Object.freeze([
        Object.freeze({
            id: "BIRTHDATE",
            title: t('studyEligibilityQuestion.ageTitle'),
            component: <StudyEligibilityAge />
        }),
        Object.freeze({
            id: "ETHNICITY",
            title: t('studyEligibilityQuestion.ethnicityTitle'),
            component: <StudyEligibilityEthnicity />
        }),
        Object.freeze({
            id: "BIRTHSEX",
            title: t('studyEligibilityQuestion.sexTitle'),
            component: <StudyEligibilitySex />
        }),
        Object.freeze({
            id: "TWIN",
            title: t('studyEligibilityQuestion.geneticsTitle'),
            component: <StudyEligibilityGenetics />
        }),
    ]),
    condition: Object.freeze([
        Object.freeze({
            id: "DIAGNOSIS",
            title: t('studyEligibilityQuestion.diagnosisTitle'),
            component: <StudyEligibilityDiagnosis />
        }),
        Object.freeze({
            id: "BRITTLE",
            title: t('studyEligibilityQuestion.brittleTitle'),
            component: <YesNoStudyEligibilityContainer
                name="eligibility.condition.brittle"
                criteria="BRITTLE" />
        }),
    ]),
    lifestyle: Object.freeze([
        Object.freeze({
            id: "EXERCISE",
            title: t('studyEligibilityQuestion.activityTitle'),
            component: <StudyEligibilityActivity />
        }),
    ]),
    monitoring: Object.freeze([
        Object.freeze({
            id: "MONITOR",
            title: t('studyEligibilityQuestion.monitorTitle'),
            component: <StudyEligibilityMonitor />
        }),
    ]),
    insulin: Object.freeze([
        Object.freeze({
            id: "DEVICES",
            title: t('studyEligibilityQuestion.devicesTitle'),
            component: <StudyEligibilityInsulinDevice />
        }),
        Object.freeze({
            id: "INSULIN_DURATION",
            title: t('studyEligibilityQuestion.durationTitle'),
            component: <StudyEligibilityInsulinDuration />
        })
    ]),
    advanced: Object.freeze([
        Object.freeze({
            id: "HYBRID_SYSTEM",
            title: t('studyEligibilityQuestion.hybridSystemsTitle'),
            component: <StudyEligibilityHybrids />
        }),
    ]),
    medication: Object.freeze([
        Object.freeze({
            id: "MEDICATION",
            title: t('studyEligibilityQuestion.otherDiabetesMedicationsTitle'),
            component: <YesNoStudyEligibilityContainer
                name="eligibility.medication.diabetes"
                criteria="MEDICATION" />
        }),
        Object.freeze({
            id: "IMMUNOSUPPRESSANT",
            title: t('studyEligibilityQuestion.immunosuppressantDrugsTitle'),
            component: <YesNoStudyEligibilityContainer
                name="eligibility.medication.immunosuppressant"
                criteria="IMMUNOSUPPRESSANT" />
        }),
    ]),
    a1c: Object.freeze([
        Object.freeze({
            id: "A1C_VALUE",
            title: t('studyEligibilityQuestion.currentValueTitle'),
            component: <StudyEligibilityA1cValue />
        }),
        Object.freeze({
            id: "A1C_TIME",
            title: t('studyEligibilityQuestion.lastTestTitle'),
            component: <StudyEligibilityA1cTimeframe />
        }),
    ]),
    ketoacidosis: Object.freeze([
        Object.freeze({
            id: "KETO",
            title: t('studyEligibilityQuestion.mostRecentKetoacidosisEpisodeTitle'),
            component: <StudyEligibilityKetoacidosis />
        }),
        Object.freeze({
            id: "KETO_HOSPITAL",
            title: t('studyEligibilityQuestion.didYouRequireAssistance'),
            component: <StudyEligibilityRequireAssistance path="ketoacidosis" referenceQuestion="ketoepisode" />
        }),
    ]),
    hypoglycemia: Object.freeze([
        Object.freeze({
            id: "HYPOGLYCEMIA",
            title: t('studyEligibilityQuestion.mostRecentHypoglycemiaEpisodeTitle'),
            component: <StudyEligibilityHypoglycemia />
        }),
        Object.freeze({
            id: "HYPOGLYCEMIA_HOSPITAL",
            title: t('studyEligibilityQuestion.didYouRequireAssistance'),
            component: <StudyEligibilityRequireAssistance path="hypoglycemia" referenceQuestion="hypoepisode" />
        }),
    ]),
    pregnancy: Object.freeze([
        Object.freeze({
            id: "PREGNANCY",
            title: t('studyEligibilityQuestion.pregnantTitle'),
            component: <YesNoStudyEligibilityContainer
                name="eligibility.pregnancy.pregnant"
                criteria="PREGNANCY" />
        }),
        Object.freeze({
            id: "BREASTFEEDING",
            title: t('studyEligibilityQuestion.breastfeedingTitle'),
            component: <YesNoStudyEligibilityContainer
                name="eligibility.pregnancy.breastfeeding"
                criteria="BREASTFEEDING" />
        }),
        Object.freeze({
            id: "TRY_PREGNANCY",
            title: t('studyEligibilityQuestion.conceivingTitle'),
            component: <YesNoStudyEligibilityContainer
                name="eligibility.pregnancy.attempting"
                criteria="TRY_PREGNANCY" />
        }),
    ]),
    secondary: Object.freeze([
        Object.freeze({
            id: "SECONDARY_NEPHRO",
            title: t('studyEligibilityQuestion.diabeticNephropathyTitle'),
            component: <YesNoStudyEligibilityContainer
                name="eligibility.secondary.nephropathy"
                criteria="SECONDARY_NEPHRO" />
        }),
        Object.freeze({
            id: "SECONDARY_NEURO",
            title: t('studyEligibilityQuestion.diabeticNeuropathyTitle'),
            component: <YesNoStudyEligibilityContainer
                name="eligibility.secondary.neuropathy"
                criteria="SECONDARY_NEURO" />
        }),
        Object.freeze({
            id: "SECONDARY_RETINO",
            title: t('studyEligibilityQuestion.diabeticRetinopathyTitle'),
            component: <YesNoStudyEligibilityContainer
                name="eligibility.secondary.retinopathy"
                criteria="SECONDARY_RETINO" />
        }),
    ]),
    conditions: Object.freeze([
        Object.freeze({
            id: "CONDITIONS_CELIAC",
            title: t('studyEligibilityQuestion.celiacDiseaseTitle'),
            component: <YesNoStudyEligibilityContainer
                name="eligibility.conditions.celiac"
                criteria="CONDITIONS_CELIAC" />
        }),
        Object.freeze({
            id: "CONDITIONS_FOOTULCER",
            title: t('studyEligibilityQuestion.footUlcersTitle'),
            component: <YesNoStudyEligibilityContainer
                name="eligibility.conditions.footUlcers"
                criteria="CONDITIONS_FOOTULCER" />
        }),
        Object.freeze({
            id: "CONDITIONS_GASTRO",
            title: t('studyEligibilityQuestion.gastroporesisTitle'),
            component: <YesNoStudyEligibilityContainer
                name="eligibility.conditions.gastroporesis"
                criteria="CONDITIONS_GASTRO" />
        }),
        Object.freeze({
            id: "CONDITIONS_HEART",
            title: t('studyEligibilityQuestion.heartDiseaseTitle'),
            component: <YesNoStudyEligibilityContainer
                name="eligibility.conditions.heartDisease"
                criteria="CONDITIONS_HEART" />
        }),
        Object.freeze({
            id: "CONDITIONS_HEPATITIS",
            title: t('studyEligibilityQuestion.hepatitisTitle'),
            component: <YesNoStudyEligibilityContainer
                name="eligibility.conditions.hepatitis"
                criteria="CONDITIONS_HEPATITIS" />
        }),
        Object.freeze({
            id: "CONDITIONS_HYPERT",
            title: t('studyEligibilityQuestion.hypertensionTitle'),
            component: <YesNoStudyEligibilityContainer
                name="eligibility.conditions.hypertension"
                criteria="CONDITIONS_HYPERT" />
        }),
        Object.freeze({
            id: "CONDITIONS_HYPOT",
            title: t('studyEligibilityQuestion.hypotensionTitle'),
            component: <YesNoStudyEligibilityContainer
                name="eligibility.conditions.hypotension"
                criteria="CONDITIONS_HYPOT" />
        }),
        Object.freeze({
            id: "CONDITIONS_LIVER",
            title: t('studyEligibilityQuestion.liverProblemsTitle'),
            component: <YesNoStudyEligibilityContainer
                name="eligibility.conditions.liver"
                criteria="CONDITIONS_LIVER" />
        }),
        Object.freeze({
            id: "CONDITIONS_PSYCH",
            title: t('studyEligibilityQuestion.psychiatricConditionsTitle'),
            component: <YesNoStudyEligibilityContainer
                name="eligibility.conditions.psychiatric"
                criteria="CONDITIONS_PSYCH" />
        }),
    ]),
    customCategory: Object.freeze([
        Object.freeze({
            component: <StudyEligibilityCustomCategory />,
        }),
    ]),
});

// Register all the required translation keys
const t = _ => {};
t('studyEligibilityComparitivePhrase.BRITTLE.include.yes');
t('studyEligibilityComparitivePhrase.BRITTLE.include.no');
t('studyEligibilityComparitivePhrase.BRITTLE.exclude.yes');
t('studyEligibilityComparitivePhrase.BRITTLE.exclude.no');
t('studyEligibilityComparitivePhrase.BRITTLE.undefined.yes');
t('studyEligibilityComparitivePhrase.BRITTLE.undefined.no');
t('studyEligibilityComparitivePhrase.MEDICATION.include.yes');
t('studyEligibilityComparitivePhrase.MEDICATION.include.no');
t('studyEligibilityComparitivePhrase.MEDICATION.exclude.yes');
t('studyEligibilityComparitivePhrase.MEDICATION.exclude.no');
t('studyEligibilityComparitivePhrase.MEDICATION.undefined.yes');
t('studyEligibilityComparitivePhrase.MEDICATION.undefined.no');
t('studyEligibilityComparitivePhrase.IMMUNOSUPPRESSANT.include.yes');
t('studyEligibilityComparitivePhrase.IMMUNOSUPPRESSANT.include.no');
t('studyEligibilityComparitivePhrase.IMMUNOSUPPRESSANT.exclude.yes');
t('studyEligibilityComparitivePhrase.IMMUNOSUPPRESSANT.exclude.no');
t('studyEligibilityComparitivePhrase.IMMUNOSUPPRESSANT.undefined.yes');
t('studyEligibilityComparitivePhrase.IMMUNOSUPPRESSANT.undefined.no');
t('studyEligibilityComparitivePhrase.PREGNANCY.include.yes');
t('studyEligibilityComparitivePhrase.PREGNANCY.include.no');
t('studyEligibilityComparitivePhrase.PREGNANCY.exclude.yes');
t('studyEligibilityComparitivePhrase.PREGNANCY.exclude.no');
t('studyEligibilityComparitivePhrase.PREGNANCY.undefined.yes');
t('studyEligibilityComparitivePhrase.PREGNANCY.undefined.no');
t('studyEligibilityComparitivePhrase.BREASTFEEDING.include.yes');
t('studyEligibilityComparitivePhrase.BREASTFEEDING.include.no');
t('studyEligibilityComparitivePhrase.BREASTFEEDING.exclude.yes');
t('studyEligibilityComparitivePhrase.BREASTFEEDING.exclude.no');
t('studyEligibilityComparitivePhrase.BREASTFEEDING.undefined.yes');
t('studyEligibilityComparitivePhrase.BREASTFEEDING.undefined.no');
t('studyEligibilityComparitivePhrase.TRY_PREGNANCY.include.yes');
t('studyEligibilityComparitivePhrase.TRY_PREGNANCY.include.no');
t('studyEligibilityComparitivePhrase.TRY_PREGNANCY.exclude.yes');
t('studyEligibilityComparitivePhrase.TRY_PREGNANCY.exclude.no');
t('studyEligibilityComparitivePhrase.TRY_PREGNANCY.undefined.yes');
t('studyEligibilityComparitivePhrase.TRY_PREGNANCY.undefined.no');
t('studyEligibilityComparitivePhrase.SECONDARY_NEPHRO.include.yes');
t('studyEligibilityComparitivePhrase.SECONDARY_NEPHRO.include.no');
t('studyEligibilityComparitivePhrase.SECONDARY_NEPHRO.exclude.yes');
t('studyEligibilityComparitivePhrase.SECONDARY_NEPHRO.exclude.no');
t('studyEligibilityComparitivePhrase.SECONDARY_NEPHRO.undefined.yes');
t('studyEligibilityComparitivePhrase.SECONDARY_NEPHRO.undefined.no');
t('studyEligibilityComparitivePhrase.SECONDARY_NEURO.include.yes');
t('studyEligibilityComparitivePhrase.SECONDARY_NEURO.include.no');
t('studyEligibilityComparitivePhrase.SECONDARY_NEURO.exclude.yes');
t('studyEligibilityComparitivePhrase.SECONDARY_NEURO.exclude.no');
t('studyEligibilityComparitivePhrase.SECONDARY_NEURO.undefined.yes');
t('studyEligibilityComparitivePhrase.SECONDARY_NEURO.undefined.no');
t('studyEligibilityComparitivePhrase.SECONDARY_RETINO.include.yes');
t('studyEligibilityComparitivePhrase.SECONDARY_RETINO.include.no');
t('studyEligibilityComparitivePhrase.SECONDARY_RETINO.exclude.yes');
t('studyEligibilityComparitivePhrase.SECONDARY_RETINO.exclude.no');
t('studyEligibilityComparitivePhrase.SECONDARY_RETINO.undefined.yes');
t('studyEligibilityComparitivePhrase.SECONDARY_RETINO.undefined.no');
t('studyEligibilityComparitivePhrase.CONDITIONS_CELIAC.include.yes');
t('studyEligibilityComparitivePhrase.CONDITIONS_CELIAC.include.no');
t('studyEligibilityComparitivePhrase.CONDITIONS_CELIAC.exclude.yes');
t('studyEligibilityComparitivePhrase.CONDITIONS_CELIAC.exclude.no');
t('studyEligibilityComparitivePhrase.CONDITIONS_CELIAC.undefined.yes');
t('studyEligibilityComparitivePhrase.CONDITIONS_CELIAC.undefined.no');
t('studyEligibilityComparitivePhrase.CONDITIONS_FOOTULCER.include.yes');
t('studyEligibilityComparitivePhrase.CONDITIONS_FOOTULCER.include.no');
t('studyEligibilityComparitivePhrase.CONDITIONS_FOOTULCER.exclude.yes');
t('studyEligibilityComparitivePhrase.CONDITIONS_FOOTULCER.exclude.no');
t('studyEligibilityComparitivePhrase.CONDITIONS_FOOTULCER.undefined.yes');
t('studyEligibilityComparitivePhrase.CONDITIONS_FOOTULCER.undefined.no');
t('studyEligibilityComparitivePhrase.CONDITIONS_GASTRO.include.yes');
t('studyEligibilityComparitivePhrase.CONDITIONS_GASTRO.include.no');
t('studyEligibilityComparitivePhrase.CONDITIONS_GASTRO.exclude.yes');
t('studyEligibilityComparitivePhrase.CONDITIONS_GASTRO.exclude.no');
t('studyEligibilityComparitivePhrase.CONDITIONS_GASTRO.undefined.yes');
t('studyEligibilityComparitivePhrase.CONDITIONS_GASTRO.undefined.no');
t('studyEligibilityComparitivePhrase.CONDITIONS_HEART.include.yes');
t('studyEligibilityComparitivePhrase.CONDITIONS_HEART.include.no');
t('studyEligibilityComparitivePhrase.CONDITIONS_HEART.exclude.yes');
t('studyEligibilityComparitivePhrase.CONDITIONS_HEART.exclude.no');
t('studyEligibilityComparitivePhrase.CONDITIONS_HEART.undefined.yes');
t('studyEligibilityComparitivePhrase.CONDITIONS_HEART.undefined.no');
t('studyEligibilityComparitivePhrase.CONDITIONS_HEPATITIS.include.yes');
t('studyEligibilityComparitivePhrase.CONDITIONS_HEPATITIS.include.no');
t('studyEligibilityComparitivePhrase.CONDITIONS_HEPATITIS.exclude.yes');
t('studyEligibilityComparitivePhrase.CONDITIONS_HEPATITIS.exclude.no');
t('studyEligibilityComparitivePhrase.CONDITIONS_HEPATITIS.undefined.yes');
t('studyEligibilityComparitivePhrase.CONDITIONS_HEPATITIS.undefined.no');
t('studyEligibilityComparitivePhrase.CONDITIONS_HYPERT.include.yes');
t('studyEligibilityComparitivePhrase.CONDITIONS_HYPERT.include.no');
t('studyEligibilityComparitivePhrase.CONDITIONS_HYPERT.exclude.yes');
t('studyEligibilityComparitivePhrase.CONDITIONS_HYPERT.exclude.no');
t('studyEligibilityComparitivePhrase.CONDITIONS_HYPERT.undefined.yes');
t('studyEligibilityComparitivePhrase.CONDITIONS_HYPERT.undefined.no');
t('studyEligibilityComparitivePhrase.CONDITIONS_HYPOT.include.yes');
t('studyEligibilityComparitivePhrase.CONDITIONS_HYPOT.include.no');
t('studyEligibilityComparitivePhrase.CONDITIONS_HYPOT.exclude.yes');
t('studyEligibilityComparitivePhrase.CONDITIONS_HYPOT.exclude.no');
t('studyEligibilityComparitivePhrase.CONDITIONS_HYPOT.undefined.yes');
t('studyEligibilityComparitivePhrase.CONDITIONS_HYPOT.undefined.no');
t('studyEligibilityComparitivePhrase.CONDITIONS_LIVER.include.yes');
t('studyEligibilityComparitivePhrase.CONDITIONS_LIVER.include.no');
t('studyEligibilityComparitivePhrase.CONDITIONS_LIVER.exclude.yes');
t('studyEligibilityComparitivePhrase.CONDITIONS_LIVER.exclude.no');
t('studyEligibilityComparitivePhrase.CONDITIONS_LIVER.undefined.yes');
t('studyEligibilityComparitivePhrase.CONDITIONS_LIVER.undefined.no');
t('studyEligibilityComparitivePhrase.CONDITIONS_PSYCH.include.yes');
t('studyEligibilityComparitivePhrase.CONDITIONS_PSYCH.include.no');
t('studyEligibilityComparitivePhrase.CONDITIONS_PSYCH.exclude.yes');
t('studyEligibilityComparitivePhrase.CONDITIONS_PSYCH.exclude.no');
t('studyEligibilityComparitivePhrase.CONDITIONS_PSYCH.undefined.yes');
t('studyEligibilityComparitivePhrase.CONDITIONS_PSYCH.undefined.no');

// Translation keys for eligibilityReviewPhrases
t('studyEligibilityReview.BRITTLE_yesNo.yes');
t('studyEligibilityReview.BRITTLE_yesNo.no');
t('studyEligibilityReview.MEDICATION_yesNo.yes');
t('studyEligibilityReview.MEDICATION_yesNo.no');
t('studyEligibilityReview.IMMUNOSUPPRESSANT_yesNo.yes');
t('studyEligibilityReview.IMMUNOSUPPRESSANT_yesNo.no');
t('studyEligibilityReview.PREGNANCY_yesNo.yes');
t('studyEligibilityReview.PREGNANCY_yesNo.no');
t('studyEligibilityReview.BREASTFEEDING_yesNo.yes');
t('studyEligibilityReview.BREASTFEEDING_yesNo.no');
t('studyEligibilityReview.TRY_PREGNANCY_yesNo.yes');
t('studyEligibilityReview.TRY_PREGNANCY_yesNo.no');
t('studyEligibilityReview.SECONDARY_NEPHRO_yesNo.yes');
t('studyEligibilityReview.SECONDARY_NEPHRO_yesNo.no');
t('studyEligibilityReview.SECONDARY_NEURO_yesNo.yes');
t('studyEligibilityReview.SECONDARY_NEURO_yesNo.no');
t('studyEligibilityReview.SECONDARY_RETINO_yesNo.yes');
t('studyEligibilityReview.SECONDARY_RETINO_yesNo.no');
t('studyEligibilityReview.CONDITIONS_CELIAC_yesNo.yes');
t('studyEligibilityReview.CONDITIONS_CELIAC_yesNo.no');
t('studyEligibilityReview.CONDITIONS_FOOTULCER_yesNo.yes');
t('studyEligibilityReview.CONDITIONS_FOOTULCER_yesNo.no');
t('studyEligibilityReview.CONDITIONS_GASTRO_yesNo.yes');
t('studyEligibilityReview.CONDITIONS_GASTRO_yesNo.no');
t('studyEligibilityReview.CONDITIONS_HEART_yesNo.yes');
t('studyEligibilityReview.CONDITIONS_HEART_yesNo.no');
t('studyEligibilityReview.CONDITIONS_HEPATITIS_yesNo.yes');
t('studyEligibilityReview.CONDITIONS_HEPATITIS_yesNo.no');
t('studyEligibilityReview.CONDITIONS_HYPERT_yesNo.yes');
t('studyEligibilityReview.CONDITIONS_HYPERT_yesNo.no');
t('studyEligibilityReview.CONDITIONS_HYPOT_yesNo.yes');
t('studyEligibilityReview.CONDITIONS_HYPOT_yesNo.no');
t('studyEligibilityReview.CONDITIONS_LIVER_yesNo.yes');
t('studyEligibilityReview.CONDITIONS_LIVER_yesNo.no');
t('studyEligibilityReview.CONDITIONS_PSYCH_yesNo.yes');
t('studyEligibilityReview.CONDITIONS_PSYCH_yesNo.no');
