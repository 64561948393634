import React from 'react';
import { Field, useField } from 'formik';
import { Grid, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { TextBox } from 'Components/Parts/Form/TextBoxes';
import ExpandableSelection from 'Components/Parts/Form/ExpandableSelection';
import TeamMemberAutocompleteBox from 'Components/Parts/Form/Autocompletes/TeamMember/TeamMemberAutocompleteBox';
import { SuccessText } from 'Components/Parts/Form/Validators/ValidationText';

const defaultValue = {
    name: '',
    email: ''
};

function TeamMemberInput({field}) {
    const { t } = useTranslation();
    const theme = useTheme();
    const [ , meta] = useField(field.name);
    return (
        <Grid container spacing={6}>
            <Grid item xs={6}>
                <Field
                    component={TeamMemberAutocompleteBox}
                    name={`${field.name}`}
                />
            </Grid>
            <Grid item xs={6}>
                <Field
                    component={TextBox} 
                    name={`${field.name}.email`}
                    type="email"
                    label={t('studySetupTeam.emailPlaceholder')}
                    variant="filled"
                />
                {field.value.email && !field.value.signupComplete && !meta.error && <SuccessText message={t('studySetupTeam.confirmationSend')} color={theme.palette.success.main} spacing={0.5}/>}
            </Grid>
        </Grid>
    );
}

function Section({title, subtitle, children}) {
    return (
        <Grid item container spacing={3}> 
            <SectionTitle title={title} subtitle={subtitle}/>
            <Grid item xs={12}>
                {children}
            </Grid>
        </Grid>
    );
}

function SectionTitle({title, subtitle}) {
    return (
        <Grid item container spacing={1}>
            <Grid item xs={12}>
                <Typography variant="h5">{title}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="subtitle1">{subtitle}</Typography>
            </Grid>
        </Grid>
    );
} 

export default function StudyTeam() {
    const { t } = useTranslation();
    return (
        <Grid container spacing={4}>
            <Section 
                title={t('studySetupTeam.principalInvestigatorTitle')}
                subtitle={t('studySetupTeam.principalInvestigatorDescription')}
            >
                <Field
                    name="studyTeam.principalInvestigator"
                    component={TeamMemberInput}
                />
            </Section>
            <Section 
                title={t('studySetupTeam.researchAssistantsTitle')} 
                subtitle={t('studySetupTeam.researchAssistantsDescription')}
            >
                <ExpandableSelection 
                    name="studyTeam.researchers" 
                    title={t('studySetupTeam.researchAssistantTitle')} 
                    defaultValue={_ => defaultValue}
                    component={TeamMemberInput}
                    minimum={1}
                    hideErrors
                />
            </Section>
            <Section 
                title={t('studySetupTeam.coinvestigatorsTitle')} 
                subtitle={t('studySetupTeam.coinvestigatorDescription')}
            >
                <ExpandableSelection 
                    name="studyTeam.coinvestigators" 
                    title={t('studySetupTeam.coinvestigator')} 
                    defaultValue={_ => defaultValue}
                    component={TeamMemberInput}
                    hideErrors
                />
            </Section>
        </Grid>
    );
}