import { produce, original } from "immer";
import * as Actions from "./actions";
import { ParticipantStatus } from 'Constants/constants';


const initialState = {
    study: null,
    studyResource: null,
    noteModal: {
        isOpen: false,
        participant: null,
    },
    [ParticipantStatus.INTERESTED]: {
        entries: [],
        pageSize: 0,
        totalEntries: 0,
        totalPages: 0,
    },
    [ParticipantStatus.RECRUITED]: {
        entries: [],
        pageSize: 0,
        totalEntries: 0,
        totalPages: 0,
    },
    [ParticipantStatus.DECLINED]: {
        entries: [],
        pageSize: 0,
        totalEntries: 0,
        totalPages: 0,
    },
};

function formatParticipantPayloadData(payload) {
    return {
        entries: payload.entries,
        pageSize: payload.pageSize,
        totalEntries: payload.totalEntries,
        totalPages: payload.totalPages,
        orderBy: payload.orderBy,
        order: payload.order,
    };
}

export default (state = initialState, action) =>
    produce(state, draft => {
        const { type, payload } = action;
        switch(type) {
            case Actions.RESET_MY_STUDY_PAGE:
                return initialState;
            case Actions.SET_INTERESTED_PARTICIPANTS:
                draft[ParticipantStatus.INTERESTED] = formatParticipantPayloadData(payload);
                break;
            case Actions.SET_RECRUITED_PARTICIPANTS:
                draft[ParticipantStatus.RECRUITED] = formatParticipantPayloadData(payload);
                break;
            case Actions.SET_DECLINED_PARTICIPANTS:
                draft[ParticipantStatus.DECLINED] = formatParticipantPayloadData(payload);
                break;
            case Actions.REMOVE_PARTICIPANT_FROM_LIST: {
                const { previousStatus, participantId } = payload;
                draft[previousStatus].entries = draft[previousStatus].entries.filter(p => p.patientUserId !== participantId);
                draft[previousStatus].totalEntries -= 1;
                break;
            }
            case Actions.UPDATE_MY_STUDY_PARTICIPANT_NOTE: {
                const { status, participantId, note } = payload;
                const patient = draft[status].entries.find(p => p.patientUserId === participantId);
                if (!patient) {
                    break;
                }
                patient.note = note;
                break;
            }
            case Actions.ADD_TO_MY_STUDY_PARTICIPANT_LIST: {
                const { entries, totalEntries, totalPages, status } = payload;
                draft[status].totalEntries = totalEntries;
                draft[status].totalPages = totalPages;

                const list = original(draft[status]);
                const existingPatientIds = list.entries.map(p => p.patientUserId);
                const newEntries = entries.filter(p => !existingPatientIds.includes(p.patientUserId));
                draft[status].entries = list.entries.concat(newEntries);
                break;
            }
            case Actions.OPEN_MY_STUDY_NOTE_MODAL:
                draft.noteModal.isOpen = true;
                draft.noteModal.participant = payload.participant;
                break;
            case Actions.CLOSE_MY_STUDY_NOTE_MODAL:
                draft.noteModal.isOpen = false;
                draft.noteModal.participant = null;
                break;
            case Actions.SET_MY_STUDY:
                draft.study = payload.study;
                break;
            case Actions.SET_MY_STUDY_RESOURCE:
                draft.studyResource = payload.study;
                break;
            default:
                return draft;
        }
    });
