import React from 'react';
import { SnackbarProvider } from 'notistack';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

const t = _ => {};
t('error.loginSessionEnded');
t('error.studyRetrieval');
t('error.questionnaireRetrieval');
t('error.unableToConnect');
t('error.registrationSendFail');
t('error.studyNotFound');
t('error.studyNotSaved');
t('error.submitFailed');
t('warning.loginSessionExpired');
t('info.loggedOut')
t('success.emailSent');
t('success.passwordReset');
t('success.registrationResent');
t('success.studySubmitted');

export default function GlobalMessagingProvider({children}) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{
                vertical: isMobile ? 'bottom' : 'top',
                horizontal: 'center',
            }}
            preventDuplicate={true}
        >
            {children}
        </SnackbarProvider>
    );
}