import React, { useEffect, useState } from 'react';
import { useParams, useHistory, useRouteMatch } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import getStudyRequest from 'api/study/getStudy';
import getResearchStudy from 'api/fhir/getResearchStudy';
import DetailsPage from './DetailsPage';
import FindingPage from './FindingPage';
import { StudyStatus, UserType } from 'Constants/constants';
import { getUserProfile } from 'redux/reducers/authorization/actions';
import { updateUserEligibilityStatus, updateUserInterest } from 'redux/reducers/userEligibility/actions';
import { Page } from 'Constants/constants';
import { isOnStudyTeam } from 'data/study/Details/isOnStudyTeam';
import MyStudy from '../MyStudy/MyStudyPage';

function StudyPage() {
    const history = useHistory();
    const dispatch = useDispatch();
    const isMyStudyPage = useRouteMatch(Page.MY_STUDY);
    const { userId, userEmail, accountType, profile } = useSelector(state => state.authorization);
    const { studyId } = useParams();
    const [study, setStudy] = useState();
    const [fhirBundle, setFhirBundle] = useState();
    const [studyStatus, setStudyStatus] = useState();
    const [previousProcessing, setPreviousProcessing] = useState(profile.processingEligibilities);
    const [refreshed, setRefreshed] = useState(!profile.processingEligibilities);
    const [isOnTeam, setIsOnTeam] = useState(false);

    useEffect(() => {
        getStudyRequest(studyId)
                .then(({ data }) => {
                    setStudy(data);
                    if (accountType !== UserType.PATIENT) {
                        setIsOnTeam(isOnStudyTeam(data.studyTeam, userEmail));
                    }
                    dispatch(updateUserEligibilityStatus(studyId, data.calculatedEligibilityStatus));
                    dispatch(updateUserInterest(studyId, data.interestedUserStatus));
                    return getResearchStudy(data.fhirId);
                })
                .then(({ data }) => {
                    setFhirBundle(data.entry);
                    
                    const study = data.entry.find(item => item.resource.resourceType === 'ResearchStudy')?.resource;
                    setStudyStatus(study?.status);
                })
                .catch(() => history.push('/dashboard/explore'))
                .finally(() => dispatch(getUserProfile(userId)));
    }, [studyId]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (fhirBundle) {
                if (profile.processingEligibilities) {
                    dispatch(getUserProfile(userId));
                } else if (previousProcessing && !profile.processingEligibilities && !refreshed) {
                    getStudyRequest(studyId)
                        .then(({ data }) => {
                            setStudy(data);
                            dispatch(updateUserEligibilityStatus(studyId, data.calculatedEligibilityStatus));
                            setRefreshed(true);
                        });
                }
            }
            setPreviousProcessing(profile.processingEligibilities)}, 5000);

        return _ => clearInterval(interval);
    }, [studyId, userId, profile, previousProcessing, refreshed]);

    function getStudyPage() {
        if (isMyStudyPage && isOnTeam) {
            return <MyStudy />;
        }
        if (studyStatus === StudyStatus.COMPLETED) {
            return <FindingPage study={study} fhirBundle={fhirBundle} />;
        }

        return <DetailsPage study={study} fhirBundle={fhirBundle} refreshed={refreshed}/>;
    }

    if (!studyStatus) {
        return null;
    }

    return getStudyPage();
}

export default StudyPage;
