import React from 'react';
import SelectEligibilityContainer from '../SelectEligibilityContainer';
import { geneticsOptions } from 'data/study/Eligibility/eligibilitySelectionOptions';


const name="eligibility.demographics.genetics";

// Register all the required translation keys
const t = _ => {};
t('studyEligibilityComparitivePhrase.demographicsGenetics.include.option');
t('studyEligibilityComparitivePhrase.demographicsGenetics.exclude.option');
t('studyEligibilityComparitivePhrase.demographicsGenetics.undefined.option');

export default function StudyEligibilityGenetics({title}) {
    return (
        <SelectEligibilityContainer
            criteria="demographicsGenetics"
            name={name}
            options={geneticsOptions}
            title={title}
        />
    );
}