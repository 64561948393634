import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import { ModalPaper } from 'Components/Styles/Modals/ModalContainers';

import BaseModal from '../BaseModal';
import { ReactComponent as AlertCircleIcon } from 'resources/images/icons/alertCircleLine.svg';
import { useHistory } from 'react-router';
import { FixedSizeIcon } from 'Components/Styles/Modals/Questionnaire';
import { TitleText, ModalButton } from 'Components/Styles/Modals/Components';
import { useDispatch, useSelector } from 'react-redux';
import { resetContents, toggleExitRequest } from 'redux/reducers/questionnaires/actions';

export default function ExitQuestionnaireModal({isBaseline, studyId}) {
    const {t} = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const exitRequestActive = useSelector(state => state.questionnaires.exitRequestActive);

    function handleClose() {
        dispatch(toggleExitRequest);
    }

    function handleConfirm() {
        if (isBaseline) {
            history.push("/dashboard");
        } else {
            history.push(`/study/${studyId}`)
        }
        dispatch(toggleExitRequest);
        dispatch(resetContents);
    }

    return (
        <BaseModal
            aria-describedby="baseline-questionnaire-complete-modal-description"
            aria-labelledby="baseline-questionnaire-complete-modal-title"
            onClose={handleClose}
            open={exitRequestActive}
        >
            <ModalPaper>
                <Grid container direction="column" alignItems="center" spacing={2}>
                    <Grid item>
                        <FixedSizeIcon component={AlertCircleIcon} color="primary" />
                    </Grid>
                    <Grid item>
                        <TitleText>{t('questionnaire.areYouSure')}</TitleText>
                    </Grid>
                    <Grid item>
                        {isBaseline 
                        ? <Trans i18nKey='questionnaire.baselineQuitRepercussions' t={t}>
                            <Typography variant="body2" color="textSecondary" paragraph align="center">This questionnaire helps to create a personalized experience for you on Connect1d Canada.</Typography>
                            <Typography variant="body2" color="textSecondary" align="center">Your progress will be saved, so you can always continue later.</Typography>
                        </Trans>
                        : <Trans i18nKey='questionnaire.eligibilityQuitRepercussions' t={t}>
                            <Typography variant="body2" color="textSecondary" paragraph align="center">This questionnaire helps confirm your eligibility to this study.</Typography>
                            <Typography variant="body2" color="textSecondary" align="center">Your progress will be saved, so you can always continue later by returning to the study to mark your interest.</Typography>
                        </Trans>}
                    </Grid>
                    <ModalButton variant="outlined" color="primary" size="small" onClick={handleClose}>{t('questionnaire.continueNow')}</ModalButton>
                    <ModalButton variant="contained" color="primary" size="small" onClick={handleConfirm}>{t('questionnaire.continueLater')}</ModalButton>
                </Grid>
            </ModalPaper>
        </BaseModal>
    );
}