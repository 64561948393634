import React from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { CompletedField } from '../ApprovalFields';
import ExternalLink from '../../Navigation/ExternalLink';

export default function StudyEthicsApproval({funder, letter}) {
    const { t } = useTranslation();
    const [url, setUrl] = React.useState();

    React.useEffect(() => {
        if (!url && letter) {
            setUrl(URL.createObjectURL(letter));
        }

        return _ => URL.revokeObjectURL(url);
    }, [letter]);

    return (
        <Grid container spacing={4}>
            <Grid item xs={12}>
                <CompletedField
                    label={t('studySetupEthics.funderPlaceholder')}
                    text={funder}
                />
            </Grid>
            <Grid item xs={12}>
                {url && <ExternalLink url={url}>
                    {t('studySetupEthics.rebApprovalLabel')}
                </ExternalLink>}
            </Grid>
        </Grid>
    );
}