import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import { RoundedButton } from 'Components/Styles/Buttons/RoundedButton';


export const ModalButton = styled(RoundedButton)`
    width: 100%;
    margin: 8px 0;
    justify-content: center;
`;

export const TitleText = styled(Typography)`
    font-size: 26px;
    font-weight: bold;
    line-height: 1.54;
    letter-spacing: -0.87px;
    text-align: center;
    color: ${props => props.color ? props.color : '#000'};
`;