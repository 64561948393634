import React from 'react';
import { Field } from 'formik';
import { DatePicker } from 'formik-material-ui-pickers';

export default function QuestionDate({name}) {
    return (
        <Field
            views={["year", "month", "date"]}
            openTo={"year"}
            component={DatePicker}
            name={name}
            format="dd/MM/yyyy"
            maxDate={new Date(Date.now())}
            autoOk
        />
    );
}