import React from 'react';
import { Chip } from '@material-ui/core';
import styled, { css } from 'styled-components';

const PrimaryChip = styled(Chip)`
    background-color: ${props => props.htmlcolor};
    color: #fff;
    cursor: pointer;
    font-size: 10px;
    font-weight: 600;
    height: 16px;
    position: relative;
    z-index: 2;

    ${props => props.variant === 'outlined' && css`
        background-color: #fff;
        border-color: ${props => props.htmlcolor};
        border-width: 2px;
        color: ${props => props.htmlcolor};
    `}
`;

const SecondaryChip = styled(Chip)`
    color: #fff;
    cursor: pointer;
    font-size: 10px;
    height: 16px;
    left: -22px;
    padding-left: 22px;
    position: relative;
    z-index: 1;

    svg {
        color: #fff;
    }
`;

export default function CardTag({ className, label, primaryColor, secondaryButtonText, secondaryButtonIcon, secondaryButtonColor, variant }) {
  const hasSecondary = secondaryButtonText && secondaryButtonIcon;

  return (
    <div className={className}>
      <PrimaryChip size="small" label={label} htmlcolor={primaryColor} variant={variant} />
      { hasSecondary &&
        <SecondaryChip size="small" label={secondaryButtonText} style={{backgroundColor: secondaryButtonColor}} icon={secondaryButtonIcon} />
      }
    </div>
  );
}
