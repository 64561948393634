import React from 'react';
import { Field } from 'formik';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

export default function EligibilitySelectedOptionsField() {
    const { t } = useTranslation();
    const categories = useSelector(state => state.eligibilityTracking.eligibilityCategories);
    return (
        <Field type="hidden" name="eligibility.selectedOptions" validate={() => {
            if (categories.length === 0) {
                return t('studySetupError.minOneEligibilityCriteria');
            }
        }}/>
    );
}