import React from 'react';
import {
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
} from '@material-ui/core';
import styled from 'styled-components';


const StyledListItem = styled(ListItem)`
    min-height: 71px;

    @media (max-width: 959px) {
        border-bottom: 1px solid #e6e6e6;
    }

    &.Mui-selected {
        background: #dbe3fb;
    }
`;

const IconWrapper = styled(ListItemIcon)`
    color: ${props => props.theme.palette.primary.main};
    margin-right: 8px;
    min-width: 0;
`;

const StyledListItemText = styled(ListItemText)`
    .MuiListItemText-primary {
        color: #212121;
    }

    .MuiListItemText-secondary {
        color: #939393;
    }
`;

const ActionWrapper = styled(ListItemSecondaryAction)`
    align-items: center;
    display: flex;
`;

function ListBaseRow({ icon, text, detailText, actionComponent, interactiveAction, ...props }) {
    return (
        <StyledListItem {...props}>
            {icon && 
                <IconWrapper>
                    {icon}
                </IconWrapper>
            }
            <StyledListItemText primary={text} secondary={detailText} />
            {actionComponent &&
                <ActionWrapper style={{pointerEvents: interactiveAction ? 'auto' : 'none'}}>
                    {actionComponent}
                </ActionWrapper>
            }
        </StyledListItem>
    );
}

ListBaseRow.defaultProps = {
    interactiveAction: false
}

export default ListBaseRow;
