// Register all the required translation keys
const t = _ => {};
t('sort.newest');
t('sort.oldest');
t('sort.aToZ');
t('sort.zToA');

export const sortOrderings = Object.freeze([
    Object.freeze({
       label: "sort.newest",
       value: "-_lastUpdated"
    }),
    Object.freeze({
        label: "sort.oldest",
        value: "_lastUpdated"
    }),
    Object.freeze({
        label: "sort.aToZ",
        value: "title"
    }),
    Object.freeze({
        label: "sort.zToA",
        value: "-title"
    }),
]);