import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@material-ui/core';
import { getServerVersion } from 'redux/reducers/appInfo/actions';
import { useTranslation, Trans } from 'react-i18next';
import { version } from 'package.alias.json';

export default function SettingsSoftwareVersion() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const serverVersion = useSelector(state => state.appInfo.serverVersion);

    React.useEffect(() => {
        dispatch(getServerVersion());
    }, []);

    return (
        <React.Fragment>
            <Typography>{t('settingsSoftwareVersion.productVersion')}</Typography>
            <Typography>
                <Trans key='settingsSoftwareVersion.client'>
                    Client v{{version}}
                </Trans>    
            </Typography>
            <Typography>
                <Trans key='settingsSoftwareVersion.server'>
                    Server v{{serverVersion}}
                </Trans>    
            </Typography>
        </React.Fragment>
    );
}