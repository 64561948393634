import styled, { css } from 'styled-components';
import { List } from '@material-ui/core';


export const FluidList = styled(List)`
    ${props => {
        const paddingMobile = props.theme.mixins.gutters().paddingLeft;
        const paddingDesktop = props.theme.mixins.gutters()['@media (min-width:600px)'].paddingLeft;
        return css`
            margin-left: -${paddingMobile}px;
            width: calc(100% + ${paddingMobile * 2}px);

            @media (min-width: 600px) {
                margin-left: -${paddingDesktop}px;
                width: calc(100% + ${paddingDesktop * 2}px);
            }

            @media (min-width: 960px) {
                margin-left: inherit;
                width: 100%;
            }
        `;        
    }};
`;
