import React from 'react';
import { Grid } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import CardDetailTextValue from './StudyReview/CardDetailTextValue';

const prettyBytes = require('pretty-bytes');

export default function DocumentPreview({files}) {
    if (files[0]) {
        const fileName = files[0].name;
        const fileSize = prettyBytes(files[0].size);
        const url = URL.createObjectURL(files[0]);
        return (
            <Grid container>
                <Grid item xs={12}>
                    <Grid item container justify="center">
                        <a href={url} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faFilePdf} color="red" size="8x"/></a>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid item container justify="center">
                        <CardDetailTextValue>{fileName}</CardDetailTextValue>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid item container justify="center">
                        <CardDetailTextValue>({fileSize})</CardDetailTextValue>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
    return null;
}