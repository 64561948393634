import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { scroller } from 'react-scroll';


const PageScrollController = () => {
    const location = useLocation();
    const [currentPathname, setCurrentPathname] = useState();

    useEffect(() => {

        if (location.hash) {
            const delay = location.pathname === currentPathname ? 0 : 1000;

            setTimeout(() => {
                const element = location.hash.slice(1);
                const hasElement = document.querySelectorAll(`[name="${element}"]`);

                if (hasElement.length !== 0) {
                    scroller.scrollTo(element, {
                        smooth: true,
                    });
                }
            }, delay)
        }
        
        if (location.pathname === currentPathname) {
            return;
        }

        window.scrollTo(0, 0);
        setCurrentPathname(location.pathname);
    }, [location]);

    return null;
};

export default PageScrollController;
