import produce from "immer";
import {
    USER_LOGIN,
    DISMISS_BASELINE,
    COMPLETED_BASELINE,
    CHANGED_LANGUAGE,
    CHANGED_COMMUNICATION,
    UPDATE_USER_PROFILE,
    USER_FHIR_PROFILE,
    UPDATE_PROFILE_QUALIFICATIONS,
    UPDATING_ELIGIBILITIES
} from "./actions";

const initialState = {
    userId: "",
    fhirId: "",
    userEmail: "",
    authorized: false,
    accountType: "",
    lastLoggedInAt: null,
    dismissedBaseline: false,
    profile: undefined,
    fhirProfile: undefined,
};

export default (state = initialState, action) =>
    produce(state, draft => {
        const {type, payload} = action;
        switch(type) {
            case USER_LOGIN:
                draft.authorized = true;
                draft.userId = payload.userId;
                draft.fhirId = payload.fhirId;
                draft.userEmail = payload.userEmail ?? draft.userEmail;
                draft.accountType = payload.accountType;
                draft.lastLoggedInAt = payload.lastLoggedInAt ? new Date(payload.lastLoggedInAt) : draft.lastLoggedInAt;
                draft.profile = payload.profile;
                break;
            case DISMISS_BASELINE:
                draft.dismissedBaseline = true;
                break;
            case COMPLETED_BASELINE:
                draft.profile.baselineQuestionnaireCompleted = true;
                draft.profile.processingEligibilities = true;
                break;
            case UPDATING_ELIGIBILITIES:
                draft.profile.processingEligibilities = true;
                break;
            case CHANGED_LANGUAGE:
                draft.profile.preferredLanguage = payload.language;
                break;
            case CHANGED_COMMUNICATION:
                draft.profile.allowsCommunication = payload.communication;
                break;
            case USER_FHIR_PROFILE:
                draft.fhirProfile = payload.profile;
                break;
            case UPDATE_USER_PROFILE:
                draft.profile = payload.profile;
                break;
            case UPDATE_PROFILE_QUALIFICATIONS:
                draft.fhirProfile.qualification = payload.qualifications.map(q => (
                    { code: {
                        coding: [{
                            display: q.label
                        }]
                    }
                }));
                break;
            default:
                return draft;
        }
    });