import styled from 'styled-components';
import { Paper } from '@material-ui/core';

export const ModalContainer = styled.div`
    background-color: #fff;
    border-radius: 1rem;
    height: 85%;
    left: 50%;
    outline: none;
    overflow: hidden;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 90%;

    @media (min-width: 960px) {
        max-width: 37.5rem;
    }
`;

export const ModalScrollView = styled.div`
    height: 100%;
    overflow-y: scroll;
    position: relative;
    width: 100%;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
        display: none;
    }
`;

export const ModalPaper = styled(Paper)`
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 75%;
    max-width: 540px;
    border: 0px;
    border-radius: 10px;
    padding: 2em;

    @media (max-width: 959px) {
        width: 90%;
        top: 50%;
    }
`;

export const ModalDescriptionSection = styled.div`
    min-height: 121px;
    text-align: center;
`;