import React from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, SvgIcon } from '@material-ui/core';
import { ReactComponent as ArrowForwardIcon } from 'resources/images/icons/arrowForwardIcon.svg';
import { ReactComponent as ArrowBackIcon } from 'resources/images/icons/arrowBackwardIcon.svg';
import { CircularButton } from 'Components/Styles/Buttons/CircularButton';
import { setQuestion } from 'redux/reducers/questionnaires/actions';
import QuestionnaireProgressBar from './QuestionnaireProgressBar';

const QuestionnaireFooterGrid = styled(Grid)`
    flex-grow: 1;
    padding-top: 1em;
    border-top: 2px solid rgb(236, 236, 236);
`;

export default function QuestionnaireFooter({formikValues, setFieldValue}) {
    const dispatch = useDispatch();
    const { questions, currentQuestion } = useSelector(state => state.questionnaires);
    const saving = useSelector(state => state.serverCommunication.saving);

    return (
        <QuestionnaireFooterGrid container alignItems="center" justify="space-between">
            <Grid item>
                <CircularButton 
                    variant="outlined" 
                    color="primary" 
                    onClick={() => dispatch(setQuestion(calculateTargetQuestion(formikValues, setFieldValue, questions, currentQuestion, "previous")))} 
                    disabled={currentQuestion===0}
                >
                    <SvgIcon component={ArrowBackIcon} color={currentQuestion===0 ? "disabled" : "primary"}/>
                </CircularButton>
            </Grid>
            <Grid item xs>
                <QuestionnaireProgressBar/>
            </Grid>
            <Grid item>
                <CircularButton 
                    variant="contained" 
                    color="primary" 
                    onClick={() => dispatch(setQuestion(calculateTargetQuestion(formikValues, setFieldValue, questions, currentQuestion, "next")))} 
                    type="submit"
                    disabled={saving || (currentQuestion < questions.length && !isAnswered(formikValues[questions[currentQuestion].linkId]))}
                >
                    <SvgIcon component={ArrowForwardIcon} htmlColor="#ffffff"/>
                </CircularButton>
            </Grid>
        </QuestionnaireFooterGrid>
    );
}

function calculateTargetQuestion(formikValues, setFieldValue, questions, currentQuestion, direction) {
    if (currentQuestion === questions.length-1 && direction === "next") {
        return questions.length;
    }

    const candidateQuestions = direction === "previous"
        ? questions.slice(0, currentQuestion).reverse()
        : questions.slice(currentQuestion+1, questions.length);

    const nextEnabled = candidateQuestions.find(question => isQuestionEnabled(question, setFieldValue, formikValues));
    if (!nextEnabled) {
        return questions.length;
    }
    const index = candidateQuestions.indexOf(nextEnabled) + 1;

    return direction === "previous"
        ? currentQuestion - index
        : currentQuestion + index;
}

function isQuestionEnabled(question, setFieldValue, formikValues) {
    const {enableWhen} = question;
    if (!enableWhen) {
        return true;
    }

    let enable = true;
    const formikValue = formikValues[enableWhen[0].question];
    const targetQuestion = formikValue.id ? formikValue.id : formikValue;
    const firstEnableCode = enableWhen[0].answerCoding.code;
    // we have some special codes embedded in some questions -> 
    //    the - prefix means ONLY the specified answer has been selected
    //    the + prefix means the specified answer AND some other answer has been selected
    //    the not: prefix means that the specified answer has NOT been selected
    if (firstEnableCode.startsWith('-')) {
        const answer = firstEnableCode.substring(1);
        if (Array.isArray(targetQuestion)) {
            enable = targetQuestion.filter(Boolean).length === 1 && answerCheck(targetQuestion.filter(Boolean)[0], answer);
        } else {
            enable = targetQuestion && answerCheck(targetQuestion, answer);
        }
    } else if (firstEnableCode.startsWith('+')) {
        const answer = firstEnableCode.substring(1);
        enable = Array.isArray(targetQuestion) && targetQuestion.filter(Boolean).length > 1 && targetQuestion.some(target => answerCheck(target, answer));
    } else if (firstEnableCode.startsWith('not:')) {
        const answer = firstEnableCode.substring(4);
        if (Array.isArray(targetQuestion)) {
            enable = targetQuestion.filter(Boolean).length > 0 && !targetQuestion.some(target => answerCheck(target, answer));
        } else {
            enable = targetQuestion && !answerCheck(targetQuestion, answer);
        }
    } else { 
        if (typeof targetQuestion === "string" || !targetQuestion.length) {
            enable = enableWhen.some(enable => answerCheck(targetQuestion, enable.answerCoding.code));
        } else {
            enable = enableWhen.some(enable => targetQuestion.some(target => answerCheck(target, enable.answerCoding.code)));
        }
    }

    if (!enable) {
        // we need to give a default value to these skipped questions
        giveDefaultAnswer(question, setFieldValue);
    }

    return enable;
}

function answerCheck(given, expected) {
    if (!given && expected) {
        return false;
    } else if (!given && !expected) {
        return true;
    }

    return given === expected 
        || given.id === expected
        || given.select === expected
        || given.select?.id === expected
        || (given.select?.id === "other" && given.text === expected);
}

function isAnswered(question) {
    if (Array.isArray(question)) {
        return question.length > 0;
    }
    return !answerCheck(question, "");
}

function giveDefaultAnswer(question, setFieldValue) {
    if (question.type === "integer") {
        setFieldValue(question.linkId, 0);
    } else {
        setFieldValue(question.linkId, getDefaultChoice(question));
    }
}

function getDefaultChoice(question) {
    switch (question.clientSelection) {
        case "deviceSelection":
            return "other";
        case "unitSelection":
            return "dontKnow";
        case "yesNoSelection":
        case "assistanceSelection":
        default:
            return "no";
    }
}