export function isOnStudyTeam(studyTeam, email) {
    if (!studyTeam) {
        return false;
    }

    return isOnStudyTeamHelper(studyTeam, email);
}

function isOnStudyTeamHelper({principalInvestigator, researchAssistants, coinvestigators}, email) {
    if (principalInvestigator.email === email
        || researchAssistants.find(ra => ra.email === email)
        || coinvestigators.find(ci => ci.email === email)) {
        return true;
    }

    return false;
}