import React from 'react';
import { timeframeSelection } from 'data/study/Eligibility/eligibilitySelectionOptions';
import SelectEligibilityContainer from '../SelectEligibilityContainer';

const name="eligibility.a1c.timeframe";

// Register all the required translation keys
const t = _ => {};
t('studyEligibilityComparitivePhrase.a1cTimeframe.include.option');
t('studyEligibilityComparitivePhrase.a1cTimeframe.exclude.option');
t('studyEligibilityComparitivePhrase.a1cTimeframe.undefined.option');

export default function StudyEligibilityA1cTimeframe({title}) {
    return (
        <SelectEligibilityContainer
            criteria="a1cTimeframe"
            name={name}
            options={timeframeSelection} 
            title={title}
        />
    );
}