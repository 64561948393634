import { axiosInstance } from '../api';


export default function(id, force) {
    return axiosInstance.get(`/fhir/Practitioner/${id}`, {
        headers: force ? {
            'Cache-Control': 'no-cache'
        } : undefined
    });
}
