import React from 'react';
import { Field } from 'formik';
import { FormControlLabel, Typography } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import { useTranslation } from 'react-i18next';

export default function QuestionString({name, prefix}) {
    const { t } = useTranslation();
    return (
        <FormControlLabel
            control={<Field
                    component={TextField} 
                    name={name} 
                />}
            label={<Typography variant="body2" color="textSecondary">{t(prefix)}</Typography>}
            labelPlacement="bottom"
        />
    );
}