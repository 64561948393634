import React from 'react';
import { useTranslation } from 'react-i18next';

import Dropdown from 'Components/Parts/Form/Dropdowns/SingleDropdown';
import { getLanguageText, getLocaleCode } from 'translations/i18n';


const LanguageValues = [
    { id: 'en-CA', getLabel: _ => getLanguageText('en-CA') },
    { id: 'fr-CA', getLabel: _ => getLanguageText('fr-CA') },
];

export const getLanguageOption = locale => {
    const option = LanguageValues.find(option => getLocaleCode(option.id) === getLocaleCode(locale));
    return option ?? LanguageValues[0];
};

function AccountTypeDropdown({ onSelect }) {
    const { t, i18n } = useTranslation();

    const handleSelection = event => {
        const locale = event.target.value.id
        i18n.changeLanguage(locale);
        onSelect && onSelect(locale);
    };

    return (
        <Dropdown
            name="language"
            defaultText={t('changeLangaugeModal.title')}
            values={LanguageValues}
            handleChange={handleSelection}
        />
    );
}

export default AccountTypeDropdown;
