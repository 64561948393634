import React from 'react';
import styled from 'styled-components';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';

const StyledBackdrop = styled(Backdrop) `
    z-index: ${props => props.theme.zIndex.drawer + 1};
    color: #fff;
`;

export default function GlobalProgressOverlay({text, open}) {
    const { t } = useTranslation();

    return (
        <StyledBackdrop open={open}>
            <Grid container direction="column" alignItems="center" spacing={4}>
                <CircularProgress color="primary" />
                <Typography>{text ? text : t('loading')}</Typography>
            </Grid>
        </StyledBackdrop>
    );
}