import { createMuiTheme } from "@material-ui/core";


export const Gradiant = {
    primary: 'linear-gradient(103deg, #6078ea -10%, #17ead9 132%)',
};

const mainTheme = {
    palette: {
        primary: {
            main: '#5b80e9'
        },
        secondary: {
            main: '#2cc7de',
            contrastText: '#fff',
        },
        success: {
            main: '#2cc7de',
        },
        info: {
            main: '#5b80e9',
        },
        warning: {
            main: '#ffbb38',
        },
        error: {
            main: '#f10ebc',
        },
        background: {
            default: '#ecf1fd',
        }
    },
    typography: {
        fontFamily: "'Inter', 'Roboto', 'Varela Round', 'Helvetica', 'Quicksand', 'Arial', sans-serif",
        h1: {
            fontSize: '3rem',
            fontWeight: 700,
        },
        h2: {
            fontFamily: "'Arial Rounded MT Bold', Arial",
            fontSize: '49px',
            fontWeight: 400,
            lineHeight: 1.39,
        },
        h4: {
            fontSize: '1.5rem',
            fontWeight: 600,
        },
        h5: {
            fontSize: '1.25rem',
            fontWeight: 700,
        },
        h6: {
            fontSize: '1.125rem',
            letterSpacing: '0.0075em',
            fontWeight: 600
        },
        body1: {
            fontSize: '21px',
            lineHeight: '1.57',
        },
        body2: {
            fontSize: '16px'
        },
        subtitle1: {
            fontFamily: 'Inter',
            fontSize: '12px',
            lineHeight: 1.25,
            color: '#939393',
        },
    },
    overrides: {
        MuiButton: {
            contained: {
                boxShadow: 'none',
            },
        },

        MuiPickersCalendarHeader: {
            transitionContainer: {
                height: '36px',
            }
        }
    },
};

export const getMainTheme = () => {
    const theme = createMuiTheme(mainTheme);
    theme.overrides.MuiTypography = {
        h1: {
            [theme.breakpoints.down('sm')]: {
                fontSize: '40px',
                lineHeight: 1.4,
            },
        },
        h2: {
            [theme.breakpoints.down('sm')]: {
                fontSize: '34px',
                lineHeight: 1.35,
            },
        },
        h4: {
            [theme.breakpoints.down('sm')]: {
                fontSize: '1.5rem',
            },
        },
        body1: {
            [theme.breakpoints.down('sm')]: {
                fontSize: '16px',
                lineHeight: 1.5,
            },
        },
    };
    return theme;
};

export default mainTheme;
