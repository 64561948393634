import React from 'react';
import {
    FormControlLabel,
    Grid,
    Radio,
    Typography,
} from '@material-ui/core';
import styled from 'styled-components';


const StyledFormControlLabel = styled(FormControlLabel)`
    border-bottom: 1px solid #e6e6e6;
    padding: 28px 0;
    margin: 0;

    &:first-child {
        border-top: 1px solid #e6e6e6;
    }

    .MuiButtonBase-root {
        padding: 0;
    }
`;

const Flag = styled(Typography)`
    padding-right: 16px;
`;

const Subtitle = styled(Typography)`
    color: rgba(0, 0, 0, 0.38);
    font-size: 12px;
    font-style: italic;
    margin-left: 8px;
`;

const LanguageRadio = ({ label, subtitle, flag, ...props }) => {
    return (
        <Grid container alignItems="center" justify="space-between" wrap="nowrap">
            <div>
                <Flag display="inline">{flag}</Flag>
                <Typography display="inline">{label}</Typography>
                {subtitle && <Subtitle display="inline">({subtitle})</Subtitle>}
            </div>
            <Radio color="primary" {...props} />
        </Grid>
    );
};

const LanguageControlLabel = ({ value, ...props }) => {
    return (
        <StyledFormControlLabel
            value={value}
            control={<LanguageRadio color="primary" {...props} />}
            labelPlacement="start"
        />
    );
};

export default LanguageControlLabel;
