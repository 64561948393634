import React from 'react';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import DetailsCard from './DetailsCard';


const GridSpacing = 8;

const DetailGridWrapper = styled(Grid)`
    margin-left: -${GridSpacing}px;
    width: calc(100% + ${GridSpacing * 2}px);
`;

const SpacedCard = styled(Grid)`
    margin: ${GridSpacing}px;

    &:first-child {
        margin-top: 0;
    }
`;

export default function DetailsGrid({contents}) {
    return (
        <DetailGridWrapper container spacing={0}>
            <Grid item xs={12} md={7} container direction="column" spacing={0}>
                <SelectDetailsCard name="eligibility" contents={contents}/>
                <SelectDetailsCard name="personalData" contents={contents}/>
                <SelectDetailsCard name="compensation" contents={contents}/>
                <SelectDetailsCard name="commitment" contents={contents}/>
            </Grid>
            <Grid item xs={12} md={5} container direction="column" spacing={0}>
                <SelectDetailsCard name="location" contents={contents}/>
                <SelectDetailsCard name="ethicsApproval" contents={contents}/>
                <SelectDetailsCard name="studyTeam" contents={contents}/>
            </Grid>
        </DetailGridWrapper>
    );
}

function SelectDetailsCard({name, contents}) {
    const item = contents.find(content => content.name === name);
    const Component = item.detailsComponent;

    return (
        <SpacedCard item>
            <DetailsCard name={name}>
                <Component {...item.content}/>
            </DetailsCard>
        </SpacedCard>
    );
}