import React from 'react';
import { ReactComponent as PersonOutlineOutlinedIcon } from 'resources/images/icons/study/personOutline.svg';
import Grid from '@material-ui/core/Grid';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import CardDetailTextValue from 'Components/Parts/Study/StudyReview/CardDetailTextValue';

export default function TeamMemberAutocompleteSelector({option, inputValue, onFetchAction}) {
    const matches = match(option.name, inputValue);
    const parts = parse(option.name, matches);

    function handleClick() {
        onFetchAction({
            name: option.name,
            email: option.email,
            signupComplete: true
        });
    }

    return (
        <Grid container alignItems="center" onClick={handleClick}>
            <Grid item>
                <PersonOutlineOutlinedIcon />
            </Grid>
            <Grid item xs>
                {parts.map((part, index) => (
                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                        {part.text}
                    </span>
                ))}

                <CardDetailTextValue>
                    {option.email}
                </CardDetailTextValue>
            </Grid>
        </Grid>
    );
}