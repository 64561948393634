import React from 'react';
import styled from 'styled-components';
import { Grid, Checkbox as MuiCheckbox, Typography } from '@material-ui/core';
import { Field, useField } from 'formik';
import { Checkbox } from 'formik-material-ui';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import { WizardDataRowTitle } from 'Components/Styles/Typography/Heading';

const CustomCheckbox = styled(Checkbox)`
    color: rgba(0, 0, 0, 0.12);
`;

const CustomMuiCheckbox = styled(MuiCheckbox)`
    color: rgba(0, 0, 0, 0.12);
`;

function InputDescription({title, description}) {
    return (
        <Grid>
            <Grid item>
                <WizardDataRowTitle>{title}</WizardDataRowTitle>
            </Grid>
            <Grid item>
                {description && <Typography variant="subtitle1" style={{whiteSpace: 'pre-line'}}>{description}</Typography>}
            </Grid>
        </Grid>
    );
}

export default function WizardDataRow({title, description, required, children}) {
    return (
        <Grid item container spacing={5}>
            <Grid item xs={4}>
                <InputDescription title={title} description={description} required={required}/>
            </Grid>
            <Grid item xs={8}>
                {children}
            </Grid>
        </Grid>
    );
}

export function WizardCheckboxRow({title, description, checked, handleClick}) {
    return (
        <WizardCheckboxBaseRow
            title={title}
            description={description}
            checkboxObject={<CustomMuiCheckbox
                type="checkbox"
                color="primary"
                checked={checked}
                checkedIcon={<CheckBoxOutlinedIcon/>}
                onClick={handleClick}
            />}
        />
    );
}

export function WizardCheckboxFieldRow({name, title, description, children}) {
    const [field] = useField(name);

    return (
        <WizardCheckboxBaseRow
            title={title}
            description={description}
            showChildren={field.value}
            children={children}
            checkboxObject={<Field
                type="checkbox"
                component={CustomCheckbox}
                checked={field.value}
                checkedIcon={<CheckBoxOutlinedIcon/>}
                color="primary" 
                name={name}
            />}
        />
    );
}

function WizardCheckboxBaseRow({title, description, showChildren, children, checkboxObject}) {
    return (
        <Grid item container spacing={2}>
            <Grid item xs={1}>
                {checkboxObject}
            </Grid>
            <Grid container item xs={11} spacing={2}>
                <Grid item xs={12}>
                    <InputDescription title={title} description={description}/>
                </Grid>
                <Grid item xs={12}>
                    {showChildren && children}
                </Grid>
            </Grid>
        </Grid>
    );
}