import React from 'react';
import styled from 'styled-components';
import { Grid, Typography } from '@material-ui/core';

const LabelText = styled(Typography)`
font-size: 12px;
line-height: 16px;
letter-spacing: 0.4px;
`;

export function CompletedField({label, text}) {
    return (
        <Grid container>
            <Grid item xs={12}>
                <LabelText color="primary">{label}</LabelText>
            </Grid>
            <Grid item xs={12}>
                <Typography>{text}</Typography>
            </Grid>
        </Grid>
    );
}