import React, { useState } from 'react';
import {
    Collapse,
    Paper,
    Table,
    TableBody,
    TableHead,
    TableContainer,
    useMediaQuery,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import styled from 'styled-components';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';

import ParticipantTableToolbar from './Table/ParticipantTableToolbar';
import ParticipantTableHead from './Table/ParticipantTableHead';
import ParticipantRow from './Table/ParticipantRow';
import { getNextPageForList } from 'redux/reducers/myStudy/actions';
import { ParticipantStatus } from 'Constants/constants';


// Show 4 rows. Header is 53px and each row is 80px
const StyledTableContainer = styled(TableContainer)`
    height: 373px;
    width: 100%;
`;

const StyledTable = styled(Table)`
    background-color: none;

    @media (min-width: 960px) {
        .MuiTableCell-root:first-child {
            padding-left: 31px;
        }

        .MuiTableCell-root:last-child {
            padding-right: 24px;
        }
    }
`;

export default function ParticipantList({ className, title, icon, status, expand=false }) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [open, setOpen] = useState(expand);
    const dispatch = useDispatch();
    const listData = useSelector(state => state.myStudy[status]);
    const lastLoggedInAt = useSelector(state => state.authorization.lastLoggedInAt);

    const handleExpandClick = () => {
        setOpen(!open);
    };

    return (
        <Paper className={className}>
            <ParticipantTableToolbar
                title={`${title} (${listData.totalEntries})`}
                icon={icon}
                expand={open}
                onExpandClick={handleExpandClick}
            />
            <Collapse in={open} timeout="auto" unmountOnExit>
                <InfiniteScroll
                    dataLength={listData.entries.length}
                    next={() => dispatch(getNextPageForList(status))}
                    hasMore={listData.entries.length !== listData.totalEntries}
                    scrollableTarget={`participant-list--${status}`}
                >
                    <StyledTableContainer id={`participant-list--${status}`}>
                        <StyledTable padding={isMobile ? "none" : "default"} stickyHeader>
                            <TableHead>
                                <ParticipantTableHead status={status} />
                            </TableHead>
                            <TableBody>
                                {listData.entries.map((participant, i) => (
                                    <ParticipantRow
                                        isNew={status === ParticipantStatus.INTERESTED && new Date(participant.interestedDate) > lastLoggedInAt}
                                        key={`patient-${i}`}
                                        participant={participant}
                                        status={status}
                                    />
                                ))}
                            </TableBody>
                        </StyledTable>
                    </StyledTableContainer>
                </InfiniteScroll>
            </Collapse>
        </Paper>
    );
};
