import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';


export default function CompletedStudies() {
    const { t } = useTranslation();
    return (
        <Typography variant="body2">{t('dashboardPage.completedStudiesDescription')}</Typography>
    );
}
