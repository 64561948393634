import React from 'react';
import { Grid, SvgIcon } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { ReactComponent as CheckCircleOutlineIcon } from 'resources/images/icons/study/checkmarkCircledOutline.svg';
import { ReactComponent as ReportProblemOutlinedIcon } from 'resources/images/icons/study/alertTriangleFill.svg';

export default function MenuItem({title, id}) {
    const { completeList, errorList } = useSelector(state => state.studyCreation);
    const isCompleted = completeList.indexOf(id) >= 0;
    const hasError = errorList.indexOf(id) >= 0;

    return (
        <Grid container justify="space-between">
            <Grid item>
                {title}
            </Grid>
            <Grid item>
                {isCompleted && <SvgIcon component={CheckCircleOutlineIcon} htmlColor="#fff"/>}
                {hasError && <SvgIcon component={ReportProblemOutlinedIcon} htmlColor="#fff"/>}
            </Grid>
        </Grid>
    );
}