import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import GlobalUserMessage from './GlobalUserMessage';
import { ackGlobalUserMessageState } from 'redux/reducers/globalUserMessaging/index';

export default function GlobalMessageDisplay() {
    const dispatch = useDispatch();
    const messages = useSelector(state => state.globalUserMessaging.messages);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [displayed, setDisplayed] = React.useState([]);

    React.useEffect(() => {
        messages.forEach(({key, visible, urgency, message, source}, i) => {
            if (!visible) {
                closeSnackbar(key);
                return;
            }

            if (displayed.includes(key)) {
                return;
            }

            enqueueSnackbar(message, 
            {
                key,
                content: (<GlobalUserMessage key={key} message={message} variant={urgency} hasNavbar={source === "dashboard" && i===0}/>),
                onExited: (event, myKey) => {
                    dispatch(ackGlobalUserMessageState(myKey));
                    setDisplayed(displayed.filter(message => message.key !== myKey));
                }
            });

            setDisplayed([...displayed, key]);
        });
    }, [messages, dispatch, enqueueSnackbar, closeSnackbar])

    return null;
}