import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
    AppBar,
    Grid,
    IconButton,
    Toolbar,
    Fade,
    useScrollTrigger,
    Menu,
    MenuItem,
    SvgIcon,
    Typography,
    useMediaQuery,
    Popover,
} from '@material-ui/core';
import {
  usePopupState,
  bindTrigger,
  bindPopover
} from 'material-ui-popup-state/hooks'
import { useTheme } from '@material-ui/core/styles';
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { UserType, Page } from 'Constants/constants';
import { ReactComponent as Connect1dHeaderLogo } from 'resources/images/logos/logoTitleBar.svg';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertOutlinedIcon from '@material-ui/icons/MoreVertOutlined';
import { RoundedButton } from 'Components/Styles/Buttons/RoundedButton';
import { ReactComponent as PersonOutlineSmallIcon } from 'resources/images/icons/personOutlineSmall.svg';
import AccountButtons from './AccountButtons';
import MyStudyMobileMenu from './MyStudyMobileMenu';
import { getUserFhirProfile } from 'redux/reducers/account/actions';
import { openLanguageModal, openLogoutModal } from 'redux/reducers/dashboard/actions';
import { ReactComponent as Connect1DIcon } from 'resources/icons/connect1dIcon.svg';
import MobileCombinationMenu from 'Components/Parts/Dashboard/StudyActions/MobileCombinationMenu';
import { ReactComponent as ArrowBackIcon } from 'resources/images/icons/arrowBackwardIcon.svg';
import { CircularButton } from 'Components/Styles/Buttons/CircularButton';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { ReactComponent as InfoOutlinedIcon } from 'resources/images/icons/study/infoOutline.svg';
import { ReactComponent as QuestionOutlineIcon } from 'resources/icons/questionOutlineIcon.svg';
import LogoutModal from 'Components/Parts/Modals/AccountModals/LogoutModal';
import ChangeLanguageModal from 'Components/Parts/Modals/AccountModals/ChangeLanguageModal';
import { closeLanguageModal, closeLogoutModal } from 'redux/reducers/dashboard/actions';
import { getLocaleCode } from 'translations/i18n';


export const PagesWithSortMenu = [
    Page.EXPLORE,
];

const StyledAppBar = styled(AppBar)`
    background: linear-gradient(147deg, #6078ea 40%, #17ead9 132%);
    overflow: hidden;
`;

const StyledToolbar = styled(Toolbar)`
    justify-content: space-between;
`;

const RippleWrapper = styled(Grid)`
    position: relative;
    width: auto;
`;

const Connect1dRippleWrapper = styled(RippleWrapper)`
    margin-left: -13px;
`;

const RippleImage = styled.img`
    left: 16%;
    position absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
`;

const IconRippleImage = styled(RippleImage)`
    left: 47%;
    top: 46%;
`;

const StyledMobileTitle = styled(Typography)`
    font-size: 20px;
    font-weight: 700;
`;

const BackButton = styled(CircularButton)`
    margin-right: 16px;
`;

const TitleIconWrapper = styled.div`
    align-items: center;
    display: flex;
    margin-right: 8px;
`;

const TitleLogo = styled(Connect1dHeaderLogo)`
    cursor: pointer;
    height: 67px;
    margin-left: 24px;
    width: 200px;
`;

function HideOnScroll({children}) {
    const trigger = useScrollTrigger();
  
    return (
        <Fade appear={false} direction="down" in={!trigger}>
            {children}
        </Fade>
    );
}

function DesktopConnect1dLogoIcon() {
    const history = useHistory();
    return <SvgIcon component={TitleLogo} onClick={_ => history.push("/dashboard")} viewBox="0 0 202.6 59.2" />;
}

function DesktopTitle() {
    return (
        <Connect1dRippleWrapper>
            <RippleImage src="/images/authorization/ripple.svg" />
            <DesktopConnect1dLogoIcon />
        </Connect1dRippleWrapper>
    );
}

const getPageData = (t, pathname) => ({
    [Page.DASHBOARD]: {
        title: t('tabBar.myStudies'),
    },
    [Page.FINDINGS]: {
        title: t('tabBar.findings'),
    },
    [Page.EXPLORE]: {
        title: t('tabBar.explore'),
    },
    [Page.SETTINGS]: {
        title: t('tabBar.settings'),
    },
    [Page.SETTINGS_ACCOUNT]: {
        title: t('settingsPage.accountRowText'),
        icon: <LockOutlinedIcon />,
    },
    [Page.SETTINGS_ABOUT]: {
        title: t('settingsPage.aboutRowText'),
        icon: <SvgIcon component={InfoOutlinedIcon} />,
    },
    [Page.SETTINGS_DATA]: {
        title: t('settingsPage.yourDataRowText'),
        icon: <SvgIcon component={InfoOutlinedIcon} />,
    },
    [Page.SETTINGS_HELP]: {
        title: t('settingsPage.helpText'),
        icon: <SvgIcon component={QuestionOutlineIcon} viewBox="0 0 32 33" />,
    },
    [Page.CHANGE_PASSWORD]: {
        title: t('accountSettingsPage.changePasswordText'),
    },
    [Page.SOFTWARE_VERSION]: {
        title: t('aboutSettingsPage.softwareVersionText'),
    },
}[pathname]);

function MobileTitle() {
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const [title, setTitle] = useState();
    const [showBackButton, setShowBackButton] = useState(false);
    const [titleIcon, setTitleIcon] = useState(undefined);

    useEffect(() => {
        const pageData = getPageData(t, location.pathname);
        setTitle(pageData?.title);
        setTitleIcon(pageData?.icon);
        setShowBackButton(location.pathname.startsWith(`${Page.SETTINGS}/`));
    }, [location]);

    return (
        title
            ?   <Grid container item alignItems="center" wrap="nowrap">
                    <RippleWrapper container item alignItems="center">
                        {showBackButton
                            ?   <BackButton onClick={history.goBack}>
                                    <SvgIcon component={ArrowBackIcon} htmlColor="#fff" />
                                </BackButton>
                            :   <MobileConnect1dLogoIcon/>
                        }
                        <IconRippleImage src="/images/authorization/ripple.svg" />
                    </RippleWrapper>
                    {titleIcon && 
                        <TitleIconWrapper>{titleIcon}</TitleIconWrapper>
                    }
                    <StyledMobileTitle>{title}</StyledMobileTitle>
                </Grid>
            :   <Connect1dRippleWrapper>
                    <RippleImage src="/images/authorization/ripple.svg" />
                    <DesktopConnect1dLogoIcon/>
                </Connect1dRippleWrapper>
    );
}

function MobileConnect1dLogoIcon() {
    const { i18n } = useTranslation();

    let icon = Connect1DIcon;
    let viewBox = "0 0 49 49";

    return <SvgIcon component={icon} viewBox={viewBox} style={{fontSize: '49px'}}/>
}

function DesktopItems() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const isMenuOpen = Boolean(anchorEl);
    const { authorized, fhirProfile, fhirId } = useSelector(state => state.authorization);
    const [name, setName] = React.useState("Name");

    React.useEffect(() => {
        if (fhirProfile) {
            setName(fhirProfile.name[0].given[0]);
        } else if (fhirId) {
            dispatch(getUserFhirProfile());
        }
    }, [fhirProfile, fhirId]);

    if (!authorized) {
        return null;
    }

    function handleMenuOpen(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleMenuClose() {
        setAnchorEl(null);
    }

    function handleLogout() {
        handleMenuClose();
        dispatch(openLogoutModal());
    }

    function handleChangeLanguage() {
        handleMenuClose();
        dispatch(openLanguageModal());
    }

    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={handleChangeLanguage}>{t('dashboardMenuItem.changeLanguage')}</MenuItem>
            <MenuItem onClick={handleLogout}>{t('dashboardMenuItem.logOut')}</MenuItem>
        </Menu>
    );

    return (
        <React.Fragment>
            {/* currently this code for initiating study setup from the navbar is disabled to avoid running into bugs with 2 active drafts
                re-enable when this issue is fixed 
            
              {accountType === UserType.RESEARCHER &&
                <Tooltip title={t('tooltips.createStudy')}>
                    <IconButton onClick={() => history.push("/setup")}>
                        <AddCircleOutlineIcon fontSize="large" style={{color: '#fff'}} />
                    </IconButton>
                </Tooltip>
            } */}
            <RoundedButton 
                variant="outlined" 
                style={{color: '#fff'}} 
                startIcon={<PersonOutlineSmallIcon fill='#fff' />} 
                endIcon={<ExpandMoreIcon/>}
                onClick={handleMenuOpen}
            >
                {name}
            </RoundedButton>
            {renderMenu}
        </React.Fragment>
    );
}

function MobileItems() {
    const isMyStudyPage = useRouteMatch(Page.MY_STUDY);
    const location = useLocation();
    const popupState = usePopupState({ variant: 'popover', popupId: `mobile-nav-popup-menu` });
    const { authorized, accountType } = useSelector(state => state.authorization);

    function handleMenuClose() {
        popupState.close();
    }

    let menu = null;
    if (authorized && accountType === UserType.RESEARCHER && isMyStudyPage) {
        menu = <MyStudyMobileMenu handleMenuClose={handleMenuClose} />
    } else if (PagesWithSortMenu.includes(location.pathname)) {
        return <MobileCombinationMenu />
    }

    if (menu === null) {
        return null;
    }

    return (
        <React.Fragment>
            <IconButton {...bindTrigger(popupState)}>
                <MoreVertOutlinedIcon style={{color: '#fff'}} />
            </IconButton>
            <Popover
                {...bindPopover(popupState)}
                anchorOrigin={{
                  horizontal: 'right',
                  vertical: 'bottom',
                }}
                transformOrigin={{
                    horizontal: 'right',
                    vertical: 'top',
                }}
            >
                {menu}
            </Popover>
        </React.Fragment>
    );
}

export default function DashboardNavbar() {
    const dispatch = useDispatch();
    const theme = useTheme();
    const mdUp = useMediaQuery(theme.breakpoints.up('md'));
    const authorized = useSelector(state => state.authorization.authorized);
    const showLanguageModal = useSelector(state => state.dashboard.showLanguageModal);
    const showLogoutModal = useSelector(state => state.dashboard.showLogoutModal);

    return (
        <React.Fragment>
            <HideOnScroll>
                <StyledAppBar elevation={0}>
                    <StyledToolbar>
                        {mdUp
                            ? <DesktopTitle />
                            : <MobileTitle />
                        }
                        <div>
                            {!authorized && 
                                <AccountButtons allowMobile/>
                            }
                            {mdUp
                                ? <DesktopItems />
                                : <MobileItems />
                            }
                        </div>
                    </StyledToolbar>
                </StyledAppBar>
            </HideOnScroll>
            <ChangeLanguageModal open={showLanguageModal} onClose={() => dispatch(closeLanguageModal())} />
            <LogoutModal open={showLogoutModal} onClose={() => dispatch(closeLogoutModal())} />
        </React.Fragment>
    );
}