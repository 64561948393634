import React from 'react';
import { useField, Field, FieldArray } from 'formik';
import { Grid } from '@material-ui/core';
import AdditionButton from 'Components/Parts/Buttons/AdditionButton';
import RemovalButton from '../Buttons/RemovalButton';
import { MinorErrorMessage } from 'Components/Styles/Form/ErrorMessage';

function ExpandableSelection({name, defaultValue, title, component, minimum, hideErrors}) {
    const [field] = useField(name);
    const values = field.value;

    const handleAdd = (arrayHelpers) => {
        const value = defaultValue?.(values.length);
        arrayHelpers.push(value);
    };

    return (
        <React.Fragment>
            <FieldArray
                name={name}
                render={arrayHelpers =>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {values.map((item, i) =>
                                <Grid key={i} container alignItems="center" spacing={4}>
                                    <Grid item xs={12}>
                                        <Field
                                            name={`${name}.${i}`}
                                            component={component}
                                            placeholder={title && `${title} ${i + 1}`}
                                            onClear={_ => arrayHelpers.remove(i)}
                                        />
                                    </Grid>
                                </Grid>)}
                        </Grid>
                        <Grid item container justify="space-between">
                            <AdditionButton onClick={_ => handleAdd(arrayHelpers)} size="small"/>
                            {values.length > minimum && <RemovalButton onClick={_ => arrayHelpers.remove(values.length-1)} size="small"/>}
                        </Grid>
                    </Grid>}
            />
            {!hideErrors && <MinorErrorMessage name={name}/>}
        </React.Fragment>
    );
}

ExpandableSelection.defaultProps = {
    minimum: 0,
    hideErrors: false
};

export default ExpandableSelection;
