import React from 'react';
import { Route, Redirect, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { isAuthorized } from 'redux/reducers/authorization/actions';
import { UserType } from 'Constants/constants';

function AuthorizedRoute({ component: Component, alternate, requiredCredentials, failQuietly, checkAuthOnly, ...rest }) {
    const dispatch = useDispatch();
    const location = useLocation();
    const {authorized, accountType} = useSelector(state => state.authorization);
    const [authDetermined, setAuthDetermined] = React.useState(authorized);

    function isApproved() {
        return authorized && (!requiredCredentials || accountType === UserType.ADMIN || requiredCredentials === accountType);
    }
    
    React.useEffect(() => {
        if (!authorized) {
            dispatch(isAuthorized((failQuietly || checkAuthOnly), () => {
                setAuthDetermined(true);
            }));
        }
    }, [authorized, dispatch]);

    function getRenderComponent(props) {
        if (isApproved() || checkAuthOnly) {
            return <Component {...props} />;
        } else {
            return <Redirect to={{ 
                    pathname: alternate,
                    state: {
                        sourceUrl: location.pathname,
                        sourceQuery: location.search
                    }
                }} />;
        }
    }

    return (
        authDetermined 
        ?   <Route
                {...rest}
                render={props => getRenderComponent(props)}
            />
        : null
    );
}

AuthorizedRoute.defaultProps = {
    alternate: "/signin"
};

export default AuthorizedRoute;