import React from 'react';
import styled from 'styled-components';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { useTranslation } from 'react-i18next';
import {LightRoundedButton} from 'Components/Styles/Buttons/LightRoundedButton';

const AddingRoundedButton = styled(LightRoundedButton)`
    padding-top: 0;
    padding-bottom: 0;
    font-weight: bold;
`;

export default function AdditionButton({onClick}) {
    const { t } = useTranslation();
    return (
        <AddingRoundedButton 
            weight="light" 
            variant="outlined" 
            color="primary" 
            type="button" 
            size="small" 
            onClick={onClick}
            endIcon={<AddCircleOutlineIcon/>}
        >
            {t('addButton.buttonText')}
        </AddingRoundedButton>
    );
}