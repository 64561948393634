import React from 'react';
import { Grid } from '@material-ui/core';
import StudyCommitmentHeader from 'Components/Parts/Study/StudyCommitmentHeader';
import CardFieldError from '../CardFieldError';

export default function CommitmentDetails({visits, visitDuration, visitFrequency, visitRequirements, isFinalized, meta}) {
    return (
        <React.Fragment>
            <Grid container item justify="center">
                {visits && 
                <StudyCommitmentHeader 
                    visitNumber={visits} 
                    visitDuration={visitDuration} 
                    visitFrequency={visitFrequency}
                    visitRequirements={visitRequirements}
                    isFinalized={isFinalized}
                />}
            </Grid>
            <Grid container item justify="flex-start">
                <CardFieldError meta={meta}/>
            </Grid>
        </React.Fragment>
    );
}