import React from 'react';
import {
    Grid,
    SvgIcon,
    Typography,
    useMediaQuery,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useTranslation, Trans } from 'react-i18next';
import styled from 'styled-components';
import { differenceInDays } from 'date-fns'

import { ReactComponent as CheckCircleOutlineIcon } from 'resources/images/icons/checkCircleOutlineIcon.svg';
import { StudyStatus } from 'Constants/constants';
import Theme from 'Theme';


const StatusLabel = styled(Typography)`
    margin-left: 10px;
    font-family: Arial;
    font-weight: 600;

    @media (max-width: 960px) {
        font-size: 12px;
    }
`;

const StudyStatusBorder = styled.div`
    background-color: black;
    display: inline-block;
    height: 20px;
    margin: 0 24px;
    opacity: 0.11;
    width: 2px;
`;

const StyledLabel = styled(Typography)`
    color: #000;
    font-family: Inter;
    font-size: 16px;

    @media (max-width: 960px) {
        font-size: 12px;
    }
`;

const DaysLeftContainer = styled.div`
    display: inline-block;
    align-items: baseline;
    padding-bottom: 0.3em;
`;

const DaysLeftCountLabel = styled(Typography)`
    color: #000;
    font-family: Inter;
    display: inline-block;
    margin: 0 10px;
    font-size: 20px;
    font-weight: 600;

    @media (max-width: 960px) {
        font-size: 12px;
    }
`;

const DaysLeftSuffixLabel = styled(Typography)`
    font-family: Inter;
    font-size: 14px;

    @media (max-width: 960px) {
        font-size: 10px;
    }
`;

const statusText = {
    [StudyStatus.DRAFT]: {
        getLabel: t => t('studyStatus.draft'),
        getIcon: size => <SvgIcon component={CheckCircleOutlineIcon} htmlColor={Theme.palette.warning.main} style={{ fontSize: size === "small" ? "1rem" : "1.5rem" }} viewBox="0 0 32 32" />,
        color: Theme.palette.warning.main,
    },
    [StudyStatus.UNDER_REVIEW]: {
        getLabel: t => t('studyStatus.underReview'),
        getIcon: size => <SvgIcon component={CheckCircleOutlineIcon} htmlColor={Theme.palette.error.main} style={{ fontSize: size === "small" ? "1rem" : "1.5rem" }} viewBox="0 0 32 32" />,
        color: Theme.palette.error.main,
    },
    [StudyStatus.ACTIVE]: {
        getLabel: t => t('studyStatus.active'),
        getIcon: size => <SvgIcon component={CheckCircleOutlineIcon} color="secondary" style={{ fontSize: size === "small" ? "1rem" : "1.5rem" }} viewBox="0 0 32 32" />,
        color: Theme.palette.secondary.main,
    },
};

function StudyMeta({ status, studyPeriodEnd, mobileJustify }) {
    const { t } = useTranslation();
    const theme = useTheme();
    const mdUp = useMediaQuery(theme.breakpoints.up('md'));
    const statusDisplay = statusText[status];
    const daysLeft = studyPeriodEnd ? differenceInDays(new Date(studyPeriodEnd), new Date()) : -1;
    const isOver = studyPeriodEnd ? new Date(studyPeriodEnd) < new Date() : true;

    return (
        <Grid container alignItems="center" justify={!mdUp ? mobileJustify : undefined}>
            {statusDisplay.getIcon(mdUp ? "large" : "small")}
            <StatusLabel display="inline" variant="body2" style={{ color: statusDisplay.color }}>
                {statusDisplay.getLabel(t)}
            </StatusLabel>
            {status !== StudyStatus.DRAFT && status !== StudyStatus.UNDER_REVIEW && 
                <React.Fragment>
                    <StudyStatusBorder />
                    {!isOver
                        ?   
                            <DaysLeftContainer>
                                <StyledLabel display="inline">{t('myStudyPage.recruitingText')}:</StyledLabel>
                                <Trans i18nKey='myStudyPage.daysLeftText'>
                                    <DaysLeftCountLabel>{{daysLeft}}</DaysLeftCountLabel>
                                    <DaysLeftSuffixLabel display="inline" color="textSecondary">days left</DaysLeftSuffixLabel>
                                </Trans>
                            </DaysLeftContainer>
                            
                        :   <StyledLabel>{t('myStudyPage.inAnalysisText')}</StyledLabel>}
                </React.Fragment>
            }
        </Grid>
    );
}

StudyMeta.defaultProps = {
    mobileJustify: 'flex-start',
};

export default StudyMeta;
