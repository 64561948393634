import styled from 'styled-components';
import { Typography } from '@material-ui/core';


export const ModalCardTitle = styled(Typography)`
    font-family: Varela Round;
    font-size: 28px;
    margin-bottom: 8px;

    @media (min-width: 960px) {
        font-size: 37px;
    }
`;

export const WizardDataRowTitle = styled(Typography)`
    font-family: Inter;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.56;
    color: #212121;
`;
