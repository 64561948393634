import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { RoundedButton } from 'Components/Styles/Buttons/RoundedButton';
import { ReactComponent as ArrowForwardIcon } from 'resources/images/icons/study/arrowCircleRightOutline.svg';
import { ReactComponent as ConfirmedIcon } from 'resources/images/icons/study/checkmarkCircledOutline.svg';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import { SvgIcon } from '@material-ui/core';
import LocalProgressOverlay from 'Components/Parts/Progress/LocalProgressOverlay';

const ConfirmationButton = styled(RoundedButton)`
    cursor: default;
`;

function ApprovalButton({approved, rejected, saving}) {
    const {values} = useFormikContext();
    const {t} = useTranslation();

    function Button() { 
        if (rejected) {
            return (
                <ConfirmationButton variant="contained" color="secondary" endIcon={<SvgIcon component={ConfirmedIcon}/>}>
                    {t("studyApproval.feedbackSent")}
                </ConfirmationButton>
            );
        } else if (approved) {
            return (
                <ConfirmationButton variant="contained" color="secondary" endIcon={<SvgIcon component={ConfirmedIcon}/>}>
                    {t("studyApproval.approved")}
                </ConfirmationButton>
            );
        } else if (Object.values(values).some(value => value.approved === "declined")) {
            return (
                <RoundedButton variant="contained" color="primary" type="submit" endIcon={<EmailOutlinedIcon/>} disabled={saving}>
                    {t("studyApproval.sendFeedback")}
                </RoundedButton>
            );
        } else {
            return (
                <RoundedButton variant="contained" color="primary" type="submit" endIcon={<SvgIcon component={ArrowForwardIcon} style={{fontSize: '24px'}} />} disabled={saving}>
                    {t("studyApproval.sendApproval")}
                </RoundedButton>
            );
        }
    }

    return (
        <LocalProgressOverlay waiting={saving}>
            <Button/>
        </LocalProgressOverlay>
    );
}

function mapStateToProps(state) {
    return {
        approved: state.studyApproval.approved,
        rejected: state.studyApproval.rejected,
        saving: state.serverCommunication.saving
    };
}

export default connect(
    mapStateToProps
)(ApprovalButton)