import React, { useState } from 'react';
import { SvgIcon } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import toggleStudyInterest from 'api/study/toggleStudyInterest';
import notEligibleRequest from 'api/eligibility/notEligible';
import { ReactComponent as QuestionOutlineIcon } from 'resources/icons/questionOutlineIcon.svg';
import {
    BarText,
    BarButton,
    EligibilityBarTemplate,
} from './EligibilityBarTemplate';


export default function EligibilityCustomCriteriaBar({studyId, interested}) {
    const { t } = useTranslation();
    const [isInterested, setIsInterested] = useState(interested);
    const [showBar, setShowBar] = useState(true);

    const toggleInterstClick = async () => {
        const { status } = await toggleStudyInterest(studyId);
        if (status === 204) {
            setIsInterested(!isInterested);
        }
    };

    const handleNotEligible = async () => {
        const { status } = await notEligibleRequest(studyId);
        setShowBar(status !== 204);
    };

    const text = isInterested
        ? t('eligibilityBar.interestedText')
        : t('eligibilityBar.eligibilityCustomCriteriaText');
    
    if (!showBar) {
        return null;
    }
    
    return (
        <EligibilityBarTemplate color="primary">
            <SvgIcon component={QuestionOutlineIcon} style={{ fontSize: "2rem" }} viewBox="0 0 32 33" />
            <BarText>{text}</BarText>
            {isInterested
                ?   <BarButton onClick={toggleInterstClick}>{t('eligibilityBar.interestedButtonText')}</BarButton>
                :   <React.Fragment>
                        <BarButton onClick={toggleInterstClick}>{t('eligibilityBar.isEligibleButtonText')}</BarButton>
                        <BarButton onClick={handleNotEligible} variant="outlined">{t('eligibilityBar.notEligibilityQuestionnaireButtonText')}</BarButton>
                    </React.Fragment>
            }
        </EligibilityBarTemplate>
    );
}