import { axiosInstance } from '../api';

export default function(studyValues) {
    return axiosInstance.post('/api/study/submit',
        studyValues, {
            headers: {
                'Content-Type': undefined,
            },
            withCredentials: true,
            timeout: 240000
        }
    );
}