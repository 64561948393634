import React from 'react';
import {
    Grid,
    Typography,
    SwipeableDrawer,
} from '@material-ui/core';
import styled from 'styled-components';

import { ReactComponent as Connect1dHeaderLogo } from 'resources/images/logos/logoTitleBar.svg';
import { RoundedButton } from 'Components/Styles/Buttons/RoundedButton';
import { Gradiant } from 'Theme';


const HeaderHeight = '58px';

const StyledDrawer = styled(SwipeableDrawer)`
    .MuiDrawer-paper {
        background: ${Gradiant.primary};
        height: 100vh;
        max-width: 100vw;
        overflow: hidden;
    }
`;

const Card = styled.div`
    background-color: #fff;
    border-radius: 12px 12px 0 0;
    height: calc(100% - ${HeaderHeight});
    overflow-y: scroll;
`;

const Header = styled(Grid)`
    height: ${HeaderHeight};
    padding: 0 13px;
`;

const Title = styled(Typography)`
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    position: relative;
`;

const CardBody = styled.div`
    height: 100%;
    margin: 0 21px;
    padding: 21px 0;
`;

const LogoWrapper = styled.div`
    align-items: center;
    display: flex;
    margin-left: 12px;
    position: relative;
    width: 160px;
`;

const RippleImage = styled.img`
    left: 7%;
    position absolute;
    top: calc(50%);
    transform: translate(-50%, -50%);
    z-index: -1;
`;

const MobileRippleImage = styled(RippleImage)`
    left: 5px;
`;

const Connect1dLogoHeader = () => (
    <LogoWrapper>
        <RippleImage src="/images/authorization/ripple.svg" />
        <Connect1dHeaderLogo />
    </LogoWrapper>
);

const Connect1dTextHeader = ({ title }) => (
    <Title>
        <MobileRippleImage src="/images/authorization/ripple.svg" />
        {title}
    </Title>
);

function MobileModal({ title, children, open, onOpen, onClose }) {
    return (
        <StyledDrawer
            anchor="bottom"
            open={open}
            onClose={onClose}
            onOpen={onOpen}
        >
            <Header container wrap="nowrap" alignItems="center" justify="space-between">
                <Grid item>
                    {title
                        ? <Connect1dTextHeader title={title} />
                        : <Connect1dLogoHeader />
                    }
                </Grid>
                <Grid item>
                    <RoundedButton variant="outlined" size="small" color="default" onClick={onClose}>
                        Exit
                    </RoundedButton>
                </Grid>
            </Header>
            <Card>
                <CardBody>
                    {children}
                </CardBody>
            </Card>
        </StyledDrawer>
    )
};

MobileModal.defaultProps = {
    title: '',
    open: false,
    onOpen: _ => {},
    onClose: _ => {},
};

export default MobileModal;
