import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Switch, Route, Redirect, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { sidebarItems } from 'data/study/sidebarItems';
import WizardPage from 'Components/Layouts/Setup/WizardPage';
import WizardReviewPage from 'Components/Layouts/Setup/WizardReviewPage';
import { setCurrentStep } from 'redux/reducers/studyCreation/actions';

function StudyPage() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { wizardStep } = useParams();
    const page = wizardStep ? sidebarItems.find(({id}) => id === parseInt(wizardStep)) : sidebarItems[0];

    useEffect(() => {
        dispatch(setCurrentStep(wizardStep));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wizardStep]);

    const title = page.getTitle ? page.getTitle(t) : undefined;
    const subtitle = page.getSubtitle ? page.getSubtitle(t) : undefined;

    return (
        <React.Fragment>
            {page.isReview
                ? <WizardReviewPage title={title} subtitle={subtitle} icon={page.icon}/>
                : <WizardPage title={title} subtitle={subtitle} icon={page.icon}>
                    {page.resource}
                </WizardPage>}
        </React.Fragment>
    );
}

export default function StudyCreationWizard() {    
    return (
        <Switch>
            <Route exact path="/setup">
                <Redirect to="/setup/1"/>
            </Route>
            <Route path="/setup/:wizardStep" render={routeProps => <StudyPage {...routeProps} />}/>
        </Switch>
    );
}