import { axiosInstance } from '../api';
import { convertCredentialsToServerRequest } from 'data/apiRequests/convertAccountCreateToServerRequest';

export default function(email, password, type, firstName, lastName, credentials, preferredLanguage, allowsCommunication) {
    return axiosInstance.post('/api/auth/register', {
        username: email,
        email,
        password,
        extraFields: {
            type,
            firstName,
            lastName,
            preferredLanguage,
            allowsCommunication,
            ...convertCredentialsToServerRequest(credentials)
        }
    });
}