import React from 'react';
import MultiSelectEligibilityContainer from '../MultiSelectEligibilityContainer';
import { monitorOptions, monitorSelectionOptions } from 'data/study/Eligibility/eligibilitySelectionOptions';


const name="eligibility.monitoring.monitor";

// Register all the required translation keys
const t = _ => {};
t('studyEligibilityComparitivePhrase.monitoringMonitor.include.single');
t('studyEligibilityComparitivePhrase.monitoringMonitor.include.mixed');
t('studyEligibilityComparitivePhrase.monitoringMonitor.exclude.single');
t('studyEligibilityComparitivePhrase.monitoringMonitor.exclude.mixed');
t('studyEligibilityComparitivePhrase.monitoringMonitor.undefined.single');
t('studyEligibilityComparitivePhrase.monitoringMonitor.undefined.mixed');

export default function StudyEligibilityMonitor({title}) {
    return (
        <MultiSelectEligibilityContainer
            criteria="monitoringMonitor"
            name={name}
            title={title}
            answerOptions={monitorSelectionOptions}
            selectOptions={monitorOptions}
        />
    );
}