import React from 'react';
import { useField } from 'formik';
import SelectEligibilityContainer from './SelectEligibilityContainer';
import { assistanceOptions } from 'data/study/Eligibility/eligibilitySelectionOptions';

// Register all the required translation keys
const t = _ => {};
t('studyEligibilityComparitivePhrase.assistance.include.option');
t('studyEligibilityComparitivePhrase.assistance.exclude.option');
t('studyEligibilityComparitivePhrase.assistance.undefined.option');

export default function StudyEligibilityRequireAssistance({title, path, referenceQuestion}) {
    const name = `eligibility.${path}.assistance`;
    const [hadIssue] = useField(`eligibility.${path}.${referenceQuestion}`);

    return (
        <SelectEligibilityContainer
            criteria="assistance"
            name={name}
            options={assistanceOptions}
            title={title}
            disabled={!hadIssue.value.answer.id || hadIssue.value.answer.id === "never"}
        />
    );
}