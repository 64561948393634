import React, { useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import NewPasswordFields from 'Components/Parts/Form/NewPasswordFields';
import PasswordTextBox from 'Components/Parts/Form/TextBoxes/PasswordTextBox';
import { RoundedButton } from 'Components/Styles/Buttons/RoundedButton';
import changePassword from 'api/auth/changePassword'


const Page = styled.div`
    padding-top: 22px;
`;

const SubmitButton = styled(RoundedButton)`
    display: block;
`;

function SettingsChangePassword() {
    const { t } = useTranslation();
    const [errorMessage, setErrorMessage] = useState();
    const [didChange, setDidChange] = useState(false);

    function handleSubmit(values, { setSubmitting }) {
        if (didChange) {
            return;
        }

        const { currentPassword, newPassword } = values;
        changePassword(currentPassword, newPassword)
            .then(() => {
                setErrorMessage("");
                setDidChange(true)
            })
            .catch(() => {
                setErrorMessage(t('changePasswordPage.errorMessage'))
                setSubmitting(false);
            });
    }

    return (
        <Page>
            <Formik
                initialValues={{
                    currentPassword: "",
                    newPassword: "",
                    confirmPassword: ""
                }}
                validationSchema={Yup.object({
                    currentPassword: Yup.string()
                        .required(t('errorMessage.requiredField')),
                    newPassword: Yup.string()
                        .min(8, " ")
                        .matches(/[a-z]/, " ")
                        .matches(/[A-Z]/, " ")
                        .matches(/[^a-zA-Z]/, " ")
                        .required(" "),
                    confirmPassword: Yup.string()
                        .oneOf([Yup.ref("newPassword"), null], t('errorMessage.passwordMismatch'))
                        .required(t('errorMessage.passwordMismatch'))
                })}
                onSubmit={handleSubmit}
            >
                {({isValid, isSubmitting, dirty}) => 
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Field
                                    name="currentPassword" 
                                    label={t('changePasswordPage.currentPasswordLabel')}
                                    component={PasswordTextBox}
                                />
                            </Grid>
                            <NewPasswordFields />
                            <Grid item xs={12}>
                                <SubmitButton 
                                    variant="contained" 
                                    color={didChange ? 'secondary' : 'primary'}
                                    type="submit" 
                                    disabled={!didChange && (!isValid || isSubmitting || !dirty)}
                                    fullWidth
                                >
                                    {didChange
                                        ? t('changePasswordPage.successButtonText')
                                        : t('changePasswordPage.submitButtonText')}
                                </SubmitButton>
                            </Grid>
                            {errorMessage &&
                                <Grid item container xs={12}>
                                    <Typography color="error">{errorMessage}</Typography>
                                </Grid>
                            }
                        </Grid>
                    </Form>
                }
            </Formik>
        </Page>
    );
}

export default SettingsChangePassword;
