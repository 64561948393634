import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import { useHistory } from 'react-router';

import AuthPageLayout from 'Components/Layouts/Authorization/AuthPageLayout';
import MobileModal from 'Components/Parts/Modals/MobileModal';
import AccountCreationDesktopTemplate from 'Components/Parts/Registration/AccountCreationDesktopTemplate';
import MobileModalPage from 'Components/Parts/Modals/Components/MobileModalPage';


function DesktopPage({ children }) {
    return (
        <AccountCreationDesktopTemplate>
            {children}
        </AccountCreationDesktopTemplate>
    );
}

function MobilePage({ children }) {
    const history = useHistory();

    return (
        <MobileModal
            open={true}
            onClose={_ => history.push('/signin')}
        >
            <MobileModalPage>
                {children}
            </MobileModalPage>
        </MobileModal>
    );
}

function AuthCardPageLayout({ children }) {
    const theme = useTheme();
    const mdUp = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <AuthPageLayout>
            {mdUp
                ? <DesktopPage children={children} />
                : <MobilePage children={children} />
            }
        </AuthPageLayout>
    );
}

export default AuthCardPageLayout;
