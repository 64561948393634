export const compensationOptions = Object.freeze([
    Object.freeze({
        id: "money",
        getTitle: t => t('compensationOption.moneyTitle'),
        getDescription: t => t('compensationOption.moneyDescription'),
    }),
    Object.freeze({
        id: "cgm",
        getTitle: t => t('compensationOption.cgmTitle'),
        getDescription: t => t('compensationOption.cgmDescription'),
    }),
    Object.freeze({
        id: "device",
        getTitle: t => t('compensationOption.deviceTitle'),
        getDescription: t => t('compensationOption.deviceDescription'),
    }),
    Object.freeze({
        id: "giftCard",
        getTitle: t => t('compensationOption.giftCardTitle'),
        getDescription: t => t('compensationOption.giftCardDescription'),
    }),
    Object.freeze({
        id: "expenses",
        getTitle: t => t('compensationOption.expensesTitle'),
        getDescription: t => t('compensationOption.expensesDescription'),
    }),
    Object.freeze({
        id: "medication",
        getTitle: t => t('compensationOption.medicationTitle'),
        getDescription: t => t('compensationOption.medicationDescription'),
    }),
    Object.freeze({
        id: "lottery",
        getTitle: t => t('compensationOption.lotteryTitle'),
        getDescription: t => t('compensationOption.lotteryDescription'),
    }),
]);