import React from 'react';
import styled from 'styled-components';
import QuestionnaireContainer from 'Components/Parts/Questionnaire/QuestionnaireNavigator';
import { Grid } from '@material-ui/core';
import QuestionnaireExitButton from 'Components/Parts/Questionnaire/QuestionnaireExitButton';
import getLogoSource from 'Components/Parts/Logo/LogoSource';

const QuestionnaireBackdrop = styled.div`
    background: linear-gradient(99deg, #6078ea -1%, #17ead9 184%);
    height: 100vh;
    width: 100vw;
`;

const MobileHeaderLogo = styled.img`
    height: 100%;
    width: 149px;
    object-fit: contain;
    margin-left: -1em;
`;

const QuestionnaireHeader = styled.div`
    display: flex;
    height: 58px;
    width: 100%;
    padding: 0 1em;
    justify-content: center;
`;

const QuestionnaireMainCard = styled.div`
    background: #fff;
    border-radius: 25px 25px 0 0;
    height: calc(100% - 58px);
    width: 100%;
`;

export default function QuestionnaireMobileLayout({questionnaireId}) {
    return (
        <QuestionnaireBackdrop>
            <QuestionnaireHeader>
                <Grid container justify="space-between" alignItems="center">
                    <MobileHeaderLogo src={getLogoSource()}/>
                    <Grid item>
                        <QuestionnaireExitButton/>
                    </Grid>
                </Grid>
            </QuestionnaireHeader>
            <QuestionnaireMainCard>
                <QuestionnaireContainer questionnaireId={questionnaireId}/>
            </QuestionnaireMainCard>
        </QuestionnaireBackdrop>
    );
}

export function QuestionnaireMobileSplashPage({children}) {
    return (
        <QuestionnaireBackdrop>
            <QuestionnaireHeader>
                <Grid container justify="flex-start">
                    <MobileHeaderLogo src={getLogoSource()}/>
                </Grid>
            </QuestionnaireHeader>
            <QuestionnaireMainCard>
                {children}
            </QuestionnaireMainCard>
        </QuestionnaireBackdrop>
    );
}