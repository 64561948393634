import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from '@material-ui/core';
import { buildDetailsContents } from 'data/study/Details/detailsContentsArray';
import GlobalProgressOverlay from 'Components/Parts/Progress/GlobalProgressOverlay';
import { updateServerCriteria } from 'data/apiResponses/eligibilityHelpers';
import DetailsHeader from 'Components/Parts/Study/Details/DetailsHeader';
import DashboardNavbar from 'Components/Parts/Dashboard/Navigation/DashboardNavbar';
import NavbarTemplate from 'Components/Layouts/NavbarTemplate';
import DetailsGrid from 'Components/Parts/Study/Details/DetailsGrid';
import EligibilityBar from 'Components/Parts/Study/Details/EligibilityBar';

export default function DetailsPage({ study, fhirBundle, refreshed }) {
    const dispatch = useDispatch();
    const loading = useSelector(state => state.serverCommunication.loading);
    const [fhirStudy, setFhirStudy] = React.useState();
    const [sponsor, setSponsor] = React.useState();
    const [locations, setLocations] = React.useState([]);
    const [letter, setLetter] = React.useState();
    const [studyTeam, setStudyTeam] = React.useState();
    const [studyEligibility, setStudyEligibility] = React.useState();

    React.useEffect(() => {
        const fhirStudyResource = fhirBundle.find(item => item.resource.resourceType === 'ResearchStudy').resource;
        setStudyTeam(study.studyTeam);
        setStudyEligibility(study.eligibilityCriteria && study.eligibilityCriteria.map(criteria => updateServerCriteria(criteria)));
        
        setFhirStudy(fhirStudyResource);
        setSponsor(fhirBundle.find(item => item.resource.resourceType === 'Organization').resource);
        setLetter(fhirBundle.find(item => item.resource.resourceType === 'DocumentReference'));
        setLocations(fhirBundle.filter(entry => entry.resource.resourceType === 'Location'));
    }, [study, fhirStudy, dispatch]);

    if (!fhirStudy) {
        return <DashboardNavbar/>;
    }

    const contentsArray = buildDetailsContents(study.id, fhirStudy, studyTeam, studyEligibility, sponsor, locations, letter, true);
    return (
        <React.Fragment>
            <GlobalProgressOverlay open={loading}/>
            <NavbarTemplate noGutters maxWidth={false}>
                <Container>
                    <DetailsHeader
                        id={study.id}
                        title={fhirStudy.title}
                        description={fhirStudy.description}
                        status={fhirStudy.status}
                        studyPeriodEnd={fhirStudy.period?.end}
                    />
                </Container>
                <EligibilityBar
                    studyId={study.id}
                    studyPeriodEnd={fhirStudy.period?.end}
                    interestedStatus={study.interestedUserStatus}
                    eligibilityStatus={study.calculatedEligibilityStatus}
                    refreshed={refreshed}
                />
                <Container>
                    <DetailsGrid contents={contentsArray}/>
                </Container>
            </NavbarTemplate>
        </React.Fragment>
    );
}