import React from 'react';
import styled from 'styled-components';
import { SvgIcon } from '@material-ui/core';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import { ReactComponent as CheckCircleOutlineIcon } from 'resources/images/icons/checkCircleOutlineIcon.svg';
import { ReactComponent as CrossCircleOutlineIcon } from 'resources/images/icons/crossCircledOutline.svg';

const NegativeIcon = styled(SvgIcon)`
    font-size: 2rem;
    fill: ${props => props.theme.palette.error.main};
`;

export default function CheckIcon({type="default", className}) {
    if (type === "positive") {
        return <SvgIcon className={className} component={CheckCircleOutlineIcon} viewBox="0 0 32 32" color="secondary"/>;
    } else if (type === "negative") {
        return <NegativeIcon className={className} component={CrossCircleOutlineIcon} viewBox="0 0 32 32"/>;
    } else {
        return <CheckBoxOutlinedIcon className={className} color="primary"/>
    }
}