import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ModalPaper } from 'Components/Styles/Modals/ModalContainers';

import BaseModal from './BaseModal';
import { ReactComponent as TriangleWarningIcon } from 'resources/images/icons/alertTriangleOutline.svg';
import { useHistory } from 'react-router';
import { FixedSizeIcon } from 'Components/Styles/Modals/Questionnaire';
import { TitleText, ModalButton } from 'Components/Styles/Modals/Components';


export default function ReviewNotNeededModal() {
    const {t} = useTranslation();
    const history = useHistory();

    function handleDismiss() {
        history.push("/dashboard");
    }

    return (
        <BaseModal
            aria-describedby="baseline-questionnaire-modal-description"
            aria-labelledby="baseline-questionnaire-modal-title"
            onClose={handleDismiss}
            open={true}
        >
            <ModalPaper>
                <Grid container direction="column" alignItems="center" spacing={2}>
                    <Grid item>
                        <FixedSizeIcon component={TriangleWarningIcon} color="primary" />
                    </Grid>
                    <Grid item>
                        <TitleText>{t('review.reviewNotNeeded')}</TitleText>
                    </Grid>
                    <Grid item>
                        <Typography variant="body2" color="textSecondary" align="center" dangerouslySetInnerHTML={{__html: t('review.reviewNotNeededDescription')}} />
                    </Grid>
                    <ModalButton variant="contained" color="primary" size="small" onClick={handleDismiss}>{t('review.returnToDashboard')}</ModalButton>
                </Grid>
            </ModalPaper>
        </BaseModal>
    );
}