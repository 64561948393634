import React from 'react';
import { Grid } from '@material-ui/core';

import StudyHeader from 'Components/Parts/Study/StudyHeader';
import { sidebarItems } from 'data/study/sidebarItems';
import { useField } from 'formik';

function DetailsSection({item}) {
    const [field, meta] = useField(item.key);

    if (item.reviewResource) {
        return item.reviewResource(field.value, meta);
    }

    return item.resource;
}

export function SectionCard({title, icon, id}) {
    const item = sidebarItems.find(i => i.id === id);
    if (!item) {
        return null;
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <StudyHeader title={title} icon={icon} buttonId={item.reviewResource && id+1} size="small"/>
            </Grid>
            <Grid item xs={12}>
                <DetailsSection item={item}/>
            </Grid>
        </Grid>
    );
}