import React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import { TextField } from 'formik-material-ui';

const nameMask = (rawValue => [...rawValue].map(() => /[^0-9]/));

function TextMaskName(props) {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={nameMask}
            placeholderChar={'\u2000'}
        />
    );
}
TextMaskName.propTypes = {
    inputRef: PropTypes.func.isRequired,
};

export default function NameTextBox({...props}) {
    return (
        <TextField 
            {...props} 
            variant="outlined"
            InputProps={{inputComponent: TextMaskName}}
            fullWidth
        />
    );
}