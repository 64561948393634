import React from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PickerProvider from 'Components/Parts/Form/PickerProvider';
import { CompletedField } from '../ApprovalFields';
import { format as formatDate } from 'date-fns';
import { getDateLocale } from 'translations/i18n';

export default function StudyTimelinesApproval({recruitStart, recruitEnd, studyEnd, resultsAvailable}) {
    const { t, i18n } = useTranslation();

    const recruitmentStartDateString = recruitStart && formatDate(recruitStart, 'dd/MM/YYY');
    const recruitmentEndDateString = recruitEnd && formatDate(recruitEnd, 'dd/MM/YYY');
    const studyEndDateString = studyEnd ? formatDate(studyEnd, 'dd/MM/YYY') : "-";
    const availableDateString = resultsAvailable ? formatDate(resultsAvailable, 'MMMM yyyy', { locale: getDateLocale(i18n.language) }) : "-";

    return (
        <PickerProvider>
            <Grid container spacing={2}>
                <Grid item container spacing={2} xs={12}>
                    <Grid item xs={6}>
                        <CompletedField
                            label={t('studySetupTimeline.recruitmentStartPlaceholder')}
                            text={recruitmentStartDateString}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <CompletedField
                            label={t('studySetupTimeline.recruitmentEndPlaceholder')}
                            text={recruitmentEndDateString}
                        />
                    </Grid>
                </Grid>
                <Grid container item xs={12} spacing={2}>
                    <Grid item xs={6}>
                        <CompletedField
                            label={t('studySetupTimeline.endDatePlaceholder')}
                            text={studyEndDateString}
                        />
                    </Grid>
                </Grid>
                <Grid container item xs={12} spacing={2}>
                    <Grid item xs={6}>
                        <CompletedField
                            label={t('studySetupTimeline.resultsAvailablePlaceholder')}
                            text={availableDateString}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </PickerProvider>
    );
}