// `label` appears to be used by convertFhirStudyResponseToFormik
// TODO: verify if it is safe to remove label
export const visitValues = Object.freeze(getVisitValues(50));

function getVisitValues(maximum) {
    const values = [];
    let i = 0;
    for(i=0; i<=maximum; i++) {
        values.push(Object.freeze({ id: i, label: i.toString() }));
    }

    return values;
}

export const sliderValues = Object.freeze([
    Object.freeze({ id: 0, label: "0-15 mins", getLabel: t => t('studyCommitmentSelection.zeroToFifteenMins') }),
    Object.freeze({ id: 1, label: "15-30 mins", getLabel: t => t('studyCommitmentSelection.fifteenToThirtyMins') }),
    Object.freeze({ id: 2, label: "30-45 mins", getLabel: t => t('studyCommitmentSelection.thirtyMinsToFortyFiveMins') }),
    Object.freeze({ id: 3, label: "45-60 mins", getLabel: t => t('studyCommitmentSelection.fortyFiveMinsToSixtyMins') }),
    Object.freeze({ id: 4, label: "60-90 mins", getLabel: t => t('studyCommitmentSelection.sixtyToNinetyMins') }),
    Object.freeze({ id: 5, label: "90+ mins", getLabel: t => t('studyCommitmentSelection.ninetyPlusMins') }),
]);

export const frequencyValues = Object.freeze([
    Object.freeze({ id: "flex", label: "Flexible", getLabel: t => t('studyCommitmentSelection.flexible') }),
    Object.freeze({ id: "weekly", label: "Every few weeks", getLabel: t => t('studyCommitmentSelection.everyFewWeeks') }),
    Object.freeze({ id: "monthly", label: "Every few months", getLabel: t => t('studyCommitmentSelection.everyFewMonths') }),
    Object.freeze({ id: "yearly", label: "Few times per year", getLabel: t => t('studyCommitmentSelection.fewTimesPerYear') }),
]);

export const requirementsValues = Object.freeze([
    Object.freeze({
        id: "Overnights",
        label: "Overnights",
        getLabel: t => t('studyCommitmentSelection.overnights'),
    }),
    Object.freeze({
        id: "Bloodwork",
        label: "Bloodwork",
        getLabel: t => t('studyCommitmentSelection.bloodwork'),
    })
]);