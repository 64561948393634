import React from 'react';
import { Field, useField } from 'formik';
import { Grid } from '@material-ui/core';
import StudyEligibilityContainer from './StudyEligibilityContainer';
import { CustomTextField } from 'Components/Parts/Form/TextBoxes/CustomInputs';
import { eligibilityNumericItem } from 'data/study/WizardFormSetup/wizardFormInitialValues';
import TextMaskInteger from 'Components/Parts/Form/TextBoxes/TextMasks/TextMaskInteger';
import TextMaskDecimal from 'Components/Parts/Form/TextBoxes/TextMasks/TextMaskDecimal';

export function NumericField({name, label, width=12, floatingPoint}) {
    const mask = floatingPoint ? TextMaskDecimal : TextMaskInteger;

    return (
        <Grid item xs={width}>
            <Field
                name={name}
                component={CustomTextField}
                type="text"
                label={label}
                variant="filled"
                InputProps={{inputComponent: mask}}
                fullWidth
            />
        </Grid>
    );
}

export function MinField({name, label, width=12, floatingPoint}) {
    return <NumericField name={`${name}.min`} label={label} width={width} floatingPoint={floatingPoint}/>;
}

export function MaxField({name, label, width=12, floatingPoint}) {
    return <NumericField name={`${name}.max`} label={label} width={width} floatingPoint={floatingPoint}/>;
}

export function RangeField({name, minLabel, maxLabel, floatingPoint}) {
    return (
        <Grid container spacing={2}>
            <MinField name={name} label={minLabel} width={6} floatingPoint={floatingPoint}/>
            <MaxField name={name} label={maxLabel} width={6} floatingPoint={floatingPoint}/>
        </Grid>
    );
}

export default function NumericFieldEligibilityContainer({name, answerOptions, componentOptions, criteria}) {
    const [minField] = useField(`${name}.min`);
    const [maxField] = useField(`${name}.max`);

    const properties = {
        minValue: minField.value || '___',
        maxValue: maxField.value || '___',
    };

    return (
        <StudyEligibilityContainer
            criteria={criteria}
            name={name}
            options={answerOptions} 
            components={componentOptions}
            emptyValue={eligibilityNumericItem}
            getProperties={() => properties}
        />
    );
}