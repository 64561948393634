import { useState, useEffect } from 'react';
import { debounce, isEqual } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { autosaveDraftStudy } from 'redux/reducers/studyCreation/actions';

const save = debounce((values, dispatch, setPreviousValues) => {
    dispatch(autosaveDraftStudy(values));
    setPreviousValues(values);
}, 5000);

export default function Autosave({formikValues}) {
    const dispatch = useDispatch();
    const { saving, loading } = useSelector(state => state.serverCommunication);
    const [previousValues, setPreviousValues] = useState(null);

    useEffect(() => {
        if (!saving && !loading && !isEqual(formikValues, previousValues) && formikValues.studyInfo.title) {
            save(formikValues, dispatch, setPreviousValues);
        }
    }, [formikValues]); 

    return null;
}