import React from 'react';
import { Grid, Typography, Link } from '@material-ui/core';
import styled from 'styled-components';

import { CentredGridItem } from 'Components/Layouts/Authorization/AuthPageLayout';
import { ModalButton } from 'Components/Styles/Modals/Components';
import ModalHeader from 'Components/Parts/Modals/ModalHeader';
import { useSelector } from 'react-redux';
import LocalProgressOverlay from 'Components/Parts/Progress/LocalProgressOverlay';


const MainContentBox = styled.div`
    min-height: 200px;
`;

function AccountCardLayout({ icon, title, primaryButtonText, primaryButtonType, secondaryButtonText, ternaryButtonText, onPrimaryClick, onSecondaryClick, onTernaryClick, children }) {
    const hasPrimaryButton = primaryButtonText && (onPrimaryClick || primaryButtonType);
    const hasSecondaryButton = secondaryButtonText && onSecondaryClick;
    const saving = useSelector(state => state.serverCommunication.saving);

    return (
        <React.Fragment>
            <ModalHeader
                icon={icon}
                title={title}
            />
            <CentredGridItem item xs={12}>
                <MainContentBox>
                    {children}
                </MainContentBox>
            </CentredGridItem>
            {(hasPrimaryButton || hasSecondaryButton) &&
                <Grid container item justify="center">
                    {hasSecondaryButton &&
                        <ModalButton size="small" variant="outlined" color="primary" onClick={onSecondaryClick}>{secondaryButtonText}</ModalButton>
                    }
                    {hasPrimaryButton &&
                        <Grid item xs={12}>
                            <LocalProgressOverlay waiting={saving}>
                                <ModalButton size="small" variant="contained" color="primary" type={primaryButtonType} onClick={onPrimaryClick} disabled={saving}>{primaryButtonText}</ModalButton>
                            </LocalProgressOverlay>
                        </Grid>
                    }
                </Grid>
            }
            {ternaryButtonText && onTernaryClick &&
                <CentredGridItem item xs={12}>
                    <Typography style={{fontSize: '14px'}}>
                        <Link href="#" style={{color: '#000'}} onClick={onTernaryClick}>
                            {ternaryButtonText}
                        </Link>
                    </Typography>
                </CentredGridItem>
            }
        </React.Fragment>
    );
}

export default AccountCardLayout;
