import React from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { CustomTextField } from './CustomInputs';

function MultilineTextBox({maxLength, variant, initialRows, displayRemainingLength, ...props}) {
    const { t } = useTranslation();
    const remainingLength = maxLength - props.field.value.length;
    const [hasFocus, setHasFocus] = React.useState(false);

    return (
        <Grid container spacing={0}>
            <Grid item xs={12}>
                <CustomTextField
                    {...props}
                    variant={variant}
                    inputProps={ maxLength ? {maxLength: maxLength} : undefined }
                    InputProps={{ 
                        onFocus: _ => setHasFocus(true),
                        onBlur: _ => setHasFocus(false)
                    }}
                    multiline
                    rows={initialRows}
                    helperText={`${remainingLength} ${t('multilineTextBox.charactersLeft')}`}
                    hasFocus={hasFocus}
                    displayRemainingLength={displayRemainingLength}
                    fullWidth
                />
            </Grid>
        </Grid>
    );
}

MultilineTextBox.defaultProps = {
    variant: "outlined",
    displayRemainingLength: true
};

export default MultilineTextBox;