import React, { useState } from 'react';
import { Typography, SvgIcon, TextField, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import { ReactComponent as HeartOutlineIcon } from 'resources/images/icons/heartOutline.svg';
import AccountCardLayout from 'Components/Layouts/Authorization/AccountCardLayout';
import { ModalButton } from 'Components/Styles/Modals/Components';
import feedbackRequest from 'api/freshdesk/anonymousFeedback';
import getWebsiteLink from 'utilities/getWebsiteLink';


const StyledTextField = styled(TextField)`
    margin-bottom: 29px;
    margin-top: 18px;
`;

function FeedbackPage() {
    const { t } = useTranslation();
    const history = useHistory();
    const [feedback, setFeedback] = useState('');
    const [isSendingFeedback, setIsSendingFeedback] = useState(false);

    const goToLandingPage = () => {
        window.location.href = getWebsiteLink('/');
    };

    async function sendFeedback() {
        if (isSendingFeedback) {
            return;
        }

        setIsSendingFeedback(true);
        await feedbackRequest(feedback);
        goToLandingPage();
    };

    return (
        <AccountCardLayout
            icon={<SvgIcon component={HeartOutlineIcon} color="primary" viewBox="0 0 88 88" />}
            title={t('signUpTermsFeedbackAlert.title')}
        >
            <Typography variant="body2" color="textSecondary">{t('signUpTermsFeedbackAlert.description')}</Typography>
            <StyledTextField
                onChange={e => setFeedback(e.target.value)}
                variant="outlined"
                multiline
                placeholder={t('signUpTermsFeedbackAlert.textboxPlaceholder')}
                inputProps={{ maxLength: 500}}
                rows={4}
                fullWidth
            />
            <Grid container item justify="center">
                <ModalButton size="small" variant="outlined" color="primary" onClick={_ => goToLandingPage()}>
                    {t('signUpTermsFeedbackAlert.cancelButtonText')}
                </ModalButton>
                <ModalButton size="small" variant="contained" color="primary" onClick={sendFeedback} disabled={feedback.length === 0}>
                    {t('signUpTermsFeedbackAlert.sendButtonText')}
                </ModalButton>
            </Grid>
        </AccountCardLayout>
    );
}

export default FeedbackPage;
