import React from 'react';
import {
    SvgIcon,
} from '@material-ui/core';
import styled from 'styled-components';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { ReactComponent as CheckCircleOutlineIcon } from 'resources/images/icons/checkCircleOutlineIcon.svg';
import DashboardTemplate from 'Components/Layouts/NavbarTemplate';
import ParticipantList from 'Components/Parts/MyStudy/ParticipantList';
import MyStudyNoteModal from 'Components/Parts/MyStudy/MyStudyNoteModal';
import DetailsHeader from 'Components/Parts/Study/Details/DetailsHeader';
import { RoundedButton } from 'Components/Styles/Buttons/RoundedButton';
import { ReactComponent as EyeOutlineIcon } from 'resources/images/icons/eyeOutline.svg';
import { getMyStudy } from 'redux/reducers/myStudy/actions';
import { ParticipantStatus } from 'Constants/constants';
import getStudyParticipantCsvLink from 'api/study/getStudyParticipantCsvLink';
import downloadLink from 'utilities/downloadLink';


const StyledDashboardTemplate = styled(DashboardTemplate)`
    padding-bottom: 32px;
`;

const StyledParticipantList = styled(ParticipantList)`
    margin-top: 8px;
`;

const ViewStudyButton = ({ studyId }) => {
    const { t } = useTranslation();
    const history = useHistory();
    return (
        <RoundedButton
            onClick={() => history.push(`/study/${studyId}`)}
            variant="outlined"
            size="small"
            endIcon={<SvgIcon component={EyeOutlineIcon} viewBox="0 0 8 8" />}
        >
            {t('myStudyPage.viewStudyButtonText')}
        </RoundedButton>
    );
};

const DownloadCsvButton = ({ studyId }) => {
    const { t } = useTranslation();

    const handleDownloadCsv = async () => {
        const link = await getStudyParticipantCsvLink(studyId);
        if (link) {
            downloadLink(link, 'participants.csv');
        }
    };

    return (
        <RoundedButton
            variant="outlined"
            size="small"
            endIcon={<GetAppOutlinedIcon />}
            onClick={handleDownloadCsv}
        >
            {t('myStudyPage.downloadParticipantCsvButtonText')}
        </RoundedButton>
    );
}

export default function MyStudy() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { studyId } = useParams();
    const study = useSelector(state => state.myStudy.studyResource);

    React.useEffect(() => {
        dispatch(getMyStudy(studyId));
    }, [studyId, dispatch]);

    if (!study) {
        return null;
    }

    return (
        <StyledDashboardTemplate>
            <DetailsHeader
                id={studyId}
                title={study.title}
                status={study.status}
                imageSize="109px"
                mobileAlign="center"
                studyPeriodEnd={study.period?.end}
                actionComponent={
                    <React.Fragment>
                        <DownloadCsvButton studyId={studyId} />
                        <ViewStudyButton studyId={studyId} />
                    </React.Fragment>
                }
            />
            <StyledParticipantList
                icon={<ShowChartIcon color="primary" style={{ fontSize: "24px" }} />}
                title={t('myStudyPage.interestedLabel')}
                status={ParticipantStatus.INTERESTED}
                expand={true}
            />
            <StyledParticipantList
                icon={<SvgIcon component={CheckCircleOutlineIcon} htmlColor="#2cc7de" style={{ fontSize: "24px" }} viewBox="0 0 32 32" />}
                title={t('myStudyPage.recruitedLabel')}
                status={ParticipantStatus.RECRUITED}
            />
            <StyledParticipantList
                icon={<HighlightOffIcon htmlColor="#f10ebc" style={{ fontSize: "24px" }} />}
                title={t('myStudyPage.declinedLabel')}
                status={ParticipantStatus.DECLINED}
            />
            <MyStudyNoteModal />
        </StyledDashboardTemplate>
    );
}
