import React from 'react';
import { useSelector } from 'react-redux';

import { UserType, EligibilityStatus } from 'Constants/constants';
import GuestBar from './EligibilityBars/GuestBar';
import BaselineQuestionnaireBar from './EligibilityBars/BaselineQuestionnaireBar';
import EligibilityCustomCriteriaBar from './EligibilityBars/EligibilityCustomCriteriaBar';
import EligibilityQuestionnaireBar from './EligibilityBars/EligibilityQuestionnaireBar';
import EligibleBar from './EligibilityBars/EligibleBar';
import NotEligibleBar from './EligibilityBars/NotEligibleBar';
import CalculatingEligibilityBar from './EligibilityBars/CalculatingEligibilityBar';


function EligibilityBar({ studyId, studyPeriodEnd, eligibilityStatus, interestedStatus, refreshed }) {
    const { authorized, accountType, profile } = useSelector(state => state.authorization);
    const userEligibilities = useSelector(state => state.userEligibility.eligibilities);
    const eligibility = userEligibilities && userEligibilities[studyId] && userEligibilities[studyId].status
        ? userEligibilities[studyId].status
        : eligibilityStatus;
    const interested = userEligibilities && userEligibilities[studyId]
        ? userEligibilities[studyId].interested
        : interestedStatus;

    const isOver = studyPeriodEnd ? new Date(studyPeriodEnd) < new Date() : true;
    
    if (accountType !== UserType.PATIENT || isOver) {
        return null;
    }

    let bar = null;
    if (!authorized) {
        bar = <GuestBar />;
    } else if (interested) {
        bar = <EligibleBar studyId={studyId} interested={true} />;
    } else if (!profile.baselineQuestionnaireCompleted && !profile.processingEligibilities) {
        bar = <BaselineQuestionnaireBar />;
    } else if (eligibility === EligibilityStatus.ELIGIBLE) {
        bar = <EligibleBar studyId={studyId} />;
    } else if (eligibility === EligibilityStatus.NOT_ELIGIBLE) {
        bar = <NotEligibleBar />;
    } else if (eligibility === EligibilityStatus.POTENTIALLY_ELIGIBLE_QUESTIONS_PENDING && (profile.processingEligibilities || !refreshed)) {
        bar = <CalculatingEligibilityBar />;
    } else if (eligibility === EligibilityStatus.POTENTIALLY_ELIGIBLE_CUSTOM_CRITERIA) {
        bar = <EligibilityCustomCriteriaBar studyId={studyId} />;
    } else if (eligibility === EligibilityStatus.POTENTIALLY_ELIGIBLE_QUESTIONS_PENDING) {
        bar = <EligibilityQuestionnaireBar studyId={studyId} />;
    }

    return bar;
}

EligibleBar.defaultProps = {
    eligibilityStatus: EligibilityStatus.POTENTIALLY_ELIGIBLE_QUESTIONS_PENDING,
    interestedStatus: false,
};

export default EligibilityBar;
