import React from 'react';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import EligibilityGroup from 'Components/Parts/Study/StudyEligibility/StudyEligibilityGroup';
import { getEligibilityQuestions } from 'data/study/Eligibility/eligibilityQuestions';
import EligibilitySelectedOptionsField from 'Components/Parts/Study/StudyEligibility/EligibilitySelectedOptionsField';
import { MajorErrorMessage } from 'Components/Styles/Form/ErrorMessage';

const SpacedErrorMessage = styled.div`
    padding-top: 2em;
`;

export default function Eligibility() {
    const { t } = useTranslation();
    const eligibilityQuestions = getEligibilityQuestions(t);
    return (
        <Grid container spacing={2}>
            <Demographics t={t} questions={eligibilityQuestions.demographics} />
            <Condition t={t} questions={eligibilityQuestions.condition} />
            <Lifestyle t={t} questions={eligibilityQuestions.lifestyle} />
            <Monitoring t={t} questions={eligibilityQuestions.monitoring} />
            <Insulin t={t} questions={eligibilityQuestions.insulin} />
            <Advanced t={t} questions={eligibilityQuestions.advanced} />
            <Medication t={t} questions={eligibilityQuestions.medication} />
            <A1C t={t} questions={eligibilityQuestions.a1c} />
            <Ketoacidosis t={t} questions={eligibilityQuestions.ketoacidosis} />
            <Hypoglycemia t={t} questions={eligibilityQuestions.hypoglycemia} />
            <Pregnancy t={t} questions={eligibilityQuestions.pregnancy} />
            <SecondaryComplications t={t} questions={eligibilityQuestions.secondary} />
            <OtherConditions t={t} questions={eligibilityQuestions.conditions} />
            <CustomCategory t={t} questions={eligibilityQuestions.customCategory} />
            <EligibilitySelectedOptionsField/>
            <SpacedErrorMessage>
                <MajorErrorMessage name="eligibility.selectedOptions" />
            </SpacedErrorMessage>
        </Grid>
    );
}

function Demographics({ t, questions }) {
    return (
        <EligibilityGroup 
            title={t('studySetupEligibility.demographicsTitle')}
            questions={questions}
        />
    );
}

function Condition({ t, questions }) {
    return (
        <EligibilityGroup 
            title={t('studySetupEligibility.conditionTitle')}
            questions={questions}
        />
    );
}

function Lifestyle({ t, questions }) {
    return (
        <EligibilityGroup 
            title={t('studySetupEligibility.lifestyleTitle')}
            questions={questions}
        />
    );
}

function Monitoring({ t, questions }) {
    return (
        <EligibilityGroup 
            title={t('studySetupEligibility.monitoringTitle')}
            questions={questions}
        />
    );
}

function Insulin({ t, questions }) {
    return (
        <EligibilityGroup 
            title={t('studySetupEligibility.insulinTitle')}
            questions={questions}
        />
    );
}

function Advanced({ t, questions }) {
    return (
        <EligibilityGroup
            title={t('studySetupEligibility.advancedTitle')}
            questions={questions}
        />
    );
}

function Medication({ t, questions }) {
    return (
        <EligibilityGroup 
            title={t('studySetupEligibility.medicationTitle')}
            questions={questions}
        />
    );
}

function A1C({ t, questions }) {
    return (
        <EligibilityGroup 
            title={t('studySetupEligibility.a1cTitle')}
            questions={questions}
        />
    );
}

function Ketoacidosis({ t, questions }) {
    return (
        <EligibilityGroup 
            title={t('studySetupEligibility.ketoacidosisTitle')}
            questions={questions}
        />
    );
}

function Hypoglycemia({ t, questions }) {
    return (
        <EligibilityGroup 
            title={t('studySetupEligibility.hypoglycemiaTitle')}
            questions={questions}
        />
    );
}

function Pregnancy({ t, questions }) {
    return (
        <EligibilityGroup 
            title={t('studySetupEligibility.pregnancyTitle')}
            questions={questions}
        />
    );
}

function SecondaryComplications({ t, questions }) {
    return (
        <EligibilityGroup 
            title={t('studySetupEligibility.secondaryTitle')}
            questions={questions}
        />
    );
}

function OtherConditions({ t, questions }) {
    return (
        <EligibilityGroup 
            title={t('studySetupEligibility.conditionsTitle')}
            questions={questions}
        />
    );
}

function CustomCategory({ t, questions }) {
    return (
        <EligibilityGroup 
            title={t('studySetupEligibility.customCategoryTitle')}
            tabbed={false}
            questions={questions}
        />
    );
}
