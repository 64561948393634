import React, { useEffect, useState } from 'react';
import {
    Container,
    Grid,
    SvgIcon,
    Typography,
} from '@material-ui/core';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { format as dateFormat } from 'date-fns'

import { ReactComponent as PeopleOutlineIcon } from 'resources/icons/peopleOutlineIcon.svg';
import GlobalProgressOverlay from 'Components/Parts/Progress/GlobalProgressOverlay';
import NavbarTemplate from 'Components/Layouts/NavbarTemplate';
import { SERVER_API_CONTEXT_PATH } from 'Constants/constants';
import { getDateLocale } from 'translations/i18n';


const StudyImage = styled.img`
    height: 185px;
    margin-bottom: 29px;
    object-fit: cover;
    width: 100%;
`;

const Title = styled(Typography)`
    font-size: 32px;
    letter-spacing: -1.07px;
    line-height: 52px;
`;

const StudyDetails = styled(Grid)`
    margin: 27px 0 32px;
`;

const LocationText = styled(Typography)`
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
`;

const DateText = styled(Typography)`
    color: #939393;
    font-size: 12px;
    margin-left: 24px;
`;

const Description = styled(Typography)`
    color: rgba(0, 0, 0, 0.7);
    font-size: 16px;
    letter-spacing: -0.53px;
    line-height: 1.63;
`;

const Separator = styled.div`
    margin: 50px 0;
    border-top: 1px solid #e6e6e6;
`;

const AuthorsWrapper = styled(Grid)`
    margin-left: 25px;
`;

const ResearchTeam = styled(Grid)`
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 16px;
`;

const ResearchTeamTitle = styled(Typography)`
    margin-left: 16px;
`;

const ResearcherText = styled(Typography)`
    color: rgba(0, 0, 0, 0.7);
    font-size: 16px;
    font-style: italic;
    font-weight: 500;
`;

function FindingContent({ finding }) {
    const { t, i18n } = useTranslation();
    const { title, description, image, location, publishDate, authors } = finding;

    return (
        <React.Fragment>
            <StudyImage src={image} />
            <Container maxWidth="md">
                <Title variant="h1">{title}</Title>
                <StudyDetails container wrap="nowrap">
                    <LocationText>{location}</LocationText>
                    {publishDate && <DateText>{dateFormat(new Date(publishDate), 'yyyy MMM d', { locale: getDateLocale(i18n.language) })}</DateText>}
                </StudyDetails>
                <Description dangerouslySetInnerHTML={{__html: description}} />
                <Separator />
                <AuthorsWrapper>
                    <ResearchTeam container wrap="nowrap" alignContent="center">
                        <SvgIcon component={PeopleOutlineIcon} color="primary" style={{ fontSize: '31px' }}/>
                        <ResearchTeamTitle variant="h5" color="primary">{t('findingPage.researchTeamTitle')}</ResearchTeamTitle>
                    </ResearchTeam>
                    <ResearcherText>
                        {authors}
                    </ResearcherText>
                </AuthorsWrapper>
            </Container>
        </React.Fragment>
    )
}

function FindingPage({ study, fhirBundle }) {
    const [finding, setFinding] = useState(null);
    const loading = useSelector(state => state.serverCommunication.loading);

    useEffect(() => {
        const studyResource = fhirBundle.find(item => item.resource.resourceType === 'ResearchStudy')?.resource;                
        setFinding({
            title: studyResource.title,
            description: studyResource.description,
            publishDate: studyResource.extension.find(ext => ext.url === 'https://app.connect1d.ca/ext/finding/publishdate')?.valueString,
            authors: studyResource.extension.find(ext => ext.url === 'https://app.connect1d.ca/ext/finding/authors')?.valueString,
            image: `${SERVER_API_CONTEXT_PATH(process.env.REACT_APP_STAGE)}/api/study/${study.id}/image`,
            location: studyResource.jurisdiction?.[0]?.coding.find(data => data.system === 'http://ehealthontario.ca/API/FHIR/CodeSystem/StateCode')?.display,
        });
    }, [study, fhirBundle]);

    return (
        <React.Fragment>
            <GlobalProgressOverlay open={loading}/>
            <NavbarTemplate noGutters maxWidth={false}>
                {finding &&
                    <FindingContent finding={finding} />
                }
            </NavbarTemplate>
        </React.Fragment>
    )
}

export default FindingPage;