import getQuestionnaireRequest from 'api/fhir/getQuestionnaire';
import getQuestionnaireResponseRequest from 'api/fhir/getQuestionnaireResponse';
import { enqueueGlobalUserMessageState, enqueueGlobalUserMessageUnknownError } from 'redux/reducers/globalUserMessaging/index';
import { setLoading, unsetLoading, setSaving, unsetSaving } from '../serverCommunication/actions';
import generateInitialValues, { getQuestionnaireResponseAnswer } from 'data/questionnaires/generateInitialValues';
import putQuestionnaireResponse from 'api/fhir/putQuestionnaireResponse';
import postQuestionnaireResponse from 'api/fhir/postQuestionnaireResponse';
import { CLIENT_SELECTION_URI, QuestionnaireStatus } from 'Constants/constants';
import { completedBaseline, updateUserProfile, updatingEligibilities } from '../authorization/actions';
import getUserProfile from 'api/users/getUserProfile';

export const SET_CONTENTS = 'SET_CONTENTS';
export function setContents(id, title, questions) {
    return {
        type: SET_CONTENTS,
        payload: {
            id,
            title,
            questions
        }
    };
}

export const RESET_CONTENTS = 'RESET_CONTENTS';
export const resetContents = {
    type: RESET_CONTENTS
};

export const SET_QUESTION = 'SET_QUESTION';
export function setQuestion(questionNumber) {
    return {
        type: SET_QUESTION,
        payload: {
            questionNumber
        }
    };
}

export const ADD_VALUE_SET = 'ADD_VALUE_SET';
export function addValueSet(id, valueSet) {
    return {
        type: ADD_VALUE_SET,
        payload: {
            id,
            valueSet
        }
    };
}

export const SET_QUESTIONNAIRE_REPONSE_ID = 'SET_QUESTIONNAIRE_REPONSE_ID';
export function setQuestionnaireResponseId(id) {
    return {
        type: SET_QUESTIONNAIRE_REPONSE_ID,
        payload: {
            id
        }
    }
}

export const TOGGLE_EXIT_REQUEST_ACTIVE = 'TOGGLE_EXIT_REQUEST_ACTIVE';
export const toggleExitRequest = {
    type: TOGGLE_EXIT_REQUEST_ACTIVE
};

export function getQuestionnaire(id, buildInitialValues) {
    return function(dispatch, getState) {
        const patientFhirId = getState().authorization.fhirId;
        dispatch(setLoading);
        return getQuestionnaireRequest(id)
            .then(({data}) => {
                const title = data.title;
                const questions = data.item.map(question => {
                    question.valueSetId = question.options?.reference?.split('/')[1];
                    question.clientSelection = question.extension?.find(ext => ext.url === CLIENT_SELECTION_URI)?.valueString;
                    const appendedCharacter = question.linkId.match(/_[^_]$/);
                    if (appendedCharacter) {
                        question.clientSelection = question.clientSelection.concat(appendedCharacter[0].substring(1));
                    }
                    delete question.options;
                    delete question.extension;
                    return question;
                });

                dispatch(getQuestionnaireResponse(patientFhirId, id, title, QuestionnaireStatus.ACTIVE, questions, buildInitialValues))
            }).catch(() => {
                dispatch(enqueueGlobalUserMessageState(
                    "error", 
                    "error.questionnaireRetrieval"
                ));
            }).finally(() => {
                dispatch(unsetLoading)
            });
    };
}

export function getQuestionnaireResponse(patientFhirId, questionnaireId, questionnaireTitle, status, questions, buildInitialValues) {
    return function(dispatch) {
        return getQuestionnaireResponseRequest(patientFhirId, questionnaireId, status)
            .then(({data}) => {
                if (!data.entry) {
                    // if there is no existing QR, we get a successful response, but it is empty
                    // in this case, make sure that we clear out the QR id so we don't accidentally overwrite another QR
                    dispatch(setQuestionnaireResponseId(""));
                    return;
                }

                const questionnaireResponse = data.entry[0].resource;
                dispatch(setQuestionnaireResponseId(questionnaireResponse.id));
                if (!questionnaireResponse.item) {
                    return;
                }
                
                questions.map(question => {
                    const qrItem = questionnaireResponse.item.find(ans => ans.linkId === question.linkId);
                    if (qrItem && qrItem.answer && question.type !== "open-choice") {
                        question.defaultAnswer = qrItem.answer.map(answer => getQuestionnaireResponseAnswer(answer));
                        if (question.defaultAnswer.length === 1) {
                            question.defaultAnswer = question.defaultAnswer[0];
                        }
                    } else if (qrItem && qrItem.answer) {
                        question.defaultAnswer = {
                            text: getQuestionnaireResponseAnswer(qrItem.answer.find(answer => answer.valueString)) || "",
                            select: qrItem.answer.filter(answer => !answer.valueString).map(answer => getQuestionnaireResponseAnswer(answer)) || ""
                        }
                    }
                    return question;
                });
            })
            .catch(() => dispatch(setQuestionnaireResponseId("")))
            .finally(() => {
                dispatch(setContents(questionnaireId, questionnaireTitle, questions));
                buildInitialValues(generateInitialValues(questions));
            })
    }
}

export function sendQuestionnaireResponse(questionnaireResponse, complete, baseline) {
    return async function(dispatch, getState) {
        dispatch(setSaving);
        if (questionnaireResponse.id) {
            await putQuestionnaireResponse(questionnaireResponse.id, questionnaireResponse);
        } else {
            await postQuestionnaireResponse(questionnaireResponse)
                .then(({data}) => {
                    dispatch(setQuestionnaireResponseId(data.id));
                });
        }
        if (complete) {
            if (baseline) {
                dispatch(completedBaseline);
            }
            else {
                dispatch(updatingEligibilities);
            }
            getUserProfile(getState().authorization.userId)
                .then(({data}) => dispatch(updateUserProfile(data)))
                .catch(dispatch(enqueueGlobalUserMessageUnknownError));
        }
        dispatch(unsetSaving);
    }
}