import React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

const maskOptions = {
    prefix: '',
    suffix: '', 
    includeThousandsSeparator: false,
    allowDecimal: true,
    decimalLimit: 1,
    integerLimit: 2,
    allowNegative: false,
    allowLeadingZeroes: false,
};

function TextMaskDecimal(props) {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={createNumberMask(maskOptions)}
            placeholderChar={'\u2000'}
        />
    );
}
TextMaskDecimal.propTypes = {
    inputRef: PropTypes.func.isRequired,
};

export default TextMaskDecimal;