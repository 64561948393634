import React from 'react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { useTranslation } from 'react-i18next';

import { getDateLocale } from 'translations/i18n';


export default function PickerProvider({ children }) {
    const { i18n } = useTranslation();

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={getDateLocale(i18n.language)}>
            {children}
        </MuiPickersUtilsProvider>
    );
}
