import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import { Switch, Route, useLocation, useHistory } from 'react-router-dom';
import { registrationConfirmation } from 'redux/reducers/authorization';
import RegistrationErrorExpired from 'Components/Views/AuthPages/UserRegistration/RegistrationErrorExpired';
import RegistrationErrorActivated from 'Components/Views/AuthPages/UserRegistration/RegistrationErrorActivated';
import RegistrationErrorInvalid from 'Components/Views/AuthPages/UserRegistration/RegistrationErrorInvalid';
import RegistrationSuccess from 'Components/Views/AuthPages/UserRegistration/RegistrationSuccess';

export default function UserRegistration() {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const queryValues = queryString.parse(location.search);
    const errorRedirectBase = "/registered/error/";

    function handleSuccess() {
        history.push("/registered/success");
    }

    function handleError({error}) {
        switch(error) {
            case "ACTIVATION_CODE_NOT_FOUND":
            case "MAXIMUM_ATTEMPTS_EXCEEDED":
                history.push(errorRedirectBase+"invalid");
                break;
            case "EXPIRED_ACTIVATION_CODE":
                history.push(errorRedirectBase+"expired");
                break;
            default: 
                history.push(errorRedirectBase+"activated");
        }
    }

    useEffect(() => {
        dispatch(registrationConfirmation(queryValues.token, handleSuccess, handleError));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Switch>
            <Route path="/registered/success/" component={RegistrationSuccess}/>
            <Route path="/registered/error/activated" component={RegistrationErrorActivated}/>
            <Route path="/registered/error/expired" component={RegistrationErrorExpired}/>
            <Route path="/registered/error/invalid" component={RegistrationErrorInvalid}/>
        </Switch>
    );
}