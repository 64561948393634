import React from 'react';
import { Field, FieldArray, useField } from 'formik';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import MultilineTextBox from 'Components/Parts/Form/TextBoxes/MultilineTextBox';
import { WizardCheckboxFieldRow, WizardCheckboxRow } from 'Components/Layouts/Setup/WizardDataRow/WizardDataRow';
import { compensationOptions } from 'data/study/studyCompensationSelection';

export default function Compensation() {
    const name = "compensation.types";
    const { t } = useTranslation();
    const [field] = useField(name);

    return (
        <Grid>
            <FieldArray
            name={name}
            render={arrayHelpers => {
                function handleClick(id) {
                    const index = field.value.indexOf(id);
                    if (index < 0) {
                        arrayHelpers.push(id);
                    } else {
                        arrayHelpers.remove(index);
                    }
                }
                return <CompensationCheckboxRows values={field.value} handleClick={handleClick} t={t} />;
            }}/>
            <WizardCheckboxFieldRow name="compensation.typeOther" title={t('compensationOption.addCustomFieldTitle')} description={t('compensationOption.addCustomFieldDescription')}>
                {<Field
                    component={MultilineTextBox} 
                    name="compensation.typeOtherDescription" 
                    type="text"
                    placeholder={t('compensationOption.addCustomFieldPlaceholder')}
                    maxLength={100}
                    variant="filled"
                />}
            </WizardCheckboxFieldRow>
        </Grid>
    );
}

function CompensationCheckboxRows({values, handleClick, t}) {
    return (
        compensationOptions.map(({id, getTitle, getDescription}) => {
            return <WizardCheckboxRow 
                key={`compensation-checkbox-${id}`}
                id={id} 
                title={getTitle(t)} 
                description={getDescription(t)}
                checked={values.indexOf(id) >= 0}
                handleClick={() => handleClick(id)}
            />;
        })
    );
}