import React from 'react';
import styled from 'styled-components';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { Typography, Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { resendActivation } from 'redux/reducers/accountCreation/actions';
import TextBox from 'Components/Parts/Form/TextBoxes/TextBox';
import AccountCardLayout from 'Components/Layouts/Authorization/AccountCardLayout';
import AuthCardPageLayout from 'Components/Layouts/Authorization/AuthCardPageLayout';

const DivWithBottomPadding = styled.div`
    padding-bottom: 2em;
`;

export default function RegistrationErrorLayout({title, description}) {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    return (
        <Formik
        initialValues={{
            email: ""
        }}
        validationSchema={Yup.object({
            email: Yup.string()
                .email("Email must be in a valid format")
                .required("Oops! Forgot one")
        })}
        onSubmit={({email}) => dispatch(resendActivation(email))}>
            <Form>
                <AuthCardPageLayout>
                    <AccountCardLayout
                        title={title}
                        primaryButtonText={t('signUpError.resendButton')}
                        primaryButtonType="submit"
                    >
                        <DivWithBottomPadding>
                            <Grid container spacing={4}>
                                <Grid item xs={12}>
                                    <Typography variant="body2" color="textSecondary" dangerouslySetInnerHTML={{__html: description}} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field
                                        component={TextBox} 
                                        name="email" 
                                        type="email"
                                        label="Email"
                                    />
                                </Grid>
                            </Grid>
                        </DivWithBottomPadding>
                    </AccountCardLayout>
                </AuthCardPageLayout>
            </Form>
        </Formik>
    );
}