import React from 'react';
import { Switch, Route } from 'react-router-dom';
import QuestionnaireLayout from 'Components/Layouts/Questionnaire/QuestionnaireLayout';
import BaselineQuestionnaireStartPage from 'Components/Parts/Questionnaire/BaselineQuestionnaireStartPage';

export default function QuestionnaireContainer() {
    return (
        <Switch>
            <Route path="/questionnaire" exact component={QuestionnaireLayout} />
            <Route path="/questionnaire/welcome" component={BaselineQuestionnaireStartPage} />
            <Route path="/questionnaire/:questionnaireId" component={QuestionnaireLayout} />
        </Switch>
    );
}