import produce from "immer";
import { Page } from 'Constants/constants';
import * as Actions from "./actions";


const defaultFilters = {
    filters: [],
    filterEnglish: true,
    filterFrench: true,
    sort: '',
};

const initialState = {
    filters: {
        [Page.EXPLORE]: { ...defaultFilters },
        [Page.DASHBOARD]: { ...defaultFilters },
        [Page.FINDINGS]: { ...defaultFilters },
    },
    showLanguageModal: false,
    showLogoutModal: false,
};

export default (state = initialState, action) =>
    produce(state, draft => {
        const {type, payload} = action;
        switch(type) {
            case Actions.SET_FILTERS:
                draft.filters[payload.page].filters = payload.filters;
                break;
            case Actions.SET_ENGLISH_FILTER:
                draft.filters[payload.page].filterEnglish = payload.value;
                break;
            case Actions.SET_FRENCH_FILTER:
                draft.filters[payload.page].filterFrench = payload.value;
                break;
            case Actions.SET_SORT:
                draft.filters[payload.page].sort = payload.sort;
                break;
            case Actions.OPEN_LANGUAGE_MODAL:
                draft.showLanguageModal = true;
                break;
            case Actions.CLOSE_LANGUAGE_MODAL:
                draft.showLanguageModal = false;
                break;
            case Actions.OPEN_LOGOUT_MODAL:
                draft.showLogoutModal = true;
                break;
            case Actions.CLOSE_LOGOUT_MODAL:
                draft.showLogoutModal = false;
                break;
            default:
                return draft;
        }
    });