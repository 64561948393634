import React from 'react';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';
import Dropdown from './Dropdown';
import { MenuItem, Checkbox, ListItemText } from '@material-ui/core';

function MultipleDropdown({className, name, variant, label, values, disabled, dropdownTitle, fullWidth, maximum, defaultText='', ...props}) {
    const { t } = useTranslation();

    const renderValue = selected => 
        selected.map(({ label, getLabel }) => 
            getLabel ? getLabel(t) : label
        ).join(', ');

    return (
        <Field name={name}>
            {({field, form}) =>
                <Dropdown
                    className={className}
                    variant={variant}
                    field={field}
                    form={form}
                    disabled={disabled}
                    label={label}
                    renderValue={selection => selection && selection.length > 0 ? renderValue(selection) : (!label ? defaultText : '')}
                    dropdownTitle={dropdownTitle}
                    multiple
                    fullWidth={fullWidth}
                    maximum={maximum}
                    defaultText={defaultText}
                    {...props}
                >
                    <MenuItem value="" disabled>{defaultText}</MenuItem>
                    {values.map(value => {
                        const { id, label, subtitle, getLabel, getSubtitle } = value;
                        const labelText = getLabel ? getLabel(t) : label;
                        const subtitleText = getSubtitle ? `(${getSubtitle(t)})` : subtitle;
                        return (
                            <MenuItem key={id} value={value}>
                                <Checkbox color="primary" checked={field.value && field.value.map(value => value.id).indexOf(id) >= 0}/>
                                <ListItemText primary={labelText} secondary={subtitleText} secondaryTypographyProps={{style: {fontStyle: 'italic'}}}/>
                            </MenuItem>
                        );
                    })}
                </Dropdown>
            }
        </Field>
    );
}

MultipleDropdown.defaultProps = {
    variant: "outlined",
    fullWidth: true,
    maximum: Number.MAX_SAFE_INTEGER
};

export default MultipleDropdown;