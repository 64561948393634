import appAdminRequest from "api/app/appAdmin";

export const UPDATE_VERSION = 'UPDATE_VERSION';
export function updateVersion(version) {
    return {
        type: UPDATE_VERSION,
        payload: {
            version
        }
    };
}

export function getServerVersion() {
    return function(dispatch) {
        appAdminRequest()
            .then(({data}) => dispatch(updateVersion(data.downstreamBuildVersion)))
            .catch(/* do nothing */);
    }
}