import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CrossCircledIcon } from 'resources/images/icons/crossCircledOutline.svg';
import {
    BarText,
    BarButton,
    EligibilityBarTemplate,
} from './EligibilityBarTemplate';
import { Page } from 'Constants/constants';


export default function NotEligibleBar() {
    const { t } = useTranslation();
    const history = useHistory();
    return (
        <EligibilityBarTemplate color="warning">
            <SvgIcon component={CrossCircledIcon} style={{ fontSize: "2rem" }} />
            <BarText>{t('eligibilityBar.notEligibleText')}</BarText>
            <BarButton onClick={() => history.push(Page.EXPLORE)}>{t('eligibilityBar.notEligibleButtonText')}</BarButton>
        </EligibilityBarTemplate>
    );
}
