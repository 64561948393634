import getUserEligibilitiesRequest from 'api/eligibility/getUserEligibilities';
import getStudyRequest from 'api/study/getStudy';
import { enqueueGlobalUserMessageUnknownError } from '../globalUserMessaging/index';

export const SET_USER_ELIGIBILITY = 'SET_USER_ELIGIBILITY';
export function setUserEligibility(eligibilities) {
    return {
        type: SET_USER_ELIGIBILITY,
        payload: {
            eligibilities
        }
    };
}

export const UPDATE_USER_ELIGIBILITY = 'UPDATE_USER_ELIGIBILITY';
export function updateUserEligibility(study, eligibility) {
    return {
        type: UPDATE_USER_ELIGIBILITY,
        payload: {
            study,
            eligibility
        }
    };
}

export const UPDATE_USER_ELIGIBILITY_STATUS = 'UPDATE_USER_ELIGIBILITY_STATUS';
export function updateUserEligibilityStatus(study, status) {
    return { 
        type: UPDATE_USER_ELIGIBILITY_STATUS,
        payload: {
            study,
            status
        }
    };
}

export const UPDATE_USER_INTEREST = 'UPDATE_USER_INTEREST';
export function updateUserInterest(study, interested) {
    return {
        type: UPDATE_USER_INTEREST,
        payload: {
            study,
            interested
        }
    };
}

export function getUserEligibilities() {
    return function(dispatch) {
        getUserEligibilitiesRequest()
            .then(({data}) => dispatch(setUserEligibility(data.eligibilities)))
            .catch(_ => dispatch(enqueueGlobalUserMessageUnknownError()));
    };
}

export function getUserEligibilityForStudy(studyId) {
    return function(dispatch) {
        getStudyRequest(studyId)
            .then(({data}) => {
                dispatch(updateUserEligibilityStatus(studyId, data.calculatedEligibilityStatus));
                dispatch(updateUserInterest(studyId, data.interestedUserStatus));
            })
            .catch(_ => dispatch(enqueueGlobalUserMessageUnknownError()));
    };
}