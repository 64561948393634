import React from 'react';
import TextMaskInteger from './TextMasks/TextMaskInteger';
import { CustomTextField } from './CustomInputs';


function NumericTextBox({variant, ...props}) {
    return (
        <CustomTextField 
            {...props} 
            variant={variant}
            InputProps={{inputComponent: TextMaskInteger}}
            fullWidth
        />
    );
}

NumericTextBox.defaultProps = {
    variant: "outlined"
};

export default NumericTextBox;