import styled from 'styled-components';

export const WizardContainer = styled.div`
    display: flex;
    justify-content: center;
    background-color: white;
    min-height: 100vh;
    height: 100%;
`;

export const WizardContent = styled.div`
    padding: 3em;
    width: 100%;
    max-width: 960px;
`;

export const ReviewContent = styled.div`
    padding: 3em;
    margin: 1em auto;
`;

export const FooterWrap = styled.div`
    margin-bottom: 2em;
`;