import React from 'react';
import styled, { css } from 'styled-components';
import { Grid, Typography, Hidden, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { RoundedImage } from 'Components/Styles/Images/RoundedImage';
import { SERVER_API_CONTEXT_PATH } from 'Constants/constants';
import StudyMeta from 'Components/Parts/MyStudy/Header/StudyMeta';

const Header = styled(Grid)`
    flex-direction: column;

    @media (min-width: 960px) {
        flex-direction: row;
        padding: 2em 0;
    }
`;

const HeaderContentContainer = styled(Grid)`
    flex-grow: 1;
    margin-left: 8px;

    @media (min-width: 960px) {
        margin-left: 24px;
    }
`;

const BottomDetailBar = styled(Grid)`
    margin: 16px 0;
`;

const TitleText = styled(Typography)`
    font-size: 20px;
    line-height: 1.5;
    color: #000;
`;

const DescriptionText = styled(Typography)`
    color: rgba(0, 0, 0, 0.7);
    font-size: 14px;
    margin-top: 11px;
`;

const FillImage = styled.img`
    height: 20vw;
    margin: 0 0 16px 0;
    min-height: 100px;
    object-fit: cover;
    width: 100%;

    ${props => {
        const padding = props.theme.mixins.gutters().paddingLeft;
        return css`
            margin-left: -${padding}px;
            width: calc(100% + ${padding * 2}px);
        `;        
    }};

    @media (min-width: 600px) {
        ${props => {
            const padding = props.theme.mixins.gutters()['@media (min-width:600px)'].paddingLeft;
            return css`
                margin-left: -${padding}px;
                width: calc(100% + ${padding * 2}px);
            `;        
        }};
    }
`;

const ActionComponentContainer = styled(Grid)`

    @media (min-width: 960px) {
        justify-content: flex-end;
        margin: 16px 0;
    }

    & > .MuiButtonBase-root {
        margin: 16px 8px 0;

        @media (min-width: 960px) {
            margin: 0 16px 0 0;

            &:last-child {
                margin-right: 0;
            }
        }
    }
`;

function DetailsHeader({id, title, description, status, imageSize, studyPeriodEnd, mobileAlign, actionComponent}) {
    const theme = useTheme();
    const mdUp = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <Header container>
            <Hidden smDown>
                <RoundedImage item xs={3} 
                    src={`${SERVER_API_CONTEXT_PATH(process.env.REACT_APP_STAGE)}/api/study/${id}/image`} 
                    size={imageSize}
                />
            </Hidden>
            <Hidden mdUp>
                <FillImage
                    src={`${SERVER_API_CONTEXT_PATH(process.env.REACT_APP_STAGE)}/api/study/${id}/image`} 
                />
            </Hidden>
            <HeaderContentContainer>
                <Grid
                    container
                    item
                    direction="column"
                    style={{textAlign: !mdUp ? mobileAlign : 'left'}}
                >
                    <TitleText><b>{title}</b></TitleText>
                    {description &&
                        <DescriptionText>{description}</DescriptionText>
                    }
                </Grid>
                <BottomDetailBar container item justify={'space-between'} alignItems="center">
                    <Grid container item md={6}>
                        <StudyMeta
                            status={status}
                            studyPeriodEnd={studyPeriodEnd}
                            mobileJustify={mobileAlign === 'center' ? 'center' : undefined}
                        />
                    </Grid>
                    {mdUp && actionComponent &&
                        <ActionComponentContainer
                            container
                            item
                            md={6}
                            justify="flex-start"
                        >
                            {actionComponent}
                        </ActionComponentContainer>
                    }
                </BottomDetailBar>
            </HeaderContentContainer>
        </Header>
    );
}

DetailsHeader.defaultProps = {
    imageSize: '159px',
    mobileAlign: 'left',
};

export default DetailsHeader;
