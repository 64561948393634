import React from 'react';
import MultiSelectEligibilityContainer from '../MultiSelectEligibilityContainer';
import { deviceOptions, insulinDeviceSelectionOptions } from 'data/study/Eligibility/eligibilitySelectionOptions';


const name="eligibility.insulin.device";

// Register all the required translation keys
const t = _ => {};
t('studyEligibilityComparitivePhrase.insulinDevice.include.single');
t('studyEligibilityComparitivePhrase.insulinDevice.include.mixed');
t('studyEligibilityComparitivePhrase.insulinDevice.exclude.single');
t('studyEligibilityComparitivePhrase.insulinDevice.exclude.mixed');
t('studyEligibilityComparitivePhrase.insulinDevice.undefined.single');
t('studyEligibilityComparitivePhrase.insulinDevice.undefined.mixed');

export default function StudyEligibilityMonitor({title}) {
    return (
        <MultiSelectEligibilityContainer
            criteria="insulinDevice"
            name={name}
            title={title}
            answerOptions={insulinDeviceSelectionOptions}
            selectOptions={deviceOptions}
        />
    );
}