import React from 'react';
import { useDropzone } from 'react-dropzone';
import { IconButton } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { DropzoneContainer } from 'Components/Styles/Form/Dropzone';
import { DropzoneDefaultContent, handleDrop } from './DropzoneComponents';
import DocumentPreview from 'Components/Parts/Study/DocumentPreview';
import { MinorErrorMessage } from 'Components/Styles/Form/ErrorMessage';

function DropzoneArea({file, fileTypes, maxFileSize, handleDrop, handleReset, title, description, explanation}) {
    const {getRootProps, getInputProps, open, isDragActive, isDragAccept, isDragReject } = useDropzone({
        // Disable click and keydown behavior
        noClick: true,
        noKeyboard: true,
        accept: fileTypes,
        maxSize: maxFileSize,
        onDrop: acceptedFiles => {
            handleDrop(acceptedFiles[0]);
        }
    });

    return (
        <DropzoneContainer {...getRootProps({isDragActive, isDragAccept, isDragReject, file})}>
            <input {...getInputProps()} />
            {!file
            ? <DropzoneDefaultContent open={open} title={title} description={description} explanation={explanation}/>
            : <React.Fragment>
                <DocumentPreview files={[file]}/>
                <IconButton onClick={handleReset}><DeleteOutlineIcon color="error"/></IconButton>
            </React.Fragment>}
        </DropzoneContainer>
    );
}

export default function DocumentDropzone({field, form, maxFileSize, title, description, explanation}) {
    return (
        <React.Fragment>
            <DropzoneArea
                fileTypes="application/pdf"
                maxFileSize={maxFileSize}
                handleDrop={files => handleDrop(field, form, files)}
                handleReset={() => form.setFieldValue(field.name, undefined)}
                file={field.value}
                title={title}
                description={description}
                explanation={explanation}
            />
            <MinorErrorMessage name={field.name}/>
        </React.Fragment>
    );
}