import { axiosInstance } from '../api';
import { OrderDirection } from 'Constants/constants';


export default function(studyId, { status, page=0, orderBy, order }) {
    const sort = orderBy ? `${order === OrderDirection.DESC ? '-' : ''}${orderBy}` : null;
    return axiosInstance.get(`/api/study/${studyId}/interested`, {
        params: {
            status,
            sort,
            page,
        },
    });
}