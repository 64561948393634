import React from 'react';
import { Switch, Route, useHistory, useRouteMatch } from 'react-router';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import AuthPageLayout from 'Components/Layouts/Authorization/AuthPageLayout';
import AccountTypeSelection from './AccountTypeSelection';
import AccountInformation from './AccountInformation';
import AccountCreationPrivacyPolicy from './AccountCreationPrivacyPolicy';
import { useTranslation } from 'react-i18next';
import { register, resetTermsPage } from 'redux/reducers/accountCreation/actions';
import MobileModal from 'Components/Parts/Modals/MobileModal';
import AccountCreationDesktopTemplate from 'Components/Parts/Registration/AccountCreationDesktopTemplate';
import MobileModalPage from 'Components/Parts/Modals/Components/MobileModalPage';
import AccountCreationSuccess from './AccountCreationSuccess';
import { getLanguageOption } from 'Components/Parts/Registration/LanguageDropdown';
import getWebsiteLink from 'utilities/getWebsiteLink';


const AccountCreationRoutes = ({ values, errors, isValid, dirty, submitForm }) => (
    <Switch>
        <Route exact path="/signup" component={AccountTypeSelection}/>
        <Route path="/signup/info" render={routeProps => <AccountInformation {...routeProps} values={values} errors={errors} isValid={isValid} dirty={dirty}/>}/>
        <Route path="/signup/terms" render={routeProps => <AccountCreationPrivacyPolicy {...routeProps} submitForm={submitForm}/>}/>
        <Route path="/signup/success" render={routeProps => <AccountCreationSuccess {...routeProps} values={values}/>}/>
    </Switch>
);

function MobileFlow(props) {
    const history = useHistory();

    return (
        <MobileModal
            open={true}
            onClose={() => window.location.href = getWebsiteLink('/')}
        >
            <MobileModalPage>
                <Form>
                    <AccountCreationRoutes {...props} />
                </Form>
            </MobileModalPage>
        </MobileModal>
    );
}

function DesktopFlow(props) {
    return (
        <AccountCreationDesktopTemplate>
            <Form>
                <AccountCreationRoutes {...props} />
            </Form>
        </AccountCreationDesktopTemplate>
    );
}

function AccountCreationPage(props) {
    const theme = useTheme();
    const mdUp = useMediaQuery(theme.breakpoints.up('md'));
    
    return mdUp 
        ? <DesktopFlow {...props} />
        : <MobileFlow {...props} />;
}

export default function AccountCreationContainer() {
    const dispatch = useDispatch();
    const accountType = useSelector(state => state.accountCreation.accountType);
    const history = useHistory();
    const { t, i18n } = useTranslation();
    const match = useRouteMatch('/signup');

    if (!accountType && !match?.isExact) {
        history.push('/signup');
    }

    function handleSuccess() {
        history.push("/signup/success");
        dispatch(resetTermsPage);
    }

    function handleSubmit({email, password, firstName, lastName, credentials, language, allowsCommunication}) {
        dispatch(register(email, password, accountType, firstName, lastName, credentials, language.id, allowsCommunication, handleSuccess));
    }

    return (
        <AuthPageLayout>
            <Formik
            initialValues={{
                language: getLanguageOption(i18n.language),
                accountType: "",
                firstName: "",
                lastName: "",
                credentials: [],
                email: "",
                password: "",
                allowsCommunication: false,
                feedback: "",
            }}
            validationSchema={Yup.object({
                firstName: Yup.string()
                    .required(t('errorMessage.requiredField')),
                lastName: Yup.string()
                    .required(t('errorMessage.requiredField')),
                email: Yup.string()
                    .email(t('errorMessage.invalidEmail'))
                    .required(t('errorMessage.requiredField')),
                password: Yup.string()
                    .min(8, " ")
                    .matches(/[a-z]/, " ")
                    .matches(/[A-Z]/, " ")
                    .matches(/[^a-zA-Z]/, " ")
                    .required(" "),
            })}
            onSubmit={(values) => handleSubmit(values)}>
                {({values, errors, isValid, dirty, submitForm}) => 
                    <AccountCreationPage
                        values={values}
                        errors={errors}
                        isValid={isValid}
                        dirty={dirty}
                        submitForm={submitForm}
                    />
                }
            </Formik>
        </AuthPageLayout>
    );
}