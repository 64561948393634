import React from 'react';
import { useTranslation } from 'react-i18next';

import StudyEligibilityContainer from './StudyEligibilityContainer';
import { eligibilityBaseItem } from 'data/study/WizardFormSetup/wizardFormInitialValues';


export default function SelectEligibilityContainer(props) {
    const { options } = props;
    const { t } = useTranslation();

    function getProperties({ answer }) {
        if (!answer || !answer.id) {
            return { value: '___' };
        }

        const selectedOption = options.find(o => o.id === answer.id);

        return {
            value: selectedOption.getLabel
                ? selectedOption.getLabel(t).toLowerCase()
                : selectedOption.label.toLowerCase(),
        };
    }

    return (
        <StudyEligibilityContainer
            shareTranslationId
            getProperties={getProperties}
            emptyValue={eligibilityBaseItem}
            {...props}
        />
    );
}
