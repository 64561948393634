export const ENQUEUE_GLOBAL_USER_MESSAGE_CUSTOM = "ENQUEUE_GLOBAL_USER_MESSAGE_CUSTOM";
export const enqueueGlobalUserMessageState = (urgency, message, source) => ({
  type: ENQUEUE_GLOBAL_USER_MESSAGE_CUSTOM,
  payload: {
    urgency,
    message,
    source
  }
});

export const ENQUEUE_GLOBAL_USER_MESSAGE_UNKNOWN_ERROR = "ENQUEUE_GLOBAL_USER_MESSAGE_UNKNOWN_ERROR";
export const enqueueGlobalUserMessageUnknownError = source => ({
  type: ENQUEUE_GLOBAL_USER_MESSAGE_UNKNOWN_ERROR,
  payload: {
    source
  }
});

export const ENQUEUE_GLOBAL_USER_MESSAGE_LOGIN_EXPIRED = "ENQUEUE_GLOBAL_USER_MESSAGE_LOGIN_EXPIRED";
export const enqueueGlobalUserMessageLoginExpired = source => ({
  type: ENQUEUE_GLOBAL_USER_MESSAGE_LOGIN_EXPIRED,
  payload: {
    source
  }
});

export const ACK_GLOBAL_USER_MESSAGE = "ACK_GLOBAL_USER_MESSAGE";
export const ackGlobalUserMessageState = (key) => ({
  type: ACK_GLOBAL_USER_MESSAGE,
  payload: {
    key
  }
});