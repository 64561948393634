import produce from "immer";
import * as Actions from "./actions";

const initialState = {
    eligibilities: null
};

export default (state = initialState, action) =>
    produce(state, draft => {
        const {type, payload} = action;
        switch(type) {
            case Actions.SET_USER_ELIGIBILITY:
                draft.eligibilities = payload.eligibilities;
                break;
            case Actions.UPDATE_USER_ELIGIBILITY:
                draft.eligibilities[payload.study] = payload.eligibility;
                break;
            case Actions.UPDATE_USER_ELIGIBILITY_STATUS:
                if (draft.eligibilities && draft.eligibilities[payload.study]) {
                    draft.eligibilities[payload.study].status = payload.status;
                } else if (draft.eligibilities && !draft.eligibilities[payload.study]) {
                    draft.eligibilities[payload.study] = {
                        status: payload.status
                    };
                } else {
                    draft.eligibilities = {
                        [payload.study]: {
                            status: payload.status
                        }
                    };
                }
                break;
            case Actions.UPDATE_USER_INTEREST:
                if (draft.eligibilities && draft.eligibilities[payload.study]) {
                    draft.eligibilities[payload.study].interested = payload.interested;
                } else if (draft.eligibilities && !draft.eligibilities[payload.study]) {
                    draft.eligibilities[payload.study] = {
                        interested: payload.interested
                    };
                } else {
                    draft.eligibilities = {
                        [payload.study]: {
                            interested: payload.interested
                        }
                    };
                }
                break;
            default:
                return draft;
        }
    });