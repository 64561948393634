import React from 'react';
import { useHistory } from 'react-router';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import MobileModalHeader from 'Components/Parts/Modals/Components/MobileModalHeader';
import HaveAccountLink from 'Components/Parts/Registration/HaveAccountLink';
import AccountTypeDropdown from 'Components/Parts/Registration/AccountTypeDropdown';
import LanguageDropdown from 'Components/Parts/Registration/LanguageDropdown';


export default function AccountTypeSelection() {
    const history = useHistory();
    const { t } = useTranslation();

    const handleSelection = () => {
        history.push('/signup/info');
    };

    return (
        <React.Fragment>
            <Grid container>
                <Grid container item xs={12} spacing={2}>
                    <MobileModalHeader
                        title={t('accountTypeSelection.title')}
                        description={t('accountTypeSelection.description')}
                    />
                    <Grid item xs={12}>
                        <LanguageDropdown />
                    </Grid>
                    <Grid item xs={12}>
                        <AccountTypeDropdown onSelect={handleSelection} />
                    </Grid>
                </Grid>
            </Grid>
            <HaveAccountLink />
        </React.Fragment>
    );
}