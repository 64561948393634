import { cloneDeep } from 'lodash';

export function updateStudyValues(studyValues) {
    const formData = new FormData();
    const updatedValues = cloneDeep(studyValues);
    
    const image = updatedValues.studyInfo.image;
    const letter = updatedValues.ethicsApproval.letter;
    delete updatedValues.studyInfo.image;
    delete updatedValues.ethicsApproval.letter;
    delete updatedValues.location.typeSelected;

    updatedValues.studyInfo.category = studyValues.studyInfo.category.map(cat => getIdFromObject(cat));

    updatedValues.commitment.visits = getIdFromObject(updatedValues.commitment.visits);
    updatedValues.commitment.visitFrequency = getIdFromObject(updatedValues.commitment.visitFrequency);
    updatedValues.commitment.visitDuration = getLabelFromObject(updatedValues.commitment.visitDuration);
    updatedValues.commitment.visitRequirements = studyValues.commitment.visitRequirements.map(req => getIdFromObject(req));

    updatedValues.eligibility = getEligibilityCriteria(updatedValues.eligibility);

    if (updatedValues.studyTeam) {
        delete updatedValues.studyTeam.principalInvestigator.signupComplete;
        updatedValues.studyTeam.researchers.forEach(researcher => delete researcher.signupComplete);
        updatedValues.studyTeam.coinvestigators.forEach(coinvestigator => delete coinvestigator.signupComplete);
    }

    formData.append("information", 
        new Blob([JSON.stringify(updatedValues)], 
        {
            type: "application/json"
    }));
    formData.append("image", image);
    formData.append("letter", letter);

    return formData;
}

function getEligibilityCriteria(object) {
    if (!object) {
        return null;
    }

    return Object.keys(object).flatMap(val => {
        const criteria = object[val];
        if (criteria && criteria.answer === undefined && criteria.customText === undefined && typeof criteria === 'object') {
            return getEligibilityCriteria(criteria);
        }
        
        return criteria;
    })
    .filter(object => object && (!!object.answer || !!object.customText));
}

function getIdFromObject(object) {
    if (object) {
        return object.id;
    }
}

function getLabelFromObject(object) {
    if (object) {
        return object.label;
    }
}