import React, { useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import {
    Grid,
    Hidden,
    IconButton,
    InputBase,
    SvgIcon,
    TableCell,
    TableRow,
    Typography,
    useMediaQuery,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { formatDistanceStrict, parseISO, isToday } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import ParticipantActionCell from './ParticipantActionCell';
import {
    updateParticipantNote,
    openNoteModalForParticipant,
} from 'redux/reducers/myStudy/actions';
import { getDateLocale } from 'translations/i18n';
import { ReactComponent as CrossCircledIcon } from 'resources/images/icons/crossCircledOutline.svg';
import { ReactComponent as PencilOutlineIcon } from 'resources/images/icons/study/pencilWritingOutline.svg';
import Theme from 'Theme';


const StyledTableRow = styled(TableRow)`
    &.MuiTableRow-hover:hover {
        background-color: rgba(91, 128, 233, 0.18);
    }

    .MuiTableCell-body {
        border-bottom: none;
    }
`;

const ProfileContainer = styled.div`
    margin-left: 43px;

    @media (max-width: 960px) {
        margin-left: 16px;
    }
`;

const NameLabel = styled(Typography)`
    color: ${props => !props.archived
        ? '#212121'
        : 'rgba(0, 0, 0, 0.38)'};
    font-weight: 500;
    font-size: 12px;

    @media (min-width: 960px) {
        font-size: 14px;
    }
`;

const EmailLabel = styled(Typography)`
    color: #939393;
    font-family: Oxygen;
    font-size: 14px;

    @media (max-width: 960px) {
        font-size: 10px;
        max-width: 34vw;
        overflow-wrap: break-word;
    }
`;

const StyledDateLabel = styled(Typography)`
    color: ${props => !props.archived
        ? '#000'
        : 'rgba(0, 0, 0, 0.38)'};
    font-size: 14px;

    @media (max-width: 960px) {
        font-size: 12px;
        max-width: 15vw;
        overflow-wrap: break-word;
    }
`;

const NoteInput = styled(InputBase)`
    color: ${props => props.archived
        ? props.theme.palette.error.main
        : props.theme.palette.primary.main} !important;
    flex-grow: 1;
    font-size: 12px;

    @media (min-width: 960px) {
        font-size: 14px;
    }
`;

const StyledNoteButton = styled(IconButton)`
    margin-left: 31px;

    @media (max-width: 960px) {
        margin-left: 14px;
        padding: 0;
    }
`;

const StyledIcon = styled(SvgIcon)`
    margin-right: 26px;
    font-size: 16px;

    @media (max-width: 960px) {
        font-size: 14px;
        margin: 0;
        padding: 0;
    }
`;

const StyledDateStatus = styled.div`
    padding: 0;
    border-radius: 50%;
    flex-shrink: 0;
    height: 12px;
    width: 12px;
    ${props => props.isNew && css`
        background: #2fc4df;
    `};
    margin: 0 4px 0 2px;

    @media (min-width: 960px) {
        margin: 0 25px 0 5px;
    }
`;

const capitalize = (text = '') => {
    return text.charAt(0).toUpperCase() + text.slice(1);
};

const NoteButton = ({ active, onClick, archived }) => {
    const theme = useTheme();
    const mdUp = useMediaQuery(theme.breakpoints.up('md'));
    return (
        <StyledNoteButton onClick={onClick} disabled={mdUp || archived}>
            <StyledIcon
                component={!archived ? PencilOutlineIcon: CrossCircledIcon}
                htmlColor={!archived
                    ? ( active ? Theme.palette.primary.main : '#939393' )
                    : Theme.palette.error.main
                }
            />
        </StyledNoteButton>
    );
};

const DateLabel = ({ date, archived }) => {
    const { t, i18n } = useTranslation();
    const dateObj = parseISO(date);

    return (
        <StyledDateLabel archived={archived}>
            {isToday(dateObj)
                ? t('myStudyPage.todayDateText')
                : capitalize(formatDistanceStrict(dateObj, new Date(), { locale: getDateLocale(i18n.language) })) + ' ago'
            }
        </StyledDateLabel>
    )
};

export default function ParticipantRow({ participant, status, isNew }) {
    const {
        contactEmail,
        note,
        patientFullName,
        interestedDate,
        isArchived,
    } = participant;
    const { t } = useTranslation();
    const theme = useTheme();
    const mdUp = useMediaQuery(theme.breakpoints.up('md'));
    const dispatch = useDispatch();
    const noteInputRef = useRef(null);
    const [canEditNote, setCanEditNote] = useState(false);

    const handleAddNoteAction = () => {
        if (mdUp) {
            setCanEditNote(true);
            noteInputRef.current.focus();
        } else {
            openNoteModal();
        }
    };

    const blurIfEnter = event => {
        if (event.key === 'Enter') {
            event.target.blur();
        }
    };

    const saveNote = event => {
        setCanEditNote(false);
        dispatch(updateParticipantNote(participant, event.target.value));
    };

    const openNoteModal = () => {
        dispatch(openNoteModalForParticipant(participant));
    };

    return (
        <StyledTableRow hover={true}>
            <TableCell>
                <Grid container alignItems="center" wrap="nowrap">
                    <StyledDateStatus isNew={isNew} />
                    <DateLabel date={interestedDate} archived={isArchived ? 1 : undefined} />
                </Grid>
            </TableCell>
            <TableCell>
                <ProfileContainer>
                    <NameLabel archived={isArchived ? 1 : undefined}>{patientFullName}</NameLabel>
                    <EmailLabel>{contactEmail}</EmailLabel>
                </ProfileContainer>
            </TableCell>
            <TableCell>
                <Grid container alignItems="center">
                    <NoteButton active={!!note} onClick={openNoteModal} archived={isArchived} />
                    <Hidden smDown>
                        <NoteInput
                            defaultValue={!isArchived ? note : t('myStudyPage.archivedNoteText')}
                            disabled={!canEditNote || isArchived}
                            inputRef={noteInputRef}
                            onBlur={saveNote}
                            onKeyDown={blurIfEnter}
                            archived={isArchived ? 1 : undefined}
                        />
                    </Hidden>
                </Grid>
            </TableCell>
            <ParticipantActionCell
                handleAddNoteAction={handleAddNoteAction}
                hasNote={!!note}
                participant={participant}
                status={status}
            />
        </StyledTableRow>
    );
}