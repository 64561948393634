import React from 'react';
import { useTranslation } from 'react-i18next';
import RegistrationErrorLayout from 'Components/Layouts/Authorization/RegistrationErrorLayout';

export default function RegistrationErrorExpired() {
    const { t } = useTranslation();
    return (
        <RegistrationErrorLayout 
            title={t('signUpError.expiredTitle')}
            description={t('signUpError.expiredDescription')}
        />
    );
}