import saveDraftStudyRequest from 'api/study/postDraftStudy';
import submitDraftStudyRequest from 'api/study/submitDraftStudy';
import getDraftStudyRequest from 'api/study/getDraftStudy';
import researcherInfoRequest from 'api/fhir/getResearcherInfo';
import getResearchStudyRequest from 'api/fhir/getResearchStudy';
import { enqueueGlobalUserMessageUnknownError,
    enqueueGlobalUserMessageState,
    enqueueGlobalUserMessageLoginExpired } from '../globalUserMessaging';
import { updateStudyValues } from 'data/apiRequests/convertStudyToServerRequest';
import convertFhirStudyResponseToFormik from 'data/apiResponses/convertFhirStudyResponseToFormik';
import { setLoading, unsetLoading, setSaving, unsetSaving } from '../serverCommunication/actions';
import { eligibilityToggle, resetEligibilityTracking } from 'redux/reducers/eligibilityTracking/actions';

export const SET_LAST_SAVE_TIME = 'SET_LAST_SAVE_TIME';
export function setLastSaveTime(time) {
    return {
        type: SET_LAST_SAVE_TIME,
        payload: {
            time: time
        }
    };
}

export const SET_CURRENT_STEP = 'SET_CURRENT_STEP';
export function setCurrentStep(value) {
    return {
        type: SET_CURRENT_STEP,
        payload: {
            step: value
        }
    };
}

export const ADD_TO_ERROR_LIST = 'ADD_TO_ERROR_LIST';
export const addToErrorList = {
    type: ADD_TO_ERROR_LIST
};

export const ADD_TO_COMPLETE_LIST = 'ADD_TO_COMPLETE_LIST';
export const addToCompleteList = {
    type: ADD_TO_COMPLETE_LIST
};

export const LOADED_DRAFT = 'LOADED_DRAFT';
export const loadedDraft = {
    type: LOADED_DRAFT
};

export const SET_DRAFT_STUDY_ID = 'SET_DRAFT_STUDY_ID';
export function setDraftStudyId(id) {
    return {
        type: SET_DRAFT_STUDY_ID,
        payload: {
            studyId: id
        }
    };
} 

export const SET_DRAFT_FHIR_ID = 'SET_DRAFT_FHIR_ID';
export function setDraftFhirId(id) {
    return {
        type: SET_DRAFT_FHIR_ID,
        payload: {
            fhirId: id
        }
    };
} 

export const SET_DRAFT_STUDY_TEAM = 'SET_DRAFT_STUDY_TEAM';
export function setDraftStudyTeam(studyTeam) {
    return {
        type: SET_DRAFT_STUDY_TEAM,
        payload: {
            studyTeam: studyTeam
        }
    };
} 

export const CLEAR_DRAFT_STUDY_TEAM = 'CLEAR_DRAFT_STUDY_TEAM';
export const clearDraftStudyTeam = {
    type: CLEAR_DRAFT_STUDY_TEAM
};

export const SET_DRAFT_STUDY_ELIGIBILITY = 'SET_DRAFT_STUDY_ELIGIBILITY';
export function setDraftStudyEligibility(eligibility) {
    return {
        type: SET_DRAFT_STUDY_ELIGIBILITY,
        payload: {
            eligibility: eligibility
        }
    };
} 

export const CLEAR_DRAFT_STUDY_ELIGIBILITY = 'CLEAR_DRAFT_STUDY_ELIGIBILITY';
export const clearDraftStudyEligibility = {
    type: CLEAR_DRAFT_STUDY_ELIGIBILITY
};

export const RESET_STUDY_CREATION = 'RESET_STUDY_CREATION';
export const resetStudyCreation = {
    type: RESET_STUDY_CREATION
};

export function fetchDraftStudy() {
    return function(dispatch) {
        dispatch(setLoading);
        return getDraftStudyRequest()
            .then(function({data}) {
                dispatch(setDraftStudyTeam(data.studyTeam));
                dispatch(setDraftStudyEligibility(data.eligibilityCriteria));
                dispatch(setDraftStudyId(data.id));
                dispatch(setDraftFhirId(data.fhirId));
            }).catch(function({response}) {
                // TODO: determine error responses
            }).finally(function() {
                dispatch(unsetLoading);
            });
    };
}

export function fetchFhirStudyData(fhirId, studyTeam, studyEligibility, handleSuccess) {
    return function(dispatch) {
        dispatch(setLoading);
        return getResearchStudyRequest(fhirId)
            .then(function({data}) {
                handleSuccess(convertFhirStudyResponseToFormik(data, studyTeam, studyEligibility));
                dispatch(loadedDraft);
                studyEligibility.forEach(criteria => {
                    dispatch(eligibilityToggle(criteria.serverCoding))
                });
                dispatch(clearDraftStudyTeam);
                dispatch(clearDraftStudyEligibility);
            }).catch(function({response}) {
                // TODO: determine error responses
            }).finally(function() {
                dispatch(unsetLoading);
            });
    };
}

export function saveDraftStudy(study) {
    return function(dispatch) {
        dispatch(setSaving)
        if (study.studyInfo.title) {
            return saveDraftStudyRequest(updateStudyValues(study))
                .then(function() {
                    dispatch(setLastSaveTime(new Date(Date.now())));
                }).catch(function({response}) {
                    studySubmitErrors(dispatch, response);
                }).finally(function() {
                    dispatch(unsetSaving);
                });
        }
    };
}

export function submitDraftStudy(study, handleSuccess) {
    return function(dispatch) {
        dispatch(setSaving)
        return submitDraftStudyRequest(updateStudyValues(study))
        .then(function() {
            dispatch(enqueueGlobalUserMessageState(
                "success",
                "success.studySubmitted"
            ));
            dispatch(resetStudyCreation);
            dispatch(resetEligibilityTracking);
            handleSuccess();
        }).catch(function({response}) {
            studySubmitErrors(dispatch, response);
        }).finally(function() {
            dispatch(unsetSaving);
        });
    };
}

function studySubmitErrors(dispatch, response) {
    if (response) {
        if (response.status === 400 && response.data.errors) {
            dispatch(enqueueGlobalUserMessageState(
                "error",
                response.data.errors
            ));
        } else if (response.status === 401) {
            dispatch(enqueueGlobalUserMessageLoginExpired());
        } else {
            dispatch(enqueueGlobalUserMessageState(
                "error",
                "error.studyNotSaved"
            ));
        }
    }
    else {
        dispatch(enqueueGlobalUserMessageUnknownError());
    }
}

export function autosaveDraftStudy(study) {
    return function(dispatch, getState) {
        const { saving, loading } = getState().serverCommunication;

        if (!saving && !loading) {
            dispatch(setSaving);
            return saveDraftStudyRequest(updateStudyValues(study))
                .then(function() {
                    dispatch(setLastSaveTime(new Date(Date.now())));
                }).catch(function() {
                    // don't do anything here, just fail quietly
                }).finally(function() {
                    dispatch(unsetSaving);
                });
        }
    };
}

export function autocompleteResearcher(searchTerm, handleSuccess) {
    return function() {
        return researcherInfoRequest(searchTerm)
            .then(function({data}) {
                handleSuccess(data);
            }).catch(function() {
                // do nothing for now
            });
    };
}