import { set } from 'lodash';
import { updateServerCriteria, isCustomEligibility } from './eligibilityHelpers';

const eligibilityMap = Object.freeze({
    "BIRTHDATE": "demographics.age",
    "ETHNICITY": "demographics.ethnicity",
    "BIRTHSEX": "demographics.sex",
    "TWIN": "demographics.genetics",
    "DIAGNOSIS": "condition.diagnosis",
    "BRITTLE": "condition.brittle",
    "EXERCISE": "lifestyle.activity",
    "MONITOR": "monitoring.monitor",
    "DEVICES": "insulin.device",
    "INSULIN_DURATION": "insulin.duration",
    "HYBRID_SYSTEM": "advanced.hybrid",
    "MEDICATION": "medication.diabetes",
    "IMMUNOSUPPRESSANT": "medication.immunosuppressant",
    "A1C_VALUE": "a1c.value",
    "A1C_TIME": "a1c.timeframe",
    "KETO": "ketoacidosis.ketoepisode",
    "KETO_HOSPITAL": "ketoacidosis.assistance",
    "HYPOGLYCEMIA": "hypoglycemia.hypoepisode",
    "HYPOGLYCEMIA_HOSPITAL": "hypoglycemia.assistance",
    "PREGNANCY": "pregnancy.pregnant",
    "BREASTFEEDING": "pregnancy.breastfeeding",
    "TRY_PREGNANCY": "pregnancy.attempting",
    "SECONDARY_NEPHRO": "secondary.nephropathy", 
    "SECONDARY_NEURO": "secondary.neuropathy", 
    "SECONDARY_RETINO": "secondary.retinopathy",
    "CONDITIONS_CELIAC": "conditions.celiac", 
    "CONDITIONS_FOOTULCER": "conditions.footUlcers", 
    "CONDITIONS_GASTRO": "conditions.gastroporesis", 
    "CONDITIONS_HEART": "conditions.heartDisease",
    "CONDITIONS_HEPATITIS": "conditions.hepatitis", 
    "CONDITIONS_HYPERT": "conditions.hypertension", 
    "CONDITIONS_HYPOT": "conditions.hypotension", 
    "CONDITIONS_LIVER": "conditions.liver", 
    "CONDITIONS_PSYCH": "conditions.psychiatric",
});

export default function convertEligibilityCriteriaResponseToFormik(updatedValues, serverEligibility) {
    serverEligibility.forEach(criteria => {
        const newCriteria = updateServerCriteria(criteria);

        const formPath = isCustomEligibility(newCriteria.serverCoding)
            ? `customCategory.categories.${newCriteria.serverCoding.split('CUSTOM_').pop()}`
            : eligibilityMap[newCriteria.serverCoding];

        set(updatedValues, `eligibility.${formPath}`, newCriteria);
    });

    return updatedValues;
}