import React from 'react';
import { Typography, SvgIcon, } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { ReactComponent as TriangleWarningIcon } from 'resources/images/icons/alertTriangleOutline.svg';
import AccountCardLayout from 'Components/Layouts/Authorization/AccountCardLayout';
import { continueRegistration } from 'redux/reducers/accountCreation/actions';


function LegalPage({ handleDecline }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    function handleContinue() {
        dispatch(continueRegistration);
    }
    
    return (
        <AccountCardLayout
            icon={<SvgIcon component={TriangleWarningIcon} color="primary" />}
            title={t('signUpTermsLegalAlert.title')}
            secondaryButtonText={t('signUpTermsLegalAlert.declineButtonText')}
            onSecondaryClick={handleDecline}
            primaryButtonText={t('signUpTermsLegalAlert.continueButtonText')}
            onPrimaryClick={handleContinue}
        >
            <Typography variant="body2" color="textSecondary">{t('signUpTermsLegalAlert.description')}</Typography>
        </AccountCardLayout>
    );
}

export default LegalPage;
