import React from 'react';
import { useTranslation } from 'react-i18next';
import StudyEligibilityContainer from './StudyEligibilityContainer';
import { multipleSelectOptions } from 'data/study/Eligibility/eligibilitySelectionOptions';
import { eligibilityMultiSelectItem } from 'data/study/WizardFormSetup/wizardFormInitialValues';


export default function MultiSelectEligibilityContainer({name, disabled, answerOptions, selectOptions, criteria, processValue, processValues, getProperties}) {
    const { t } = useTranslation();

    function getPropertiesDefault(fieldValue) {
        const { answer, singleOption, options=[] } = fieldValue;
        const properties = { value: '___' };

        if ((answer.id === "single" && singleOption) || options.length === 1) {
            const option = answer.id === "single" ? singleOption : options[0];
            const { label, getLabel } = option;
            const labelText = getLabel ? getLabel(t) : label;
            properties.value = processValue ? processValue(labelText) : labelText.toLowerCase();

        } else if (options.length > 1) {
            properties.value = processValues
                ? processValues(options)
                : options.map(option => option.getLabel(t).toLowerCase()).join(', ')
        }

        return properties;
    }

    return (
        <StudyEligibilityContainer 
            criteria={criteria}
            name={name}
            disabled={disabled}
            options={answerOptions} 
            components={multipleSelectOptions(name, selectOptions, t)}
            emptyValue={eligibilityMultiSelectItem}
            getProperties={getProperties ? getProperties : getPropertiesDefault}
        />
    );
}