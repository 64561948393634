export function convertCredentialsToServerRequest(credentials) {
    if (!credentials) {
        return null;
    }

    return credentials
        .reduce((obj, item) => {
            obj[item.id] = item.label;
            return obj;
        }, {});
}