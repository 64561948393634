import React from 'react';
import { Field } from 'formik';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { TextBox, MultilineTextBox, NumericTextBox } from 'Components/Parts/Form/TextBoxes';
import ImageDropzone from 'Components/Parts/Form/Dropzones/ImageDropzone';
import { chipValues } from 'data/study/studyCategoryValues';
import MultipleDropdown from 'Components/Parts/Form/Dropdowns/MultipleDropdown';

export default function StudyInformation() {
    const { t } = useTranslation();

    return (
        <Grid container spacing={4}>
            <Grid item xs={12}>
                <Field
                    component={TextBox} 
                    name="studyInfo.title" 
                    type="text"
                    label={t('studySetupInfoPage.titleLabel')}
                    helperText={t('studySetupInfoPage.titlePlaceholder')}
                    maxLength={50}
                    variant="filled"
                />
            </Grid>
            <Grid item xs={12}>
                <Field
                    component={MultilineTextBox} 
                    name="studyInfo.summary" 
                    type="text"
                    label={t('studySetupInfoPage.summaryLabel')}
                    maxLength={500}
                    variant="filled"
                />
            </Grid>
            <Grid item container spacing={6}>
                <Grid item xs={4}>
                    <Field
                        component={NumericTextBox} 
                        name="studyInfo.recruitment" 
                        type="text"
                        label={t('studySetupInfoPage.recruitmentLabel')}
                        helperText={t('studySetupInfoPage.recruitmentGoalPlaceholder')}
                        variant="filled"
                    />
                </Grid>
                <Grid item xs={8}>
                    <MultipleDropdown
                        name="studyInfo.category"
                        label={t('studySetupInfoPage.categoryLabel')}
                        dropdownTitle={t('studySetupInfoPage.categoryLabel')}
                        values={chipValues}
                        helperText={t('studySetupInfoPage.categoryDescription')}
                        variant="filled"
                        maximum={3}
                    />
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Field
                    component={ImageDropzone}
                    title={t("studySetupInfoPage.imageTitle")}
                    description={t("studySetupInfoPage.imageDescription")}
                    explanation={t("studySetupInfoPage.imageExplanation")}
                    name="studyInfo.image"
                    maxFileSize={8388608}
                />
            </Grid>
        </Grid>
    );
}