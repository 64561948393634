import produce from "immer";
import * as Actions from "./actions";

const initialState = {
    serverVersion: null
};

export default (state = initialState, action) =>
    produce(state, draft => {
        const {type, payload} = action;
        switch(type) {
            case Actions.UPDATE_VERSION:
                draft.serverVersion = payload.version;
                break;
            default:
                return draft;
        }
    });