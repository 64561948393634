import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { StyledMenuItem, 
    SelectedMenuItem,
    ItemDivider } from 'Components/Styles/Navigation/Sidebar/StyledMenuItem';
import MenuItem from './MenuItem';

export default function MenuItems({ items }) {
    const { t } = useTranslation();
    const currentStep = useSelector(state => state.studyCreation.currentStep);

    return (
        <React.Fragment>
            {items.map(({ id, getTitle }) => {
                return (
                    <React.Fragment key={id}>
                        {id === currentStep 
                        ?   <SelectedMenuItem>
                                <MenuItem title={getTitle(t)} id={id}/>
                            </SelectedMenuItem>
                        :   <StyledMenuItem to={"/setup/" + id}>
                                <MenuItem title={getTitle(t)} id={id}/>
                            </StyledMenuItem>
                        }
                        <ItemDivider/>
                    </React.Fragment>
                );
            })}
        </React.Fragment>
    );
}