export function convertAutocompleteToServerRequest(searchText) {
    if (!searchText) {
        return null;
    }

    const names = searchText.split(" ");

    if (names.length === 1) {
        return `&name:contains=${names[0]}`;
    } else {
        return `&given:contains=${names[0]}&family:contains=${names[1]}`;
    }
}