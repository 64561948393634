import React from 'react';
import { Typography } from '@material-ui/core';
import RadioSelection from 'Components/Parts/Form/RadioSelection';
import SingleDropdown from 'Components/Parts/Form/Dropdowns/SingleDropdown';
import { useTranslation } from 'react-i18next';
import MultipleDropdown from 'Components/Parts/Form/Dropdowns/MultipleDropdown';
import SelectorGroup from 'Components/Parts/Form/Groups/SelectorGroup';
import { useField } from 'formik';
import * as Selectors from 'data/study/Eligibility/eligibilitySelectionOptions';

const SELECT_BREAKPOINT_SINGLE = 5;
const SELECT_BREAKPOINT_MULTI = 6;

export default function QuestionChoice({name, clientSelection, repeats, prefix}) {
    const { t } = useTranslation();
    const valueSet = Selectors[clientSelection];
    if (!valueSet || valueSet.length === 0) {
        return null;
    }
    
    let component = null;
    if (!repeats) {
        component = <SingleChoice name={name} valueSet={valueSet} t={t}/>
    } else {
        component = <MultiChoice name={name} valueSet={valueSet} t={t}/>;
    }

    return (
        <React.Fragment>
            {component}
            {prefix && <Typography variant="body2" color="textSecondary">{t(prefix)}</Typography>}
        </React.Fragment>
    );
}

function SingleChoice({name, valueSet, t}) {
    const [field, ,helpers] = useField(name);
    React.useEffect(() => {
        if (valueSet.length >= SELECT_BREAKPOINT_SINGLE && field.value && !field.value.id) {
            const newValue = valueSet.find(option => option.id === field.value);
            helpers.setValue(newValue);
        }
    }, [name]);

    if (valueSet.length < SELECT_BREAKPOINT_SINGLE) {
        return <RadioSelection name={name} values={valueSet}/>;
    } else {
        if (field.value && !field.value.id) {
            return null;
        }
        return <SingleDropdown name={name} values={valueSet} variant="standard" defaultText={t("questionnaire.selectOption")}/>;
    }
} 

function MultiChoice({name, valueSet, t}) {
    const [field, ,helpers] = useField(name);
    React.useEffect(() => {
        if (valueSet.length >= SELECT_BREAKPOINT_MULTI && field.value && !field.value.some(val => val.id)) {
            const options = field.value.map(val => valueSet.find(option => option.id === val));
            helpers.setValue(options);
        }
    }, [name]);

    if (valueSet.length < SELECT_BREAKPOINT_MULTI) {
        return (
            <React.Fragment>
                <Typography variant="body2">{t("questionnaire.selectOptionMulti")}:</Typography>
                <SelectorGroup name={name} items={valueSet} formikValues={field.value}/>
            </React.Fragment>
        );
    } else {
        return <MultipleDropdown name={name} values={valueSet} variant="standard" label={t("questionnaire.selectOptionMulti")}/>;
    }
}