import React from 'react';
import styled from 'styled-components';
import { Grid, Typography } from '@material-ui/core';

const RestrictedSizeString = styled.div`
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-all;
    hyphens: auto;
`;

function CardDetailRow({className, name, value, stackXs}) {
    return (
        <Grid container item className={className}>
            <Grid item xs={stackXs ? 12 : 4}>
                <Typography variant="h6">{name}</Typography>
            </Grid>
            <Grid item xs={stackXs ? 12 : 8}>
                <RestrictedSizeString>
                    {value}
                </RestrictedSizeString>
            </Grid>
        </Grid>
    );
}

CardDetailRow.defaultProps = {
    stackXs: false,
};

export default CardDetailRow;
