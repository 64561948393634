import React from 'react';
import {
    Link as LinkExternal,
    Typography,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import ListBaseRow from './ListBaseRow';


const StyledArrowIcon = styled(ArrowForwardIosIcon)`
    font-size: 18px;
    margin-left: 18px;
`;

export default function ListLinkRow({ to, icon, text, detailText, actionText, onClick, showArrow, href, ...props }) {
    return (
        <ListBaseRow
            icon={icon}
            text={text}
            detailText={detailText}
            color="primary"
            actionComponent={
                <React.Fragment>
                    {actionText && <Typography display="inline">{actionText}</Typography>}
                    {(to || showArrow || href) &&
                        <StyledArrowIcon color="primary" />
                    }
                </React.Fragment>
            }
            component={!!to 
                ? Link 
                : !!href
                    ? LinkExternal
                    : undefined}
            to={to}
            onClick={onClick}
            button
            href={href}
            {...props}
        />
    );
}
