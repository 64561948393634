import { initialValues } from 'data/study/WizardFormSetup/wizardFormInitialValues';
import * as ExtensionHelpers from 'data/apiResponses/fhir/extensionHelpers';
import * as StudyHelpers from 'data/apiResponses/fhir/studyHelpers';
import * as Constants from 'Constants/constants';
import * as Commitment from 'data/study/studyCommitmentSelection';
import convertEligibilityCriteriaResponseToFormik from './convertEligibilityCriteriaResponseToFormik';

export default function convertFhirStudyResponseToFormik(fhirData, studyTeam, studyEligibility) {
    const updatedValues = Object.assign({}, initialValues);
    const fhirStudy = fhirData.entry.find(entry => entry.resource.resourceType === "ResearchStudy").resource;
    const fhirLocations = fhirData.entry.filter(entry => entry.resource.resourceType === "Location");
    const fhirSponsor = fhirData.entry.find(entry => entry.resource.resourceType === "Organization");
    const fhirLetter = fhirData.entry.find(entry => entry.resource.resourceType === "DocumentReference");

    updatedValues.studyInfo.title = fhirStudy.title || "";
    updatedValues.studyInfo.summary = fhirStudy.description || "";
    updatedValues.studyInfo.category = StudyHelpers.getCategories(fhirStudy);
    updatedValues.studyInfo.recruitment = ExtensionHelpers.conditionallyReturnExtension(fhirStudy, Constants.STUDY_RECRUITMENT_URI) || "";

    updatedValues.ethicsApproval.funder = StudyHelpers.getOrganizationName(fhirSponsor);
    updatedValues.ethicsApproval.letter = StudyHelpers.getArtifactAsPdf(fhirLetter);

    [updatedValues.timelines.recruitStart, updatedValues.timelines.recruitEnd] = StudyHelpers.getStudyPeriod(fhirStudy);
    updatedValues.timelines.studyEnd = ExtensionHelpers.conditionallyReturnDateFromExtension(fhirStudy, Constants.STUDY_END_DATE_URI);
    updatedValues.timelines.resultsAvailable = ExtensionHelpers.conditionallyReturnDateFromExtension(fhirStudy, Constants.STUDY_RESULTS_DATE_URI);

    if (fhirLocations) {
        updatedValues.location.online = StudyHelpers.isStudyOnline(fhirLocations);
        updatedValues.location.homeVisit = StudyHelpers.isStudyInHome(fhirLocations);
        [updatedValues.location.inClinic, updatedValues.location.clinics] = StudyHelpers.getLocations(fhirLocations);

        if (updatedValues.location.online || updatedValues.location.homeVisit || updatedValues.location.inClinic) {
            updatedValues.location.typeSelected = true;
        }
    }

    updatedValues.commitment.visits = ExtensionHelpers.conditionallyReturnObjectFromExtensionById(fhirStudy, Constants.STUDY_COMMITMENT_VISITS_URI, Commitment.visitValues) || initialValues.commitment.visits;
    updatedValues.commitment.visitDuration = ExtensionHelpers.conditionallyReturnObjectFromExtensionByLabel(fhirStudy, Constants.STUDY_COMMITMENT_DURATION_URI, Commitment.sliderValues) || initialValues.commitment.visitDuration;
    updatedValues.commitment.visitFrequency = ExtensionHelpers.conditionallyReturnObjectFromExtensionById(fhirStudy, Constants.STUDY_COMMITMENT_FREQUENCY_URI, Commitment.frequencyValues) || initialValues.commitment.visitFrequency;
    updatedValues.commitment.visitRequirements = ExtensionHelpers.conditionallyReturnMultipleObjectsFromExtensions(fhirStudy, Constants.STUDY_COMMITMENT_OPTIONS_URI, Commitment.requirementsValues) || initialValues.commitment.visitRequirements;

    updatedValues.personalData = ExtensionHelpers.conditionallyReturnMultipleExtension(fhirStudy, Constants.STUDY_PERSONALDATA_URI) || [];

    updatedValues.compensation.types = ExtensionHelpers.conditionallyReturnMultipleExtension(fhirStudy, Constants.STUDY_COMPENSATION_URI) || [];
    updatedValues.compensation.typeOtherDescription = ExtensionHelpers.conditionallyReturnExtension(fhirStudy, Constants.STUDY_COMPENSATION_OTHER_URI) || "";
    updatedValues.compensation.typeOther = !!updatedValues.compensation.typeOtherDescription;

    if (studyEligibility) {
        convertEligibilityCriteriaResponseToFormik(updatedValues, studyEligibility);
    }

    if (studyTeam) {
        updatedValues.studyTeam.principalInvestigator = StudyHelpers.createTeamMember(studyTeam.principalInvestigator) || initialValues.studyTeam.principalInvestigator;
        updatedValues.studyTeam.researchers = studyTeam.researchAssistants && studyTeam.researchAssistants.length > 0 ? StudyHelpers.createTeamMemberList(studyTeam.researchAssistants) : initialValues.studyTeam.researchers;
        updatedValues.studyTeam.coinvestigators = studyTeam.coinvestigators && studyTeam.coinvestigators.length > 0 ? StudyHelpers.createTeamMemberList(studyTeam.coinvestigators) : initialValues.studyTeam.coinvestigators;
    }

    return updatedValues;
}