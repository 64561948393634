import React from 'react';
import styled from 'styled-components';
import { Field, useField } from 'formik';
import { Grid, SvgIcon } from '@material-ui/core';
import { KeyboardDatePicker } from 'formik-material-ui-pickers';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CalendarIcon } from 'resources/icons/calendarOutlineIcon.svg';
import PickerProvider from 'Components/Parts/Form/PickerProvider';

const StyledKeyboardDatePicker = styled(KeyboardDatePicker)`
    .MuiOutlinedInput-root {
        padding: 0;
        margin: 6px 0;
        border-radius: 6px;

        &:hover fieldset {
            border-color: ${props => props.theme.palette.primary.light};
        }
    }

    .MuiOutlinedInput-input {
        padding: 12px;
    }

    .MuiOutlinedInput-notchedOutline {
        border: solid 2px #e6e6e6;
        border-radius: 6px;
    }

    .MuiIconButton-root {
        z-index: 5;
        height: 100%;
        padding: 25px 9px;
        color: #fff;
        background-color: ${props => props.theme.palette.primary.main};
        border-radius: 0 6px 6px 0;

        &:hover {
            background-color: ${props => props.theme.palette.primary.light};
        }
    }
`;

export default function Timelines() {
    const { t } = useTranslation();
    const [field] = useField("timelines");

    return (
        <PickerProvider>
            <Grid container spacing={2}>
                <Grid item container spacing={2} xs={12}>
                    <Grid item xs={6}>
                        <Field
                            component={StyledKeyboardDatePicker}
                            variant="inline"
                            inputVariant="outlined"
                            name="timelines.recruitStart"
                            label={t('studySetupTimeline.recruitmentStartPlaceholder')}
                            format="dd/MM/yyyy"
                            keyboardIcon={<SvgIcon component={CalendarIcon}/>}
                            fullWidth
                            autoOk
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Field
                            component={StyledKeyboardDatePicker}
                            variant="inline"
                            inputVariant="outlined"
                            name="timelines.recruitEnd"
                            label={t('studySetupTimeline.recruitmentEndPlaceholder')}
                            minDate={field.value.recruitStart}
                            format="dd/MM/yyyy"
                            keyboardIcon={<SvgIcon component={CalendarIcon}/>}
                            fullWidth
                            autoOk
                        />
                    </Grid>
                </Grid>
                <Grid container item xs={12} spacing={2}>
                    <Grid item xs={6}>
                        <Field
                            component={StyledKeyboardDatePicker}
                            variant="inline"
                            inputVariant="outlined"
                            name="timelines.studyEnd"
                            label={t('studySetupTimeline.endDatePlaceholder')}
                            minDate={field.value.recruitEnd}
                            format="dd/MM/yyyy"
                            keyboardIcon={<SvgIcon component={CalendarIcon}/>}
                            fullWidth
                            autoOk
                        />
                    </Grid>
                </Grid>
                <Grid container item xs={12} spacing={2}>
                    <Grid item xs={6}>
                        <Field
                            component={StyledKeyboardDatePicker}
                            views={["year", "month"]}
                            openTo={"year"}
                            variant="inline"
                            inputVariant="outlined"
                            name="timelines.resultsAvailable"
                            label={t('studySetupTimeline.resultsAvailablePlaceholder')}
                            minDate={field.value.studyEnd}
                            keyboardIcon={<SvgIcon component={CalendarIcon}/>}
                            fullWidth
                            autoOk
                        />
                    </Grid>
                </Grid>
            </Grid>
        </PickerProvider>
    );
}