import React from 'react';
import styled from 'styled-components';
import {
    ListItemIcon,
    ListItemText,
    MenuList,
    MenuItem,
    SvgIcon,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import { useHistory, useParams } from 'react-router-dom';

import { ReactComponent as EyeOutlineIcon } from 'resources/images/icons/eyeOutline.svg';
import downloadLink from 'utilities/downloadLink';
import getStudyParticipantCsvLink from 'api/study/getStudyParticipantCsvLink';


const StyledListItemIcon = styled(ListItemIcon)`
    min-width: 24px;
    padding-right: 16px;
`;


export default function MyStudyMobileMenu({ handleMenuClose }) {
    const { t } = useTranslation();
    const history = useHistory();
    const { studyId } = useParams();

    const goToStudyDetails = () => {
        history.push(`/study/${studyId}`);
        handleMenuClose();
    };

    const downloadParticipantList = async () => {
        const link = await getStudyParticipantCsvLink(studyId);
        if (link) {
            downloadLink(link, 'participants.csv');
        }
        
        handleMenuClose();
    };

    return (
        <MenuList disablePadding>
            <MenuItem onClick={downloadParticipantList}>
                <StyledListItemIcon>
                    <GetAppOutlinedIcon color="primary" />
                </StyledListItemIcon>
                <ListItemText primary={t('myStudyPage.downloadParticipantCsvButtonText')} />
            </MenuItem>
            <MenuItem onClick={goToStudyDetails}>
                <StyledListItemIcon>
                    <SvgIcon color="primary" component={EyeOutlineIcon} viewBox="0 0 8 8" />
                </StyledListItemIcon>
                <ListItemText primary={t('myStudyPage.viewStudyButtonText')} />
            </MenuItem>
        </MenuList>
    )
}