import produce from "immer";
import { SET_LOGIN_USERNAME } from "./actions";

const initialState = {
    username: ""
};

export default (state = initialState, action) =>
    produce(state, draft => {
        const {type, payload} = action;
        switch(type) {
            case SET_LOGIN_USERNAME:
                draft.username = payload.username;
                break;
            default:
                return draft;
        }
    });