import styled from 'styled-components';
import { Chip } from '@material-ui/core';

export const TabList = styled.ol`
    display: table;
    width: 100%;
    align-items: baseline;
    padding-left: 0;
`;

export const TabListItem = styled.li`
    display: inline-block;
    list-style: none;
    padding-left: 0;
    padding-right: 1.5em;
    cursor: pointer;
`;

export const TabSelectedItem = styled(Chip)`
    && {
        font-weight: bold;
        font-size: 1rem;
        padding: 0.5em;
    }
`; 