import putUserProfile from 'api/users/putUserProfile';
import updateUserQualificationsRequest from 'api/users/updateUserQualifications';
import getPractitioner from 'api/fhir/getPractitioner';
import { enqueueGlobalUserMessageUnknownError } from 'redux/reducers/globalUserMessaging/index';
import { changedCommunication, changedLanguage, gotUserFhirProfile, updateProfileQualifications } from '../authorization/actions';
import getPatient from 'api/fhir/getPatient';
import { UserType } from 'Constants/constants';
import { setSaving, unsetSaving } from '../serverCommunication/actions';


export function changeLanguage(language) {
    return function(dispatch, getState) {
        const userId = getState().authorization.userId;
        const profileRequest = {
            preferredLanguage: language,
        };

        putUserProfile(userId, profileRequest)
            .then(_ => dispatch(changedLanguage(language)))
            .catch(_ => dispatch(enqueueGlobalUserMessageUnknownError()));
    }
}

export function toggleCommunicationSetting() {
    return function(dispatch, getState) {
        const userId = getState().authorization.userId;
        const communication = !getState().authorization.profile.allowsCommunication;

        const profileRequest = {
            allowsCommunication: communication
        }

        putUserProfile(userId, profileRequest)
            .then(_ => dispatch(changedCommunication(communication)))
            .catch(_ => dispatch(enqueueGlobalUserMessageUnknownError()));
    }
}

export function getUserFhirProfile(force=false) {
    return function(dispatch, getState) {
        const state = getState();
        const accountType = state.authorization.accountType;
        const fhirId = state.authorization.fhirId;
        const syncRequired = state.authorization.fhirProfile === undefined;

        if (!force && !syncRequired) {
            return;
        }

        if (accountType === UserType.PATIENT) {
            getPatient(fhirId, force)
                .then(({data}) => dispatch(gotUserFhirProfile(data)))
                .catch(_ => dispatch(enqueueGlobalUserMessageUnknownError("dashboard")));
        } else {
            getPractitioner(fhirId, force)
                .then(({data}) => dispatch(gotUserFhirProfile(data)))
                .catch(_ => dispatch(enqueueGlobalUserMessageUnknownError("dashboard")));
        }
    }
}

export function updateUserQualifications(userId, qualifications) {
    return function(dispatch) {
        dispatch(setSaving);
        updateUserQualificationsRequest(userId, qualifications)
            .then(_ => dispatch(updateProfileQualifications(qualifications)))
            .finally(_ => {
                dispatch(unsetSaving);
            });
    }
}
