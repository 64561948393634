import React from 'react';
import { Field } from 'formik';
import { MenuItem, ListItemText } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Dropdown from './Dropdown';

export default function SingleDropdown({name, variant="outlined", defaultText, values, fullWidth=true, handleChange, disabled, error}) {
    const { t } = useTranslation();

    const getLabelFromValue = ({ label, getLabel, id }) => (
        getLabel
            ? getLabel(t)
            : label ?? defaultText
    );

    return (
        <Field name={name}>
            {({field, form}) =>
                <Dropdown
                    variant={variant}
                    field={field}
                    form={form}
                    disabled={disabled}
                    renderValue={getLabelFromValue}
                    handleChange={handleChange}
                    error={error}
                    fullWidth={fullWidth}
                    defaultText={defaultText}
                >
                    <MenuItem value="" disabled>{defaultText}</MenuItem>
                    {values.map((value) => {
                        const {id, label, subtitle, getLabel, getSubtitle} = value;
                        const labelText = getLabel ? getLabel(t) : label;
                        const subtitleText = getSubtitle ? `(${getSubtitle(t)})` : subtitle;
                        return <MenuItem key={id} value={value}>
                            <ListItemText primary={labelText ? labelText : id} secondary={subtitleText} secondaryTypographyProps={{style: {fontStyle: 'italic'}}}/>
                        </MenuItem>;
                    })}
                </Dropdown>
            }
        </Field>
    );
}