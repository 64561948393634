import React from 'react';
import {
    Grid,
    Paper,
    Link,
} from '@material-ui/core';
import styled from 'styled-components';
import getLogoSource from '../Logo/LogoSource';
import getWebsiteLink from 'utilities/getWebsiteLink';


const LogoWrapper = styled.div`
    position: relative;
    max-width: 351px;
    width: 100%;
`;

const Logo = styled.img`
    width: 100%;
`;

const Card = styled(Paper)`
    border-radius: 16px;
    padding: 64px 66px 28px;
    width: 100%;
`;

const RippleImage = styled.img`
    left: 17%;
    position absolute;
    top: calc(50% - 10px);
    transform: translate(-50%, -50%);
    z-index: -1;
`;

function AccountCreationDesktopTemplate({ children }) {
    return (
        <Grid container alignItems="center">
            <Grid item md={4} lg={5} style={{position: 'relative'}}>
                <LogoWrapper>
                    <RippleImage src="/images/authorization/ripple.svg" />
                    <Link href={getWebsiteLink('/')}>
                        <Logo src={getLogoSource()} alt="connect1d logo" />
                    </Link>
                </LogoWrapper>
            </Grid>
            <Grid item md={1}/>
            <Grid container item md={7} lg={6}>
                <Card>
                    {children}
                </Card>
            </Grid>
        </Grid>
    );
}

export default AccountCreationDesktopTemplate;