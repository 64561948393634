import React from 'react';
import StudyDisplay from 'Components/Layouts/Dashboard/StudyDisplay';
import { StudyStatus } from 'Constants/constants';


export default function () {
    return (
        <React.Fragment>
            <StudyDisplay
                status={StudyStatus.ACTIVE}
                isPublic
            />
        </React.Fragment>
    );
}
