import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ReactComponent as QuestionOutlineIcon } from 'resources/icons/questionOutlineIcon.svg';
import {
    BarText,
    BarButton,
    EligibilityBarTemplate,
} from './EligibilityBarTemplate';


export default function EligibilityQuestionnaireBar({studyId}) {
    const { t } = useTranslation();
    const history = useHistory();
    return (
        <EligibilityBarTemplate color="primary">
            <SvgIcon component={QuestionOutlineIcon} style={{ fontSize: "2rem" }} viewBox="0 0 32 33" />
            <BarText>{t('eligibilityBar.eligibilityQuestionnaireText')}</BarText>
            <BarButton onClick={() => history.push(`/questionnaire/${studyId}`)}>{t('eligibilityBar.eligibilityQuestionnaireButtonText')}</BarButton>
        </EligibilityBarTemplate>
    );
}
