import { Button } from '@material-ui/core';
import styled, { css } from 'styled-components';

export const RoundedButton = styled(Button)`
    border-radius: 25px;
    border: 2px solid transparent;
    font-size: 1.25rem;
    font-weight: ${props => props.weight ? props.weight : "bold"};
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    text-transform: none;

    ${props => props.size === "small" && css`
        font-size: 1rem;
    `}

    ${props => (props.color === "default" && props.variant === "contained") && css`
        background: #fff;
        color: ${props.theme.palette.primary.main};
    `}

    ${props => props.variant === "outlined" && css`
        border: 2px solid;
        ${props.color === "default" ? 'color: #fff;' : ''}

        &:hover {
            border: 2px solid;
        }
    `}

    ${props => props.color === 'error' && css`
        background: ${props => props.theme.palette.error.main};
        color: #fff;

        svg {
            color: #fff;
        }

        &:hover {
            background: ${props => props.theme.palette.error.main};
        }
    `}
`;
