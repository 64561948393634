import React from 'react';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import LocationAutocompleteBox from 'Components/Parts/Form/Autocompletes/Google/LocationAutocompleteBox';
import ExpandableSelection from 'Components/Parts/Form/ExpandableSelection';
import { WizardCheckboxFieldRow } from 'Components/Layouts/Setup/WizardDataRow/WizardDataRow';
import {MinorErrorMessage} from 'Components/Styles/Form/ErrorMessage';

const defaultValue = {
    address: "",
    lat: "",
    lon: ""
};

const SeparatedErrorMessage = styled.div`
    padding-top: 1em;
`;

export default function Location() {
    const { t } = useTranslation();
    return (
        <React.Fragment>
            <Grid container spacing={2}>
                <WizardCheckboxFieldRow name="location.inClinic" title={t('studySetupLocation.clinicLabel')} description={t('studySetupLocation.clinicDescription')} >
                    <ExpandableSelection 
                        name="location.clinics" 
                        title={t('studySetupLocation.locationLabel')} 
                        defaultValue={_ => defaultValue} 
                        component={LocationAutocompleteBox}
                    />
                </WizardCheckboxFieldRow>
                <WizardCheckboxFieldRow name="location.online" title={t('studySetupLocation.onlineLabel')} description={t('studySetupLocation.onlineDescription')} />
                <WizardCheckboxFieldRow name="location.homeVisit" title={t('studySetupLocation.homeLabel')} description={t('studySetupLocation.homeDescription')} />
            </Grid>
            <SeparatedErrorMessage>
                <MinorErrorMessage name="location.typeSelected" />
            </SeparatedErrorMessage>
        </React.Fragment>
    );
}