import getStudyRequest from 'api/study/getStudy';
import getResearchStudyRequest from 'api/fhir/getResearchStudy';
import { updateServerCriteria } from 'data/apiResponses/eligibilityHelpers';
import { enqueueGlobalUserMessageState } from 'redux/reducers/globalUserMessaging/index';

export const SET_LOADING = 'SET_LOADING';
export const setLoading = {
    type: SET_LOADING
};

export const UNSET_LOADING = 'UNSET_LOADING';
export const unsetLoading = {
    type: UNSET_LOADING
};

export const SET_SAVING = 'SET_SAVING';
export const setSaving = {
    type: SET_SAVING
};

export const UNSET_SAVING = 'UNSET_SAVING';
export const unsetSaving = {
    type: UNSET_SAVING
};

export const SET_STUDY_LOADING = 'SET_STUDY_LOADING';
export const setStudyLoading = {
    type: SET_STUDY_LOADING
};

export const UNSET_STUDY_LOADING = 'UNSET_STUDY_LOADING';
export const unsetStudyLoading = {
    type: UNSET_STUDY_LOADING
};

export function fetchStudyDetails(studyId, setStudyTeam, setStudyEligibility, setFhirStudy, setSponsor, setLetter, setLocations, setStudy) {
    return function(dispatch) {
        dispatch(setLoading);
        return getStudyRequest(studyId)
            .then(({data}) => {
                setStudy && setStudy(data);
                setStudyTeam(data.studyTeam);
                setStudyEligibility(data.eligibilityCriteria.map(criteria => updateServerCriteria(criteria)));
                getResearchStudyRequest(data.fhirId)
                    .then(response => {
                        const bundle = response.data.entry;
                        setFhirStudy(bundle.find(item => item.resource.resourceType === "ResearchStudy").resource);
                        setSponsor(bundle.find(item => item.resource.resourceType === "Organization").resource);
                        setLetter(bundle.find(item => item.resource.resourceType === "DocumentReference"));
                        setLocations(bundle.filter(entry => entry.resource.resourceType === "Location"));
                    });
            }).catch(() => {
                dispatch(enqueueGlobalUserMessageState(
                    "error", 
                    "error.studyNotFound"
                ));
            }).finally(() => {
                dispatch(unsetLoading);
            });
    }
}