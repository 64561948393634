import TextBox from './TextBox';
import { withStyles } from '@material-ui/core';

export function getColor(theme, {success, error}) {
    if (success) {
        return theme.palette.success.main;
    } else if (error) {
        return theme.palette.error.main;
    } 
}

export function getWidth({success, error}) {
    if (success || error) {
        return 2;
    }
}

const ConfirmationTextBox = withStyles(theme => ({
    root: props => ({
        "& .MuiInputLabel-root": {
            color: getColor(theme, props)
        },
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: getColor(theme, props),
            borderWidth: getWidth(props)
        }

    })
}))(TextBox);

export default ConfirmationTextBox;