import React from 'react';
import styled from 'styled-components';
import { FormGroup, Typography } from '@material-ui/core';
import { FieldArray } from 'formik';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';

const LightCheckbox = styled(Checkbox)`
    color: rgba(0, 0, 0, 0.12);
`;

const LabelText = styled(Typography)`
    font-family: Inter;
    font-size: 14px;
    line-height: 2;
    color: #000000;
`;

export default function SelectorGroup({ name, items, formikValues, disabled}) {
    const { t } = useTranslation();

    const handleClick = (arrayHelpers, id, index) => {
        if (index < 0) {
            arrayHelpers.push(id);
        } else {
            arrayHelpers.remove(index);
        }
    };

    return (
        <FieldArray
            name={name}
            render={arrayHelpers => (
                <FormGroup>
                    {items.map(({id, label, getLabel}) => {
                        const index = formikValues.indexOf(id);
                        const labelText = getLabel ? getLabel(t) : label;
                        return <FormControlLabel
                            key={id}
                            control={<LightCheckbox 
                                    checked={index >= 0} 
                                    onClick={() => handleClick(arrayHelpers, id, index)} 
                                    disabled={disabled} 
                                    color="primary"
                                    checkedIcon={<CheckBoxOutlinedIcon/>}
                                  />}
                            label={<LabelText>{labelText ? labelText : id}</LabelText>}
                            labelPlacement="end"
                        />;
                    })}
                </FormGroup>)
            }
        />
    );
}