import React from 'react';
import { useSelector } from 'react-redux';
import { SvgIcon, Typography, Grid } from '@material-ui/core';
import moment from 'moment';
import 'moment/locale/fr';
import { ReactComponent as ClockOutlinedIcon } from 'resources/images/icons/study/clockOutline.svg';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const SavedTimeDiv = styled.div`
    margin: 0 auto;
    padding: 0.5em 0;
    vertical-align: baseline;
    color: white;
`;

const IconSpacing = styled.div`
    padding-right: 0.5em;
`;

const LastSavedText = styled(Typography)`
    font-size: 14px;
    font-face: 'Inter';
    font-style: italic;
    color: ${props => props.theme.palette.primary.main};
`;

export default function LastSavedTime() {
    const { t, i18n } = useTranslation();
    const lastSavedTime = useSelector(state => state.studyCreation.lastSaveTime);
    const [timeString, setTimeString] = React.useState();

    React.useEffect(() => {
        const interval = setInterval(() => {
            const m = moment(lastSavedTime); 
            m.locale(i18n.language)
            setTimeString(m.fromNow())
        }, 2000);
        return () => {
            clearInterval(interval);
        };
    }, [lastSavedTime]);

    if (!lastSavedTime) {
        return null;
    } else {
        return (
            <SavedTimeDiv>
                <Grid container justify="center">
                    <IconSpacing>
                        <SvgIcon component={ClockOutlinedIcon} color="primary" style={{fontSize: '20px'}}/>
                    </IconSpacing>
                    <LastSavedText>
                        {t('studySetup.lastSavedAtLabel', { time: timeString })}
                    </LastSavedText>
                </Grid>
            </SavedTimeDiv>
        );
    }
}