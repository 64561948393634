import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { SvgIcon } from '@material-ui/core';
import {LightRoundedButton} from 'Components/Styles/Buttons/LightRoundedButton';
import { ReactComponent as DeleteIcon } from 'resources/icons/deleteOutlineIcon.svg';


const AddingRoundedButton = styled(LightRoundedButton)`
    padding-top: 0;
    padding-bottom: 0;
    font-weight: bold;
    color: #000;
`;

export default function RemovalButton({onClick}) {
    const { t } = useTranslation();
    return (
        <AddingRoundedButton 
            weight="light" 
            variant="outlined" 
            type="button" 
            size="small" 
            onClick={onClick}
            endIcon={<SvgIcon component={DeleteIcon}/>}
        >
            {t('removeButton.buttonText')}
        </AddingRoundedButton>
    );
}