import React from 'react';
import Dropdown from 'Components/Parts/Form/Dropdowns/SingleDropdown';
import MultipleDropdown from 'Components/Parts/Form/Dropdowns/MultipleDropdown';

export const inclusionOptions = Object.freeze([
    Object.freeze({id: "include", getLabel: t => t('eligibilitySelectionOptions.inclusion.include') }),
    Object.freeze({id: "exclude", getLabel: t => t('eligibilitySelectionOptions.inclusion.exclude') }),
]);

export const multipleSelectOptions = (name, selectOptions, t) => (
    Object.freeze([
        Object.freeze({id: "single", component: <Dropdown name={`${name}.singleOption`} defaultText={t('eligibilitySelectionOptions.multipleSelect.title')} values={selectOptions} variant="filled"/>}),
        Object.freeze({id: "mixed", component: <MultipleDropdown name={`${name}.options`} label={t('eligibilitySelectionOptions.multipleSelect.title')} values={selectOptions} variant="filled"/>}),
    ])
);

export const yesNoSelection = Object.freeze([
    Object.freeze({id: "yes", getLabel: t => t('eligibilitySelectionOptions.yesNo.yes') }),
    Object.freeze({id: "no", getLabel: t => t('eligibilitySelectionOptions.yesNo.no') }),
]);

export const yesNoDontKnowSelection = Object.freeze([
    ...yesNoSelection,
    Object.freeze({id: "dontKnow", getLabel: t => t('eligibilitySelectionOptions.dontKnow') }),
])

export const deviceOptions = Object.freeze([
    Object.freeze({id: "insulinPen", getLabel: t => t('eligibilitySelectionOptions.device.insulinPen') }),
    Object.freeze({id: "insulinSyringe", getLabel: t => t('eligibilitySelectionOptions.device.insulinSyringe') }),
    Object.freeze({id: "insulinPump", getLabel: t => t('eligibilitySelectionOptions.device.insulinPump'), getSubtitle: t => t('eligibilitySelectionOptions.device.insulinPumpSubtitle') })
]);

export const deviceSelection = [
    ...deviceOptions,
    Object.freeze({id: "closedLoopSystem", getLabel: t => t('studyEligibilityAdvanced.answerOptionClosedLoopText')}),
    Object.freeze({id: "other", getLabel: t => t('eligibilitySelectionOptions.other')}),
]

export const mdiAnswerOptions = Object.freeze([
    Object.freeze({id: "basal", getLabel: t => t('eligibilitySelectionOptions.mdiAnswer.basal') }),
    Object.freeze({id: "bolus", getLabel: t => t('eligibilitySelectionOptions.mdiAnswer.bolus') }),
]);

export const pumpOptions = Object.freeze([
    Object.freeze({id: "total", getLabel: t => t('eligibilitySelectionOptions.pump.dailyLabel') })
]);

export const unitSelectionA = Object.freeze([
    Object.freeze({id: "basal", getLabel: t => t('eligibilitySelectionOptions.units.basal') }),
    Object.freeze({id: "bolus", getLabel: t => t('eligibilitySelectionOptions.units.bolus') }),
    Object.freeze({id: "dontKnow", getLabel: t => t('eligibilitySelectionOptions.dontKnow')}),
]);

export const unitSelectionB = Object.freeze([
    Object.freeze({id: "basal", getLabel: t => t('eligibilitySelectionOptions.units.basal') }),
    Object.freeze({id: "bolus", getLabel: t => t('eligibilitySelectionOptions.units.bolus') }),
    Object.freeze({id: "dailyTotal", getLabel: t => t('eligibilitySelectionOptions.units.dailyLabel') }),
    Object.freeze({id: "needle", getLabel: t => t('eligibilitySelectionOptions.units.needle')}),
    Object.freeze({id: "dontKnow", getLabel: t => t('eligibilitySelectionOptions.dontKnow')}),
]);

export const unitSelectionC = Object.freeze([
    Object.freeze({id: "dailyTotal", getLabel: t => t('eligibilitySelectionOptions.units.dailyLabel') }),
    Object.freeze({id: "needle", getLabel: t => t('eligibilitySelectionOptions.units.needle')}),
    Object.freeze({id: "dontKnow", getLabel: t => t('eligibilitySelectionOptions.dontKnow')}),
]);

export const monitorOptions = Object.freeze([
    Object.freeze({id: "fingerStick", getLabel: t => t('eligibilitySelectionOptions.monitor.stick') }),
    Object.freeze({id: "flashMonitor", getLabel: t => t('eligibilitySelectionOptions.monitor.flash'), getSubtitle: t => t('eligibilitySelectionOptions.monitor.flashSubtitle') }),
    Object.freeze({id: "continuousMonitor", getLabel: t => t('eligibilitySelectionOptions.monitor.continuous'), getSubtitle: t => t('eligibilitySelectionOptions.monitor.continuousSubtitle') })
]);

export const monitorSelection = Object.freeze([
    ...monitorOptions,
    Object.freeze({id: "other", getLabel: t => t('eligibilitySelectionOptions.other')}),
])

export const timeframeSelection = Object.freeze([
    Object.freeze({id: "twoweeks", getLabel: t => t('studyEligibilityTimeframeOptions.twoWeeks')}),
    Object.freeze({id: "onemonth", getLabel: t => t('studyEligibilityTimeframeOptions.oneMonth')}),
    Object.freeze({id: "threemonths", getLabel: t => t('studyEligibilityTimeframeOptions.threeMonths')}),
    Object.freeze({id: "sixmonths", getLabel: t => t('studyEligibilityTimeframeOptions.sixMonths')}),
    Object.freeze({id: "ninemonths", getLabel: t => t('studyEligibilityTimeframeOptions.nineMonths')}),
    Object.freeze({id: "twelvemonths", getLabel: t => t('studyEligibilityTimeframeOptions.oneYear')}),
]);

export const timeframeNeverSelection = Object.freeze([
    ...timeframeSelection,
    Object.freeze({id: "never", getLabel: t => t('studyEligibilityTimeframeOptions.never')}),
]);

export const ethnicityOptions = Object.freeze([
    Object.freeze({
        id: "asianEast",
        getLabel: t => t('eligibilitySelectionOptions.ethnicity.asianEast'),
        getSubtitle: t => t('eligibilitySelectionOptions.ethnicity.asianEastSubtitle'),
    }),
    Object.freeze({
        id: "asianWest",
        getLabel: t => t('eligibilitySelectionOptions.ethnicity.asianWest'),
        getSubtitle: t => t('eligibilitySelectionOptions.ethnicity.asianWestSubtitle'),
    }),
    Object.freeze({
        id: "asianSouth",
        getLabel: t => t('eligibilitySelectionOptions.ethnicity.asianSouth'),
        getSubtitle: t => t('eligibilitySelectionOptions.ethnicity.asianSouthSubtitle'),
    }),
    Object.freeze({
        id: "blackCaribbean",
        getLabel: t => t('eligibilitySelectionOptions.ethnicity.blackCaribbean'),
    }),
    Object.freeze({
        id: "blackAfrican",
        getLabel: t => t('eligibilitySelectionOptions.ethnicity.blackAfrican'),
    }),
    Object.freeze({
        id: "blackNorthAmerican",
        getLabel: t => t('eligibilitySelectionOptions.ethnicity.blackNorthAmerican'),
    }),
    Object.freeze({
        id: "indianWest",
        getLabel: t => t('eligibilitySelectionOptions.ethnicity.westIndian'),
    }),
    Object.freeze({
        id: "indigenous",
        getLabel: t => t('eligibilitySelectionOptions.ethnicity.indigenous'),
        getSubtitle: t => t('eligibilitySelectionOptions.ethnicity.indigenousSubtitle'),
    }),
    Object.freeze({
        id: "latinAmerican",
        getLabel: t => t('eligibilitySelectionOptions.ethnicity.latinAmerican'),
    }),
    Object.freeze({
        id: "middleEastArabic",
        getLabel: t => t('eligibilitySelectionOptions.ethnicity.middleEastArabic'),
    }),
    Object.freeze({
        id: "pacificIslands",
        getLabel: t => t('eligibilitySelectionOptions.ethnicity.pacificIslander'),
        getSubtitle: t => t('eligibilitySelectionOptions.ethnicity.pacificIslanderSubtitle')
    }),
    Object.freeze({
        id: "whiteEuropean",
        getLabel: t => t('eligibilitySelectionOptions.ethnicity.whiteEuropean'),
    }),
    Object.freeze({
        id: "whiteNorthAmerican",
        getLabel: t => t('eligibilitySelectionOptions.ethnicity.whiteNorthAmerican'),
    }),
]);

export const ethnicitySelection = Object.freeze([
    ...ethnicityOptions,
    Object.freeze({id: "unknown", getLabel: t => t('eligibilitySelectionOptions.ethnicity.unknown')}),
    Object.freeze({id: "other", getLabel: t => t('eligibilitySelectionOptions.other')}),
    Object.freeze({id: "decline", getLabel: t => t('eligibilitySelectionOptions.ethnicity.decline')}),
])

export const marketingSelection = Object.freeze([
    Object.freeze({id: "diabetesActionCanada", getLabel: t => t('eligibilitySelectionOptions.marketing.diabetesActionCanada')}),
    Object.freeze({id: "jdrf", getLabel: t => t('eligibilitySelectionOptions.marketing.jdrf')}),
    Object.freeze({id: "familyOrFriends", getLabel: t => t('eligibilitySelectionOptions.marketing.familyOrFriends')}),
    Object.freeze({id: "peers", getLabel: t => t('eligibilitySelectionOptions.marketing.peers')}),
    Object.freeze({id: "clinic", getLabel: t => t('eligibilitySelectionOptions.marketing.clinic')}),
    Object.freeze({id: "socialMedia", getLabel: t => t('eligibilitySelectionOptions.marketing.socialMedia')}),
    Object.freeze({id: "other", getLabel: t => t('eligibilitySelectionOptions.other')}),
]);

export const complicationSelection = Object.freeze([
    Object.freeze({id: "nephropathy", getLabel: t => t('eligibilitySelectionOptions.complication.nephropathy'), getSubtitle: t => t('eligibilitySelectionOptions.complication.nephropathySubtitle')}),
    Object.freeze({id: "neuropathy", getLabel: t => t('eligibilitySelectionOptions.complication.neuropathy'), getSubtitle: t => t('eligibilitySelectionOptions.complication.neuropathySubtitle')}),
    Object.freeze({id: "retinopathy", getLabel: t => t('eligibilitySelectionOptions.complication.retinopathy'), getSubtitle: t => t('eligibilitySelectionOptions.complication.retinopathySubtitle')}),
    Object.freeze({id: "none", getLabel: t => t('eligibilitySelectionOptions.complication.none')}),
]);

export const assistanceOptions = Object.freeze([
    Object.freeze({id: "yesperson", getLabel: t => t('eligibilitySelectionOptions.assistance.yesPersonDesc')}),
    Object.freeze({id: "yeshospital", getLabel: t => t('eligibilitySelectionOptions.assistance.yesHospitalDesc')}),
    Object.freeze({id: "no", getLabel: t => t('eligibilitySelectionOptions.assistance.noDesc')}),
]);

export const assistanceSelection = Object.freeze([
    Object.freeze({id: "yesPerson", getLabel: t => t('eligibilitySelectionOptions.assistance.yesPerson'), getDescriptor: t => t('eligibilitySelectionOptions.assistance.yesPersonDesc')}),
    Object.freeze({id: "yesHospital", getLabel: t => t('eligibilitySelectionOptions.assistance.yesHospital'), getDescriptor: t => t('eligibilitySelectionOptions.assistance.yesHospitalDesc')}),
    Object.freeze({id: "no", getLabel: t => t('eligibilitySelectionOptions.assistance.no'), getDescriptor: t => t('eligibilitySelectionOptions.assistance.noDesc')}),
]);

// Used in single dropdown selects

export const ageSelectionOptions = Object.freeze([
    Object.freeze({id: "range", getLabel: t => t('studyEligibilityAge.answerOptionRangeText') }),
    Object.freeze({id: "less", getLabel: t => t('studyEligibilityAge.answerOptionUnderText') }),
    Object.freeze({id: "greater", getLabel: t => t('studyEligibilityAge.answerOptionOverText') }),
    Object.freeze({id: "equal", getLabel: t => t('studyEligibilityAge.answerOptionEqualText') }),
]);

export const hybridSystemSelection = Object.freeze([
    Object.freeze({id: "closedloop", getLabel: t => t('studyEligibilityAdvanced.answerOptionClosedLoopText')}),
    Object.freeze({id: "openloop", getLabel: t => t('studyEligibilityAdvanced.answerOptionOpenLoopText')}),
]);

export const ethnicitySelectionOptions = Object.freeze([
    Object.freeze({id: "single", getLabel: t => t('studyEligibilityEthnicity.answerOptionSingleText') }),
    Object.freeze({id: "mixed", getLabel: t => t('studyEligibilityEthnicity.answerOptionMixedText') }),
]);

export const birthSexOptions = Object.freeze([
    Object.freeze({id: "male", getLabel: t => t('studyEligibilitySex.answerOptionMaleText') }),
    Object.freeze({id: "female", getLabel: t => t('studyEligibilitySex.answerOptionFemaleText') }),
]);

export const sexSelection = Object.freeze([
    ...birthSexOptions,
    Object.freeze({id: "nonBinary", getLabel: t => t('studyEligibilitySex.answerOptionNonbinaryText') }),
    Object.freeze({id: "preferNotToSay", getLabel: t => t('studyEligibilitySex.answerOptionPreferNotToSayText') }),
]);

export const geneticsOptions = Object.freeze([
    Object.freeze({id: "fraternal", getLabel: t => t('studyEligibilityGenetics.answerOptionFraternalText') }),
    Object.freeze({id: "identical", getLabel: t => t('studyEligibilityGenetics.answerOptionIdenticalText') }),
]);

export const insulinDeviceSelectionOptions = Object.freeze([
    Object.freeze({id: "single", getLabel: t => t('studyEligibilityInsulinDevice.answerOptionSingleText')}),
    Object.freeze({id: "mixed", getLabel: t => t('studyEligibilityInsulinDevice.answerOptionMixedText')}),
]);

export const insulinDeviceDurationSelection = Object.freeze([
    Object.freeze({id: "threemonths", getLabel: t => t('studyEligibilityInsulinDuration.answerOptionThreeMonthsText')}),
    Object.freeze({id: "sixmonths", getLabel: t => t('studyEligibilityInsulinDuration.answerOptionSixMonthsText')}),
]);

export const insulinRegimenSelectionOptions = Object.freeze([
    Object.freeze({id: "single", getLabel: t => t('studyEligibilityInsulinRegimen.answerOptionSingleText')}),
    Object.freeze({id: "mixed", getLabel: t => t('studyEligibilityInsulinRegimen.answerOptionMixedText')}),
]);

export const exerciseSelection = Object.freeze([
    Object.freeze({id: "light", getLabel: t => t('studyEligibilityActivity.answerOptionLightText')}),
    Object.freeze({id: "moderate", getLabel: t => t('studyEligibilityActivity.answerOptionModerateText')}),
    Object.freeze({id: "high", getLabel: t => t('studyEligibilityActivity.answerOptionHighText')}),
    Object.freeze({id: "vigorous", getLabel: t => t('studyEligibilityActivity.answerOptionVigorousText')}),
]);

export const activitySelection = Object.freeze([
    Object.freeze({id: "never", getLabel: t => t('eligibilitySelectionOptions.activity.never'), getSubtitle: t => t('eligibilitySelectionOptions.activity.neverSubtitle')}),
    Object.freeze({id: "rarely", getLabel: t => t('eligibilitySelectionOptions.activity.rarely'), getSubtitle: t => t('eligibilitySelectionOptions.activity.rarelySubtitle')}),
    Object.freeze({id: "sometimes", getLabel: t => t('eligibilitySelectionOptions.activity.sometimes'), getSubtitle: t => t('eligibilitySelectionOptions.activity.sometimesSubtitle')}),
    Object.freeze({id: "often", getLabel: t => t('eligibilitySelectionOptions.activity.often'), getSubtitle: t => t('eligibilitySelectionOptions.activity.oftenSubtitle')}),
    Object.freeze({id: "veryOften", getLabel: t => t('eligibilitySelectionOptions.activity.veryOften'), getSubtitle: t => t('eligibilitySelectionOptions.activity.veryOftenSubtitle')}),
]);

export const monitorSelectionOptions = Object.freeze([
    Object.freeze({id: "single", getLabel: t => t('studyEligibilityMonitor.answerOptionSingleText')}),
    Object.freeze({id: "mixed", getLabel: t => t('studyEligibilityMonitor.answerOptionMixedText')}),
]);

export const diagnosisSelectionOptions = Object.freeze([
    Object.freeze({id: "greater", getLabel: t => t('studyEligibilityCondition.answerOptionGreaterText')}),
    Object.freeze({id: "less", getLabel: t => t('studyEligibilityCondition.answerOptionLessText')}),
    Object.freeze({id: "range", getLabel: t => t('studyEligibilityCondition.answerOptionRangeText')}),
]);

export const a1cValueSelectionOptions = Object.freeze([
    Object.freeze({id: "range", getLabel: t => t('studyEligibilityA1c.answerOptionRangeText')}),
    Object.freeze({id: "less", getLabel: t => t('studyEligibilityA1c.answerOptionLessText')}),
    Object.freeze({id: "greater", getLabel: t => t('studyEligibilityA1c.answerOptionGreaterText')}),
]);

export const singleSelectOptionMap = Object.freeze({
    BIRTHDATE: ageSelectionOptions,
    ETHNICITY: ethnicitySelectionOptions,
    BIRTHSEX: birthSexOptions,
    TWIN: geneticsOptions,
    DIAGNOSIS: diagnosisSelectionOptions,
    BRITTLE: yesNoSelection,
    MONITOR: monitorSelectionOptions,
    DEVICES: insulinDeviceSelectionOptions,
    EXERCISE: exerciseSelection,
    INSULIN_DURATION: insulinDeviceDurationSelection,
    HYBRID_SYSTEM: hybridSystemSelection,
    MEDICATION: yesNoSelection,
    IMMUNOSUPPRESSANT: yesNoSelection,
    A1C_VALUE: a1cValueSelectionOptions,
    A1C_TIME: timeframeSelection,
    KETO: timeframeNeverSelection,
    KETO_HOSPITAL: assistanceOptions,
    HYPOGLYCEMIA: timeframeNeverSelection,
    HYPOGLYCEMIA_HOSPITAL: assistanceOptions,
    PREGNANCY: yesNoSelection,
    BREASTFEEDING: yesNoSelection,
    TRY_PREGNANCY: yesNoSelection,
    SECONDARY_NEPHRO: yesNoSelection,
    SECONDARY_NEURO: yesNoSelection,
    SECONDARY_RETINO: yesNoSelection,
    CONDITIONS_CELIAC: yesNoSelection,
    CONDITIONS_FOOTULCER: yesNoSelection,
    CONDITIONS_GASTRO: yesNoSelection,
    CONDITIONS_HEART: yesNoSelection,
    CONDITIONS_HEPATITIS: yesNoSelection,
    CONDITIONS_HYPERT: yesNoSelection,
    CONDITIONS_HYPOT: yesNoSelection,
    CONDITIONS_LIVER: yesNoSelection,
    CONDITIONS_PSYCH: yesNoSelection,
});
