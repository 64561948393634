import React from 'react';
import { Button, Grid, Menu, MenuItem, Radio, RadioGroup, FormControlLabel, Divider } from '@material-ui/core';
import SortIcon from '@material-ui/icons/Sort';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { useTranslation } from 'react-i18next';
import { LightRoundedButton } from 'Components/Styles/Buttons/LightRoundedButton';
import { connect, useDispatch, useSelector } from 'react-redux';
import { setSort } from 'redux/reducers/dashboard/actions';
import { useLocation } from 'react-router-dom';
import { sortOrderings } from './sortOrderings';


export default function SortMenu() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { pathname: page } = useLocation();
    const { sort } = useSelector(state => state.dashboard.filters[page]) ?? {};
    const [ordering, setOrdering] = React.useState(sort ?? '');

    function handleChange(event) {
        setOrdering(event.target.value);
    }

    function handleApply(close) {
        close();
        dispatch(setSort(ordering, page));
    }

    return (
        <PopupState variant="popover" popupId="dashboard-sort-popup-menu">
        {(popupState) => (
            <Grid container spacing={1} alignItems="center">
                <Grid item>
                    <LightRoundedButton variant="outlined" size="small" color="primary" endIcon={<SortIcon/>} {...bindTrigger(popupState)}>
                        {ordering ? t(sortOrderings.find(sortOrdering => sortOrdering.value === ordering).label) : t("dashboardMenu.sortBy")}
                    </LightRoundedButton>
                    <Menu {...bindMenu(popupState)}>
                        <RadioGroup name="sorting" value={ordering || "-_lastUpdated"} onChange={handleChange}>
                            {sortOrderings.map(({value, label}) => (
                                <MenuItem key={value}>
                                    <FormControlLabel value={value} control={<Radio />} label={t(label)} />
                                </MenuItem>
                            ))}
                        </RadioGroup>
                        <Divider/>
                        <Grid container justify="flex-end">
                            <Button color="primary" onClick={() => handleApply(popupState.close)}>{t("dashboardMenu.apply")}</Button>
                        </Grid>
                    </Menu>
                </Grid>
            </Grid>
        )}
        </PopupState>
    );
}
