import { axiosInstance } from '../api';
import { StudyStatus } from 'Constants/constants';

function getLanguageFilter(english, french) {
    if (english && !french) {
        return '&_language=en-CA';
    } else if (french && !english) {
        return '&_language=fr-CA';
    }

    return "";
}

export default function({fhirId, status, order, filters, filterEnglish, filterFrench, count=20}) {
    const authorString = status === `${StudyStatus.DRAFT},${StudyStatus.UNDER_REVIEW}` ? `&principalinvestigator=${fhirId}` : "";
    const statusString = `&status=${status}`;
    const orderString = `&_sort=${order ? order : "status,-_lastUpdated"}`;
    const filterString = filters && filters.length > 0 ? `&category:text=${filters.join(",")}` : "";

    return axiosInstance.get(`/fhir/ResearchStudy?_count=${count}${authorString}${statusString}${orderString}${filterString}${getLanguageFilter(filterEnglish, filterFrench)}`);
}
