import React from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useParams } from 'react-router';
import BaselineQuestionnaireCompletedModal from 'Components/Parts/Modals/Questionnaire/BaselineQuestionnaireCompleted';
import MobileLayout from './QuestionnaireMobileLayout';
import DesktopLayout from './QuestionnaireDesktopLayout';

export default function QuestionnaireLayout() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { questionnaireId } = useParams();
    const baselineComplete = useSelector(state => state.authorization.profile.baselineQuestionnaireCompleted);
    const [localBaseline, setLocalBaseline] = React.useState(baselineComplete);

    React.useEffect(() => {
        setLocalBaseline(baselineComplete);
    }, []);

    function getQuestionnaireId() {
        if (questionnaireId) {
            return "eq-" + questionnaireId;
        } else {
            return "baselineQuestionnaire";
        }
    }

    if (!questionnaireId && localBaseline) {
        return <BaselineQuestionnaireCompletedModal/>;
    }

    if (isMobile) {
        return <MobileLayout questionnaireId={getQuestionnaireId()}/>;
    } else {
        return <DesktopLayout questionnaireId={getQuestionnaireId()}/>;
    }
    
}