import React from 'react';
import { useField } from 'formik';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import StudyCommitmentHeader from 'Components/Parts/Study/StudyCommitmentHeader';
import Dropdown from 'Components/Parts/Form/Dropdowns/SingleDropdown';
import * as Selections from 'data/study/studyCommitmentSelection';
import MultipleDropdown from 'Components/Parts/Form/Dropdowns/MultipleDropdown';

export default function CommitmentApproval() {
    const { t } = useTranslation();
    const [field] = useField("commitment");
    const {visits, visitFrequency, visitDuration, visitRequirements} = field.value;
    const isDisabled = !visits || parseInt(visits.id) < 1;

    return (
        <Grid container spacing={4}>
            <Grid item>
                <StudyCommitmentHeader 
                    visitNumber={visits} 
                    visitFrequency={visitFrequency} 
                    visitDuration={visitDuration}
                    visitRequirements={visitRequirements}
                />
            </Grid>
            <Grid item container spacing={4}>
                <Grid item xs={6}>
                    <Dropdown 
                        name="commitment.visits" 
                        defaultText={t('studySetupCommitment.visitsDefaultDropdownText')} 
                        values={Selections.visitValues}
                        variant="filled"
                    />
                </Grid>
                <Grid item xs={6}>
                    <Dropdown 
                        name="commitment.visitDuration" 
                        values={Selections.sliderValues} 
                        defaultText={t('studySetupCommitment.durationDefaultDropdownText')} 
                        variant="filled"
                        disabled={isDisabled}
                    />
                </Grid>
            </Grid>
            <Grid item container spacing={4}>
                <Grid item xs={6}>
                    <Dropdown 
                        name="commitment.visitFrequency" 
                        values={Selections.frequencyValues}
                        defaultText={t('studySetupCommitment.frequencyDefaultDropdownText')} 
                        variant="filled"
                        disabled={isDisabled}
                    />
                </Grid>
                <Grid item xs={6}>
                    <MultipleDropdown 
                        name="commitment.visitRequirements" 
                        values={Selections.requirementsValues} 
                        defaultText={t('studySetupCommitment.requirementsLabel')} 
                        variant="filled"
                        displayEmpty
                        disabled={isDisabled}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
}