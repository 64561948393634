import * as Constants from 'Constants/constants';
import * as Helpers from 'data/apiResponses/fhir/studyHelpers';
import * as Extension from 'data/apiResponses/fhir/extensionHelpers';
import * as Commitment from 'data/study/studyCommitmentSelection';

import StudyInformationDetails from 'Components/Parts/Study/StudyReview/ReviewSectionCardDetails/StudyInformationDetails';
import StudyApprovalDetails from 'Components/Parts/Study/StudyReview/ReviewSectionCardDetails/StudyApprovalDetails';
import TimelinesDetails from 'Components/Parts/Study/StudyReview/ReviewSectionCardDetails/TimelinesDetails';
import LocationDetails from 'Components/Parts/Study/StudyReview/ReviewSectionCardDetails/LocationDetails';
import CommitmentDetails from 'Components/Parts/Study/StudyReview/ReviewSectionCardDetails/CommitmentDetails';
import PersonalDataDetails from 'Components/Parts/Study/StudyReview/ReviewSectionCardDetails/PersonalDataDetails';
import CompensationDetails from 'Components/Parts/Study/StudyReview/ReviewSectionCardDetails/CompensationDetails';
import EligibilityDetails from 'Components/Parts/Study/StudyReview/ReviewSectionCardDetails/EligibilityDetails';
import StudyTeamDetails from 'Components/Parts/Study/StudyReview/ReviewSectionCardDetails/StudyTeamDetails';

import StudyInformationApproval from 'Components/Parts/Study/StudyApproval/Sections/StudyInformationApproval';
import StudyEthicsApproval from 'Components/Parts/Study/StudyApproval/Sections/StudyEthicsApproval';
import StudyTimelinesApproval from 'Components/Parts/Study/StudyApproval/Sections/StudyTimelinesApproval';
import StudyTeamApproval from 'Components/Parts/Study/StudyApproval/Sections/StudyTeamApproval';


export function buildDetailsContents(studyId, fhirStudy, studyTeam, studyEligibility, sponsor, locations, letter) {

    const [recruitStart, recruitEnd] = Helpers.getStudyPeriod(fhirStudy);
    const [inClinic, clinics] = Helpers.getLocations(locations);
    const typeOtherDescription = Extension.conditionallyReturnExtension(fhirStudy, Constants.STUDY_COMPENSATION_OTHER_URI) || "";

    return [
        {
            name: "studyInfo",
            reviewComponent: StudyInformationApproval,
            detailsComponent: StudyInformationDetails,
            content: {
                title: fhirStudy.title,
                summary: fhirStudy.description,
                recruitment: Extension.conditionallyReturnExtension(fhirStudy, Constants.STUDY_RECRUITMENT_URI),
                category: Helpers.getCategories(fhirStudy),
                image: `${Constants.SERVER_API_CONTEXT_PATH(process.env.REACT_APP_STAGE)}/api/study/${studyId}/image`
            },
        },
        {
            name: "ethicsApproval",
            reviewComponent: StudyEthicsApproval,
            detailsComponent: StudyApprovalDetails,
            content: {
                funder: sponsor ? sponsor.name : "",
                letter: Helpers.getArtifactAsPdf(letter),
            },
        },
        {
            name: "timelines",
            reviewComponent: StudyTimelinesApproval,
            detailsComponent: TimelinesDetails,
            content: {
                recruitStart,
                recruitEnd,
                studyEnd: Extension.conditionallyReturnDateFromExtension(fhirStudy, Constants.STUDY_END_DATE_URI),
                resultsAvailable: Extension.conditionallyReturnDateFromExtension(fhirStudy, Constants.STUDY_RESULTS_DATE_URI),
            },
        },
        {
            name: "location",
            reviewComponent: LocationDetails,
            detailsComponent: LocationDetails,
            content: {
                inClinic,
                clinics,
                online: Helpers.isStudyOnline(locations),
                homeVisit: Helpers.isStudyInHome(locations),
            },
        },
        {
            name: "commitment",
            reviewComponent: CommitmentDetails,
            detailsComponent: CommitmentDetails,
            content: {
                visits: Extension.conditionallyReturnObjectFromExtensionById(fhirStudy, Constants.STUDY_COMMITMENT_VISITS_URI),
                visitDuration: Extension.conditionallyReturnObjectFromExtensionByLabel(fhirStudy, Constants.STUDY_COMMITMENT_DURATION_URI, Commitment.sliderValues),
                visitFrequency: Extension.conditionallyReturnObjectFromExtensionById(fhirStudy, Constants.STUDY_COMMITMENT_FREQUENCY_URI, Commitment.frequencyValues),
                visitRequirements: Extension.conditionallyReturnMultipleObjectsFromExtensions(fhirStudy, Constants.STUDY_COMMITMENT_OPTIONS_URI, Commitment.requirementsValues),
            },
        },
        {
            name: "personalData",
            reviewComponent: PersonalDataDetails,
            detailsComponent: PersonalDataDetails,
            content: {
                value: Extension.conditionallyReturnMultipleExtension(fhirStudy, Constants.STUDY_PERSONALDATA_URI),
            },
        },
        {
            name: "compensation",
            reviewComponent: CompensationDetails,
            detailsComponent: CompensationDetails,
            content: {
                types: Extension.conditionallyReturnMultipleExtension(fhirStudy, Constants.STUDY_COMPENSATION_URI),
                typeOther: !!typeOtherDescription,
                typeOtherDescription: typeOtherDescription,
            },
        },
        {
            name: "eligibility",
            reviewComponent: EligibilityDetails,
            detailsComponent: EligibilityDetails,
            content: {
                value: studyEligibility,
            },
        },
        {
            name: "studyTeam",
            reviewComponent: StudyTeamApproval,
            detailsComponent: StudyTeamDetails,
            content: {
                principalInvestigator: Helpers.createTeamMember(studyTeam.principalInvestigator),
                researchers: Helpers.createTeamMemberList(studyTeam.researchAssistants),
                coinvestigators: Helpers.createTeamMemberList(studyTeam.coinvestigators),
            },
        }
    ]
}

