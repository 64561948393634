import React from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { CardDetailOption } from 'Components/Parts/Study/StudyReview';
import { compensationOptions } from 'data/study/studyCompensationSelection';
import CardFieldError from '../CardFieldError';

function CheckedDetailRow({id, displayCheck}) {
    const { t } = useTranslation();
    const selectedOption = compensationOptions.find(option => { return option.id === id; });
    if (!selectedOption) {
        return null;
    }

    return (
        <Grid item xs={12}>
            <CardDetailOption title={selectedOption.getTitle(t)} description={selectedOption.getDescription(t)} displayCheck={displayCheck}/>
        </Grid>
    );
}

function CheckedDetailOtherRow({value, description, displayCheck}) {
    if (!value) {
        return null;
    }

    return (
        <Grid item xs={12}>
            <CardDetailOption title="Other" description={description} displayCheck={displayCheck}/>
        </Grid>
    );
}

function CompensationDetails({types, typeOther, typeOtherDescription, isFinalized=false, meta}) {
    return (
        <Grid container spacing={1}>
            {types && types.map((id, i) => <CheckedDetailRow key={i} id={id} displayCheck={!isFinalized}/>)}
            <CheckedDetailOtherRow value={typeOther} description={typeOtherDescription} displayCheck={!isFinalized}/>
            <Grid item xs={12}>
                <CardFieldError meta={meta}/>
            </Grid>
        </Grid>
    );
}

CompensationDetails.defaultProps = {
    isFinalized: false
};

export default CompensationDetails;