import React from 'react';
import styled from 'styled-components';
import { ReactComponent as ExitIcon } from 'resources/icons/exitIcon.svg';
import { Drawer } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import MenuItems from './MenuItems';
import { ItemDivider } from 'Components/Styles/Navigation/Sidebar/StyledMenuItem';
import { RoundedButton } from 'Components/Styles/Buttons/RoundedButton';
import { useHistory } from 'react-router';
import getLogoSource from 'Components/Parts/Logo/LogoSource';

const MenuLogo = styled.img`
    width: 265px;
    object-fit: contain;
`;

const StyledMenuHeader = styled.div`
    font-family: Varela Round;
    color: white;
    padding-bottom: 3.5em;
    padding-left: 2em;
`;

const NavbarExitButton = styled(RoundedButton)`
    font-family: Inter;
    font-size: 14px;
    justify-content: flex-start;
    width: 172px;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 2rem;
    margin-left: 2rem;
`;

const drawerWidth = 260;
const styles = theme => ({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
        background: 'linear-gradient(168deg, #6078ea, #33bee0 187%)',
        height: '100%',
        overflow: 'hidden',
    }
});

const NavbarDrawer = styled(Drawer)`
    ${({ theme }) => {
        const classes = styles(theme);
        return {
            ...classes.drawer
        };
    }}
    .MuiDrawer-paper {
        ${({ theme }) => {
            const classes = styles(theme);
            return {
                ...classes.drawerPaper
            };
        }}
    }
`;

export default function SideNavbar({ header, items }) {
    const { t } = useTranslation();
    const history = useHistory();

    return (
        <NavbarDrawer
            variant="permanent"
            anchor="left"
        >
            <MenuLogo src={getLogoSource()} alt="connect1d logo"/>
            <StyledMenuHeader>{header}</StyledMenuHeader>
            <ItemDivider/>
            <MenuItems items={items} />
            <NavbarExitButton 
                variant="outlined" 
                size="small" 
                color="default" 
                startIcon={<ExitIcon />}
                onClick={() => history.push("/dashboard")}
            >
                {t('studySetup.exit')}
            </NavbarExitButton>
        </NavbarDrawer>
    );
}