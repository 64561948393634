import { setToken, clearToken } from 'api/api';
import loginRequest from 'api/auth/login';
import logoutRequest from 'api/auth/logout';
import forgotPasswordRequest from 'api/auth/forgotPassword';
import registrationConfirmationRequest from 'api/auth/registrationConfirmation';
import deleteAccountRequest from 'api/auth/deleteAccount';
import archiveAccountRequest from 'api/auth/archiveAccount';
import changePasswordRequest from 'api/reset/changePassword';
import authorizedRequest from 'api/auth/authorized';
import { enqueueGlobalUserMessageUnknownError,
    enqueueGlobalUserMessageState } from '../globalUserMessaging';
import getUserProfileRequest from 'api/users/getUserProfile';
import i18n from 'translations/i18n';
import getWebsiteLink from 'utilities/getWebsiteLink';

export const USER_LOGIN = 'USER_LOGIN';
export function userLogin(userId, fhirId, userEmail, accountType, lastLoggedInAt, profile) {
    return {
        type: USER_LOGIN,
        payload: {
            userId,
            fhirId,
            userEmail,
            accountType,
            lastLoggedInAt,
            profile
        }
    };
}

export const DISMISS_BASELINE = 'DISMISS_BASELINE';
export const dismissBaseline = {
    type: DISMISS_BASELINE
};

export const COMPLETED_BASELINE = 'COMPLETED_BASELINE';
export const completedBaseline = {
    type: COMPLETED_BASELINE
};

export const UPDATING_ELIGIBILITIES = 'UPDATING_ELIGIBILITIES';
export const updatingEligibilities = {
    type: UPDATING_ELIGIBILITIES
};

export const CHANGED_LANGUAGE = 'CHANGED_LANGUAGE';
export function changedLanguage(language) {
    return {
        type: CHANGED_LANGUAGE,
        payload: {
            language,
        },
    };
}

export const CHANGED_COMMUNICATION = 'CHANGED_COMMUNICATION';
export function changedCommunication(communication) {
    return {
        type: CHANGED_COMMUNICATION,
        payload: {
            communication,
        },
    };
}

export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';
export function updateUserProfile(profile) {
    return {
        type: UPDATE_USER_PROFILE,
        payload: {
            profile
        }
    }
}

export const USER_FHIR_PROFILE = 'USER_FHIR_PROFILE';
export function gotUserFhirProfile(profile) {
    return {
        type: USER_FHIR_PROFILE,
        payload: {
            profile,
        },
    };
}

export const UPDATE_PROFILE_QUALIFICATIONS = 'UPDATE_PROFILE_QUALIFICATIONS';
export function updateProfileQualifications(qualifications) {
    return {
        type: UPDATE_PROFILE_QUALIFICATIONS,
        payload: {
            qualifications,
        },
    };
}

export function isAuthorized(failQuietly, handleResponse) {
    return function(dispatch) {
        return authorizedRequest()
        .then(async function({data}) {
            // this will only work when we are using cookies, so we'll set the remember me flag to true
            await dispatch(authorizeAccount(data, true));
        }).catch(function() {
            if (!failQuietly) {
                dispatch(enqueueGlobalUserMessageState(
                    "error",
                    "error.loginSessionEnded"
                ));
            }
        }).finally(function() {
            handleResponse && handleResponse();
        });
    };
}

export function login(email, password, rememberMe, handleSuccess, handleError) {
    return function(dispatch) {
        return loginRequest(email, password, rememberMe)
        .then(function({data}) {
            dispatch(authorizeAccount(data, false, email));
            handleSuccess(data.lastLoggedInAt, data.extraFields.type);
        }).catch(function(error) {
            if (error.response) {
                handleError(error.response.data);                
            } else {
                dispatch(enqueueGlobalUserMessageUnknownError());
            }
        });
    };
}

export function logout() {
    return function(dispatch) {
        return logoutRequest()
        .then(function() {
            clearToken();
            window.location.href = getWebsiteLink('/');
        }).catch(function(error) {
            dispatch(enqueueGlobalUserMessageUnknownError());
        });
    };
}

export function deleteAccount(shouldDonateData) {
    return function(dispatch, getState) {
        const userId = getState().authorization.userId;
        const request = shouldDonateData ? archiveAccountRequest : deleteAccountRequest;
        
        request(userId).then(function () {
            clearToken();
            window.location.href = getWebsiteLink('/');
        });
    };
}

export function registrationConfirmation(token, handleSuccess, handleError) {
    return function(dispatch) {
        return registrationConfirmationRequest(token)
        .then(_ => handleSuccess())
        .catch(function({response}) {
            if (response?.data?.error) {
                handleError(response.data.error);
            } else {
                dispatch(enqueueGlobalUserMessageUnknownError());
            }
        });
    };
}

export function forgotPassword(email) {
    return function(dispatch) {
        return forgotPasswordRequest(email)
        .then(function() {
            dispatch(enqueueGlobalUserMessageState(
                "success",
                "success.emailSent"
            ));
        }).catch(function() {
            dispatch(enqueueGlobalUserMessageUnknownError());
        });
    };
}

export function changePassword(userId, token, newPassword, handleSuccess, handleError) {
    return function(dispatch) {
        return changePasswordRequest(userId, token, newPassword)
        .then(function() {
            dispatch(enqueueGlobalUserMessageState(
                "success",
                "success.passwordReset"
            ));
            handleSuccess();
        }).catch(function(error) {
            if (error.response) {
                dispatch(enqueueGlobalUserMessageState(
                    "error",
                    error.response.data
                ));
                handleError();                
            } else {
                dispatch(enqueueGlobalUserMessageUnknownError());
            }
        });
    };
}

export function authorizeAccount({userId, practitionerId, patientId, extraFields, token, lastLoggedInAt}, rememberMe, email) {
    return function(dispatch, getState) {
        if ( getState()?.authorization?.authorized ) {
            return;
        }

        if (!rememberMe) {
            setToken(token);
        }
        const { type, email: userEmail } = extraFields;
        const fhirId = type === "patient" ? patientId : practitionerId;
        
        return getUserProfileRequest(userId)
            .then(function({data: profile}) {
                if (profile.preferredLanguage) {
                    i18n.changeLanguage(profile.preferredLanguage);
                }

                dispatch(userLogin(userId, fhirId, userEmail ?? email, type, lastLoggedInAt, profile));
            });
    };
}

export function getUserProfile(userId) {
    return function(dispatch) {
        return getUserProfileRequest(userId)
            .then(function({data}) {
                dispatch(updateUserProfile(data));
            })
            .catch(/*fail quietly*/);
    }
}
