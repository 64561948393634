export default function generateInitialValues(questions) {
    const object = questions.reduce((question, {linkId, type, repeats, defaultAnswer}) => {
        
        let initialVal = defaultAnswer || (repeats ? [] : "");
        if (type === "open-choice") {
            initialVal = defaultAnswer || {
                select: repeats ? [] : "",
                text: ""
            };
        } else if (type === "date") {
            initialVal = defaultAnswer || null;
        } else if (repeats && defaultAnswer && !Array.isArray(defaultAnswer)) {
            initialVal = [defaultAnswer];
        }

        return Object.assign(question, {[linkId]: initialVal})
    }, {});

    return object;
}

export function getQuestionnaireResponseAnswer(answer) {
    return answer.valueString || answer.valueDate || answer.valueInteger || answer.valueDecimal || answer.valueCoding.code;
}