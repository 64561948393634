import React from 'react';
import styled from 'styled-components';
import {
    Grid,
    IconButton,
    ListSubheader,
    MenuItem,
    MenuList,
    Popover,
    SvgIcon,
    TableCell,
    Typography,
    useMediaQuery,
} from '@material-ui/core';
import {
  usePopupState,
  bindTrigger,
  bindPopover
} from 'material-ui-popup-state/hooks'
import { useTheme } from '@material-ui/core/styles';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import MoreHorizOutlinedIcon from '@material-ui/icons/MoreHorizOutlined';
import MoreVertOutlinedIcon from '@material-ui/icons/MoreVertOutlined';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import {
    markParticipantInterested,
    markParticipantDeclined,
    markParticipantRecruited,
} from 'redux/reducers/myStudy/actions';
import { ReactComponent as CheckCircleOutlineIcon } from 'resources/images/icons/checkCircleOutlineIcon.svg';
import { ParticipantStatus } from 'Constants/constants';


const StyledMenuItem = styled(MenuItem)`
    min-height: 40px;
    @media (min-width: 960px) {
        min-width: 216px;
    }
`;

const StyledListSubheader = styled(ListSubheader)`
    color: #000;
    font-size: 12px;
    font-weight: 700;
`;

const ItemLabel = styled(Typography)`
    color: #000;
    font-size: 16px;
    margin-left: 8px;
`;

const ActionMenuItem = ({ className, icon, title, onClick }) => (
    <StyledMenuItem className={className} onClick={onClick}>
        <Grid container alignItems="center">
            {icon}
            <ItemLabel>{title}</ItemLabel>
        </Grid>
    </StyledMenuItem>
);

// TODO: Depending on the table, the actions may differ. Update this when connecting with real endpoints
export default function ParticipantActionCell({ participant, status, handleAddNoteAction, hasNote }) {
    const theme = useTheme();
    const mdUp = useMediaQuery(theme.breakpoints.up('md'));
    const dispatch = useDispatch();
    const popupState = usePopupState({ variant: 'popover', popupId: `row-action-${participant.id}` });
    const { t } = useTranslation();

    const handleAddNote = () => {
        popupState.close();
        handleAddNoteAction && setTimeout(handleAddNoteAction, 50);
    };

    const handleInterest = () => {
        dispatch(markParticipantInterested(participant.patientUserId, participant.status));
        popupState.close();
    };

    const handleRecruit = () => {
        dispatch(markParticipantRecruited(participant.patientUserId, participant.status));
        popupState.close();
    };

    const handleDecline = () => {
        dispatch(markParticipantDeclined(participant.patientUserId, participant.status));
        popupState.close();
    };

    return (
        <TableCell padding="none">
            <Grid container alignItems="center" justify="flex-end">
                <IconButton {...bindTrigger(popupState)}>
                    {mdUp
                        ? <MoreHorizOutlinedIcon />
                        : <MoreVertOutlinedIcon />
                    }
                </IconButton>
                <Popover
                    {...bindPopover(popupState)}
                    anchorOrigin={{
                      horizontal: 'center',
                      vertical: 'bottom',
                    }}
                    transformOrigin={{
                        horizontal: 'right',
                        vertical: 'top',
                    }}
                >
                    <MenuList disablePadding>
                        {!participant.isArchived &&
                            <ActionMenuItem
                                icon={<BorderColorIcon color="primary" style={{ fontSize: "24px" }} />}
                                title={hasNote ? t('myStudyPage.editNoteLabel') : t('myStudyPage.addNoteLabel') }
                                onClick={handleAddNote}
                            />
                        }
                        <StyledListSubheader>{t('myStudyPage.statusLabel')}</StyledListSubheader>
                        {[ParticipantStatus.RECRUITED, ParticipantStatus.DECLINED].includes(status) && 
                            <ActionMenuItem
                                icon={<SvgIcon component={CheckCircleOutlineIcon} htmlColor="#2cc7de" style={{ fontSize: "24px" }} viewBox="0 0 32 32" />}
                                title={t('myStudyPage.interestedLabel')}
                                onClick={handleInterest}
                            />
                        }
                        {[ParticipantStatus.INTERESTED, ParticipantStatus.DECLINED].includes(status) && 
                            <ActionMenuItem
                                icon={<SvgIcon component={CheckCircleOutlineIcon} htmlColor="#2cc7de" style={{ fontSize: "24px" }} viewBox="0 0 32 32" />}
                                title={t('myStudyPage.recruitedLabel')}
                                onClick={handleRecruit}
                            />
                        }
                        {[ParticipantStatus.INTERESTED, ParticipantStatus.RECRUITED].includes(status) &&
                            <ActionMenuItem
                                icon={<HighlightOffIcon htmlColor="#f10ebc" style={{ fontSize: "24px" }} />}
                                title={t('myStudyPage.declinedLabel')}
                                onClick={handleDecline}
                            />
                        }
                    </MenuList>
                </Popover>
            </Grid>
        </TableCell>
    );
}
