import React, { useState } from 'react';
import {ExpansionPanelDetails, Grid, Typography} from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { StyledPanel, StyledPanelSummary, GroupMarker } from 'Components/Styles/Form/CollapsibleGroup';

export default function CollapsibleGroup({groupTitle, selections, children}) {
    const [expanded, setExpanded] = useState(false);

    return (
        <Grid item xs={12}>
            <StyledPanel expanded={expanded}>
                <StyledPanelSummary
                    onClick={() => setExpanded(!expanded)}
                    expandIcon={expanded
                    ? <RemoveCircleOutlineIcon color="primary"/>
                    : <AddCircleOutlineIcon color="primary"/>}
                >
                    <Grid container justify="space-between">
                        <Grid item>
                            <Typography variant="h6" color="primary">{groupTitle}</Typography>
                        </Grid>
                        <Grid item>
                            {selections &&
                            selections.map((selection, i) => <GroupMarker variant="outlined" label={selection} key={`${selection}-${i}`}/>)}
                        </Grid>
                    </Grid>
                </StyledPanelSummary>
                <ExpansionPanelDetails>
                    {children}
                </ExpansionPanelDetails>
            </StyledPanel>
        </Grid>
    );
}