import React from 'react';
import { Switch, Route } from 'react-router-dom';

import SettingsPage from './DashboardSettingsPage';
import SettingsAccountPage from './SettingsAccountPage';
import SettingsAboutPage from './SettingsAboutPage';
import SettingsHelpPage from './SettingsHelpPage';
import { Page } from 'Constants/constants';
import SettingsChangePassword from './SettingsChangePassword';
import SettingsYourDataPage from './SettingsYourDataPage';
import DashboardSettingsWrapper from './DashboardSettingsWrapper';
import SettingsSoftwareVersion from './SettingsSoftwareVersion';


export default function DashboardContainer() {
    return (
        <DashboardSettingsWrapper>
            <Switch>
                <Route path={Page.SETTINGS} exact component={SettingsPage} />
                <Route path="/dashboard/settings/account" exact component={SettingsAccountPage} />
                <Route path={Page.CHANGE_PASSWORD} exact component={SettingsChangePassword} />
                <Route path="/dashboard/settings/about" exact component={SettingsAboutPage} />
                <Route path={Page.SOFTWARE_VERSION} exact component={SettingsSoftwareVersion} />
                <Route path="/dashboard/settings/data" exact component={SettingsYourDataPage} />
                <Route path="/dashboard/settings/help" exact component={SettingsHelpPage} />
            </Switch>
        </DashboardSettingsWrapper>
    );
}