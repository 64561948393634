import React from 'react';
import styled from 'styled-components';
import { Field, useField } from 'formik';
import { Grid, Typography } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { RoundedButton } from 'Components/Styles/Buttons/RoundedButton';
import { useTranslation, Trans } from 'react-i18next';
import DeclineSection from './DeclineSection';
import {MinorErrorMessage} from 'Components/Styles/Form/ErrorMessage';

const ApprovalButton = styled(RoundedButton)`
    min-height: 0;
    padding: 0 0.5em;
`; 


const ApprovedButton = styled(ApprovalButton)`
    color: #fff;
    background-color: rgb(46, 199, 222);
    border: 2px solid rgb(46, 199, 222);

    &:hover {
        background-color: rgb(23, 99, 111);
        border: 2px solid rgb(23, 99, 111);
    }
`; 

const DeclinedButton = styled(ApprovalButton)`
    color: #fff;
    background-color: rgb(223, 21, 66);
    border: 2px solid rgb(223, 21, 66);

    &:hover {
        background-color: rgb(112, 10, 33);
        border: 2px solid rgb(112, 10, 33);
    }
`; 

export default function ApprovalOptions({title, name}) {
    return (
        <Grid container direction="column" spacing={2}>
            <Grid item>
                <Typography variant="h6">
                    <Trans i18nKey='studyApproval.isSectionReady'>
                        {{title}}
                    </Trans>
                </Typography>
            </Grid>
            <ApprovalButtons name={name}/>
        </Grid>
    );
}

function ApprovalButtons({name}) {
    const [field] = useField(name);
    const state = field.value.approved;

    function handleClick(value, field, form) {
        form.setFieldValue(field.name, value);
    }

    return (
        <Grid item container spacing={2}>
            <Field 
                name={`${name}.approved`} 
                state={state} 
                handleClick={handleClick} 
                component={ButtonSection}
            />
            <Grid item xs={12}>
                <MinorErrorMessage name={`${name}.approved`}/>
            </Grid>
            {state === "declined" &&
                <Field name={name} component={DeclineSection}/>
            }
        </Grid>
    );
}

function ButtonSection({field, form, handleClick}) {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <Grid item>
                {field.value === "approved"
                    ? <ApprovedButton variant="contained" endIcon={<CheckCircleOutlineIcon/>} onClick={() => handleClick("", field, form)}>{t("studyApproval.approved")}</ApprovedButton>
                    : <ApprovalButton variant="outlined" endIcon={<CheckCircleOutlineIcon/>} onClick={() => handleClick("approved", field, form)}>{t("studyApproval.approve")}</ApprovalButton>
                }
            </Grid>
            <Grid item>
                {field.value === "declined"
                    ? <DeclinedButton variant="contained" endIcon={<HighlightOffIcon/>} onClick={() => handleClick("", field, form)}>{t("studyApproval.declined")}</DeclinedButton>
                    : <ApprovalButton variant="outlined" endIcon={<HighlightOffIcon/>} onClick={() => handleClick("declined", field, form)}>{t("studyApproval.decline")}</ApprovalButton>
                }
            </Grid>
        </React.Fragment>
    );
}