import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useField } from 'formik';
import QuestionChoice from './QuestionChoice';
import QuestionString from './QuestionString';
import { useTranslation } from 'react-i18next';

export default function QuestionOpenChoice({name, clientSelection, prefix, repeats}) {
    const { t } = useTranslation();
    const [field] = useField(name);
    return (
        <Grid container direction="column" spacing={2}>
            <Grid item>
                <QuestionChoice name={`${name}.select`} clientSelection={clientSelection} repeats={repeats}/>
            </Grid>
            {(field.value.text || field.value.select?.id === "other" || (field.value.select?.length > 0 && field.value.select?.find(val => val.select.id === "other"))) &&
                <Grid item>
                    <Typography>{t('questionnaire.specify')}</Typography>
                    <QuestionString name={`${name}.text`} prefix={t(prefix)}/>
                </Grid>
            }
        </Grid>
    );
}