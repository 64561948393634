import React, { useState } from 'react';
import { InputBase } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';

import {
    closeNoteModal,
    updateParticipantNote,
} from 'redux/reducers/myStudy/actions';
import MobileModal from 'Components/Parts/Modals/MobileModal';


export default function MyStudyNoteModal() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [note, setNote] = useState(null);
    const noteModalData = useSelector(state => state.myStudy.noteModal);

    const handleNoteModalClose = async () => {
        if (note !== null) {
            await dispatch(updateParticipantNote(noteModalData.participant, note));
        }
        dispatch(closeNoteModal());
    };

    return (
        <MobileModal
            title={t('myStudyPage.addNoteTitle')}
            icon={<ChatBubbleOutlineIcon color="primary" />}
            open={noteModalData.isOpen}
            onClose={handleNoteModalClose}
        >
            <InputBase
                autoFocus
                defaultValue={noteModalData.participant?.note}
                fullWidth
                multiline
                onChange={e => setNote(e.target.value)}
                rowsMax={8}
                rows={4}
            />
        </MobileModal>
    );
}
