import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import { Checkbox } from 'formik-material-ui';
import * as Yup from 'yup';
import { Grid, Typography, FormControlLabel, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { RoundedButton } from 'Components/Styles/Buttons/RoundedButton';
import { TextBox, PasswordTextBox } from 'Components/Parts/Form/TextBoxes';
import AuthPageLayout, { CentredGridItem, AuthPageLink } from 'Components/Layouts/Authorization/AuthPageLayout';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import useLocalStorage from 'Components/Parts/Hooks/useLocalStorage';
import { login } from 'redux/reducers/authorization/actions';
import { setLoginUsername } from 'redux/reducers/loginInformation/actions';
import { UserType, Page } from 'Constants/constants';
import MobileModal from 'Components/Parts/Modals/MobileModal';
import AccountCreationDesktopTemplate from 'Components/Parts/Registration/AccountCreationDesktopTemplate';
import MobileModalPage from 'Components/Parts/Modals/Components/MobileModalPage';
import { ModalCardTitle } from 'Components/Styles/Typography/Heading'
import getWebsiteLink from 'utilities/getWebsiteLink';


const BottomActions = styled(CentredGridItem)`
    margin-top: 20px;
`;

const SignInButton = styled(RoundedButton)`
    display: block;
    margin: 28px 0;
`;

function LoginForm({ children }) {
    const history = useHistory();
    const theme = useTheme();
    const mdUp = useMediaQuery(theme.breakpoints.up('md'));

    if (mdUp) {
        return (
            <AccountCreationDesktopTemplate>
                <Form>
                    {children}
                </Form>
            </AccountCreationDesktopTemplate>
        );
    }

    return (
        <MobileModal
            open={true}
            onClose={() => window.location.href = getWebsiteLink('/')}
        >
            <MobileModalPage>
                <Form>
                    {children}
                </Form>
            </MobileModalPage>
        </MobileModal>
    );
}

export default function SignInPage() {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const { t } = useTranslation();
    const [savedEmail, setSavedEmail] = useLocalStorage("savedEmail", "");
    const [loginError, setLoginError] = React.useState(false);    
    const [loginErrorMessage, setLoginErrorMessage] = React.useState("");
    
    function handleSubmit({rememberMe, email, password}) {
        return dispatch(login(email, password, rememberMe, (lastLoggedInAt, accountType) => handleSuccess(rememberMe, email, accountType, lastLoggedInAt), handleError));
    }

    function handleError({failureCode}) {
        setLoginError(true);

        switch (failureCode) {
            case "USERNAME_OR_PASSWORD_IS_INCORRECT":
                setLoginErrorMessage(t('errorMessage.usernamePasswordMismatch'));
                break;
            case "UNVERIFIED_ACCOUNT":
                setLoginErrorMessage(t('errorMessage.unverifiedAccount'));
                break;
            case "ARCHIVED_ACCOUNT":
                setLoginErrorMessage(t('errorMessage.archivedAccount'));
                break;
            default:
                setLoginErrorMessage(t('errorMessage.unknownSignInFailure'));
        }
    }

    function handleSuccess(rememberMe, email, accountType, lastLoggedInAt) {
        if (rememberMe) {
            setSavedEmail(email);
        } else {
            setSavedEmail("");
        }

        const state = {
            firstLogin: !lastLoggedInAt && accountType === UserType.PATIENT
        };
        const url = location.state?.sourceUrl
            ? location.state.sourceUrl
            : (lastLoggedInAt 
                ? Page.DASHBOARD
                : Page.EXPLORE);
        
        history.push({
            pathname: url,
            search: location.state?.sourceQuery,
            state
        });
    }

    // TODO replace ConnecT1D typography with official product image
    return (
        <AuthPageLayout>
            <Formik
                initialValues={{
                    email: savedEmail,
                    password: "",
                    rememberMe: !!savedEmail
                }}
                validationSchema={Yup.object({
                    email: Yup.string()
                        .email(t('errorMessage.invalidEmail'))
                        .required(t('errorMessage.requiredField')),
                    password: Yup.string()
                        .required(t('errorMessage.requiredField')),
                })}
                onSubmit={handleSubmit}>
                { ({isValid, isSubmitting, dirty, values}) => 
                <LoginForm>
                    <Grid container spacing={2}>
                        <CentredGridItem item xs={12}>
                            <ModalCardTitle variant="h3" color="primary">{t('signIn.title')}</ModalCardTitle>
                        </CentredGridItem>
                        <Grid item xs={12}>
                            <Field
                                component={TextBox} 
                                name="email" 
                                type="email"
                                label={t('signIn.emailLabel')}
                                error={loginError}
                                InputProps={{ onBlur: () => dispatch(setLoginUsername(values.email)) }}
                            />
                        </Grid>
                        <Grid container item spacing={0}>
                            <Grid item xs={12}>
                                <Field
                                    component={PasswordTextBox}
                                    name="password"
                                />
                            </Grid>
                            <Grid item container xs={12}>
                                {loginError && <Typography align="left" color="error"><ErrorOutlineIcon fontSize="small"/>{loginErrorMessage}</Typography>}
                            </Grid>
                        </Grid>
                        <Grid item container>
                            <FormControlLabel
                                control={<Field
                                            type="checkbox"
                                            component={Checkbox}
                                            color="primary" 
                                            name="rememberMe"
                                        />}
                                label={t('signIn.rememberMeLabel')}
                            />
                        </Grid>
                    </Grid>
                    <BottomActions container justify="center">
                        <Grid item xs={12}>
                            <AuthPageLink color="primary" to="/forgot">{t('signIn.forgotPasswordLinkText')}</AuthPageLink>
                        </Grid>
                        <Grid container item>
                            <SignInButton
                                variant="contained"
                                color="primary"
                                type="submit"
                                disabled={!isValid || !dirty || isSubmitting}
                                fullWidth
                            >
                                {t('signIn.signInButtonText')}
                            </SignInButton>
                        </Grid>
                        <Grid container item xs={12} alignItems="center" justify="center">
                            <Typography style={{ display: "inline-block"}}>{t('signIn.signUpText')}</Typography>
                            <AuthPageLink to="/signup">{t('signIn.signUpLinkText')}</AuthPageLink>
                        </Grid>
                    </BottomActions>
                </LoginForm>}
            </Formik>
        </AuthPageLayout>
    );
}