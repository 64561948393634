import React from 'react';
import { Grid } from '@material-ui/core';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import {useDropzone} from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { ImageDropzoneContainer } from 'Components/Styles/Form/Dropzone';
import { DropzoneDefaultContent } from './DropzoneComponents';
import { handleDrop } from './DropzoneComponents';
import { RoundedButton } from 'Components/Styles/Buttons/RoundedButton';
import { MinorErrorMessage } from 'Components/Styles/Form/ErrorMessage';
import { useSelector } from 'react-redux';
import getStudyImageRequest from 'api/study/getStudyImageAsBlob';

function DropzoneArea({image, fileTypes, maxFileSize, handleDrop, title, description, explanation}) {
    const [preview, setPreview] = React.useState();
    React.useEffect(() => {
        URL.revokeObjectURL(preview);
        if (image) {
            setPreview(URL.createObjectURL(image));
        } else {
            setPreview(null);
        }
    }, [image]);

    const {getRootProps, getInputProps, open, isDragActive, isDragAccept, isDragReject} = useDropzone({
        // Disable click and keydown behavior
        noClick: true,
        noKeyboard: true,
        accept: fileTypes,
        maxSize: maxFileSize,
        onDrop: acceptedFiles => {
            handleDrop(acceptedFiles[0]);
        }
    });

    return (
        <ImageDropzoneContainer {...getRootProps({isDragActive, isDragAccept, isDragReject})} preview={preview}>
            <input {...getInputProps()} />
            {!preview && <DropzoneDefaultContent open={open} title={title} description={description} explanation={explanation}/>}
        </ImageDropzoneContainer>
    );
}

export default function ImageDropzone({field, form, maxFileSize, title, description, explanation}) {
    const { t } = useTranslation();
    const { studyId, draftLoaded } = useSelector(state => state.studyCreation);

    React.useEffect(() => {
        if (draftLoaded && field.value === undefined) {
            getStudyImageRequest(studyId, (image) => form.setFieldValue(field.name, image));
        }
    }, [studyId, draftLoaded]);

    return (
        <React.Fragment>
            <Grid container justify="center" spacing={2}>
                <Grid item xs={12}>
                    <DropzoneArea
                        fileTypes="image/jpeg, image/png, image/gif"
                        maxFileSize={maxFileSize}
                        handleDrop={(files) => handleDrop(field, form, files)}
                        image={field.value}
                        title={title} 
                        description={description} 
                        explanation={explanation}
                    />
                </Grid>
                <Grid item>
                    {field.value && <RoundedButton 
                        variant="outlined" 
                        color="primary" 
                        endIcon={<NotInterestedIcon/>} 
                        onClick={() => form.setFieldValue(field.name, null)}
                    >
                        {t('dropzone.clearImageButtonText')}
                    </RoundedButton>}
                </Grid>
            </Grid>
            <MinorErrorMessage name={field.name}/>
        </React.Fragment>
    );
}