import produce from "immer";
import * as Actions from "./actions";

const initialState = {
    approved: false,
    rejected: false
};

export default (state = initialState, action) =>
    produce(state, draft => {
        const { type } = action;
        switch(type) {
            case Actions.SET_APPROVAL_SUCCESS:
                draft.approved = true;
                break;
            case Actions.SET_REJECTION_SUCCESS:
                draft.rejected = true;
                break;
            default:
                return draft;
        }
    });