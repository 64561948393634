import React from 'react';
import styled from 'styled-components';
import { Button, Grid, Menu, MenuItem, SvgIcon, FormControlLabel, Checkbox, Divider, Typography } from '@material-ui/core';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { useTranslation } from 'react-i18next';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { ReactComponent as FunnelLineIcon } from 'resources/images/icons/funnelLine.svg';
import { LightRoundedButton } from 'Components/Styles/Buttons/LightRoundedButton';
import { chipValues } from 'data/study/studyCategoryValues';
import { useDispatch, useSelector } from 'react-redux';
import { setEnglishFilter, setFilters, setFrenchFilter } from 'redux/reducers/dashboard/actions';
import { useLocation } from 'react-router-dom';
import { FilterChip } from 'Components/Styles/Dashboard/DashboardFilterChip';


const FilterMenuTitle = styled(Typography)`
    font-size: 18px;
    font-weight: bold;
    color: rgb(0, 0, 0, 0.45);
    padding-left: 12px;
    padding-top: 8px;
`;

export default function FilterMenu() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { pathname: page} = useLocation();
    const { filters, filterEnglish, filterFrench } = useSelector(state => state.dashboard.filters[page]) ?? {};
    const [values, setValues] = React.useState(filters ?? [])
    const [showEnglish, setShowEnglish] = React.useState(!!filterEnglish);
    const [showFrench, setShowFrench] = React.useState(!!filterFrench);

    function handleChange(id) {
        const index = values.indexOf(id);
        if ( index < 0 ) {
            setValues([...values, id]);
        } else {
            setValues(values.filter(item => item !== id));
        }
    }

    function handleDelete(id) {
        setValues(values.filter(item => item !== id));
        dispatch(setFilters(filters.filter(filter => filter !== id), page));
    }

    function handleApply(close) {
        close();
        dispatch(setEnglishFilter(showEnglish, page));
        dispatch(setFrenchFilter(showFrench, page));
        dispatch(setFilters(values, page));
    }

    return (
        <PopupState variant="popover" popupId="dashboard-filter-popup-menu">
        {(popupState) => (
            <Grid container spacing={1} alignItems="center">
                <Grid item>
                    <LightRoundedButton variant="outlined" size="small" color="primary" endIcon={<SvgIcon component={FunnelLineIcon}/>} {...bindTrigger(popupState)}>
                        {t("dashboardMenu.filter")}
                    </LightRoundedButton>
                    <Menu {...bindMenu(popupState)}>
                        <FilterMenuTitle>{t("dashboardMenu.filterByLanguage")}</FilterMenuTitle>
                        <MenuItem key="filter-english">
                            <FormControlLabel 
                                label={t("changeLangaugeModal.language.englishLabel")}
                                control={<Checkbox 
                                    name="en-CA"
                                    checked={showEnglish}
                                    onChange={_ => setShowEnglish(!showEnglish)}
                                    color="primary"
                                />}
                            />
                        </MenuItem>
                        <MenuItem key="filter-french">
                            <FormControlLabel 
                                label={t("changeLangaugeModal.language.frenchLabel")}
                                control={<Checkbox 
                                    name="fr-CA"
                                    checked={showFrench}
                                    onChange={_ => setShowFrench(!showFrench)}
                                    color="primary"
                                />}
                            />
                        </MenuItem>
                        <Divider/>
                        <FilterMenuTitle>{t("dashboardMenu.filterByCategory")}</FilterMenuTitle>
                        {chipValues.map(({id, getLabel}) => (
                            <MenuItem key={id}>
                                <FormControlLabel 
                                    label={getLabel(t)}
                                    control={<Checkbox 
                                        name={id}
                                        checked={values.indexOf(id) >= 0}
                                        onChange={() => handleChange(id)}
                                        color="primary"
                                    />}
                                />
                            </MenuItem>
                        ))}
                        <Divider/>
                        <Grid container justify="space-between">
                            <Button color="primary" disabled={values.length === 0} onClick={() => setValues([])}>{t("dashboardMenu.reset")}</Button>
                            <Button color="primary" onClick={() => handleApply(popupState.close)}>{t("dashboardMenu.apply")}</Button>
                        </Grid>
                    </Menu>
                </Grid>
                {filters.map((filter, i) => (
                    <Grid item key={i}>
                        <FilterChip 
                            color="primary"
                            clickable
                            onClick={() => handleDelete(filter)}
                            onDelete={() => handleDelete(filter)}
                            deleteIcon={<HighlightOffIcon/>}
                            label={chipValues.find(({id}) => id === filter).getLabel(t)}
                        />
                    </Grid>
                ))}
            </Grid>
        )}
        </PopupState>
    );
}