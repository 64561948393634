import React from 'react';
import { Grid, Typography, SvgIcon } from '@material-ui/core';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PencilFillIcon } from 'resources/images/icons/study/pencilFill.svg';
import { useHistory } from 'react-router';
import { RoundedButton } from 'Components/Styles/Buttons/RoundedButton';
import LastSavedTime from './Navigation/LastSavedTime';


const IconWrapper = styled.div`
    margin-right: 16px;
`;

const StudySectionTitle = styled(Typography)`
    font-family: Varela Round;
    font-size: ${props => props.size === "small" ? "18px" : "24px"};
    line-height: 1.25;
`;

export default function StudyHeader({ title, subtitle, icon, buttonId, size }) {
    const { t } = useTranslation();
    const history = useHistory();

    function getFontSize() {
        switch (size) {
            case "small":
                return '24px';
            default:
                return '30px';
        }
    }

    return (
        <Grid container>
            <Grid item container justify="space-between" xs={12}>
                <Grid item container xs>
                    <IconWrapper>
                        <SvgIcon component={icon} style={{fontSize: getFontSize()}} color="primary"/>
                    </IconWrapper>
                    <StudySectionTitle size={size} color="primary" display="inline">{title}</StudySectionTitle>
                </Grid>
                <Grid item>
                    {buttonId 
                        ?   <RoundedButton
                                variant="outlined"
                                color="primary"
                                endIcon={<PencilFillIcon/>}
                                onClick={() => history.push(`/setup/${buttonId-1}`)}
                                size="small"
                            >
                                {t('studySetupReview.editButtonText')}
                            </RoundedButton>
                        :   size !== "small" && <LastSavedTime/>
                    }
                </Grid>
            </Grid>
            <Typography variant="subtitle1">{subtitle}</Typography>
        </Grid>
    );
}