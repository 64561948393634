import { axiosInstance } from '../api';

export default function(studyId, handleSuccess) {
    return axiosInstance.get(`/api/study/${studyId}/image`, { responseType: 'blob'})
        .then(function(response) {
            const imageFile = new File([response.data], studyId);
            handleSuccess(imageFile);
        }).catch(function(error) {
            // no need to do anything here for now
        });
}