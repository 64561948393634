import React from 'react';
import SelectEligibilityContainer from '../SelectEligibilityContainer';
import { insulinDeviceDurationSelection } from 'data/study/Eligibility/eligibilitySelectionOptions';


const name="eligibility.insulin.duration";

// Register all the required translation keys
const t = _ => {};
t('studyEligibilityComparitivePhrase.insulinDuration.include.option');
t('studyEligibilityComparitivePhrase.insulinDuration.exclude.option');
t('studyEligibilityComparitivePhrase.insulinDuration.undefined.option');

export default function StudyEligibilityInsulinDuration({title}) {
    return (
        <SelectEligibilityContainer
            criteria="insulinDuration"
            shareTranslationId
            name={name}
            options={insulinDeviceDurationSelection} 
            title={title}
        />
    );
}