import React from 'react';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';

const ProgressWrapper = styled.div`
    margin: ${props => props.theme.spacing(1)};
    position: relative;
`;

const ProgressOverlay = styled(CircularProgress)`
    position: absolute;
    top: 25%;
    left: 40%;
`;

export default function LocalProgressOverlay({waiting, children}) {
    return (
        <ProgressWrapper>
            {children}
            {waiting && <ProgressOverlay size={24} color="primary"/>}
        </ProgressWrapper>
    );
}