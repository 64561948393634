import approveStudyRequest from 'api/studyReview/approveStudy';
import rejectStudyRequest from 'api/studyReview/rejectStudy';

import { enqueueGlobalUserMessageState } from '../globalUserMessaging';
import convertStudyApprovalToServerRequest from 'data/apiRequests/convertStudyApprovalToServerRequest';
import { setSaving, unsetSaving } from '../serverCommunication/actions';

export const SET_APPROVAL_SUCCESS = 'SET_APPROVAL_SUCCESS';
export const setApprovalSuccess = {
    type: SET_APPROVAL_SUCCESS
}

export const SET_REJECTION_SUCCESS = 'SET_REJECTION_SUCCESS';
export const setRejectionSuccess = {
    type: SET_REJECTION_SUCCESS
}

export function approveStudy(studyId) {
    return function(dispatch) {
        dispatch(setSaving);
        return approveStudyRequest(studyId)
            .then(function() {
                dispatch(setApprovalSuccess);
            }).catch(function() {
                dispatch(enqueueGlobalUserMessageState(
                    "error",
                    "error.submitFailed"
                ));
            }).finally(function() {
                dispatch(unsetSaving);
            });
    }
}

export function rejectStudy(studyId, values) {
    return function(dispatch) {
        const request = convertStudyApprovalToServerRequest(studyId, values);

        dispatch(setSaving);
        return rejectStudyRequest(request)
            .then(function() {
                dispatch(setRejectionSuccess);
            }).catch(function() {
                dispatch(enqueueGlobalUserMessageState(
                    "error",
                    "error.submitFailed"
                ));
            }).finally(function() {
                dispatch(unsetSaving);
            });
    }
}