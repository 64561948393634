import React from 'react';
import styled from 'styled-components';
import { Typography, Grid } from '@material-ui/core';
import { TabList, TabListItem, TabSelectedItem } from 'Components/Styles/Form/Tabs';

const TabLabel = styled(Typography)`
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 2.29;
`;

export default function TabbedGroup({values, components}) {
    const [activeTab, setActiveTab] = React.useState(values[0]);

    const handleChange = newTab => {
        setActiveTab(newTab);
    };

    function Component() {
        const { component } = components.find((item) => item.id === activeTab.id);

        return component;
    }

    return (
        <Grid container direction="column">
            <Grid item>
                <Tabs
                    activeTab={activeTab}
                >
                    {values.map((_, i) =>
                        <Tab 
                            key={i}
                            activeTab={activeTab}
                            tabData={values[i]}
                            handleChange={handleChange}
                        />
                    )}
                </Tabs>
            </Grid>
            <Grid item>
                <Component/>
            </Grid>
        </Grid>
    );
}

function Tabs({children}) {
    return (
        <TabList>
            {children}
        </TabList>
    );
}

function Tab({activeTab, tabData, handleChange}) {
    const { id, title } = tabData;
    if (id === activeTab.id) {
        return <TabListItem><TabSelectedItem color="primary" label={title}/></TabListItem>;
    }

    return <TabListItem onClick={() => handleChange(tabData)}><TabLabel color="primary">{title}</TabLabel></TabListItem>;
}