import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { LinearProgress, withStyles } from '@material-ui/core';

const QuestionProgress = withStyles((theme) => ({
    root: {
      height: 16,
      borderRadius: 12,
      width: '80%',
    },
    colorPrimary: {
      backgroundColor: theme.palette.grey[300],
    },
    bar: {
      borderRadius: 5,
    },
  }))(LinearProgress);

const ProgressDiv = styled.div`
    display: flex;
    flex-grow: 1;
    justify-content: center;
`;

export default function QuestionnaireProgressBar() {
    const { currentQuestion, totalQuestions } = useSelector(state => state.questionnaires);

    return (
        <ProgressDiv>
            <QuestionProgress variant="determinate" value={(currentQuestion/totalQuestions) * 100}/>
        </ProgressDiv>
    );
}