import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import globalUserMessaging from './reducers/globalUserMessaging/globalUserMessagingReducer';
import accountCreation from './reducers/accountCreation/accountCreationReducer';
import authorization from './reducers/authorization/authorizationReducer';
import loginInformation from './reducers/loginInformation/loginInformationReducer';
import eligibilityTracking from './reducers/eligibilityTracking/eligibilityTrackingReducer';
import studyCreation from './reducers/studyCreation/studyCreationReducer';
import serverCommunication from './reducers/serverCommunication/serverCommunicationReducer';
import dashboard from './reducers/dashboard/dashboardReducer';
import studyApproval from './reducers/studyApproval/studyApprovalReducer';
import myStudy from './reducers/myStudy/myStudyReducer';
import questionnaires from './reducers/questionnaires/questionnairesReducer';
import userEligibility from './reducers/userEligibility/userEligibilityReducer';
import appInfo from './reducers/appInfo/appInfoReducer';

  const composeEnhancers = (process.env.REACT_APP_STAGE === "local" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
  const enhancer = composeEnhancers(applyMiddleware(thunk));
  const store = createStore(combineReducers({
    // we can put some other reducers here
    globalUserMessaging,
    accountCreation,
    authorization,
    loginInformation,
    eligibilityTracking,
    studyCreation,
    serverCommunication,
    dashboard,
    studyApproval,
    myStudy,
    questionnaires,
    userEligibility,
    appInfo,
  }), enhancer);

  export default store;