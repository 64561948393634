import React from 'react';
import {
    Switch,
    Typography,
} from '@material-ui/core';

import ListBaseRow from './ListBaseRow';


export default function ListSwitchRow({ icon, text, detailText, onText, offText, onChange, isOn }) {
    const descriptiveText = isOn ? onText : offText;
    return (
        <ListBaseRow
            icon={icon}
            text={text}
            detailText={detailText}
            actionComponent={
                <React.Fragment>
                    {descriptiveText && 
                        <Typography display="inline">
                            {descriptiveText}
                        </Typography>
                    }
                    <Switch
                        color="primary"
                        edge="end"
                        onChange={onChange}
                        checked={isOn}
                    />
                </React.Fragment>
            }
            interactiveAction
        />
    );
}