import React from 'react';
import { ErrorMessage as FormikErrorMessage} from 'formik';
import styled from 'styled-components';
import { Grid, Typography, SvgIcon } from '@material-ui/core';
import { ReactComponent as TriangleWarningIcon } from 'resources/images/icons/alertTriangleOutline.svg';

const ErrorWrapper = styled.div`
    padding-left: 0.8em;
`;

export function MinorErrorMessage({name}) {
    return (
        <FormikErrorMessage name={name}>
            {msg => <ErrorWrapper>
                <Typography variant="body2" color="error">
                    {msg}
                </Typography>
            </ErrorWrapper>}
        </FormikErrorMessage>
    );
}

export function MajorErrorMessage({name}) {
    return (
        <FormikErrorMessage name={name}>
            {msg => 
                <MajorErrorMessageContent message={msg}/>
            }
        </FormikErrorMessage>
    );
}

export function MajorErrorMessageContent({message}) {
    return (
        <Grid container item spacing={1}>
            <Grid item>
                <SvgIcon component={TriangleWarningIcon} viewBox="0 0 8 8" htmlColor="#ff0000" />
            </Grid>
            <Grid item>
                <Typography color="error" display="inline">{message}</Typography>
            </Grid>
        </Grid>
    );
}