import React from 'react';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import CardDetailTextValue from 'Components/Parts/Study/StudyReview/CardDetailTextValue';

const placesService = { current: null };

export default function LocationAutocompleteSelector({option, onFetchAction}) {
    const matches = option.structured_formatting.main_text_matched_substrings;
    const parts = parse(
        option.structured_formatting.main_text,
        matches.map(match => [match.offset, match.offset + match.length]),
    );

    const fetch = React.useMemo(
        () =>
        throttle((input, callback) => {
            placesService.current.getDetails(input, callback);
        }, 200),
        [],
    );

    function handleClick() {
        if (!placesService.current && window.google) {
            placesService.current = new window.google.maps.places.PlacesService(document.createElement('div'));
        }

        fetch({ placeId: option.place_id, fields: ['geometry'] }, result => {
            onFetchAction({
                address: option.description, 
                lat: result.geometry.location.lat(), 
                lon: result.geometry.location.lng()
            });
        });
    }

    return (
        <Grid container alignItems="center" onClick={handleClick}>
            <Grid item>
                <LocationOnIcon />
            </Grid>
            <Grid item xs>
                {parts.map((part, index) => (
                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                    {part.text}
                    </span>
                ))}

                <CardDetailTextValue>
                    {option.structured_formatting.secondary_text}
                </CardDetailTextValue>
            </Grid>
        </Grid>
    );
}