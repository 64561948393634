import { axiosInstance } from 'api/api';
import getResearchStudyListRequest from 'api/fhir/getResearchStudyList';
import { setLoading, setStudyLoading, unsetLoading, unsetStudyLoading } from '../serverCommunication/actions';

import { enqueueGlobalUserMessageState } from '../globalUserMessaging';
import getInterestedResearchStudyList from 'api/fhir/getInterestedResearchStudyList';

export const SET_ENGLISH_FILTER = 'SET_ENGLISH_FILTER';
export function setEnglishFilter(value, page) {
    return {
        type: SET_ENGLISH_FILTER,
        payload: {
            value,
            page,
        },
    };
}

export const SET_FRENCH_FILTER = 'SET_FRENCH_FILTER';
export function setFrenchFilter(value, page) {
    return {
        type: SET_FRENCH_FILTER,
        payload: {
            value,
            page,
        },
    };
}

export const SET_FILTERS = 'SET_FILTERS';
export function setFilters(value, page) {
    return {
        type: SET_FILTERS,
        payload: {
            filters: value,
            page,
        },
    };
}

export const SET_SORT = 'SET_SORT';
export function setSort(value, page) {
    return {
        type: SET_SORT,
        payload: {
            sort: value,
            page,
        },
    };
}

export const OPEN_LANGUAGE_MODAL = 'OPEN_LANGUAGE_MODAL';
export function openLanguageModal() {
    return {
        type: OPEN_LANGUAGE_MODAL,
    };
}

export const CLOSE_LANGUAGE_MODAL = 'CLOSE_LANGUAGE_MODAL';
export function closeLanguageModal() {
    return {
        type: CLOSE_LANGUAGE_MODAL,
    };
}

export const OPEN_LOGOUT_MODAL = 'OPEN_LOGOUT_MODAL';
export function openLogoutModal() {
    return {
        type: OPEN_LOGOUT_MODAL,
    };
}

export const CLOSE_LOGOUT_MODAL = 'CLOSE_LOGOUT_MODAL';
export function closeLogoutModal() {
    return {
        type: CLOSE_LOGOUT_MODAL,
    };
}

export function getMyStudiesList(handleResponse) {
    return function(dispatch, getState) {
        const fhirId = getState().authorization.fhirId;
        dispatch(setLoading);
        return getInterestedResearchStudyList(fhirId)
            .then(function({data}) {
                handleResponse(data);
            }).catch(function() {
                dispatch(enqueueGlobalUserMessageState(
                    "error",
                    "error.studyRetrieval",
                    "dashboard"
                ));
            }).finally(function() {
                dispatch(unsetLoading);
            });
    };
}

export function getResearchStudyList(fhirId, status, order, filters, filterEnglish, filterFrench, count, handleResponse) {
    return function(dispatch) {
        dispatch(setLoading);
        return getResearchStudyListRequest({ fhirId, status, order, filters, filterEnglish, filterFrench, count })
        .then(function({data}) {
            handleResponse(data);
        }).catch(function({response}) {
            // show them an error only if they are still logged in
            if (response.status !== 401) {
                dispatch(enqueueGlobalUserMessageState(
                    "error",
                    "error.studyRetrieval",
                    "dashboard"
                ));
            }
        }).finally(function() {
            dispatch(unsetLoading);
        });
    };
}

export function getNextUrl(url, handleResponse) {
    return function(dispatch) {
        dispatch(setStudyLoading);
        return axiosInstance.get(url)
        .then(function({data}) {
            handleResponse(data);
        }).catch(function() {
            dispatch(enqueueGlobalUserMessageState(
                "error",
                "error.studyRetrieval",
                "dashboard"
            ));
        }).finally(function() {
            dispatch(unsetStudyLoading);
        });
    };
}