import produce from "immer";
import * as Actions from "./actions";

const initialState = {
    draftLoaded: false,
    lastSaveTime: undefined,
    currentStep: 1,
    completeList: [],
    errorList: [],
    studyId: null,
    fhirId: null,
    studyTeam: null,
    studyEligibility: null
};

export default (state = initialState, action) =>
    produce(state, draft => {
        const {type, payload} = action;
        switch(type) {
            case Actions.SET_LAST_SAVE_TIME:
                draft.lastSaveTime = payload.time;
                break;
            case Actions.SET_CURRENT_STEP:
                const stepAsInt = parseInt(payload.step);
                if (stepAsInt > 0) {
                    draft.currentStep = parseInt(payload.step);
                }
                break;
            case Actions.ADD_TO_COMPLETE_LIST:
                updateLists(draft.currentStep, draft.completeList, draft.errorList);
                break;
            case Actions.ADD_TO_ERROR_LIST:
                updateLists(draft.currentStep, draft.errorList, draft.completeList);
                break;
            case Actions.LOADED_DRAFT:
                draft.draftLoaded = true;
                break;
            case Actions.SET_DRAFT_STUDY_ID:
                draft.studyId = payload.studyId;
                break;
            case Actions.SET_DRAFT_FHIR_ID:
                draft.fhirId = payload.fhirId;
                break;
            case Actions.SET_DRAFT_STUDY_TEAM:
                draft.studyTeam = payload.studyTeam;
                break;
            case Actions.CLEAR_DRAFT_STUDY_TEAM:
                draft.studyTeam = null;
                break;
            case Actions.SET_DRAFT_STUDY_ELIGIBILITY:
                draft.studyEligibility = payload.eligibility;
                break;
            case Actions.CLEAR_DRAFT_STUDY_ELIGIBILITY:
                draft.studyEligibility = null;
                break;
            case Actions.RESET_STUDY_CREATION:
                draft.draftLoaded = false;
                draft.lastSaveTime = undefined;
                draft.currentStep = 1;
                draft.completeList = [];
                draft.errorList = [];
                draft.studyId = null;
                draft.fhirId = null;
                draft.studyTeam = null;
                draft.studyEligibility = null;
                break;
            default:
                return draft;
        }
    });

function updateLists(item, addList, removeList) {
    const addIndex = addList.indexOf(item);
    const removeIndex = removeList.indexOf(item);

    if (addIndex < 0) {
        addList.push(item);
    }
    if (removeIndex >= 0) {
        removeList.splice(removeIndex, 1);
    }
}