import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';

import { ThemeProvider } from 'styled-components';
import { StylesProvider, ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { I18nextProvider } from 'react-i18next';

import store from './redux/store';
import './App.css';
import SignInPage from 'Components/Views/AuthPages/SignInPage';
import ForgotYourPassword from 'Components/Views/AuthPages/ForgotYourPassword';
import UserRegistration from 'Components/Parts/Registration/UserRegistration';
import ResetPassword from 'Components/Views/AuthPages/ResetPassword';
import AccountCreationContainer from 'Components/Views/AuthPages/AccountCreation/AccountCreationContainer';
import ResearcherWizardContainer from 'Components/Views/ResearcherWizardPages/ResearcherWizardContainer';
import DashboardContainer from 'Components/Views/Dashboard/DashboardContainer';
import ReviewPage from 'Components/Views/StudyApproval/ReviewPage';
import AuthorizedRoute from 'Components/Parts/Routing/AuthorizedRoute';
import { UserType, Page } from 'Constants/constants';
import { getMainTheme } from 'Theme';
import i18n from 'translations/i18n';
import StudyPage from 'Components/Views/StudyDetails/StudyPage';
import QuestionnaireContainer from 'Components/Views/Questionnaires/QuestionnaireContainer';
import GlobalMessagingProvider from 'Components/Parts/Messaging/GlobalMessagingProvider';
import GlobalMessageDisplay from 'Components/Parts/Messaging/GlobalMessageDisplay';
import PageScrollController from 'Components/Parts/Routing/PageScrollController';
import UnauthorizedRouteOnly from 'Components/Parts/Routing/UnauthorizedRouteOnly';


const theme = getMainTheme();

function App() {
    return (
        <I18nextProvider i18n={i18n}>
            <Provider store={ store }>
                <StylesProvider injectFirst>
                    <MuiThemeProvider theme={theme}>
                        <ThemeProvider theme={theme}>
                            <GlobalMessagingProvider>
                                <CssBaseline/>
                                <Router>
                                    <PageScrollController />
                                    <Switch>
                                        <UnauthorizedRouteOnly path="/signup" component={AccountCreationContainer}/>
                                        <UnauthorizedRouteOnly path="/signin" component={SignInPage}/>
                                        <Route path="/registered" component={UserRegistration}/>
                                        <UnauthorizedRouteOnly path="/forgot" component={ForgotYourPassword}/>
                                        <UnauthorizedRouteOnly path="/reset" component={ResetPassword}/>
                                        <AuthorizedRoute path="/study/:studyId" component={StudyPage} />
                                        <AuthorizedRoute path="/review" component={ReviewPage} requiredCredentials={UserType.RESEARCHER}/>
                                        <AuthorizedRoute path="/setup" component={ResearcherWizardContainer} requiredCredentials={UserType.RESEARCHER}/>
                                        <AuthorizedRoute path={Page.MY_STUDY} component={StudyPage}/>
                                        <AuthorizedRoute path="/dashboard" component={DashboardContainer}/>
                                        <AuthorizedRoute path="/questionnaire" component={QuestionnaireContainer} requiredCredentials={UserType.PATIENT}/>
                                        <Route render={() => <Redirect to="/dashboard" />} />
                                    </Switch>
                                </Router>
                                <GlobalMessageDisplay/>
                            </GlobalMessagingProvider>
                        </ThemeProvider>
                    </MuiThemeProvider>
                </StylesProvider>
            </Provider>
        </I18nextProvider>
    );
}

export default App;
