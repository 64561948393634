import React from 'react';
import StudyEligibilityContainer from './StudyEligibilityContainer';
import { yesNoSelection } from 'data/study/Eligibility/eligibilitySelectionOptions';
import { eligibilityBaseItem } from 'data/study/WizardFormSetup/wizardFormInitialValues';

export default function YesNoStudyEligibilityContainer({name, criteria}) {
    return (
        <StudyEligibilityContainer
            criteria={criteria}
            name={name}
            options={yesNoSelection} 
            emptyValue={eligibilityBaseItem}
        />
    );
}