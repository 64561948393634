import { axiosInstance } from '../api';

export default function(patientFhirId, questionnaireName, status) {
    return axiosInstance.get(`/fhir/QuestionnaireResponse`, 
        {
            params: {
                subject: patientFhirId, 
                status,
                questionnaire: questionnaireName,
                _sort: "-_lastUpdated"
            }
        }
    );
}