export default function createQuestionnaireResponse(patientId, questionnaireResponseId, title, questions, values, status) {

    function buildCoding(value, valueSetId) {
        if (value) {
            return {
                valueCoding: {
                    system: valueSetId,
                    code: value.id ? value.id : value
                }
            };
        }
    }

    function getAnswerForValue(key, value) {
        const question = questions.find(question => question.linkId === key);
        switch (question.type) {
            case "integer":
                return {
                    valueInteger: value
                };
            case "decimal":
                return {
                    valueDecimal: value
                };
            case "string":
                return {
                    valueString: value
                };
            case "date":
                return {
                    valueDate: value
                };
            case "choice":
                return buildCoding(value, question.valueSetId);
            case "open-choice":
                return [
                    value.text && {
                        valueString: value.text
                    },
                    Array.isArray(value.select) 
                        ? value.select.map(val => buildCoding(val, question.valueSetId))
                        : buildCoding(value.select, question.valueSetId)
                ]
            default:
                return undefined;
        }
    }

    const items = Object.keys(values).map(key => {
        const value = values[key];
        if (!value 
            || (Array.isArray(value) && !value.some(Boolean))
            || ((value.text !== undefined && !value.text) && (value.select !== undefined && (!value.select || value.select.length === 0)))
            ) {
            return undefined;
        }

        let answer = [];
        if (Array.isArray(value)) {
            answer = value.map(val => (getAnswerForValue(key, val)));
        } else {
            answer = [getAnswerForValue(key, value)].flat();
        }
    
        return {
            linkId: key,
            answer: answer.filter(Boolean)
        };
    }).filter(Boolean);

    const questionnaireResponse = {
        resourceType: "QuestionnaireResponse",
        questionnaire: {
            reference: `Questionnaire/${title}`
        },
        author: {
            reference: `Patient/${patientId}`
        },
        subject: {
            reference: `Patient/${patientId}`
        },
        status,
        item: items
    }

    if (questionnaireResponseId) {
        questionnaireResponse.id = questionnaireResponseId;
    }

    return questionnaireResponse;
}