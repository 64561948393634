import React from 'react';
import { useDispatch } from 'react-redux';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';
import Autocomplete from '@material-ui/lab/Autocomplete';
import throttle from 'lodash/throttle';
import TeamMemberAutocompleteSelector from './TeamMemberAutocompleteSelector';
import { autocompleteResearcher } from 'redux/reducers/studyCreation/actions';
import { CustomTextField } from '../../TextBoxes/CustomInputs';

export default function TeamMemberAutocompleteBox({field, form}) {
    const name = field.name;
    const fieldValue = field.value;

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [options, setOptions] = React.useState([]);

    const fetch = React.useMemo(
        () =>
        throttle((input, callback) => {
            if (!!input) {
                dispatch(autocompleteResearcher(input, callback));
            }
        }, 600),
        [dispatch],
    );

    React.useEffect(() => {
        if (fieldValue.name === "") {
            setOptions([]);
            return undefined;
        }

        fetch(fieldValue.name, result => {
            if (result.total === 0) {
                setOptions([]);
            } else {
                const entries = result.entry;
                const selections = entries.map(entry => ({
                    name: entry.resource.name[0].given.join(" ") + " " + entry.resource.name[0].family,
                    email: entry.resource.telecom.find(contact => contact.system === "email").value
                }));
                setOptions(selections);
            }
        });
    }, [fieldValue, fetch]);

    function onFetchAction(value) {
        form.setFieldValue(name, value);
    }

    function handleClear(event, value, reason) {
        // when the user clicks the X button on this textbox to clear their text, they are sent here with reason "clear"
        if (reason === "clear") {
            form.setFieldValue(name, {
                name: "", 
                email: "", 
                signupComplete: false
            });
        }
    }

    return (
        <Autocomplete
            getOptionLabel={option => (typeof option === 'string' ? option : option.name)}
            filterOptions={x => x}
            options={options}
            onInputChange={handleClear}
            autoComplete
            includeInputInList
            freeSolo
            value={fieldValue.name}
            renderInput={params => (
                <Field
                    name={`${name}.name`}
                    component={CustomTextField}
                    {...params}
                    label={t('studySetupTeam.namePlaceholder')}
                    variant="filled"
                    fullWidth
                />
            )}
            renderOption={(option, {inputValue}) => {
                return (
                    <TeamMemberAutocompleteSelector option={option} inputValue={inputValue} onFetchAction={onFetchAction}/>
                );
            }}
        />
    );
}