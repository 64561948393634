import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { FluidList } from 'Components/Styles/Lists/FluidList';
import ListLinkRow from 'Components/Parts/Lists/ListLinkRow';
import { Page } from 'Constants/constants';
import { useSelector } from 'react-redux';
import getWebsiteLink from 'utilities/getWebsiteLink';


// TODO: When clicking on team, scroll to the team section of the home page
export default function () {
    const { t } = useTranslation();
    const history = useHistory();
    const accountType = useSelector(state => state.authorization.accountType);

    return (
        <FluidList>
            <ListLinkRow
                text={t('aboutSettingsPage.teamText')}
                href={getWebsiteLink('/#team-section')}
            />
            <ListLinkRow
                text={t('aboutSettingsPage.privacyPolicyText')}
                href={getWebsiteLink('/privacy', { type: accountType })}
            />
            <ListLinkRow
                text={t('aboutSettingsPage.termsText')}
                href={getWebsiteLink('/terms', { type: accountType })}
            />
            <ListLinkRow
                text={t('aboutSettingsPage.softwareVersionText')}
                onClick={() => history.push(Page.SOFTWARE_VERSION)}
                showArrow
            />
        </FluidList>
    );
}
