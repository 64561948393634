import React from 'react';
import { useTranslation } from 'react-i18next';
import NumericFieldEligibilityContainer,
{MinField, MaxField, RangeField} from '../NumericFieldEligibilityContainer';
import { a1cValueSelectionOptions } from 'data/study/Eligibility/eligibilitySelectionOptions';

const name="eligibility.a1c.value";

const getComponentOptions = t => {
    const minLabel = t('studyEligibilityA1c.minA1cLabel');
    const maxLabel = t('studyEligibilityA1c.maxA1cLabel');
    return Object.freeze([
        Object.freeze({id: "range", component: <RangeField name={name} minLabel={minLabel} maxLabel={maxLabel} floatingPoint/>}),
        Object.freeze({id: "less", component: <MaxField name={name} label={maxLabel} floatingPoint/>}),
        Object.freeze({id: "greater", component: <MinField name={name} label={minLabel} floatingPoint/>}),
    ]);
}

// Register all the required translation keys
const t = _ => {};
t('studyEligibilityComparitivePhrase.a1cValue.include.range');
t('studyEligibilityComparitivePhrase.a1cValue.include.less');
t('studyEligibilityComparitivePhrase.a1cValue.include.greater');
t('studyEligibilityComparitivePhrase.a1cValue.exclude.range');
t('studyEligibilityComparitivePhrase.a1cValue.exclude.less');
t('studyEligibilityComparitivePhrase.a1cValue.exclude.greater');
t('studyEligibilityComparitivePhrase.a1cValue.undefined.range');
t('studyEligibilityComparitivePhrase.a1cValue.undefined.less');
t('studyEligibilityComparitivePhrase.a1cValue.undefined.greater');

t('studyEligibilityReview.a1cValue.range');
t('studyEligibilityReview.a1cValue.less');
t('studyEligibilityReview.a1cValue.greater');

export default function StudyEligibilityA1cValue({title}) {
    const { t } = useTranslation();
    return (
        <NumericFieldEligibilityContainer
            criteria="a1cValue"
            name={name}
            title={title}
            answerOptions={a1cValueSelectionOptions}
            componentOptions={getComponentOptions(t)}
        />
    );
}