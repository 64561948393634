
export const tags = {
    contactForm: 'Support',
    mailingList: 'Mailing List',
    connect1d: 'Connect1d Canada',
    support: 'Support',
    accountCreationFeedback: 'Account Creation Feedback',
};

export const supportEmail = 'help@connect1d.ca';
export const groupId = 14000112824;
export const productId = 14000002896;
