import React from 'react';
import {
    SvgIcon,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ReactComponent as CrossCircleOutlineIcon } from 'resources/images/icons/crossCircledOutline.svg';
import { ModalPaper } from 'Components/Styles/Modals/ModalContainers';
import { ModalButton } from 'Components/Styles/Modals/Components';
import BaseModal from '../BaseModal';
import ModalHeader from '../ModalHeader';
import Theme from 'Theme';


const DeleteButton = styled(ModalButton)`
    background: ${Theme.palette.error.main};
    color: #fff;

    &:hover {
        background: ${Theme.palette.error.main};
    }
`;

function DeleteAccountModal({ shouldDelete, ...props }) {
    const { t } = useTranslation();

    return (
        <BaseModal
            aria-describedby="delete-account-modal-description"
            aria-labelledby="delete-account-modal-title"
            onClose={() => shouldDelete()}
            {...props}
        >
            <ModalPaper>
                <ModalHeader
                    icon={<SvgIcon component={CrossCircleOutlineIcon} htmlColor={Theme.palette.error.main} />}
                    title={t('deleteAccountModal.title')}
                    subtitle={t('deleteAccountModal.description')}
                />
                <ModalButton variant="outlined" size="small" onClick={() => shouldDelete(false)}>
                    {t('deleteAccountModal.cancelButtonText')}
                </ModalButton>
                <DeleteButton variant="contained" size="small" onClick={() => shouldDelete(true)}>
                    {t('deleteAccountModal.confirmButtonText')}
                </DeleteButton>
            </ModalPaper>
        </BaseModal>
    );
}

export default DeleteAccountModal;
