import produce from "immer";
import * as Actions from "./actions";

const initialState = {
    saving: false,
    loading: false,
    studyLoading: false
};

export default (state = initialState, action) =>
    produce(state, draft => {
        switch(action.type) {
            case Actions.SET_SAVING:
                draft.saving = true;
                break;
            case Actions.UNSET_SAVING:
                draft.saving = false;
                break;
            case Actions.SET_LOADING:
                draft.loading = true;
                break;
            case Actions.UNSET_LOADING:
                draft.loading = false;
                break;
            case Actions.SET_STUDY_LOADING:
                draft.studyLoading = true;
                break;
            case Actions.UNSET_STUDY_LOADING:
                draft.studyLoading = false;
                break;
            default:
                return draft;
        }
    });
            