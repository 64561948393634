import React from 'react';

import { ReactComponent as InfoOutlinedIcon } from 'resources/images/icons/study/infoOutline.svg';
import { ReactComponent as ClockOutlinedIcon } from 'resources/images/icons/study/clockOutline.svg';
import { ReactComponent as RoomOutlinedIcon } from 'resources/images/icons/study/pinOutline.svg';
import { ReactComponent as EventNoteIcon } from 'resources/images/icons/study/calendarOutline.svg';
import { ReactComponent as SecurityIcon } from 'resources/images/icons/study/shieldOutline.svg';
import { ReactComponent as RedeemOutlinedIcon } from 'resources/images/icons/study/giftOutline.svg';
import { ReactComponent as AwardOutlinedIcon } from 'resources/images/icons/study/awardOutlineIcon.svg';
import { ReactComponent as PersonOutlineOutlinedIcon } from 'resources/images/icons/study/personOutline.svg';
import { ReactComponent as PeopleAltOutlinedIcon } from 'resources/images/icons/study/peopleOutline.svg';
import { ReactComponent as MagnifyingGlassIcon } from 'resources/icons/magnifyingGlassIcon.svg';

import StudyInformation from 'Components/Views/ResearcherWizardPages/StudyCreation/StudyInformation';
import Timelines from 'Components/Views/ResearcherWizardPages/StudyCreation/StudyTimelines';
import Location from 'Components/Views/ResearcherWizardPages/StudyCreation/StudyLocation';
import Commitment from 'Components/Views/ResearcherWizardPages/StudyCreation/StudyCommitment';
import PersonalData from 'Components/Views/ResearcherWizardPages/StudyCreation/StudyPersonalData';
import Compensation from 'Components/Views/ResearcherWizardPages/StudyCreation/StudyCompensation';
import Eligibility from 'Components/Views/ResearcherWizardPages/StudyCreation/StudyEligibility';
import EthicsApproval from 'Components/Views/ResearcherWizardPages/StudyCreation/StudyEthicsApproval';
import StudyTeam from 'Components/Views/ResearcherWizardPages/StudyCreation/StudyTeam';
import EligibilityDetails from 'Components/Parts/Study/StudyReview/ReviewSectionCardDetails/EligibilityDetails';

export const sidebarItems = Object.freeze([
    Object.freeze({
        id: 1,
        key: 'studyInfo',
        getTitle: t => t('studySetupNav.studyInformationTitle'),
        getSubtitle: t => t('studySetupNav.studyInformationDescription'),
        icon: InfoOutlinedIcon,
        resource: <StudyInformation />
    }),
    Object.freeze({
        id: 2,
        key: 'ethicsApproval',
        getTitle: t => t('studySetupNav.ethicsApprovalTitle'),
        getSubtitle: t => t('studySetupNav.studyEthicsDescription'),
        icon: AwardOutlinedIcon,
        resource: <EthicsApproval />
    }),
    Object.freeze({
        id: 3,
        key: 'timelines',
        getTitle: t => t('studySetupNav.timelinesTitle'),
        getSubtitle: t => t('studySetupNav.studyTimelinesDescription'),
        icon: ClockOutlinedIcon,
        resource: <Timelines />
    }),
    Object.freeze({
        id: 4,
        key: 'location',
        getTitle: t => t('studySetupNav.locationTitle'),
        icon: RoomOutlinedIcon,
        resource: <Location />
    }),
    Object.freeze({
        id: 5,
        key: 'commitment',
        getTitle: t => t('studySetupNav.commitmentTitle'),
        getSubtitle: t => t('studySetupNav.studyCommitmentDescription'),
        icon: EventNoteIcon,
        resource: <Commitment />
    }),
    Object.freeze({
        id: 6,
        key: 'personalData',
        getTitle: t => t('studySetupNav.personalDataTitle'),
        getSubtitle: t => t('studySetupNav.studyPersonalDataDescription'),
        icon: SecurityIcon,
        resource: <PersonalData />
    }),
    Object.freeze({
        id: 7,
        key: 'compensation',
        getTitle: t => t('studySetupNav.compensationTitle'),
        getSubtitle: t => t('studySetupNav.studyCompensationDescription'),
        icon: RedeemOutlinedIcon,
        resource: <Compensation />
    }),
    Object.freeze({
        id: 8,
        key: 'eligibility',
        getTitle: t => t('studySetupNav.eligibilityTitle'),
        getSubtitle: t => t('studySetupNav.eligibilityDescription'),
        icon: PersonOutlineOutlinedIcon,
        resource: <Eligibility />,
        reviewResource: (value, meta) => <EligibilityDetails value={value} meta={meta}/>
    }),
    Object.freeze({
        id: 9,
        key: 'studyTeam',
        getTitle: t => t('studySetupNav.studyTeamTitle'),
        getSubtitle: t => t('studySetupNav.studyTeamDescription'),
        icon: PeopleAltOutlinedIcon,
        resource: <StudyTeam />
    }),
    Object.freeze({
        id: 10,
        key: 'review',
        getTitle: t => t('studySetupNav.reviewAndSubmitTitle'),
        getSubtitle: t => t('studySetupNav.reviewAndSubmitDescription'),
        icon: MagnifyingGlassIcon,
        isReview: true
    })
]);