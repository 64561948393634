import React from 'react';
import styled from 'styled-components';
import { Typography, Grid, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CheckmarkIcon } from 'resources/images/icons/partialOutlinedCheckIcon.svg';
import { useHistory } from 'react-router';
import { dismissBaseline } from 'redux/reducers/authorization/actions';
import { useDispatch } from 'react-redux';
import { FixedSizeIcon } from 'Components/Styles/Modals/Questionnaire';
import { TitleText, ModalButton } from 'Components/Styles/Modals/Components';
import NavbarTemplate from 'Components/Layouts/NavbarTemplate';
import { DashboardTabBarHeightMobile, Page } from 'Constants/constants';
import { QuestionnaireMobileSplashPage } from 'Components/Layouts/Questionnaire/QuestionnaireMobileLayout';

const QuestionnaireIntroPage = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    width: 90%;
    max-width: 600px;
    height: calc(100vh - ${DashboardTabBarHeightMobile});
`;

export default function BaselineQuestionnaireStartPage() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    if (isMobile) {
        return (
            <QuestionnaireMobileSplashPage>
                <PageContents/>
            </QuestionnaireMobileSplashPage>
        );
    } else {
        return (
            <NavbarTemplate>
                <PageContents/>
            </NavbarTemplate>
        );
    }
}

function PageContents() {
    const {t} = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();

    function handleConfirm() {
        dispatch(dismissBaseline);
        history.push("/questionnaire");
    }

    function handleDismiss() {
        dispatch(dismissBaseline);
        history.push(Page.EXPLORE);
    }

    return (
        <QuestionnaireIntroPage>
            <Grid container direction="column" alignItems="center" spacing={2}>
                <Grid item>
                    <FixedSizeIcon component={CheckmarkIcon} color="primary" />
                </Grid>
                <Grid item>
                    <TitleText>{t('questionnaire.thanks')}</TitleText>
                </Grid>
                <Grid item>
                    <Typography variant="body2" color="textSecondary" align="center">{t('questionnaire.baselineDescription')}</Typography>
                </Grid>
                <ModalButton variant="outlined" color="primary" size="small" onClick={handleDismiss}>{t('questionnaire.takeLater')}</ModalButton>
                <ModalButton variant="contained" color="primary" size="small" onClick={handleConfirm}>{t('questionnaire.beginNow')}</ModalButton>
            </Grid>
        </QuestionnaireIntroPage>
    );
}