import React, { useState } from 'react';
import {
    FormControl,
    RadioGroup,
    SvgIcon,
} from '@material-ui/core';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { ReactComponent as GlobeOutlineIcon } from 'resources/images/icons/globeOutline.svg';
import { ModalPaper } from 'Components/Styles/Modals/ModalContainers';
import { useDispatch } from 'react-redux';
import BaseModal from '../BaseModal';
import ModalHeader from '../ModalHeader';
import { changeLanguage } from 'redux/reducers/account/actions';
import LanguageListRadio from 'Components/Parts/Form/LanguageListRadio';
import { getLanguageFlag } from 'translations/i18n';


const languageOptions = [{
    flag: getLanguageFlag('en'),
    value: 'en-CA',
    getLabel: t => t('changeLangaugeModal.language.englishLabel'),
}, {
    flag: getLanguageFlag('fr'),
    value: 'fr-CA',
    getLabel: t => t('changeLangaugeModal.language.frenchLabel'),
    getSubtitle: t => t('changeLangaugeModal.language.frenchSubtitle'),
}];

const StyledFormControl = styled(FormControl)`
    width: 100%;
`;

function ChangeLanguageModal({ onClose, ...props }) {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const [language, setLanguage] = useState(i18n.language);

    const handleLanguageChange = (event) => {
        const locale = event.target.value;
        i18n.changeLanguage(locale);
        dispatch(changeLanguage(locale));
        setLanguage(locale);
    };

    return (
        <BaseModal
            aria-describedby="change-language-modal-description"
            aria-labelledby="change-language-modal-title"
            onClose={onClose}
            {...props}
        >
            <ModalPaper>
                <ModalHeader
                    icon={<SvgIcon component={GlobeOutlineIcon} color="primary" viewBox="0 0 88 88" />}
                    title={t('changeLangaugeModal.title')}
                />
                <StyledFormControl component="fieldset">
                    <RadioGroup name="locale" value={language} onChange={handleLanguageChange}>
                        {languageOptions.map(option => (
                            <LanguageListRadio
                                key={option.value}
                                flag={option.flag}
                                value={option.value}
                                label={option.getLabel(t)}
                                subtitle={option.getSubtitle?.(t)}
                            />
                        ))}
                    </RadioGroup>
                </StyledFormControl>
            </ModalPaper>
        </BaseModal>
    );
}

export default ChangeLanguageModal;
