import { useEffect } from 'react';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useHistory } from 'react-router';


// The wrapper contains the main settings menu, no UI elements here
export default function () {
    const history = useHistory();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

    // Desktop doesn't have a /dashboard/settings page
    // We detect for desktop and route the user straight to the account's page
    useEffect(() => {
        if (isDesktop) {
            history.push('/dashboard/settings/account');
        }
    }, [isDesktop]);
    
    return null;
}
