import React from 'react';
import { Field } from 'formik';
import { Grid } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import throttle from 'lodash/throttle';
import LocationAutocompleteSelector from './LocationAutocompleteSelector';
import GoogleMapsScriptLoader from 'Components/Parts/Google/GoogleMapsScriptLoader';
import GoogleMapsStaticImage from 'Components/Parts/Google/GoogleMapsStaticImage';
import { CustomTextField } from '../../TextBoxes/CustomInputs';

const autocompleteService = { current: null };

export default function LocationAutocompleteBox({field, form, placeholder}) {
    const name = field.name;
    const fieldValue = field.value;

    const [options, setOptions] = React.useState([]);

    const loaded = React.useRef(false);
    loaded.current = GoogleMapsScriptLoader();

    const fetch = React.useMemo(
        () =>
        throttle((input, callback) => {
            autocompleteService.current.getPlacePredictions(input, callback);
        }, 200),
        [],
    );

    React.useEffect(() => {
        if (!autocompleteService.current && window.google) {
            autocompleteService.current = new window.google.maps.places.AutocompleteService();
        }

        if (fieldValue.address === "") {
            setOptions([]);
            return undefined;
        }

        if (autocompleteService.current) {
            fetch({ input: fieldValue.address }, results => {
                setOptions(results || []);
            });
        }
    }, [fieldValue, fetch]);

    function onFetchAction(value) {
        form.setFieldValue(name, value);
    }

    function handleClear(event, value, reason) {
        // when the user clicks the X button on this textbox to clear their text, they are sent here with reason "clear"
        if (reason === "clear") {
            form.setFieldValue(name, {
                address: "", 
                lat: "", 
                lon: ""
            });
        }
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Autocomplete
                getOptionLabel={option => (typeof option === 'string' ? option : option.description)}
                filterOptions={x => x}
                options={options}
                onInputChange={handleClear}
                autoComplete
                includeInputInList
                freeSolo
                value={fieldValue.address}
                renderInput={params => (
                    <Field
                        name={`${name}.address`}
                        component={CustomTextField}
                        {...params}
                        label={placeholder}
                        variant="filled"
                        fullWidth
                    />
                )}
                renderOption={option => {
                    return (
                        <LocationAutocompleteSelector option={option} onFetchAction={onFetchAction}/>
                    );
                }}
                />
            </Grid>
            {(fieldValue.lat && fieldValue.lon) && <Grid item xs={12} container justify="center">
                <GoogleMapsStaticImage location={fieldValue} radius/>
            </Grid>}
        </Grid>
    );
}