import React from 'react';
import styled from 'styled-components';
import { Grid, Typography, Hidden } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const Divider = styled.div`
    background-color: #d1d1d1;
    display: inline-block;
    height: 40px;
    width: 1px;
`;

const ValueText = styled(Typography)`
    font-family: Inter;
    font-size: 28px;
    padding: 0;
`;

function getVisitRequirementLabel(visitRequirements, t) {
    return visitRequirements.map(req => req.getLabel(t)).join('/');
}

function CommitmentRegularSegment({label, isFinalized, value}) {
    return (
        <Segment label={label} isFinalized={isFinalized}>
            <ValueText color="primary">{value || "—"}</ValueText>
        </Segment> 
    );
}

function CommitmentDurationSegment({label, isFinalized, value}) {
    const durationParts = value ? value.split(' ') : [];

    return (
        <Segment label={label} isFinalized={isFinalized}>
            <Grid container alignItems="baseline">
                <ValueText color="primary">{durationParts[0] || "—"}</ValueText>
                {durationParts[1] && <Typography variant="subtitle1" color="primary">{durationParts[1]}</Typography>}
            </Grid>
        </Segment> 
    );
}

function Segment({label, children}) {
    return (
        <Grid item>
            <Grid item container>
                {children}
            </Grid>
            <Grid item container>
                <Typography variant="subtitle1">{label}</Typography>
            </Grid>
        </Grid>
    );
}

Segment.defaultProps = {
    isFinalized: true
};

function ConditionalDivider({display}) {
    if (!display) {
       return null; 
    }

    return (
        <Hidden smDown>
            <Divider />
        </Hidden>
    );
}

function StudyCommitmentHeader({visitNumber, visitFrequency, visitDuration, visitRequirements, isFinalized}) {
    const { t } = useTranslation();

    return (
        <Grid container alignItems="center" spacing={6}>
            <CommitmentRegularSegment 
                value={visitNumber && visitNumber.id}
                label={t('studyCommitmentHeader.visitsLabel')}
            />
            <ConditionalDivider display={!isFinalized}/>
            <CommitmentDurationSegment
                value={visitDuration && visitDuration.getLabel(t)}
                label={t('studyCommitmentHeader.durationLabel')}
            />
            <ConditionalDivider display={!isFinalized}/>
            <CommitmentRegularSegment 
                value={visitFrequency && visitFrequency.getLabel(t)}
                label={t('studyCommitmentHeader.frequencyLabel')}   
            />
            <ConditionalDivider display={!isFinalized}/>
            <CommitmentRegularSegment
                value={visitRequirements && getVisitRequirementLabel(visitRequirements, t)}
                label={t('studyCommitmentHeader.requirementsLabel')}
            />
        </Grid>
    );
}

StudyCommitmentHeader.defaultProps = {
    isFinalized: false
};

export default StudyCommitmentHeader;