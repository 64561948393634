import React from 'react';
import { useTranslation } from 'react-i18next';

import { MajorErrorMessageContent } from 'Components/Styles/Form/ErrorMessage';

export default function CardFieldError({meta}) {
    const { t } = useTranslation();
    if (meta && meta.error && meta.touched) {
        return (
            <MajorErrorMessageContent message={t('errorMessage.genericSectionIssue')} />
        );
    } else {
        return null;
    }
}