import React from 'react';
import SelectEligibilityContainer from '../SelectEligibilityContainer';
import { hybridSystemSelection } from 'data/study/Eligibility/eligibilitySelectionOptions';


const name="eligibility.advanced.hybrid";

// Register all the required translation keys
const t = _ => {};
t('studyEligibilityComparitivePhrase.advancedHybrid.include.option');
t('studyEligibilityComparitivePhrase.advancedHybrid.exclude.option');
t('studyEligibilityComparitivePhrase.advancedHybrid.undefined.option');

export default function StudyEligibilityHybrids({title}) {
    return (
        <SelectEligibilityContainer
            criteria="advancedHybrid"
            name={name}
            options={hybridSystemSelection} 
            title={title}
        />
    );
}