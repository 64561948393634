import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AuthPageLink } from 'Components/Layouts/Authorization/AuthPageLayout';


const Wrapper = styled(Grid)`
    margin-top: 48px;
`;

function HaveAccountLink() {
    const { t } = useTranslation();

    return (
        <Wrapper container alignItems="center" justify="center">
            <Typography style={{ display: "inline-block"}}>{t('signUp.alreadyHaveText')}</Typography>
            <AuthPageLink to="/signin">{t('signUp.signInLinkText')}</AuthPageLink>
        </Wrapper>
    );
}

export default HaveAccountLink;
