import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import ExploreLatestStudyPlaceholder from 'Components/Parts/Dashboard/Patient/ExploreLatestStudyPlaceholder';
import ResearcherStudiesDashboard from 'Components/Parts/Dashboard/Researcher/ResearcherStudiesDashboard';
import StudyDisplay from 'Components/Layouts/Dashboard/StudyDisplay';
import { UserType } from 'Constants/constants';


const Page = styled.div`
    padding-top: 24px;

    @media (min-width: 960px) {
        padding-top: 0;
    }
`;

export default function DashboardPage() {
    const accountType = useSelector(state => state.authorization.accountType);
    return (
        <Page>
            {accountType === UserType.RESEARCHER
                ? <ResearcherStudiesDashboard />
                : <StudyDisplay emptyComponent={<ExploreLatestStudyPlaceholder />} />
            }
        </Page>
    );
}
