import produce from 'immer';
import * as Actions from './actions';
import { UserType } from 'Constants/constants';

const initialState = {
    isEmailAvailable: true,
    accountType: "",
    creationError: "",
    termsPage: 0,
    isDecliningTerms: false,
};

export default (state = initialState, action) =>
    produce(state, draft => {
        const {type, payload} = action;
        switch(type) {
            case Actions.SELECT_ACCOUNT_TYPE_PATIENT:
                draft.accountType = UserType.PATIENT;
                break;
            case Actions.SELECT_ACCOUNT_TYPE_RESEARCHER:
                draft.accountType = UserType.RESEARCHER;
                break;
            case Actions.SELECT_ACCOUNT_TYPE_LOVED_ONE:
                draft.accountType = UserType.LOVED_ONE;
                break;
            case Actions.SET_ACCOUNT_CREATE_ERROR:
                draft.creationError = payload.errorText;
                break;
            case Actions.RESET_ACCOUNT_CREATE_ERROR:
                draft.creationError = "";
                break;
            case Actions.EMAIL_AVAILABLE:
                draft.isEmailAvailable = true;
                break;
            case Actions.EMAIL_UNAVAILABLE:
                draft.isEmailAvailable = false;
                break;
            case Actions.INCREMENT_TERMS_PAGE:
                draft.termsPage += 1;
                break;
            case Actions.DECLINING_ACCOUNT_CREATION_TERMS:
                draft.isDecliningTerms = true;
                break;
            case Actions.CONTINUE_REGISTRATION:
                draft.isDecliningTerms = false;
                break;
            case Actions.RESET_TERMS_PAGE:
                draft.termsPage = 0;
                draft.isDecliningTerms = false;
                break;
            default:
                return draft;
        }
    });