import React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { Paper, ButtonBase } from '@material-ui/core';


const AddButton = styled(ButtonBase)`
    background: #fff;
    border: 1px solid ${props => props.theme.palette.primary.main};
    border-radius: 24px;
    box-shadow: none;
    color: ${props => props.theme.palette.primary.main};
    font-size: 20px;
    height: 48px;
    width: 48px;
`;

const Card = styled(Paper)`
    align-items: center;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    height: 200px;
    width: 130px;
`;

function AddNewStudyCard() {
    const history = useHistory();
    
    return (
        <Card elevation={4}>
            <AddButton color="primary" onClick={() => history.push('/setup')}>+</AddButton>
        </Card>
    );
}

export default AddNewStudyCard;
