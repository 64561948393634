import React from 'react';
import styled from 'styled-components';
import { Grid, Hidden, Typography, useTheme, useMediaQuery } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import StudyCard from 'Components/Parts/Dashboard/StudyCard/StudyCard';
import { StudyStatus } from 'Constants/constants';
import { getResearchStudyList } from 'redux/reducers/dashboard/actions';
import AddNewStudyCard from 'Components/Parts/Dashboard/Researcher/AddNewStudyCard';


const CreateStudyTitle = styled(Typography)`
    font-weight: 400;
    margin-bottom: 8px;

    @media (min-width: 960px) {
        margin-bottom: 24px;
    }
`;

const DescriptionContainer = styled.div`
    b {
        font-weight: 900;
        text-transform: uppercase;
    }

    @media (min-width: 960px) {
        margin-left: 32px;
        max-width: 65%;
    }
`;

function IndividualStudyCard({ study }) {
    const { title, status, extension } = study.resource;
    const studyExtension = extension.find(ext => ext.url === "https://app.connect1d.ca/ext/relatedstudy");
    const studyId = studyExtension ? studyExtension.valueInteger : undefined;
    const studyDate = new Date(study.resource.period ? study.resource.period.start : study.resource.meta.lastUpdated);

    return (
        <StudyCard
            id={studyId}
            title={title}
            status={status}
            showStatusTag={true}
            date={studyDate}
        />
    );
}

function SubmitStudyDescription({ studyStatus }) {
    const { t } = useTranslation();

    let component = null;
    switch (studyStatus) {
        case StudyStatus.DRAFT:
            component = <Typography variant="body2" dangerouslySetInnerHTML={{ __html: t('dashboardPage.draftDescription') }} />;
            break;
        case StudyStatus.UNDER_REVIEW:
            component = <Typography variant="body2" dangerouslySetInnerHTML={{ __html: t('dashboardPage.underReviewDescription') }} />;
            break;
        default:
            component = (
                <React.Fragment>
                    <CreateStudyTitle variant="h4" color="primary"><Trans i18nKey='studyCardPlaceholder.researcherTitleDesktop'>This is where you <b>create</b> studies</Trans></CreateStudyTitle>
                    <Typography variant="body2" dangerouslySetInnerHTML={{ __html: t('studyCardPlaceholder.researcherDescriptionDesktop') }} />
                </React.Fragment>
            );
    }
    return (
        <DescriptionContainer>
            {component}
        </DescriptionContainer>
    );
}

export default function CurrentStudy() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const theme = useTheme();
    const loading = useSelector(state => state.serverCommunication.loading);
    const fhirId = useSelector(state => state.authorization.fhirId);
    const [study, setStudy] = React.useState();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    React.useEffect(() => {
        const limit = 1;
        const statusFilter = `${StudyStatus.DRAFT},${StudyStatus.UNDER_REVIEW}`;
        const sortFilter = 'status,_lastUpdated';
        const filters = undefined;
        const filterEnglish = true;
        const filterFrench = true;
        let mounted = true;
        dispatch(getResearchStudyList(fhirId, statusFilter, sortFilter, filters, filterEnglish, filterFrench, limit, data => {
            if (mounted && data.entry && data.entry.length === 1) {
                setStudy(data.entry[0]);
            }
        }));

        return _ => mounted = false;
    }, [fhirId]);

    if (loading) {
        return null;
    }

    return (
        <Grid container spacing={study ? 1 : 0} alignItems="center">
            <Hidden smDown>
                <Grid item md={study ? 3 : undefined}>
                    {study 
                        ? <IndividualStudyCard study={study} />
                        : <AddNewStudyCard />
                    }
                </Grid>
            </Hidden>
            <Grid item md={9}>
                {isMobile
                    ? <Typography>{t('studyCardPlaceholder.researcherDescriptionMobile')}</Typography>
                    : <SubmitStudyDescription studyStatus={study?.resource?.status} />
                }
            </Grid>
        </Grid>
    );
}