import React from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { getCheckBoxList } from 'data/study/studyPersonalDataSelection';
import { CardDetailOption } from 'Components/Parts/Study/StudyReview';
import CardFieldError from '../CardFieldError';

export default function PersonalDataDetails({value, isFinalized=false, meta}) {
    const { t } = useTranslation();
    const checkBoxList = getCheckBoxList(t);
    const width = isFinalized ? 6 : 12;
    const checkType = isFinalized ? "positive" : "default";

    return (
        <Grid container>
            {value && value.map(data => {
                const checkboxItem = checkBoxList.find(item => item.id === data);
                if (!checkboxItem) {
                    return null;
                }
                return (
                    <Grid item xs={12} md={width} key={checkboxItem.id}>
                        <CardDetailOption title={checkboxItem.label} checkType={checkType}/>
                    </Grid>
                );})}
            <CardFieldError meta={meta}/>
        </Grid>
    );
}