import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Field } from 'formik';
import { Checkbox } from 'formik-material-ui';
import { FormControlLabel, Grid } from '@material-ui/core';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { RoundedButton } from 'Components/Styles/Buttons/RoundedButton';
import {
    NameTextBox,
    ConfirmationTextBox,
    PasswordTextBox,
} from 'Components/Parts/Form/TextBoxes';
import { validateEmail } from 'redux/reducers/accountCreation/actions';
import PasswordValidation from 'Components/Parts/Form/PasswordValidation';
import MobileModalHeader from 'Components/Parts/Modals/Components/MobileModalHeader';
import HaveAccountLink from 'Components/Parts/Registration/HaveAccountLink';
import AccountTypeDropdown from 'Components/Parts/Registration/AccountTypeDropdown';
import { getValidationResult } from 'Components/Parts/Form/PasswordValidation';
import { FailureText } from 'Components/Parts/Form/Validators/ValidationText';
import { SuccessText } from 'Components/Parts/Form/Validators/ValidationText';
import LanguageDropdown from 'Components/Parts/Registration/LanguageDropdown';


const InputWrapper = styled(Grid)`
    .MuiFormControl-root {
        margin-top: 20px;
    }
`;

const ContinueButton = styled(RoundedButton)`
    display: block;
    margin-top: 20px;
`;

const CheckboxFormControlLabel = styled(FormControlLabel)`
    display: table;
    margin-top: 20px;
`;

const CheckboxField = styled.div`
    display: table-cell;
`;

export default function AccountCreationPage({values, errors, isValid, dirty}) {
    const dispatch = useDispatch();
    const history = useHistory();
    const { isEmailAvailable } = useSelector(state => state.accountCreation);
    const { t } = useTranslation();
    const [passwordFocus, setPasswordFocus] = useState(false);
    const emailSuccess = isEmailAvailable && !errors.email && values.email;
    const passwordSuccess = getValidationResult(values.password);

    function handleClick() {
        if (isValid && isEmailAvailable) {
            history.push('/signup/terms');
        }
    }

    return (
        <React.Fragment>
            <Grid container>
                <MobileModalHeader
                    title={t('signUp.title')}
                    description={t('signUp.description')}
                />
                <InputWrapper item xs={12}>
                    <LanguageDropdown />
                    <AccountTypeDropdown />
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Field
                                component={NameTextBox}
                                name="firstName"
                                label={t('signUp.firstNameLabel')}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Field
                                component={NameTextBox}
                                name="lastName"
                                label={t('signUp.lastNameLabel')}
                            />
                        </Grid>
                    </Grid>
                    <Field
                        component={ConfirmationTextBox} 
                        name="email" 
                        type="email"
                        error={!isEmailAvailable || (errors.email && values.email)}
                        success={emailSuccess || undefined}
                        label={t('signUp.emailLabel')}
                        InputProps={{ 
                            onBlur: () => dispatch(validateEmail(values.email))
                        }}
                    />
                    {!isEmailAvailable &&
                        <FailureText message={t('signUp.emailTakenErrorMessage')}/>                               
                    }
                    {isEmailAvailable && values.email && !emailSuccess &&
                        <FailureText message={t('signUp.emailInvalidMessage')}/>
                    }
                    {emailSuccess &&
                        <SuccessText message={t('signUp.emailSuccessMessage')}/>
                    }
                    <Field 
                        name="password"
                        component={PasswordTextBox}
                        success={passwordSuccess}
                        error={values.password && !passwordSuccess}
                        handleFocus={() => setPasswordFocus(true)}
                        handleBlur={() => setPasswordFocus(false)}
                    />
                    {passwordFocus &&
                        <PasswordValidation name="password"/>
                    }
                    {!passwordFocus && passwordSuccess &&
                        <SuccessText message={t('signUp.passwordSuccessMessage')}/>
                    }
                    <CheckboxFormControlLabel
                        control={<CheckboxField><Field
                                    type="checkbox"
                                    component={Checkbox}
                                    color="primary"
                                    name="allowsCommunication"
                                /></CheckboxField>}
                        label={t('signUp.allowsCommunicationLabel')}
                    />
                </InputWrapper>
                <ContinueButton 
                    variant="contained" 
                    color="primary" 
                    type="button" 
                    onClick={handleClick} 
                    disabled={!isValid || !isEmailAvailable || !dirty} 
                    fullWidth
                >
                    {t('signUp.continueButtonText')}
                </ContinueButton>
            </Grid>
            <HaveAccountLink />
        </React.Fragment>
    );
}