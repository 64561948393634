import React from 'react';
import styled from 'styled-components';
import {
    Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { getLocaleCode } from 'translations/i18n';
import FaqAccordion from 'Components/Parts/Settings/FaqAccordion';
import { FaqContent } from './HelpPageData';
import { useSelector } from 'react-redux';


const Title = styled(Typography)`
    font-size: 22px;
    font-weight: 700;
    margin-top: 14px;
`;

const FaqBlurb = styled(Typography)`
    color: #939393;
    font-size: 12px;
    line-height: 19px;
    margin-bottom: 28px;
`;

const Page = styled.div`
    padding-top: 22px;
`;

function SettingsHelpPage() {
    const { t, i18n } = useTranslation();
    const accountType = useSelector(state => state.authorization.accountType);
    const locale = getLocaleCode(i18n.language);
    const faqData = FaqContent(accountType)[locale];

    return (
        <Page>
            <Title>Frequently Asked Questions</Title>
            <FaqBlurb dangerouslySetInnerHTML={{__html: t('settingsPage.faqDescription')}} />
            <FaqAccordion faqData={faqData} />
        </Page>
    );
}

export default SettingsHelpPage;
