import React from 'react';
import {
    Typography,
} from '@material-ui/core';
import styled from 'styled-components';

import { TitleText } from 'Components/Styles/Modals/Components';


const Header = styled.div`
    text-align: center;
    margin-bottom: 54px;

    svg {
        font-size: 88px;
    }
`;

const Subtitle = styled(Typography)`
    color: #939393;
`;

function ModalHeader({ title, subtitle, icon }) {
    return (
        <Header>
            {icon}
            {title && <TitleText>{title}</TitleText>}
            {subtitle && <Subtitle>{subtitle}</Subtitle>}
        </Header>
    );
}

export default ModalHeader;
