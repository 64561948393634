import { HttpFreshdesk } from '../api'
import {
    tags as fdTags,
    groupId,
    productId,
} from './constants';


export default function(message) {
    return HttpFreshdesk.post('/api/v2/tickets', {
        email: 'noreply@ehealthinnovation.org',
        group_id: groupId,
        product_id: productId,
        subject: 'Account Creation Feedback',
        tags: [fdTags.accountCreationFeedback],
        description: message,
        status: 2,
        priority: 1,
    });
}
