import React from 'react';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { CompletedField } from '../ApprovalFields';

const StyledImage = styled.img`
    object-fit: cover;
    max-width: 1584px;
    max-height: 396px;
`;

export default function StudyInformationApproval({title, summary, recruitment, category, image}) {
    const { t } = useTranslation();
    const categoryString = category.map(({ label, getLabel }) => 
            getLabel ? getLabel(t) : label
        ).join(', ');

    return (
        <Grid container spacing={4}>
            <Grid item xs={12}>
                <CompletedField
                    label={t('studySetupInfoPage.titleLabel')}
                    text={title}
                />
            </Grid>
            <Grid item xs={12}>
                <CompletedField
                    label={t('studySetupInfoPage.summaryLabel')}
                    text={summary}
                />
            </Grid>
            <Grid item container spacing={6}>
                <Grid item xs={4}>
                    <CompletedField
                        label={t('studySetupInfoPage.recruitmentLabel')}
                        text={recruitment}
                    />
                </Grid>
                <Grid item xs={8}>
                    <CompletedField
                        label={t('studySetupInfoPage.categoryLabel')}
                        text={categoryString}
                    />
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <StyledImage src={image} alt="Representation of your study"/>
            </Grid>
        </Grid>
    );
}