import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Grid, Typography } from '@material-ui/core';
import QuestionChoice from './QuestionTypes/QuestionChoice';
import QuestionString from './QuestionTypes/QuestionString';
import QuestionDate from './QuestionTypes/QuestionDate';
import QuestionNumeric from './QuestionTypes/QuestionNumeric';
import QuestionOpenChoice from './QuestionTypes/QuestionOpenChoice';
import { useTranslation } from 'react-i18next';
import TextMaskInteger from '../Form/TextBoxes/TextMasks/TextMaskInteger';
import TextMaskDecimal from '../Form/TextBoxes/TextMasks/TextMaskDecimal';

const QuestionTitle = styled.div`
    height: 6em;
`;

export default function QuestionContent() {
    const { t } = useTranslation();
    const { questions, currentQuestion } = useSelector(state => state.questionnaires);
    if (questions.length === 0 || currentQuestion >= questions.length) {
        return null;
    }
    const { linkId, type, text, prefix, clientSelection, repeats } = questions[currentQuestion];

    return (
        <Grid container direction="column" spacing={2}>
            <Grid item>
                <QuestionTitle>
                    <Typography variant="h4">{t(text)}</Typography>
                </QuestionTitle>
            </Grid>
            <Grid item>
                <QuestionType name={linkId} type={type} prefix={prefix} clientSelection={clientSelection} repeats={repeats}/>
            </Grid>
        </Grid>
    );
}

function QuestionType({name, type, prefix, clientSelection, repeats}) {
    switch(type) {
        case "choice":
            return <QuestionChoice name={name} clientSelection={clientSelection} prefix={prefix} repeats={repeats}/>;
        case "open-choice":
            return <QuestionOpenChoice name={name} clientSelection={clientSelection} prefix={prefix} repeats={repeats}/>;
        case "string":
            return <QuestionString name={name} prefix={prefix}/>;
        case "date":
            return <QuestionDate name={name}/>;
        case "integer":
            return <QuestionNumeric name={name} prefix={prefix} mask={TextMaskInteger} />;
        case "decimal":
            return <QuestionNumeric name={name} prefix={prefix} mask={TextMaskDecimal} />;
        default:
            return null;
    }
}