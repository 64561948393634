import React, { useState } from 'react';
import styled from 'styled-components';
import {
    Grid,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    Typography,
} from '@material-ui/core';

const QuestionTitle = styled(Typography)`
    font-style: italic;
    font-weight: 700;
`;

const Accordion = styled.div`
    .MuiExpansionPanel-root {
        background: none;
        background-color: none;

        &:before {
            display: none;
        }
    }

    .MuiExpansionPanelSummary-content {
        margin: 0;
    }

    .MuiExpansionPanelSummary-root {
        min-height: 48px;
        padding: 0;
    }

    .MuiExpansionPanelDetails-root {
        padding: 0;
    }
`;

const Indicator = styled(Typography)`
    font-weight: 700;
    margin-right: 8px;
`;

function FaqAccordionSection({ faqData }) {
    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Accordion>
            {faqData.map(({ question, answer }, idx) => (
                <ExpansionPanel
                    square
                    expanded={expanded === `panel-${idx}`}
                    onChange={handleChange(`panel-${idx}`)}
                    key={idx}
                    elevation={0}
                >
                    <ExpansionPanelSummary aria-controls="panel1d-content" id="panel1d-header">
                        <Grid container wrap="nowrap">
                            <Indicator color="primary">Q.</Indicator>
                            <QuestionTitle color="primary">{question}</QuestionTitle>
                        </Grid>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Grid container wrap="nowrap">
                            <Indicator>A.</Indicator>
                            <div>
                                {answer}
                            </div>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            ))}
        </Accordion>
    );
}

function FaqAccordion({ faqData }) {
    return (
        <FaqAccordionSection faqData={faqData} />
    );
}

export default FaqAccordion;
