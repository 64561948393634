import React from 'react';
import styled from 'styled-components';
import {
    SvgIcon,
    Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ClockOutlinedIcon } from 'resources/images/icons/study/clockOutline.svg';


const Page = styled.div`
    padding-top: 22px;
`;

const Title = styled(Typography)`
    margin: 18px 0 17px;
`;

function SettingsYourDataPage() {
    const { t } = useTranslation();
    const description = t('yourDataSettingsPage.description');

    return (
        <Page>
            <SvgIcon component={ClockOutlinedIcon} color="primary" style={{fontSize: '64px'}}/>
            <Title variant="h3">{t('yourDataSettingsPage.title')}</Title>
            <Typography dangerouslySetInnerHTML={{__html: description}}/>
        </Page>
    );
}

export default SettingsYourDataPage;
