// dev server
export const SERVER_API_CONTEXT_PATH_DEV = "https://dev-connect1d.ehealthinnovation.org/connect1d-server";
// staging server
export const SERVER_API_CONTEXT_PATH_STAGING = "https://connect1d-2.ehealthinnovation.org/connect1d-server";
// prod server
export const SERVER_API_CONTEXT_PATH_PROD = "https://app.connect1d.ca/connect1d-server";
// local host
export const SERVER_API_CONTEXT_PATH_LOCAL = "http://localhost:8081/connect1d-server";
export const SERVER_API_CONTEXT_PATH = (stage) => {
    switch(stage) {
        case "local":
            return SERVER_API_CONTEXT_PATH_LOCAL;
        case "develop":
            return SERVER_API_CONTEXT_PATH_DEV;
        case "staging":
            return SERVER_API_CONTEXT_PATH_STAGING;
        case "prod":
            return SERVER_API_CONTEXT_PATH_PROD;
        default:
            return SERVER_API_CONTEXT_PATH_DEV;
    }
}

export const WEBSITE_URL = stage => {
    switch (stage) {
        case "local":
            return 'http://localhost:3001';
        case "prod":
            return 'https://connect1d.ca';
        case "staging":
        case "develop":
        default:
            return 'http://10.1.6.136';
    }
}

export const GOOGLE_API_KEY = "AIzaSyDAGeLYQgUFRk_xr3nFyHileH9OkNpBHFY";

export const UserType = {
    PATIENT: "patient",
    RESEARCHER: "researcher",
    LOVED_ONE: "lovedone",
    ADMIN: "admin"
};

export const EligibilityStatus = {
    ELIGIBLE: "ELIGIBLE",
    POTENTIALLY_ELIGIBLE_QUESTIONS_PENDING: "POTENTIALLY_ELIGIBLE_QUESTIONS_PENDING",
    POTENTIALLY_ELIGIBLE_CUSTOM_CRITERIA: "POTENTIALLY_ELIGIBLE_CUSTOM_CRITERIA",
    NOT_ELIGIBLE: "NOT_ELIGIBLE"
};

export const StudyStatus = {
    DRAFT: "draft",
    UNDER_REVIEW: "suspended",
    ACTIVE: "in-progress",
    COMPLETED: "completed",
};

export const QuestionnaireStatus = {
    ACTIVE: "in-progress",
    COMPLETED: "completed"
}

export const EligibilityRelationship = {
    GREATER: "GREATER",
    LESS: "LESS",
    RANGE: "RANGE",
    EQUAL: "EQUAL",
    SINGLE: "SINGLE",
    MIXED: "MIXED",
};

export const ParticipantStatus = {
    INTERESTED: 'INTERESTED',
    RECRUITED: 'RECRUITED',
    DECLINED: 'DECLINED',
    CONTACTED: 'CONTACTED',
};

export const ParticipantListOrderBy = {
    USER: 'user',
    NOTE: 'note',
    DATE: 'date',
};

export const OrderDirection = {
    ASC: 'asc',
    DESC: 'desc',
};

export const AccountCreationTermsPage = {
    PRIVACY_POLICY: 0,
    TERMS_OF_SERVICE: 1,
};

export const DashboardTabBarHeightMobile = '72px';

export const Page = {
    SIGN_IN: '/signin',
    SIGN_UP: '/signup',
    DASHBOARD: '/dashboard',
    EXPLORE: '/dashboard/explore',
    FINDINGS: '/dashboard/findings',
    MY_STUDY: '/dashboard/mystudy/:studyId',
    CHANGE_PASSWORD: '/dashboard/settings/account/changepassword',
    SOFTWARE_VERSION: '/dashboard/settings/account/softwareversion',
    SETTINGS: '/dashboard/settings',
    SETTINGS_ACCOUNT: '/dashboard/settings/account',
    SETTINGS_ABOUT: '/dashboard/settings/about',
    SETTINGS_DATA: '/dashboard/settings/data',
    SETTINGS_HELP: '/dashboard/settings/help',
};

export const STUDY_RECRUITMENT_URI = "https://app.connect1d.ca/ext/recruitment";
export const STUDY_COMMITMENT_VISITS_URI = "https://app.connect1d.ca/ext/commitment/visits";
export const STUDY_COMMITMENT_FREQUENCY_URI = "https://app.connect1d.ca/ext/commitment/frequency";
export const STUDY_COMMITMENT_DURATION_URI = "https://app.connect1d.ca/ext/commitment/duration";
export const STUDY_COMMITMENT_OPTIONS_URI = "https://app.connect1d.ca/ext/commitment/option";
export const STUDY_PERSONALDATA_URI = "https://app.connect1d.ca/ext/personaldata";
export const STUDY_COMPENSATION_URI = "https://app.connect1d.ca/ext/compensation";
export const STUDY_COMPENSATION_OTHER_URI = "https://app.connect1d.ca/ext/compensation/other";
export const STUDY_END_DATE_URI = "https://app.connect1d.ca/ext/timelines/studyend";
export const STUDY_RESULTS_DATE_URI = "https://app.connect1d.ca/ext/timelines/results";
export const STUDY_RELATED_URI = "https://app.connect1d.ca/ext/relatedstudy";

export const CLIENT_SELECTION_URI = "https://ehealthinnovation.org/connect1d/clientselection/";

export const FRESHDESK_API_KEY = 'vLVFNKr9sLIw18JtArMf';
export const FRESHDESK_DOMAIN = 'ehealthinnovation';
