import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import { saveDraftStudy, addToErrorList, addToCompleteList, fetchDraftStudy, fetchFhirStudyData, submitDraftStudy } from 'redux/reducers/studyCreation/actions';
import { sidebarItems } from 'data/study/sidebarItems';
import { initialValues } from 'data/study/WizardFormSetup/wizardFormInitialValues';
import { WizardContainer } from 'Components/Styles/Study/WizardPages';
import { getValidationSchema, getFullValidationSchema } from 'data/study/WizardFormSetup/wizardFormValidationSchema';
import SideNavbar from 'Components/Parts/Study/Navigation/SideNavbar';
import StudyCreationWizard from 'Components/Views/ResearcherWizardPages/StudyCreation/StudyCreationWizard';
import Autosave from 'Components/Parts/Form/Autosave';
import GlobalProgressOverlay from 'Components/Parts/Progress/GlobalProgressOverlay';

export default function ResearcherWizardContainer() {
    const dispatch = useDispatch();
    const {currentStep, fhirId, studyTeam, studyEligibility} = useSelector(state => state.studyCreation);
    const loading = useSelector(state => state.serverCommunication.loading);
    const history = useHistory();
    const { t } = useTranslation();
    const [draft, setDraft] = React.useState(null);
    const runFullValidation = currentStep === 10;
    const initValues = draft ? draft : initialValues;

    useEffect(() => {
        if (fhirId) {
            dispatch(fetchFhirStudyData(fhirId, studyTeam, studyEligibility, setDraft));
        } else {
            dispatch(fetchDraftStudy());
        }
    }, [fhirId]);

    function handleSubmit(values, actions) {
        if (currentStep < 10) {
            dispatch(saveDraftStudy(values));
            actions.setSubmitting(false);
            history.push("/setup/"+(currentStep+1));
        } else {
            dispatch(submitDraftStudy(values, () => history.push("/dashboard")));
            actions.setSubmitting(false);
            setDraft(null);
        }
    }

    return (
        <Formik 
            enableReinitialize={true}
            initialValues={initValues}
            validationSchema={runFullValidation ? getFullValidationSchema(t) : getValidationSchema(t)[Math.max(currentStep-1, 0)]}
            onSubmit={handleSubmit}
        >
            {({values, submitCount, isValid}) => 
                <WizardContainer>
                    <GlobalProgressOverlay open={loading}/>
                    <Autosave formikValues={values} />
                    <Effect submitCount={submitCount} isValid={isValid} />
                    <SideNavbar header={t('studySetup.title')} items={sidebarItems} />
        
                    <StudyCreationWizard />
                </WizardContainer>
            } 
        </Formik>
    );
}

function Effect({submitCount, isValid}) {
    const dispatch = useDispatch();

    useEffect(() => {
        if (submitCount > 0 && !isValid) {
            dispatch(addToErrorList);
        } else if (submitCount > 0) {
            dispatch(addToCompleteList);
        }
    }, [submitCount, isValid, dispatch]);
    return null;
}