import React from 'react';
import {
    SvgIcon,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { ReactComponent as HeartOutlineIcon } from 'resources/images/icons/heartOutline.svg';
import { ModalPaper } from 'Components/Styles/Modals/ModalContainers';
import { ModalButton } from 'Components/Styles/Modals/Components';
import BaseModal from '../BaseModal';
import ModalHeader from '../ModalHeader';


function DonateDataModal({ shouldDonate, ...props }) {
    const { t } = useTranslation();

    return (
        <BaseModal
            aria-describedby="donate-data-modal-description"
            aria-labelledby="donate-data-modal-title"
            onClose={() => shouldDonate()}
            {...props}
        >
            <ModalPaper>
                <ModalHeader
                    icon={<SvgIcon component={HeartOutlineIcon} color="primary" viewBox="0 0 88 88" />}
                    title={t('donateDataModal.title')}
                    subtitle={t('donateDataModal.description')}
                />
                <ModalButton variant="outlined" color="primary" size="small" onClick={() => shouldDonate(false)}>
                    {t('donateDataModal.cancelButtonText')}
                </ModalButton>
                <ModalButton variant="contained" color="primary" size="small" onClick={() => shouldDonate(true)}>
                    {t('donateDataModal.confirmButtonText')}
                </ModalButton>
            </ModalPaper>
        </BaseModal>
    );
}

export default DonateDataModal;
