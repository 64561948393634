import React from 'react';
import { RoundedButton } from 'Components/Styles/Buttons/RoundedButton';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toggleExitRequest } from 'redux/reducers/questionnaires/actions';

function QuestionnaireExitButton({color}) {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    return (
        <RoundedButton 
            variant="outlined" 
            size="small" 
            color={color} 
            onClick={() => dispatch(toggleExitRequest)}
        >
            {t('questionnaire.exit')}
        </RoundedButton>
    );
}

QuestionnaireExitButton.defaultProps = {
    color: "default"
};

export default QuestionnaireExitButton;