import React from 'react';
import { Route, Redirect, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { isAuthorized } from 'redux/reducers/authorization/actions';


/**
 * @ref AuthorizedRoute
 */
function UnauthorizedRouteOnly({ component: Component, alternate, ...rest }) {
    const dispatch = useDispatch();
    const location = useLocation();
    const {authorized} = useSelector(state => state.authorization);
    const [authDetermined, setAuthDetermined] = React.useState(authorized);

    function isApproved() {
        return authorized;
    }
    
    React.useEffect(() => {
        if (!authorized) {
            dispatch(isAuthorized((true), () => {
                setAuthDetermined(true);
            }));
        }
    }, [authorized, dispatch]);

    function getRenderComponent(props) {
        if (!isApproved()) {
            return <Component {...props} />;
        } else {
            return <Redirect to={{ 
                    pathname: alternate,
                    state: {
                        sourceUrl: location.pathname,
                        sourceQuery: location.search
                    }
                }} />;
        }
    }

    return (
        authDetermined 
        ?   <Route
                {...rest}
                render={props => getRenderComponent(props)}
            />
        : null
    );
}

UnauthorizedRouteOnly.defaultProps = {
    alternate: "/"
};

export default UnauthorizedRouteOnly;
