import React from 'react';
import { useSelector } from 'react-redux';
import { Form } from 'formik';
import styled from 'styled-components';
import { Grid, SvgIcon } from '@material-ui/core';
import { ReactComponent as CheckCircleOutlineIcon } from 'resources/images/icons/study/checkmarkCircledOutline.svg';
import { sidebarItems } from 'data/study/sidebarItems';
import { RoundedButton } from 'Components/Styles/Buttons/RoundedButton';
import StudyHeader from 'Components/Parts/Study/StudyHeader';
import { FooterWrap } from 'Components/Styles/Study/WizardPages';
import { SectionCard as ReviewSection } from 'Components/Parts/Study/StudyReview/ReviewSectionCard';
import LocalProgressOverlay from 'Components/Parts/Progress/LocalProgressOverlay';
import { useTranslation } from 'react-i18next';
import { WizardContent } from 'Components/Styles/Study/WizardPages';

const ReviewSectionWrapper = styled.div`
    padding-top: 3em;
`;

export default function WizardReviewPage({title, subtitle, icon}) {
    const { t } = useTranslation();
    const saving = useSelector(state => state.serverCommunication.saving);

    return (
        <Form>
            <Grid container spacing={4}>
                <WizardContent>
                    <Grid item xs={12}>
                        <StudyHeader title={title} subtitle={subtitle} icon={icon} />
                    </Grid>
                    <Grid item xs={12}>
                        {sidebarItems.map(({getTitle, icon}, i) => {
                            if (i < 9) {
                                return (
                                    <ReviewSectionWrapper key={i}>
                                        <ReviewSection title={getTitle(t)} icon={icon} id={i+1}/>
                                    </ReviewSectionWrapper>
                                );
                            } else {
                                return undefined;
                            }
                        })}
                    </Grid>
                </WizardContent>
            </Grid>
            <FooterWrap>
                <Grid container item xs={12} justify="flex-end">
                    <LocalProgressOverlay waiting={saving}>
                        <RoundedButton 
                            variant="contained" 
                            color="primary" 
                            type="submit" 
                            disabled={saving}
                            endIcon={<SvgIcon component={CheckCircleOutlineIcon} style={{fontSize: '24px'}} htmlColor="#fff"/>}
                        >
                            {t('study.submitButtonText')}
                        </RoundedButton>
                    </LocalProgressOverlay>
                </Grid>
            </FooterWrap>
        </Form>
    );
}

