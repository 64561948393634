import React from 'react';
import styled from 'styled-components';
import { Grid, Chip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { CardDetailRow, CardDetailTextValue } from 'Components/Parts/Study/StudyReview';
import { ChipGroupDiv } from 'Components/Styles/Form/ChipGroupDiv';
import CardFieldError from '../CardFieldError';
import { chipValues } from 'data/study/studyCategoryValues';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import getStudyImageRequest from 'api/study/getStudyImageAsBlob';

const StudyImage = styled.img`
    max-width: 100%;
`;

export default function StudyInformationDetails({title, summary, recruitment, category, image, meta}) {
    const { t } = useTranslation();

    function CategoryChips() {
        return ( 
            <ChipGroupDiv align="start">
                {category && category.map((cat, i) => {
                    return <Chip key={i} size="small" color="primary" label={getChipLabel(cat)}/>;
                })}
            </ChipGroupDiv>
        );
    }

    function getChipLabel(id) {
        if (id.text) {
            id = id.text;
        }

        const chipValue = chipValues.find(chip => chip.id === id);
        return chipValue ? chipValue.getLabel(t) : undefined;
    }

    return (
        <Grid container item spacing={2}>
            <CardDetailRow name={t('studySetupReview.titleText')} value={<CardDetailTextValue>{title}</CardDetailTextValue>} />
            <CardDetailRow name={t('studySetupReview.summaryText')} value={<CardDetailTextValue>{summary}</CardDetailTextValue>} />
            <CardDetailRow name={t('studySetupReview.recruitmentGoalText')} value={<CardDetailTextValue>{recruitment}</CardDetailTextValue>} />
            <CardDetailRow name={t('studySetupReview.categoryText')} value={<CategoryChips/>} />
            <CardDetailRow name={t('studySetupReview.imageText')} value={<PreviewImage image={image} t={t}/>} />
            <CardFieldError meta={meta}/>
        </Grid>
    );
}

function PreviewImage({image, t}) {
    const { studyId, draftLoaded } = useSelector(state => state.studyCreation);
    const { setFieldValue } = useFormikContext();

    if (draftLoaded && studyId && !image) {
        getStudyImageRequest(studyId, (image) => setFieldValue("studyInfo.image", image));
    }

    if (image) {
        const preview = typeof image === 'string' ? image : URL.createObjectURL(image);
        return <StudyImage src={preview} alt={t('studySetupReview.studyImageAltText')} />;
    } else {
        return null;
    }
}