import React from 'react';
import { NavLink } from 'react-router-dom';
import { Container, Grid } from '@material-ui/core';
import styled from 'styled-components';

import Section from 'Components/Parts/Section/Section';
import { Gradiant } from 'Theme';


export const CentredGridItem = styled(Grid)`
    text-align: center;
`;

export const AuthPageLink = styled(NavLink)`
    color: ${props => props.color ? props.theme.palette[props.color].main : '#000'};
    margin-left: 1rem;
    font-weight: bold;
    text-decoration: underline;
`;

const StyledSection = styled(Section)`
    overflow: hidden;
    padding-bottom: 0;
    padding-top: 0;
`;

export default function AuthPageLayout({children}) {
    return (
        <StyledSection
            sectionStyle={{background: Gradiant.primary}}
        >
            <Container maxWidth={false}>
                <Grid container
                    direction="row"
                    alignItems="center"
                    justify="center"
                    style={{ minHeight: '100vh' }}
                >
                    {children}
                </Grid>
            </Container>
        </StyledSection>
    );
}