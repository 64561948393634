export const getCheckBoxList = t => ([ 
    {
        id: "behaviour",
        label: t('studySetupPersonalDataOption.behaviour'),
    }, 
    {
        id: "insulin",
        label: t('studySetupPersonalDataOption.insulin'),
    },
    {
        id: "glucose",
        label: t('studySetupPersonalDataOption.glucose'),
    },
    {
        id: "medhistory",
        label: t('studySetupPersonalDataOption.medHistory'),
    },
    {
        id: "demographics",
        label: t('studySetupPersonalDataOption.demographics'),
    },
    {
        id: "medications",
        label: t('studySetupPersonalDataOption.medications'),
    },
    {
        id: "diet",
        label: t('studySetupPersonalDataOption.diet'),
    },
    {
        id: "mentalhealth",
        label: t('studySetupPersonalDataOption.mentalHealth'),
    }, 
    {
        id: "familyhistory",
        label: t('studySetupPersonalDataOption.familyHistory'),
    }, 
    {
        id: "physical",
        label: t('studySetupPersonalDataOption.physical'),
    }, 
    {
        id: "general",
        label: t('studySetupPersonalDataOption.general'),
    }, 
    {
        id: "pregnancy",
        label: t('studySetupPersonalDataOption.pregnancy'),
    },
    { 
        id: "a1c",
        label: t('studySetupPersonalDataOption.a1c'),
    }, 
    {
        id: "complications",
        label: t('studySetupPersonalDataOption.complications'),
    }
]);