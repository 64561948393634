import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CollapsibleGroup from 'Components/Parts/Form/Groups/CollapsibleGroup';
import TabbedGroup from 'Components/Parts/Form/Groups/TabbedGroup';
import eligibilityCodingToTitle from 'data/study/Eligibility/eligibilityCodingToTitle';


const NoTabContainer = styled.div`
    margin-top: 32px;
`;

function EligibilityGroup({title, questions, tabbed}) {
    const { t } = useTranslation();
    const categories = useSelector(state => state.eligibilityTracking.eligibilityCategories);
    const codingToTitleMap = eligibilityCodingToTitle(t);
    const selections = categories.filter(category => {
        return questions.some(question => question.id === category);
    }).map(eligibilityCoding => codingToTitleMap[eligibilityCoding]);

    return (
        <CollapsibleGroup groupTitle={title} selections={selections}>
            {tabbed ?
                <TabbedGroup 
                    values={questions} 
                    components={questions}
                />
            :   <NoTabContainer>
                    {questions[0].component}
                </NoTabContainer>
            }
        </CollapsibleGroup>
    );
}

EligibilityGroup.defaultProps = {
    tabbed: true,
};

export default EligibilityGroup;