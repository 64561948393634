import React from 'react';
import { Grid } from '@material-ui/core';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { CardDetailRow } from 'Components/Parts/Study/StudyReview/index';
import CardFieldError from '../CardFieldError';
import CardDetailTextValue from '../CardDetailTextValue';


const StyledCardDetailRow = styled(CardDetailRow)`
    @media (max-width: 959px) {
        &:not(:last-child) {
            border-bottom: 1px solid #f6f6f6;
            padding-bottom: 16px;
            margin-bottom: 16px;
        }
    }
`;

export default function StudyTeamDetails({principalInvestigator, researchers, coinvestigators, meta}) {
    const { t } = useTranslation();
    return (
        <Grid container item>
            <StyledCardDetailRow
                stackXs
                name={t('studySetupReview.principalInvestigatorLabel')} 
                value={<TeamMemberDetail member={principalInvestigator} />}
            />
            <StyledCardDetailRow 
                stackXs
                name={t('studySetupReview.researchersLabel')} 
                value={<MultipleTeamMemberDetail members={researchers} />}
            />
            {coinvestigators.length > 0 && 
                <StyledCardDetailRow 
                    stackXs
                    name={t('studySetupReview.coinvestigatorsLabel')} 
                    value={<MultipleTeamMemberDetail members={coinvestigators} />}
                />
            }
            <CardFieldError meta={meta}/>
        </Grid>
    );
}

function MultipleTeamMemberDetail({members}) {
    return (
        <Grid item container spacing={2}>
            {members.map((member, i) => (
                <TeamMemberDetail key={i} member={member}/>   
            ))}
        </Grid>
    );
}

function TeamMemberDetail({member}) {
    return (
        <Grid item container spacing={1}>
            <Grid item xs={12}>
                <CardDetailTextValue>{member.name}</CardDetailTextValue>
            </Grid>
            <Grid item xs={12}>
                <CardDetailTextValue>{member.email}</CardDetailTextValue>
            </Grid>
        </Grid>
    );
}