import React from 'react';
import { Typography } from '@material-ui/core';
import styled from 'styled-components';

import { CentredGridItem } from 'Components/Layouts/Authorization/AuthPageLayout';
import { ModalCardTitle } from 'Components/Styles/Typography/Heading'


const Description = styled(Typography)`
    margin-bottom: 28px;
`;

function MobileModalHeader({ title, description }) {
    return (
        <CentredGridItem item xs={12}>
            {title && <ModalCardTitle variant="h3" color="primary">{title}</ModalCardTitle>}
            {description && <Description>{description}</Description>}
        </CentredGridItem>
    );
}

export default MobileModalHeader
