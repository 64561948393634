import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import enLocale from 'date-fns/locale/en-CA';
import frLocale from 'date-fns/locale/fr-CA';

import en from './locales/en.json';
import fr from './locales/fr.json';

import t from './serverQuestionTranslations';


const defaultLanguageCode = 'en';
const dateLocaleMap = {
    en: enLocale,
    fr: frLocale,
};
const localeFriendlyName = {
    en: 'English',
    fr: 'Français',
};
const localeFlag = {
    en: '🇬🇧',
    fr: '🇨🇦',
};

const supportedLocales = ['en', 'fr'];

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: en,
            },
            fr: {
                translation: fr,
            },
        },
        fallbackLng: defaultLanguageCode,
        interpolation: {
            escapeValue: false,
        },
        detection: {
            lookupQuerystring: 'lang',
            order: ['querystring', 'cookie', 'localStorage'],
            lookupCookie: 'i18nlang',
            lookupLocalStorage: 'i18nlang',
        },
    });

export const getLocaleCode = (locale=defaultLanguageCode) => {
    return locale.slice(0, 2);
};

export const getDateLocale = (locale) => {
    const code = getLocaleCode(locale);
    return dateLocaleMap[code] ?? dateLocaleMap[defaultLanguageCode];
};

export const getLanguageText = (locale) => {
    const code = getLocaleCode(locale);
    return localeFriendlyName[code];
};

export const getLanguageFlag = (locale) => {
    const code = getLocaleCode(locale);
    return localeFlag[code];
};

export const toggleLanguage = () => {
    const locale = getLocaleCode(i18n.language);
    const currentIdx = supportedLocales.includes(locale) ? supportedLocales.indexOf(locale) : 0;
    const changeToLocale = supportedLocales[(currentIdx + 1) % supportedLocales.length];
    i18n.changeLanguage(changeToLocale);
}

export const getCurrentLocale = () => {
    return getLocaleCode(i18n.language) ?? defaultLanguageCode;
};

export default i18n;
