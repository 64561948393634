import * as Yup from 'yup';

const getStudyInformationSchema = t => Yup.object({
    studyInfo: Yup.object({
        title: Yup.string()
            .required(t('errorMessage.requiredField')),
        summary: Yup.string()
            .required(t('errorMessage.requiredField')),
        category: Yup.array()
            .min(1, t('errorMessage.minOne')),
        recruitment: Yup.number()
            .required(t('errorMessage.requiredField')),
        image: Yup.mixed()
            .test({
                message: t('studySetupError.requireStudyImage'),
                test: function(value) {
                    return !!value;
                }
            })
    })
});

const getEthicsApprovalSchema = t => Yup.object({
    ethicsApproval: Yup.object({
        funder: Yup.string()
        .required(t('errorMessage.requiredField')),
        letter: Yup.mixed()
            .test({
                message: t('studySetupError.requireRebUpload'),
                test: function(value) {
                    return value && value.name;
                }
            })
    })
});

const getTimelinesSchema = t => Yup.object({
    timelines: Yup.object({
        recruitEnd: Yup.date()
            .min(Yup.ref('recruitStart'), t('studySetupError.recruitEndBeforeStart')),
        studyEnd: Yup.date()
            .min(Yup.ref('recruitEnd'), t('studySetupError.recruitStartAfterEnd')),
        resultsAvailable: Yup.date()
            .test({
                message: t('studySetupError.resultsBeforeEnd'),
                test: function(value) {
                    const ref = this.resolve(Yup.ref('studyEnd'));
                    return value.getFullYear() > ref.getFullYear() || 
                        (value.getFullYear() === ref.getFullYear()
                        && value.getMonth() >= ref.getMonth());
                }
        })
    })
});

const getLocationSchema = t => Yup.object({
    location: Yup.object({
        homeVisit: Yup.boolean(),
        online: Yup.boolean(),
        inClinic: Yup.boolean(),
        clinics: Yup.array().when("inClinic", {
            is: true,
            then: Yup.array().min(1, t('studySetupError.minOneClinicLocation'))
        })
    }).test(
        "locationTest",
        null,
        (location) => {
          if ( location.homeVisit || location.online || location.inClinic ) {
            return true; // everything is fine
          }
    
          return new Yup.ValidationError(
            t('studySetupError.minOneLocationType'),
            null,
            "location.typeSelected"
          );
        }
      )
});

const getCommitmentSchema = t => Yup.object({
    commitment: Yup.object({
        visits: Yup.string()
            .required(t('errorMessage.requiredField')),
        visitFrequency: Yup.string().when("visits", {
            is: (value) => !!value && parseInt(value) > 0,
            then: Yup.string()
                .required(t('errorMessage.requiredField')),
        })
    }),
});

const getPersonalDataSchema = t => Yup.object({
    personalData: Yup.array()
});

const getCompensationSchema = t => Yup.object({
    compensation: Yup.object({
        compensationTypeOtherDescription: Yup.string().when("compensationTypeOther", {
            is: true,
            then: Yup.string()
                .required(t('studySetupError.describeOtherCompensationType')),
        })
    }),
});

const getStudyTeamSchema = t => Yup.object({
    studyTeam: Yup.object({
        principalInvestigator: Yup.object({
            name: Yup.string()
                .required(t('errorMessage.requiredField')),
            email: Yup.string()
                .email(t('errorMessage.invalidEmail'))
                .required(t('errorMessage.requiredField')),
        }),
        researchers: Yup.array().of(
            Yup.object({
                name: Yup.string()
                    .required(t('errorMessage.requiredField')),
                email: Yup.string()
                    .email(t('errorMessage.invalidEmail'))
                    .required(t('errorMessage.requiredField')),
            })
        ).min(1, t('errorMessage.minOne')),
        coinvestigators: Yup.array().of(
            Yup.object({
                name: Yup.string()
                    .required(t('errorMessage.requiredField')),
                email: Yup.string()
                    .email(t('errorMessage.invalidEmail'))
                    .required(t('errorMessage.requiredField')),
            })
        )
    })
});

const eligibilityAnswerDefinition = {
    answer: Yup.object({
        id: Yup.string()
    })
};

const eligibilityInclusionCheck = {
    ...eligibilityAnswerDefinition,
    inclusion: Yup.object().when("answer.id", {
        is: (value) => !!value,
        then: Yup.object()
            .required(),
    }),
};

const getEligibilityMultiSelectCheck = t => ({
    ...eligibilityAnswerDefinition,
    singleOption: Yup.string().when("answer.id", {
        is: "single",
        then: Yup.string()
            .required(t('studySetupError.eligibilityRequired'))
    }),
    options: Yup.array().when("answer.id", {
        is: "mixed",
        then: Yup.array()
            .min(1, t('studySetupError.eligibilityRequired'))
    }),
    ...eligibilityInclusionCheck,
});

const getEligibilitySchema = t => Yup.object({
    demographics: Yup.object({
        age: Yup.object({
            ...eligibilityAnswerDefinition,
            min: Yup.number().when("answer.id", {
                is: (value) => value === "range" || value === "greater" || value === "equal",
                then: Yup.number()
                    .transform(value => (isNaN(value) ? undefined : value))
                    .required(t('studySetupError.eligibilityRequired')),
            }),
            max: Yup.number().when("answer.id", {
                is: (value) => value === "range" || value === "less",
                then: Yup.number()
                    .transform(value => (isNaN(value) ? undefined : value))
                    .required(t('studySetupError.eligibilityRequired'))
                    .when("min", {
                        is: value => !!value,
                        then: Yup.number()
                            .moreThan(Yup.ref("min"), t('studySetupError.minDiagnosisDurationGreaterThanMax'))
                    })
            })
        }),
        ...eligibilityInclusionCheck,
    }),
    lifestyle: Yup.object({
        activity: Yup.object(eligibilityInclusionCheck),
    }),
    monitoring: Yup.object({
        monitor: Yup.object(getEligibilityMultiSelectCheck(t)),
    }),
    insulin: Yup.object({
        device: Yup.object(getEligibilityMultiSelectCheck(t)),
        duration: Yup.object(eligibilityInclusionCheck),
        regimen: Yup.object(getEligibilityMultiSelectCheck(t)),
    }),
    advanced: Yup.object({
        hybrid: Yup.object(eligibilityInclusionCheck),
    }),
    medication: Yup.object({
        diabetes: Yup.object(eligibilityInclusionCheck),
        immunosuppressant: Yup.object(eligibilityInclusionCheck),
    }),
    a1c: Yup.object({
        value: Yup.object({
            ...eligibilityAnswerDefinition,
            min: Yup.number().when("answer.id", {
            is: (value) => value === "range" || value === "greater" || value === "equal",
            then: Yup.number()
                .transform(value => (isNaN(value) ? undefined : value))
                .required(t('studySetupError.eligibilityRequired')),
            }),
            max: Yup.number().when("answer.id", {
                is: (value) => value === "range" || value === "less",
                then: Yup.number()
                    .transform(value => (isNaN(value) ? undefined : value))
                    .required(t('studySetupError.eligibilityRequired'))
                    .when("min", {
                        is: value => !!value,
                        then: Yup.number()
                            .moreThan(Yup.ref("min"), t('studySetupError.minA1CValueGreaterThanMax'))
                    })
            })
        }),
        ...eligibilityInclusionCheck,
        timeframe: Yup.object(eligibilityInclusionCheck),
    }),
    ketoacidosis: Yup.object({
        ketoepisode: Yup.object(eligibilityInclusionCheck),
        assistance: Yup.object(eligibilityInclusionCheck)
    }),
    hypoglycemia: Yup.object({
        hypoepisode: Yup.object(eligibilityInclusionCheck),
        assistance: Yup.object(eligibilityInclusionCheck)
    }),
    pregnancy: Yup.object({
        pregnant: Yup.object(eligibilityInclusionCheck),
        breastfeeding: Yup.object(eligibilityInclusionCheck),
        attempting: Yup.object(eligibilityInclusionCheck),
    }),
    secondary: Yup.object({
        nephropathy: Yup.object(eligibilityInclusionCheck),
        neuropathy: Yup.object(eligibilityInclusionCheck),
        retinopathy: Yup.object(eligibilityInclusionCheck),
    }),
    conditions: Yup.object({
        celiac: Yup.object(eligibilityInclusionCheck),
        footUlcers: Yup.object(eligibilityInclusionCheck),
        gastroporesis: Yup.object(eligibilityInclusionCheck),
        heartDisease: Yup.object(eligibilityInclusionCheck),
        hepatitis: Yup.object(eligibilityInclusionCheck),
        hypertension: Yup.object(eligibilityInclusionCheck),
        hypotension: Yup.object(eligibilityInclusionCheck),
        liver: Yup.object(eligibilityInclusionCheck),
        psychiatric: Yup.object(eligibilityInclusionCheck),
    }),
    customCategory: Yup.object({
        categories: Yup.array().of(
            Yup.object({
                critera: Yup.string()
                    .required(t('errorMessage.requiredField')),
            }),
        )
    }),
});

export const getValidationSchema = t => ([
    getStudyInformationSchema(t),
    getEthicsApprovalSchema(t),
    getTimelinesSchema(t),
    getLocationSchema(t),
    getCommitmentSchema(t),
    getPersonalDataSchema(t),
    getCompensationSchema(t),
    getEligibilitySchema(t),
    getStudyTeamSchema(t)
]);

export const getFullValidationSchema = t => (
    getStudyInformationSchema(t)
        .concat(getEthicsApprovalSchema(t))
        .concat(getTimelinesSchema(t))
        .concat(getLocationSchema(t))
        .concat(getCommitmentSchema(t))
        .concat(getPersonalDataSchema(t))
        .concat(getCompensationSchema(t))
        .concat(getEligibilitySchema(t))
        .concat(getStudyTeamSchema(t))
);