import React from 'react';
import { CustomTextField } from './CustomInputs';

function TextBox({maxLength, variant, ...props}) {
    const inputProps = maxLength && {inputProps: { maxLength : maxLength }};
    return (
        <CustomTextField
            {...props}
            {...inputProps}
            variant={variant}
            fullWidth
        />
    );
}

TextBox.defaultProps = {
    variant: "outlined"
};

export default TextBox;