import React from 'react';
import { useField } from 'formik';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import SelectorGroup from 'Components/Parts/Form/Groups/SelectorGroup';
import { getCheckBoxList } from 'data/study/studyPersonalDataSelection';

export default function PersonalData() {
    const { t } = useTranslation();
    const checkBoxList = getCheckBoxList(t);
    const halfLength = checkBoxList.length / 2;
    const checkBoxListPartOne = checkBoxList.slice(0, halfLength);
    const checkBoxListPartTwo = checkBoxList.slice(halfLength, checkBoxList.length);

    const [field] = useField("personalData");

    return (
        <Grid container>
            <Grid item xs={12} md={6}>
                <SelectorGroup name="personalData" items={checkBoxListPartOne} formikValues={field.value}/>
            </Grid>
            <Grid item xs={12} md={6}>
                <SelectorGroup name="personalData" items={checkBoxListPartTwo} formikValues={field.value}/>
            </Grid>
        </Grid>
    );
}