import React from 'react';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { sidebarItems } from 'data/study/sidebarItems';
import { useTranslation } from 'react-i18next';
import StudyHeader from '../StudyHeader';

export const CardContent = styled.div`
    height: 100%;
    background-color: white;
    padding: 1.5em;
    padding-bottom: 3em;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(91, 128, 233, 0.1), 0 0 1px 0 rgba(91, 128, 233, 0.05);
    word-break: break-word;
`;

const StudyHeaderWrapper = styled(Grid)`
    margin-bottom: 24px;
`;

const IndentedBlock = styled(Grid)`
    margin-left: 40px;
`;

export default function DetailsCard({name, children}) {
    const { t } = useTranslation();
    const { getTitle, icon } = sidebarItems.find(item => item.key === name);

    return (
        <CardContent>
            <Grid container>
                <StudyHeaderWrapper container item xs={12}>
                    <StudyHeader title={getTitle(t)} icon={icon} size="small" />
                </StudyHeaderWrapper>
                <IndentedBlock container item xs={12}>
                    {children}
                </IndentedBlock>
            </Grid>
        </CardContent>
    );
}