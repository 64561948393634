import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ReactComponent as QuestionOutlineIcon } from 'resources/icons/questionOutlineIcon.svg';
import {
    BarText,
    BarButton,
    EligibilityBarTemplate,
} from './EligibilityBarTemplate';


export default function BaselineQuestionnaireBar() {
    const { t } = useTranslation();
    const history = useHistory();
    return (
        <EligibilityBarTemplate color="primary">
            <SvgIcon component={QuestionOutlineIcon} style={{ fontSize: "2rem" }} viewBox="0 0 32 33" />
            <BarText>{t('eligibilityBar.baselineQuestionnaireText')}</BarText>
            <BarButton color="primary" onClick={() => history.push('/questionnaire')}>{t('eligibilityBar.baselineQuestionnaireButtonText')}</BarButton>
        </EligibilityBarTemplate>
    );
}
