import React from 'react';
import { Field } from 'formik';
import { Grid } from '@material-ui/core';
import { TextBox } from 'Components/Parts/Form/TextBoxes';
import Dropzone from 'Components/Parts/Form/Dropzones/DocumentDropzone';
import { useTranslation } from 'react-i18next';

export default function EthicsApproval() {
    const { t } = useTranslation();
    return (
        <Grid container spacing={4}>
            <Grid item xs={12}>
                <Field
                    component={TextBox} 
                    name="ethicsApproval.funder" 
                    type="text"
                    label={t('studySetupEthics.funderPlaceholder')}
                    variant="filled"
                />
            </Grid>
            <Grid item xs={12}>
                <Field
                    component={Dropzone}
                    title={t('studySetupEthics.rebApprovalLabel')}
                    description={t('studySetupEthics.rebApprovalDescription')}
                    explanation={t('studySetupEthics.rebApprovalExplanation')}
                    name="ethicsApproval.letter"
                    maxFileSize={8388608}
                />
            </Grid>
        </Grid>
    );
}