import React from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { format as formatDate } from 'date-fns';
import { CardDetailRow, CardDetailTextValue } from 'Components/Parts/Study/StudyReview';
import { getDateLocale } from 'translations/i18n';
import CardFieldError from '../CardFieldError';

export default function TimelinesDetails({recruitStart, recruitEnd, studyEnd, resultsAvailable, meta}) {
    const { t, i18n } = useTranslation();

    const recruitmentStartDateString = recruitStart && formatDate(recruitStart, 'dd/MM/YYY');
    const recruitmentEndDateString = recruitEnd && formatDate(recruitEnd, 'dd/MM/YYY');
    const studyEndDateString = studyEnd ? formatDate(studyEnd, 'dd/MM/YYY') : "-";
    const availableDateString = resultsAvailable ? formatDate(resultsAvailable, 'MMMM yyyy', { locale: getDateLocale(i18n.language) }) : "-";

    return (
        <Grid container item spacing={2}>
            <CardDetailRow name={t('studySetupReview.recruitmentLabel')} value={recruitStart ? <CardDetailTextValue>{recruitmentStartDateString + " - " + recruitmentEndDateString}</CardDetailTextValue> : "-"} />
            <CardDetailRow name={t('studySetupReview.endDateLabel')} value={<CardDetailTextValue>{studyEndDateString}</CardDetailTextValue>} />
            <CardDetailRow name={t('studySetupReview.resultsDateLabel')} value={<CardDetailTextValue>{availableDateString}</CardDetailTextValue>} />
            <CardFieldError meta={meta}/>
        </Grid>
    );
}