import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import DeleteAccountModalFlow from 'Components/Parts/Modals/AccountModals/DeleteAccountModalFlow';
import { FluidList } from 'Components/Styles/Lists/FluidList';
import ListSwitchRow from 'Components/Parts/Lists/ListSwitchRow';
import ListLinkRow from 'Components/Parts/Lists/ListLinkRow';
import { getLanguageText } from 'translations/i18n';
import { UserType, Page } from 'Constants/constants';
import { openLanguageModal, openLogoutModal } from 'redux/reducers/dashboard/actions';
import { toggleCommunicationSetting } from 'redux/reducers/account/actions';


export default function () {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const [showDeleteAccountModalFlow, setShowDeleteAccountModalFlow] = useState(false);
    const accountType = useSelector(state => state.authorization.accountType);
    const enabledNotifications = useSelector(state => state.authorization.profile.allowsCommunication);

    const handleNotificationChange = () => {
        dispatch(toggleCommunicationSetting())
    };

    return (
        <FluidList>
            <ListSwitchRow
                text={t('accountSettingsPage.emailNotificationText')}
                onText={t('accountSettingsPage.emailNotificationSwitchOnText')}
                offText={t('accountSettingsPage.emailNotificationSwitchOffText')}
                onChange={handleNotificationChange}
                isOn={!!enabledNotifications}
            />
            <ListLinkRow
                text={t('accountSettingsPage.languageText')}
                actionText={getLanguageText(i18n.language)}
                showArrow
                onClick={() => dispatch(openLanguageModal())}
            />
            <ListLinkRow
                text={t('accountSettingsPage.changePasswordText')}
                onClick={() => history.push(Page.CHANGE_PASSWORD)}
                showArrow
            />
            {accountType === UserType.PATIENT && <ListLinkRow
                text={t('accountSettingsPage.deleteAccountText')}
                onClick={() => setShowDeleteAccountModalFlow(true)}
                showArrow
            />}
            <ListLinkRow
                text={t('accountSettingsPage.logOutText')}
                onClick={() => dispatch(openLogoutModal())}
                showArrow
            />
            <DeleteAccountModalFlow open={showDeleteAccountModalFlow} onClose={() => setShowDeleteAccountModalFlow(false)} />
        </FluidList>
    );
}
