import React from 'react';
import { useTranslation } from 'react-i18next';
import NumericFieldEligibilityContainer,
{MinField, MaxField, RangeField} from '../NumericFieldEligibilityContainer';
import { diagnosisSelectionOptions } from 'data/study/Eligibility/eligibilitySelectionOptions';


const name="eligibility.condition.diagnosis";

const getComponentOptions = t => {
    const minLabel = t('studyEligibilityCondition.mixDurationLabel');
    const maxLabel = t('studyEligibilityCondition.maxDurationLabel');
    return Object.freeze([
        Object.freeze({id: "range", component: <RangeField name={name} minLabel={minLabel} maxLabel={maxLabel}/>}),
        Object.freeze({id: "less", component: <MaxField name={name} label={maxLabel}/>}),
        Object.freeze({id: "greater", component: <MinField name={name} label={minLabel}/>}),
    ]);
};

// Register all the required translation keys
const t = _ => {};
t('studyEligibilityComparitivePhrase.conditionDiagnosis.include.range');
t('studyEligibilityComparitivePhrase.conditionDiagnosis.include.less');
t('studyEligibilityComparitivePhrase.conditionDiagnosis.include.greater');
t('studyEligibilityComparitivePhrase.conditionDiagnosis.exclude.range');
t('studyEligibilityComparitivePhrase.conditionDiagnosis.exclude.less');
t('studyEligibilityComparitivePhrase.conditionDiagnosis.exclude.greater');
t('studyEligibilityComparitivePhrase.conditionDiagnosis.undefined.range');
t('studyEligibilityComparitivePhrase.conditionDiagnosis.undefined.less');
t('studyEligibilityComparitivePhrase.conditionDiagnosis.undefined.greater');

t('studyEligibilityReview.conditionDiagnosis.greater');
t('studyEligibilityReview.conditionDiagnosis.less');
t('studyEligibilityReview.conditionDiagnosis.range');

export default function StudyEligibilityDiagnosis({title}) {
    const { t } = useTranslation();
    return (
        <NumericFieldEligibilityContainer
            criteria="conditionDiagnosis"
            name={name}
            title={title}
            answerOptions={diagnosisSelectionOptions}
            componentOptions={getComponentOptions(t)}
        />
    );
}