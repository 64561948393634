import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
    selectAccountTypePatient, 
    selectAccountTypeResearcher,
} from 'redux/reducers/accountCreation/index';
import Dropdown from 'Components/Parts/Form/Dropdowns/SingleDropdown';
import { UserType } from 'Constants/constants';
import { resetTermsPage } from 'redux/reducers/accountCreation/actions';


const AccountTypeValues = [
    { id: UserType.PATIENT, getLabel: t => t('accountTypeSelection.patientOptionText') },
    { id: UserType.RESEARCHER, getLabel: t => t('accountTypeSelection.researcherOptionText') },
];

function AccountTypeDropdown({ onSelect }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const handleSelection = (event) => {
        const action = event.target.value.id === UserType.RESEARCHER
            ? selectAccountTypeResearcher
            : selectAccountTypePatient;

        dispatch(action);
        dispatch(resetTermsPage);
        onSelect && onSelect(event.target.value.id);
    };

    return (
        <Dropdown
            name="accountType"
            defaultText={t('accountTypeSelection.dropdownText')}
            values={AccountTypeValues}
            handleChange={handleSelection}
        />
    );
}

export default AccountTypeDropdown;
