import { cloneDeep } from 'lodash';
import * as eligibilityOptions from 'data/study/Eligibility/eligibilitySelectionOptions';

const allMultiSelectOptions = eligibilityOptions.ethnicityOptions.concat(eligibilityOptions.deviceOptions, eligibilityOptions.monitorOptions, eligibilityOptions.mdiAnswerOptions, eligibilityOptions.pumpOptions);
const { singleSelectOptionMap } = eligibilityOptions;

export const isCustomEligibility = serverCoding => {
    return /^CUSTOM_[0-9]+$/.test(serverCoding);
};

export function updateServerCriteria(criteria) {
    const newCriteria = cloneDeep(criteria);

    if (isCustomEligibility(newCriteria.serverCoding)) {
        return newCriteria;
    }

    newCriteria.inclusion = eligibilityOptions.inclusionOptions.find(inclusion => inclusion.id === criteria.inclusion) ?? '';
    if (criteria.singleOption) {
        newCriteria.singleOption = allMultiSelectOptions.find(option => option.id === criteria.singleOption);
    } else if (criteria.options && criteria.options.length > 0) {
        newCriteria.options = allMultiSelectOptions.filter(msOption => criteria.options.some(option => option === msOption.id));
    } else if (criteria.answer && criteria.answer === 'mixed') {
        newCriteria.options = [];
    }

    // The server passes down the answer id, use that to find the answer option
    const options = singleSelectOptionMap[newCriteria.serverCoding];
    if (options) {
        newCriteria.answer = options.find(option => option.id === newCriteria.answer);
    } else if (process.env.NODE_ENV === 'development') {
        console.error('Missing `serverCoding` in singleSelectOptionMap');
    }

    return newCriteria;
}