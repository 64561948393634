import React from 'react';
import { useTranslation } from 'react-i18next';
import MultiSelectEligibilityContainer from '../MultiSelectEligibilityContainer';
import { ethnicityOptions, ethnicitySelectionOptions } from 'data/study/Eligibility/eligibilitySelectionOptions';


const name="eligibility.demographics.ethnicity";

// Register all the required translation keys
const t = _ => {};
t('studyEligibilityComparitivePhrase.demographicsEthnicity.include.single');
t('studyEligibilityComparitivePhrase.demographicsEthnicity.include.mixed');
t('studyEligibilityComparitivePhrase.demographicsEthnicity.exclude.single');
t('studyEligibilityComparitivePhrase.demographicsEthnicity.exclude.mixed');
t('studyEligibilityComparitivePhrase.demographicsEthnicity.undefined.single');
t('studyEligibilityComparitivePhrase.demographicsEthnicity.undefined.mixed');

export default function StudyEligibilityEthnicity({title}) {
    const { t } = useTranslation();
    return (
        <MultiSelectEligibilityContainer
            criteria="demographicsEthnicity"
            name={name}
            title={title}
            answerOptions={ethnicitySelectionOptions}
            selectOptions={ethnicityOptions}
            processValue={value => value}
            processValues={values => values.map(v => v.getLabel(t)).join(', ')}
        />
    );
}