import React, { useState, useEffect } from 'react';
import {
    Grid,
    Hidden,
    useMediaQuery,
    SvgIcon,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { useRouteMatch, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { FluidList } from 'Components/Styles/Lists/FluidList';
import ListHeadingRow from 'Components/Parts/Lists/ListHeadingRow';
import ListLinkRow from 'Components/Parts/Lists/ListLinkRow';
import { ReactComponent as InfoOutlinedIcon } from 'resources/images/icons/study/infoOutline.svg';
import { ReactComponent as QuestionOutlineIcon } from 'resources/icons/questionOutlineIcon.svg';
import { ReactComponent as SettingsOutlineIcon } from 'resources/images/icons/tabBar/settingsOutline.svg';
import { Page, UserType } from 'Constants/constants';


const ContentContainer = styled(Grid)`
    @media (min-width: 960px) {
        padding-left: 40px;
    }
`;

export default function ({ children }) {
    const { t } = useTranslation();
    const location = useLocation();
    const theme = useTheme();
    const accountType = useSelector(state => state.authorization.accountType);
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const match = useRouteMatch(Page.SETTINGS);
    const [shouldHideMenu, setShouldHideMenu] = useState(true);

    // Only show the menu when...
    // 1. Is the settings page (not a child route of settings)
    // 2. Desktop
    // Otherwise hide the menu
    useEffect(() => {
        setShouldHideMenu(isMobile && !match?.isExact);
    }, [match, isMobile]);

    return (
        <Grid container>
            <Hidden smDown={shouldHideMenu}>
                <Grid item xs={12} md={4}>
                    <FluidList>
                        {isMobile &&
                            <ListHeadingRow
                                icon={<SvgIcon component={SettingsOutlineIcon} />}
                                text={t('settingsPage.title')}
                            />
                        }
                        <ListLinkRow
                            to={Page.SETTINGS_ACCOUNT}
                            icon={<LockOutlinedIcon />}
                            text={t('settingsPage.accountRowText')}
                            detailText={t('settingsPage.accountRowDetailText')}
                            selected={location.pathname.startsWith(Page.SETTINGS_ACCOUNT)}
                        />
                        <ListLinkRow
                            to={Page.SETTINGS_ABOUT}
                            icon={<SvgIcon component={InfoOutlinedIcon} />}
                            text={t('settingsPage.aboutRowText')}
                            detailText={t('settingsPage.aboutRowDetailText')}
                            selected={location.pathname.startsWith(Page.SETTINGS_ABOUT)}
                        />
                        {accountType === UserType.PATIENT && <ListLinkRow
                            to={Page.SETTINGS_DATA}
                            icon={<SvgIcon component={InfoOutlinedIcon} />}
                            text={t('settingsPage.yourDataRowText')}
                            detailText={t('settingsPage.yourDataRowDetailText')}
                            selected={location.pathname.startsWith(Page.SETTINGS_DATA)}
                        />}
                        <ListLinkRow
                            to={Page.SETTINGS_HELP}
                            icon={<SvgIcon component={QuestionOutlineIcon} viewBox="0 0 32 33" />}
                            text={t('settingsPage.helpText')}
                            detailText={t('settingsPage.helpDetailText')}
                            selected={location.pathname.startsWith(Page.SETTINGS_HELP)}
                        />
                    </FluidList>
                </Grid>
            </Hidden>
            <ContentContainer item xs={12} sm={12} md={8}>
                {children}
            </ContentContainer>
        </Grid>
    );
}
