
export const getEligibilityReviewPhrases = (t) => ({
    BIRTHDATE: (criteria) => t(`studyEligibilityReview.demographicsAge.${criteria.answer.id}`, {
        minValue: criteria.min,
        maxValue: criteria.max,
    }),
    ETHNICITY: (criteria) => t('studyEligibilityReview.demographicsEthnicity', getMultiSelectProperty(criteria, t)),
    BIRTHSEX: (criteria) =>  t('studyEligibilityReview.demographicsSex', multiCaseProperty(criteria.answer, t)),
    TWIN: (criteria) =>  t('studyEligibilityReview.demographicsGenetics', multiCaseProperty(criteria.answer, t)),
    DIAGNOSIS: (criteria) => t(`studyEligibilityReview.conditionDiagnosis.${criteria.answer.id}`, {
        minValue: criteria.min,
        maxValue: criteria.max,
    }),
    BRITTLE: (criteria) =>  yesNoPhrase(criteria, t),
    EXERCISE: (criteria) => t('studyEligibilityReview.lifestyleActivity', multiCaseProperty(criteria.answer, t)),
    MONITOR: (criteria) => t('studyEligibilityReview.monitoringMonitor', getMultiSelectProperty(criteria, t)),
    DEVICES: (criteria) => t('studyEligibilityReview.insulinDevice', getMultiSelectProperty(criteria, t)),
    INSULIN_DURATION: (criteria) => t('studyEligibilityReview.insulinDuration', multiCaseProperty(criteria.answer, t)),
    HYBRID_SYSTEM: (criteria) => t('studyEligibilityReview.advancedHybrid', multiCaseProperty(criteria.answer, t)),
    MEDICATION: (criteria) => yesNoPhrase(criteria, t),
    IMMUNOSUPPRESSANT: (criteria) => yesNoPhrase(criteria, t),
    A1C_VALUE: (criteria) => t(`studyEligibilityReview.a1cValue.${criteria.answer.id}`, {
        minValue: criteria.min,
        maxValue: criteria.max,
    }),
    A1C_TIME: (criteria) => t('studyEligibilityReview.a1cTimeframe', multiCaseProperty(criteria.answer, t)),
    KETO: (criteria) => t('studyEligibilityReview.ketoacidosisKetoepisode', multiCaseProperty(criteria.answer, t)),
    KETO_HOSPITAL: (criteria) => t('studyEligibilityReview.ketoacidosisHospital', multiCaseProperty(criteria.answer, t)),
    HYPOGLYCEMIA: (criteria) => t('studyEligibilityReview.hypoglycemiaHypoepisode', multiCaseProperty(criteria.answer, t)),
    HYPOGLYCEMIA_HOSPITAL: (criteria) => t('studyEligibilityReview.hypoglycemiaHospital', multiCaseProperty(criteria.answer, t)),
    PREGNANCY: (criteria) => yesNoPhrase(criteria, t),
    BREASTFEEDING: (criteria) => yesNoPhrase(criteria, t),
    TRY_PREGNANCY: (criteria) => yesNoPhrase(criteria, t),
    SECONDARY_NEPHRO: (criteria) => yesNoPhrase(criteria, t),
    SECONDARY_NEURO: (criteria) => yesNoPhrase(criteria, t),
    SECONDARY_RETINO: (criteria) => yesNoPhrase(criteria, t),
    CONDITIONS_CELIAC: (criteria) => yesNoPhrase(criteria, t),
    CONDITIONS_FOOTULCER: (criteria) => yesNoPhrase(criteria, t),
    CONDITIONS_GASTRO: (criteria) => yesNoPhrase(criteria, t),
    CONDITIONS_HEART: (criteria) => yesNoPhrase(criteria, t),
    CONDITIONS_HEPATITIS: (criteria) => yesNoPhrase(criteria, t),
    CONDITIONS_HYPERT: (criteria) => yesNoPhrase(criteria, t),
    CONDITIONS_HYPOT: (criteria) => yesNoPhrase(criteria, t),
    CONDITIONS_LIVER: (criteria) => yesNoPhrase(criteria, t),
    CONDITIONS_PSYCH: (criteria) => yesNoPhrase(criteria, t),
});

function yesNoPhrase({ serverCoding, answer }, t) {
    return t(`studyEligibilityReview.${serverCoding}_yesNo.${answer.id}`)
}

// Gives translators the ability to use capitized or not capitalized values
function multiCaseProperty(answer, t) {
    const value = getLabel(answer, t);
    return {
        Value: value,
        value: value.toLowerCase(),
    };
};

function getLabel({ id, label, getLabel }, t) {
    return getLabel
        ? getLabel(t)
        : label ?? id;
}

function getMultiSelectProperty(criteria, t) {
    const value = criteria.singleOption
        ? getLabel(criteria.singleOption, t)
        : criteria.options.map(option => getLabel(option, t)).join(', ');
    return {
        Value: value,
        value: value.toLowerCase(),
    };
}
