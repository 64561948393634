import React, { useState, useEffect } from 'react';

import DeleteAccountModal from './DeleteAccountModal';
import DonateDataModal from './DonateDataModal';
import { useDispatch } from 'react-redux';
import { deleteAccount } from 'redux/reducers/authorization/actions';


function DeleteAccountModalFlow({ open, onClose, ...props }) {
    const dispatch = useDispatch();
    const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(open);
    const [showDonateDataModal, setShowDonateDataModal] = useState(false);

    useEffect(() => {
        open && setShowDeleteAccountModal(true);
    }, [open]);

    const handleShouldDeleteAccount = (shouldDelete) => {
        setShowDeleteAccountModal(false);
        shouldDelete
            ? setShowDonateDataModal(true)
            : onClose();
    };

    const handleDataDonation = (shouldDonate) => {
        if (shouldDonate === undefined) { // If exit in via another method (ex: esc key)
            setShowDonateDataModal(false);
            onClose();
            return;
        }

        dispatch(deleteAccount(shouldDonate));
    };

    return (
        <React.Fragment>
            <DeleteAccountModal open={showDeleteAccountModal} shouldDelete={handleShouldDeleteAccount} />
            <DonateDataModal open={showDonateDataModal} shouldDonate={handleDataDonation} />
        </React.Fragment>
    );
}

export default DeleteAccountModalFlow;
