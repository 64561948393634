export default function(studyId, approvalValues) {
    const approvalArray = Object.entries(approvalValues)
        .flatMap(([title, value]) => {
            if (value.approved === "approved") {
                return;
            }

            if (value.type === "multiple") {
                return value.sections.map(section => {
                    if (section.declined) {
                        return {
                            section: title,
                            field: section.heading,
                            feedback: section.feedback
                        };
                    }

                    return null;
                });
            } else {
                return {
                    section: title,
                    feedback: value.feedback
                };
            }
        })
        .filter(Boolean);

    return {
        study: studyId,
        feedback: approvalArray
    }
}