import { TextField } from 'formik-material-ui';
import { withStyles, FormControl } from '@material-ui/core';

function hasValue(field) {
    return !(!field.value || field.value.length === 0)
}

function getLabelColor(props, theme) {
    if (props.error) {
        return theme.palette.error.main;
    } else if (props.disabled) {
        return theme.palette.text.disabled;
    } else if (!hasValue(props.field)) {
        return theme.palette.grey[700];
    }

    return theme.palette.primary.main;
}

function getHelperTextColor(props, theme) {
    if (props.error) {
        return theme.palette.error.main;
    } else if (props.displayRemainingLength && props.hasFocus) {
        return theme.palette.primary.main;
    }

    return theme.palette.text.secondary;
}

const style = (props, theme) => {    
    return {
        "& .MuiFilledInput-root": {
            backgroundColor: hasValue(props.field) ? "rgba(255, 255, 255, 0.00)" : "rgba(91, 128, 233, 0.08)"
        },
        "& .MuiFormLabel-root": {
            color: props.variant === "filled" && getLabelColor(props, theme)
        },
        "& .MuiFilledInput-underline": hasValue(props.field) ? {
            "&:before": {
                borderBottom: `2px solid ${theme.palette.primary.main}`
            },
            "&:hover": {
                "&:before": {
                    borderBottom: `2px solid ${theme.palette.primary.light}`
                }
            }
        } : {
            "&:before": {
                borderBottom: `0px solid #000`
            }
        },
        "& .MuiSelect-iconFilled": {
            fill: theme.palette.primary.main
        },
        "& .MuiFormHelperText-root": {
            color: getHelperTextColor(props, theme)
        }
    }
};

export const CustomTextField = withStyles(theme => ({
    root: props => style(props,theme)
}))(TextField)

export const CustomFormControl = withStyles(theme => ({
    root: props => style(props,theme)
}))(FormControl)