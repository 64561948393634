import getStudyRequest from 'api/study/getStudy';
import getStudyParticipants from 'api/study/getStudyParticipants';
import updateStudyParticipant from 'api/study/updateStudyParticipant';
import getResearchStudyRequest from 'api/fhir/getResearchStudy';
import { enqueueGlobalUserMessageState } from 'redux/reducers/globalUserMessaging/index';
import {
    OrderDirection,
    ParticipantStatus,
} from 'Constants/constants';


export const SET_INTERESTED_PARTICIPANTS = 'SET_INTERESTED_PARTICIPANTS';
export const SET_RECRUITED_PARTICIPANTS = 'SET_RECRUITED_PARTICIPANTS';
export const SET_DECLINED_PARTICIPANTS = 'SET_DECLINED_PARTICIPANTS';
function setParticipants(status, payload) {
    return {
        type: status,
        payload,
    };
}

export const ADD_TO_MY_STUDY_PARTICIPANT_LIST = 'ADD_TO_MY_STUDY_PARTICIPANT_LIST';
function addToParticipantList(status, listData) {
    return {
        type: ADD_TO_MY_STUDY_PARTICIPANT_LIST,
        payload: {
            ...listData,
            status,
        },
    }
};

export const REMOVE_PARTICIPANT_FROM_LIST = 'REMOVE_PARTICIPANT_FROM_LIST';
function removeParticipant(payload) {
    return {
        type: REMOVE_PARTICIPANT_FROM_LIST,
        payload,
    };
}

export const SET_MY_STUDY = 'SET_MY_STUDY';
function setStudy(study) {
    return {
        type: SET_MY_STUDY,
        payload: {
            study,
        },
    };
}

export const SET_MY_STUDY_RESOURCE = 'SET_MY_STUDY_RESOURCE';
function setStudyResource(study) {
    return {
        type: SET_MY_STUDY_RESOURCE,
        payload: {
            study,
        },
    };
}

export const RESET_MY_STUDY_PAGE = 'RESET_MY_STUDY_PAGE';
function resetMyStudy() {
    return {
        type: RESET_MY_STUDY_PAGE,
    };
}

export const UPDATE_MY_STUDY_PARTICIPANT_NOTE = 'UPDATE_MY_STUDY_PARTICIPANT_NOTE';
function updateNoteInParticipantList(participantId, status, note) {
    return {
        type: UPDATE_MY_STUDY_PARTICIPANT_NOTE,
        payload: {
            status,
            participantId,
            note,
        },
    };
}

export const OPEN_MY_STUDY_NOTE_MODAL = 'OPEN_MY_STUDY_NOTE_MODAL';
export function openNoteModalForParticipant(participant) {
    return {
        type: OPEN_MY_STUDY_NOTE_MODAL,
        payload: {
            participant,
        },
    };
}

export const CLOSE_MY_STUDY_NOTE_MODAL = 'CLOSE_MY_STUDY_NOTE_MODAL';
export function closeNoteModal() {
    return {
        type: CLOSE_MY_STUDY_NOTE_MODAL,
    };
}

export function setParticipantListSortOrder(status, field) {
    return (dispatch, getState) => {
        const myStudy = getState().myStudy;
        const { orderBy, order } = myStudy[status];

        let newOrderBy = orderBy;
        let newOrderDirection = null;

        if (orderBy !== field) {                        // Changing the order field completely 
            newOrderBy = field;
            newOrderDirection = OrderDirection.ASC;
        } else if (order === OrderDirection.ASC) {      // Toggling, going from asc to desc
            newOrderDirection = OrderDirection.DESC;
        } else {                                        // Go back to no sort state
            newOrderBy = null;
        }

        getStudyParticipants(myStudy.study.id, {
            status,
            orderBy: newOrderBy,
            order: newOrderDirection,
        }).then(function({ data }) {
            dispatch(setParticipants(`SET_${status}_PARTICIPANTS`, {
                ...data,
                orderBy: newOrderBy,
                order: newOrderDirection,
            }));
        });
    };
}

// Cannot rely on `nextPage` since user's can edit the list
export function getNextPageForList(status) {
    return (dispatch, getState) => {
        const myStudy = getState().myStudy;
        const { entries, pageSize, orderBy, order } = myStudy[status];
        if (pageSize <= 0) {
            return;
        }

        // page is an index, first page starts at 0
        // We have to recalculate the page because entries may have gotten smaller.
        // This happens when the user moved participants from one list to another.
        const page = Math.floor(entries.length / pageSize);
        getStudyParticipants(myStudy.study.id, { status, page, orderBy, order })
            .then(function ({ data }) {
                dispatch(addToParticipantList(status, data));
            });
    };
}

/**
 * 1. Update the participant status
 * 2. Reload the participant list that the participant will now change to
 * 3. Remove the participant from the previous list they were in
 */
function updateParticipantStatusAndList(participantId, currentStatus, newStatus) {
    return (dispatch, getState) => {
        const studyId = getState().myStudy.study.id;
        updateStudyParticipant(participantId, studyId, {
            status: newStatus,
        }).then(() => {
            getStudyParticipants(studyId, { status: newStatus }).then(function ({ data }) {
                dispatch(setParticipants(`SET_${newStatus}_PARTICIPANTS`, data));
                dispatch(removeParticipant({
                    participantId,
                    previousStatus: currentStatus,
                }));
            });
        });
    };
}

export const MARK_PARTICIPANT_INTERESTED = 'MARK_PARTICIPANT_INTERESTED';
export function markParticipantInterested(participantId, currentStatus) {
    return updateParticipantStatusAndList(participantId, currentStatus, ParticipantStatus.INTERESTED);
}

export const MARK_PARTICIPANT_DECLINED = 'MARK_PARTICIPANT_DECLINED';
export function markParticipantDeclined(participantId, currentStatus) {
    return updateParticipantStatusAndList(participantId, currentStatus, ParticipantStatus.DECLINED);
}

export const MARK_PARTICIPANT_RECRUITED = 'MARK_PARTICIPANT_RECRUITED';
export function markParticipantRecruited(participantId, currentStatus) {
    return updateParticipantStatusAndList(participantId, currentStatus, ParticipantStatus.RECRUITED);
}

export function updateParticipantNote(participant, note) {
    return (dispatch, getState) => {
        const studyId = getState().myStudy.study.id;
        updateStudyParticipant(participant.patientUserId, studyId, {
            note,
        }).then(() => {
            dispatch(updateNoteInParticipantList(participant.patientUserId, participant.status, note));    
        });
    };
}

/**
 * Grabs study information (including fhir data)
 *
 * Also gets the participants (interested, recruited and declined)
 */
export function getMyStudy(studyId) {
    return dispatch => {
        dispatch(resetMyStudy());

        getStudyRequest(studyId).then(function ({ data: studyData }) {
            dispatch(setStudy(studyData));

            getResearchStudyRequest(studyData.fhirId).then(function({ data: fhirData }) {
                const studyResource = fhirData.entry.find(item => item.resource.resourceType === 'ResearchStudy').resource;
                dispatch(setStudyResource(studyResource));

                Promise.all([
                    getStudyParticipants(studyId, { status: ParticipantStatus.INTERESTED }),
                    getStudyParticipants(studyId, { status: ParticipantStatus.RECRUITED }),
                    getStudyParticipants(studyId, { status: ParticipantStatus.DECLINED }),
                ]).then(function([{ data: interestedData }, { data: recruitedData }, { data: declinedData }]){
                    dispatch(setParticipants(SET_INTERESTED_PARTICIPANTS, interestedData));
                    dispatch(setParticipants(SET_RECRUITED_PARTICIPANTS, recruitedData));
                    dispatch(setParticipants(SET_DECLINED_PARTICIPANTS, declinedData));
                });
            });
        }).catch(function () {
            dispatch(enqueueGlobalUserMessageState(
                "error", 
                "error.studyNotFound"
            ));
        });
    }
}
