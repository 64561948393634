import React from 'react';
import { useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { approveStudy, rejectStudy } from 'redux/reducers/studyApproval/actions';

const defaultValue = (sections) => {
    if (sections) {
        return {
            type: "multiple",
            approved: "",
            sections: sections.map(section => ({
                    heading: section,
                    declined: false,
                    feedback: ""
                })),
            sectionsComplete: undefined
        };
    } else {
        return {
            type: "single",
            approved: "",
            feedback: ""
        };
    }
};

const multipleSectionValidator = t => Yup.object({
    type: Yup.string().required(),
    approved: Yup.string().required(t("studyApproval.selectApproveOrDecline")),
    sections: Yup.array()
        .of(Yup.object({
            heading: Yup.string(),
            declined: Yup.boolean().required(),
            feedback: Yup.string()
                .when("declined", {
                    is: true,
                    then: Yup.string().required(t("studyApproval.provideFeedback"))
                })
        })),
    sectionsComplete: Yup.string()
        .when(["approved", "sections"], {
            is: (approved, sections) => {
                const declined = approved === "declined";
                const someSectionDeclined = sections.some(section => section.declined);
                
                return declined && !someSectionDeclined;
            },
            then: Yup.string().required(t("studyApproval.selectDeclineField"))
        })
});

const singleSectionValidator = t => Yup.object({
    type: Yup.string().required(),
    approved: Yup.string().required(t("studyApproval.selectApproveOrDecline")),
    feedback: Yup.string()
        .when("approved", {
            is: "declined",
            then: Yup.string().required(t("studyApproval.provideFeedback"))
        })
})

export default function ApprovalForm({studyId, children}) {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    function handleSubmit(values) {
        if (Object.values(values).every(value => value.approved === "approved")) {
            dispatch(approveStudy(studyId));
        } else {
            dispatch(rejectStudy(studyId, values));
        }
    }

    return (
        <Formik
            initialValues={{
                studyInfo: defaultValue([
                    "titleText",
                    "summaryText",
                    "recruitmentGoalText",
                    "categoryText",
                    "imageText"
                ]),
                ethicsApproval: defaultValue([
                    "funderLabel",
                    "letterLabel"
                ]),
                timelines: defaultValue([
                    "recruitmentLabel",
                    "endDateLabel",
                    "resultsDateLabel"
                ]),
                location: defaultValue(),
                commitment: defaultValue([
                    "visitsLabel",
                    "durationLabel",
                    "frequencyLabel",
                    "requirementsLabel"
                ]),
                personalData: defaultValue(),
                compensation: defaultValue(),
                eligibility: defaultValue(),
                studyTeam: defaultValue([
                    "principalInvestigatorLabel",
                    "researchersLabel",
                    "coinvestigatorsLabel"
                ]),
            }}
            validationSchema={Yup.object({
                studyInfo: multipleSectionValidator(t),
                ethicsApproval: multipleSectionValidator(t),
                timelines: multipleSectionValidator(t),
                location: singleSectionValidator(t),
                commitment: multipleSectionValidator(t),
                personalData: singleSectionValidator(t),
                compensation: singleSectionValidator(t),
                eligibility: singleSectionValidator(t),
                studyTeam: multipleSectionValidator(t)
            })}
            onSubmit={handleSubmit}
        >
            <Form>
                {children}
            </Form>
        </Formik>
    );
}