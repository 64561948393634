import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Grid, Typography, FormControlLabel, SvgIcon } from '@material-ui/core';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import {Field } from 'formik';
import { Checkbox } from 'formik-material-ui';
import MultilineTextBox from 'Components/Parts/Form/TextBoxes/MultilineTextBox';
import {MinorErrorMessage} from 'Components/Styles/Form/ErrorMessage';

const DeclineIcon = styled(SvgIcon)`
    fill: rgb(223, 21, 66);
`;

export default function DeclineSection({field}) {
    const { t } = useTranslation();
    const headers = field.value.sections;

    if (headers) {
        return <MultipleSections name={`${field.name}`} headers={headers} t={t}/>;
    }
    
    return <DeclineDetails name={`${field.name}.feedback`} t={t}/>;
}

function MultipleSections({name, headers, t}) {
    return (
        <Grid container direction="column">
            <Typography>{t("studyApproval.declineTitle")}</Typography>
            {headers.map(({heading, declined}, i) => {
                return (
                    <Grid item key={i}>
                        <FormControlLabel
                            control={<Field
                                type="checkbox"
                                component={Checkbox}
                                color="primary" 
                                checkedIcon={<DeclineIcon component={CheckBoxOutlinedIcon}/>}
                                name={`${name}.sections[${i}].declined`}
                            />}
                            label={t(`studySetupReview.${heading}`)}
                        />
                        {declined && <DeclineDetails name={`${name}.sections[${i}].feedback`} t={t}/>}
                    </Grid>
                );
            })}
            <MinorErrorMessage name={`${name}.sectionsComplete`}/>
        </Grid>
    );
}

function DeclineDetails({name, t}) {
    return (
        <Grid container direction="column">
            <Typography>{t("studyApproval.declineReason")}</Typography>
            <Field
                component={MultilineTextBox} 
                name={name}
                type="text"
                placeholder={t('studyApproval.declinePlaceholder')}
                displayRemainingLength={false}
                maxLength={500}
                variant="filled"
            />
        </Grid>
    );
}