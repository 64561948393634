import React from 'react';
import { Grid, Typography, SvgIcon } from '@material-ui/core';
import { ReactComponent as ConfirmedIcon } from 'resources/images/icons/checkCircleOutlineIcon.svg';
import { ReactComponent as CrossCircledIcon } from 'resources/images/icons/crossCircledOutline.svg';

function SuccessText({message, color, spacing}) {
    return (
        <Grid item container xs alignItems="flex-start" wrap="nowrap">
            <SvgIcon component={ConfirmedIcon} viewBox="0 0 32 32" color="secondary" style={{fontSize: '18px', marginTop: '2px', marginLeft: '0.75em', marginRight: `${spacing}em`}}/>
            <Typography style={{fontSize: '14px', color}} display="inline">{message}</Typography>
        </Grid>
    );
} 

SuccessText.defaultProps = {
    color: "#000",
    spacing: 0,
};

function FailureText({message, spacing}) {
    return (
        <Grid item container xs alignItems="flex-start" wrap="nowrap">
            <SvgIcon component={CrossCircledIcon} color="error" style={{fontSize: '18px', marginTop: '2px', marginLeft: '0.75em', marginRight: `${spacing}em`}}/>
            <Typography color="error" style={{fontSize: '14px'}} display="inline">{message}</Typography>
        </Grid>
    );
} 

FailureText.defaultProps = {
    spacing: 0,
};

export { SuccessText, FailureText };