import React from 'react';
import { FormControlLabel, Typography } from '@material-ui/core';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { useTranslation } from 'react-i18next';

export default function QuestionInteger({name, prefix, mask}) {
    const { t } = useTranslation();

    return (
        <FormControlLabel
            control={<Field
                component={TextField} 
                InputProps={{inputComponent: mask}}
                name={name}
            />}
            label={<Typography variant="body2" color="textSecondary">{t(prefix)}</Typography>}
            labelPlacement="bottom"
        />
    );
}