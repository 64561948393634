export const eligibilityBaseItem = coding => ({
    serverCoding: coding,
    answer: "",
    inclusion: ""
});

export const eligibilityMultiSelectItem = coding => ({
    serverCoding: coding,
    answer: "",
    singleOption: "",
    options: [],
    inclusion: ""
});

export const eligibilityNumericItem = coding => ({
    serverCoding: coding,
    answer: "",
    min: "",
    max: "",
    inclusion: ""
});

export const initialValues = {
    studyInfo: {
        title: "",
        summary: "",
        category: [],
        recruitment: "",
        image: undefined,
    },
    studyTeam: {
        principalInvestigator: {
            name: "",
            email: "",
            signupComplete: false
        },
        coinvestigators: [{
            name: "",
            email: "",
            signupComplete: false
        }],
        researchers: [{
            name: "",
            email: "",
            signupComplete: false
        }],
    },
    ethicsApproval: {
        funder: "",
        letter: undefined,
    },
    timelines: {
        recruitStart: null,
        recruitEnd: null,
        studyEnd: null,
        resultsAvailable: null,
    },
    location: {
        inClinic: false,
        online: false,
        homeVisit: false,
        clinics: [],
        typeSelected: "",
    },
    commitment: {
        visits: "",
        visitDuration: "",
        visitFrequency: "",
        visitRequirements: [],
    },
    compensation: {
        types: [],
        typeOther: false,
        typeOtherDescription: "",
    },
    personalData: [],
    eligibility: {
        demographics: {
            age: eligibilityNumericItem("BIRTHDATE"),
            ethnicity: eligibilityMultiSelectItem("ETHNICITY"),
            sex: eligibilityBaseItem("BIRTHSEX"),
            genetics: eligibilityBaseItem("TWIN"),
        },
        condition: {
            diagnosis: eligibilityNumericItem("DIAGNOSIS"),
            brittle: eligibilityBaseItem("BRITTLE")
        },
        lifestyle: {
            activity: eligibilityBaseItem("EXERCISE")
        },
        monitoring: {
            monitor: eligibilityMultiSelectItem("MONITOR")
        },
        insulin: {
            device: eligibilityMultiSelectItem("DEVICES"),
            duration: eligibilityBaseItem("INSULIN_DURATION")
        },
        advanced: {
            hybrid: eligibilityBaseItem("HYBRID_SYSTEM")
        },
        medication: {
            diabetes: eligibilityBaseItem("MEDICATION"),
            immunosuppressant: eligibilityBaseItem("IMMUNOSUPPRESSANT")
        },
        a1c: {
            value: eligibilityNumericItem("A1C_VALUE"),
            timeframe: eligibilityBaseItem("A1C_TIME")
        },
        ketoacidosis: {
            ketoepisode: eligibilityBaseItem("KETO"),
            assistance: eligibilityBaseItem("KETO_HOSPITAL")
        },
        hypoglycemia: {
            hypoepisode: eligibilityBaseItem("HYPOGLYCEMIA"),
            assistance: eligibilityBaseItem("HYPOGLYCEMIA_HOSPITAL")
        },
        pregnancy: {
            pregnant: eligibilityBaseItem("PREGNANCY"),
            breastfeeding: eligibilityBaseItem("BREASTFEEDING"),
            attempting: eligibilityBaseItem("TRY_PREGNANCY")
        },
        secondary: {
            nephropathy: eligibilityBaseItem("SECONDARY_NEPHRO"),
            neuropathy: eligibilityBaseItem("SECONDARY_NEURO"),
            retinopathy: eligibilityBaseItem("SECONDARY_RETINO")
        },
        conditions: {
            celiac: eligibilityBaseItem("CONDITIONS_CELIAC"),
            footUlcers: eligibilityBaseItem("CONDITIONS_FOOTULCER"),
            gastroporesis: eligibilityBaseItem("CONDITIONS_GASTRO"),
            heartDisease: eligibilityBaseItem("CONDITIONS_HEART"),
            hepatitis: eligibilityBaseItem("CONDITIONS_HEPATITIS"),
            hypertension: eligibilityBaseItem("CONDITIONS_HYPERT"),
            hypotension: eligibilityBaseItem("CONDITIONS_HYPOT"),
            liver: eligibilityBaseItem("CONDITIONS_LIVER"),
            psychiatric: eligibilityBaseItem("CONDITIONS_PSYCH"),
        },
        customCategory: {
            categories: [],
        },
        selectedOptions: "",
    },
};