import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ModalPaper } from 'Components/Styles/Modals/ModalContainers';

import BaseModal from '../BaseModal';
import { ReactComponent as PencilIcon } from 'resources/images/icons/pencilOutline.svg';
import { FixedSizeIcon } from 'Components/Styles/Modals/Questionnaire';
import { TitleText, ModalButton } from 'Components/Styles/Modals/Components';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { resetContents } from 'redux/reducers/questionnaires/actions';

export default function IntroductionQuestionnaireModal({open, studyId, handleClose}) {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();

    function handleCancel() {
        history.push(`/study/${studyId}`);
        dispatch(resetContents);
    }

    return (
        <BaseModal
            aria-describedby="baseline-questionnaire-complete-modal-description"
            aria-labelledby="baseline-questionnaire-complete-modal-title"
            onClose={handleClose}
            open={open}
        >
            <ModalPaper>
                <Grid container direction="column" alignItems="center" spacing={2}>
                    <Grid item>
                        <FixedSizeIcon component={PencilIcon} color="primary" />
                    </Grid>
                    <Grid item>
                        <TitleText>{t('questionnaire.aFewQuestionsTitle')}</TitleText>
                    </Grid>
                    <Grid item>
                        <Typography variant="body2" color="textSecondary" align="center" dangerouslySetInnerHTML={{__html: t('questionnaire.aFewQuestionsDescription')}} />
                    </Grid>
                    <ModalButton variant="outlined" color="primary" size="small" onClick={handleCancel}>{t('questionnaire.cancelQuestionnaire')}</ModalButton>
                    <ModalButton variant="contained" color="primary" size="small" onClick={handleClose}>{t('questionnaire.beginNow')}</ModalButton>
                </Grid>
            </ModalPaper>
        </BaseModal>
    );
}